import React from 'react';
import { capitalizeFirstLetter } from '../../common';
import { SmallTextMuted } from '../../components/ui';
import { WorkflowHealth } from '../../workflows/models/WorkflowModel';
import { Dot, Stat } from './workflow-health.styled-components';

interface Props {
	numWorkflows: number;
	status: WorkflowHealth;
}

const WorkflowHealthIndicator = (props: Props) => (
	<>
		<Stat>
			<span>{props.numWorkflows}</span>
			<Dot className={props.status} />
		</Stat>
		<SmallTextMuted className="mt-2">
			{capitalizeFirstLetter(props.status)}
		</SmallTextMuted>
	</>
);

export default WorkflowHealthIndicator;

import {
	applyPatch,
	applySnapshot,
	getSnapshot,
	Instance,
	types,
} from 'mobx-state-tree';
import { MetadataTemplate } from '../../metadata-templates/models/EntityMetadataTemplateModel';
import { stringMatcherFor } from '../../common/string.utils';
import { values } from 'mobx';

export const EntityMetadataModel = types
	.model('EntityMetadata', {
		fields: types.array(types.string),
		values: types.optional(types.map(types.string), {}),
		tags: types.optional(types.array(types.string), []),
	})
	.actions((self) => ({
		addTag(tag: string) {
			self.tags.push(tag);
		},
		removeTag(tag: string) {
			const tagIndex = self.tags.indexOf(tag);

			applyPatch(self, {
				path: '/tags/' + tagIndex,
				op: 'remove',
			});
		},
		setKvPair(key: string, value: string): void {
			if (!self.fields.includes(key)) {
				self.fields.push(key);
			}
			self.values.set(key, value);
		},
		removeField(field: string) {
			const fieldIndex = self.fields.indexOf(field);

			applyPatch(self, {
				path: '/fields/' + fieldIndex,
				op: 'remove',
			});

			// also removes from the values object
			self.values.delete(field);
		},
	}))
	.actions((self) => ({
		applyTemplate(metadataTemplate: MetadataTemplate) {
			applySnapshot(self, getSnapshot(metadataTemplate));
			return self;
		},
		applyTemplateMetadata(templateMetadata: EntityMetadata) {
			applySnapshot(self, getSnapshot(templateMetadata));
			return self;
		},
	}))
	.views((self) => ({
		includesMentionOf(value: string): boolean {
			if (!value) {
				return false;
			}
			const matcher = stringMatcherFor(value);
			return (
				self.fields.some(matcher) ||
				values(self.values).some(matcher) ||
				self.tags.some(matcher)
			);
		},
	}));

export interface EntityMetadata extends Instance<typeof EntityMetadataModel> {}

import { flow, Instance } from 'mobx-state-tree';

import { getId, includesCaseInsensitive } from '../../common';
import { makeEntityStore } from '../../models/EntityStore.model';
import { Multimap } from '../../common/Multimap';
import { User } from '../../accounts/models/UserModel';

import { AssetVersion } from './AssetVersionModel';
import { $loadingDamAsset, DamAsset, DamAssetModel } from './DamAssetModel';
import { getClient } from '../../core';

const AssetStoreInferred = makeEntityStore(
	DamAssetModel,
	'assets',
	$loadingDamAsset
)
	.views((self) => ({
		get assetsByWorkflow() {
			return self.all.reduce(
				(acc: Multimap<string, DamAsset>, asset: DamAsset) => {
					if (asset.workflowId) {
						acc.add(getId(asset.workflowId), asset);
					}
					return acc;
				},
				new Multimap<string, DamAsset>()
			);
		},
		getDAMAssetWithAssetVersion(assetVersion: AssetVersion): Maybe<DamAsset> {
			let response: Maybe<DamAsset>;
			this.assetsByWorkflow.forEach((damAsset) => {
				damAsset.forEach((asset, index) => {
					if (asset.versionId && asset.versionId._id === assetVersion._id) {
						response = damAsset[index];
					}
				});
			});
			return response;
		},
	}))
	.actions((self) => ({
		share: flow(function* share(
			link: string,
			target: readonly User[] | string
		) {
			const client = getClient(self);

			if (typeof target === 'string') {
				// share by email
				// TODO: rrr-192
				yield client.get(
					`/assets/share/email/${encodeURIComponent(
						target
					)}/${encodeURIComponent(link)}`
				);
				return true;
			} else {
				// share by notification
				// TODO: rrr-194
				yield client.get(`/assets/share/notify/${encodeURIComponent(link)}`);
				return true;
			}
		}),
	}));
export interface AssetStoreModel extends Infer<typeof AssetStoreInferred> {}

export const AssetStoreModel: AssetStoreModel = AssetStoreInferred;

export interface AssetStore extends Instance<AssetStoreModel> {}

export const doesAssetMatch = (input: string | null, a: AssetVersion) =>
	includesCaseInsensitive(a.fileName, input);

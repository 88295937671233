import { types } from 'mobx-state-tree';
import { nonEmptyStringWithDefault } from '../../models/common';

const ThemeImagesModel = types.model(
	'ThemeImages',
	{
		desktopLogo: nonEmptyStringWithDefault('/themes/default/logo-desktop.svg'),
		mobileLogo: nonEmptyStringWithDefault('/themes/default/logo-mobile.svg'),
		loginBackground: nonEmptyStringWithDefault('/themes/default/login-bg.jpg'),
	},
);

export default ThemeImagesModel;

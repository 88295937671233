import { AssetCollection } from '../dam-asset-collections/models/AssetCollectionModel';
import { WorkflowCollection } from '../workflow-collections/models/WorkflowCollectionModel';
import { Workflow } from '../workflows/models';

export enum Filter {
	ARCHIVED = 'archived',
	COLLABORATING = 'collaborating',
	CREATED_BY = 'createdBy',
	FOLLOWING = 'following',
	OWNED = 'owned',
}

export enum WFilter {
	ACTIVE = 'active',
	CANCELLED = 'cancelled',
	COLLABORATING = 'collaborating',
	COMPLETED = 'completed',
	CREATED_BY = 'createdBy',
	FOLLOWING = 'following',
	OWNED = 'owned',
	PAUSED = 'paused',
}

// workflows
export type WorkflowFilter = Maybe<WFilter | Predicate<Workflow>>;
export const builtinWorkflowPredicates: Record<WFilter, Predicate<Workflow>> = {
	[WFilter.COLLABORATING]: (w) => w.isCurrentUserInvolved,

	[WFilter.CREATED_BY]: (w) => w.isCreatedByCurrentUser,

	// TODO: obtain workflow collections which current user follows (RRR-147)
	[WFilter.FOLLOWING]: (x) => true,

	[WFilter.OWNED]: (w) => w.isOwnedByCurrentUser,
	[WFilter.PAUSED]: (w) => w.isPaused,
	[WFilter.CANCELLED]: (w) => w.isCancelled,
	[WFilter.COMPLETED]: (w) => w.isCompleted,
	[WFilter.ACTIVE]: (w) => w.isActive,
};

// asset collections
export type AssetCollectionFilter = Maybe<Filter | Predicate<AssetCollection>>;
export const builtInAssetCollectionPredicates: Record<
	Filter,
	Predicate<AssetCollection>
> = {
	// TODO: obtain archived asset collections (RRR-125)
	[Filter.ARCHIVED]: (c) => true,

	[Filter.COLLABORATING]: (c) => c.isCurrentUserInvolved,

	[Filter.CREATED_BY]: (c) => c.isCreatedByCurrentUser,

	// TODO: obtain asset collections which current user follows (RRR-128)
	[Filter.FOLLOWING]: (c) => true,

	[Filter.OWNED]: (c) => c.isOwnedByCurrentUser,
};

// workflow collections (projects)
export type WorkflowCollectionFilter = Maybe<
	Filter | Predicate<WorkflowCollection>
>;
export const builtInWorkflowCollectionPredicates: Record<
	Filter,
	Predicate<WorkflowCollection>
> = {
	// TODO: obtain archived collections (RRR-130)
	[Filter.ARCHIVED]: (c) => true,

	[Filter.COLLABORATING]: (c) => c.isCurrentUserInvolved,

	[Filter.CREATED_BY]: (c) => c.isCreatedByCurrentUser,

	// TODO: obtain collections which current user follows (RRR-131)
	[Filter.FOLLOWING]: (c) => true,

	[Filter.OWNED]: (c) => c.isOwnedByCurrentUser,
};

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { CalendarTileProperties } from 'react-calendar';
import { observer } from 'mobx-react';
import moment from 'moment';

import { useWorkflowStore } from '../../stores';

import TooltipWorkflowsDueList from './tooltip-workflows-due-list.component';


// component
const WorkflowDueDatesCalendarTile = observer((props: CalendarTileProperties) => {
	const workflowStore = useWorkflowStore();
	const workflows = workflowStore.getWorkflowsWithDueDate(props.date);

	const tileId = 'dueDateTile' + moment(props.date).format('YYYYMMDD');

	const render = () => (
		<div className="workflow-due-dates-calendar-tile">
			{
				(workflows.length > 0)
				? <div id={tileId} className="has-workflow-marker">
						<div className="marker-label">&#9679;</div>
						<UncontrolledTooltip
							target={tileId}
						>
							<TooltipWorkflowsDueList
								workflows={workflows}
							/>
						</UncontrolledTooltip>
					</div>
				: null
			}
		</div>
	);

	return render();
});

export default WorkflowDueDatesCalendarTile;

import React from 'react';
import styled from 'styled-components';

import { Workflow } from '../../workflows/models/WorkflowModel';

// styled components
const StyledList = styled.ul`
	margin: 0;
	padding: 1em 1.5em;
	text-align: left;
`;

// interface
interface TooltipWorkflowsDueListProps {
	workflows: ReadonlyArray<Workflow>;
}

// components
const TooltipWorkflowsDueList = (props: TooltipWorkflowsDueListProps) => {
	const render = () => (
		<StyledList className="tooltip-workflows-due-list">
			{props.workflows.map((workflow) => (
				<li key={workflow._id} className="list-item">
					{workflow.title}
				</li>
			))}
		</StyledList>
	);

	return render();
};

export default TooltipWorkflowsDueList;

import {
	getParentOfType,
	Instance,
	IStateTreeNode,
	OnReferenceInvalidatedEvent,
	types,
} from 'mobx-state-tree';

import { InstanceReference } from '../../models/common';
import {
	BaseWorkflowTransitionModel,
	TransitionType,
} from '../../models/BaseWorkflowTransitionModel';

import {
	TemplateRootStage,
	TemplateRootStageModel,
	TemplateStage,
	TemplateStageModel,
	TemplateSubstage,
	TemplateSubstageModel,
} from './TemplateStageModel';

const handleDeletion = {
	onInvalidated<T extends TemplateStage>(
		event: OnReferenceInvalidatedEvent<T>
	) {
		const invalidTransition = event.parent as TemplateTransition;
		const parentStage = getParentOfType(invalidTransition, TemplateStageModel);
		parentStage.removeTransition(invalidTransition);
	},
};

const TemplateRootStageReference: InstanceReference<TemplateRootStage> = types.late(
	(): InstanceReference<TemplateRootStage> =>
		types.reference(TemplateRootStageModel, handleDeletion)
);

const TemplateSubstageReference: InstanceReference<TemplateSubstage> = types.late(
	(): InstanceReference<TemplateSubstage> =>
		types.reference(TemplateSubstageModel, handleDeletion)
);

const TemplateForwardRootTransitionModel = BaseWorkflowTransitionModel.named(
	'ForwardRootTransition'
)
	.props({
		type: types.literal(TransitionType.forward),
		targetStage: TemplateRootStageReference as InstanceReference<
			TemplateRootStage
		>,
	})
	.views((self) => ({
		get sourceStage(): TemplateRootStage {
			return getParentOfType(self, TemplateRootStageModel);
		},
	}));

const TemplateBackwardRootTransitionModel = BaseWorkflowTransitionModel.named(
	'BackwardRootTransition'
)
	.props({
		type: types.literal(TransitionType.backward),
		targetStage: TemplateRootStageReference as InstanceReference<
			TemplateRootStage
		>,
	})
	.views((self) => ({
		get sourceStage(): TemplateStage {
			return getParentOfType(self, TemplateStageModel);
		},
	}));

const TemplateForwardSubstageTransitionModel = BaseWorkflowTransitionModel.named(
	'ForwardSubstageTransition'
)
	.props({
		type: types.literal(TransitionType.forward),
		targetStage: TemplateSubstageReference as InstanceReference<
			TemplateSubstage
		>,
	})
	.views((self) => ({
		get sourceStage(): TemplateSubstage {
			return getParentOfType(self, TemplateSubstageModel);
		},
	}));

export const TemplateTransitionModel = types.union(
	TemplateBackwardRootTransitionModel,
	TemplateForwardRootTransitionModel,
	TemplateForwardSubstageTransitionModel
);

export const TemplateRootTransitionModel = types.union(
	TemplateBackwardRootTransitionModel,
	TemplateForwardRootTransitionModel
);

export const TemplateSubstageTransitionModel = types.union(
	TemplateBackwardRootTransitionModel,
	TemplateForwardSubstageTransitionModel
);

interface TemplateBackwardRootTransition
	extends Instance<Infer<typeof TemplateBackwardRootTransitionModel>> {}
interface TemplateForwardRootTransition
	extends Instance<Infer<typeof TemplateForwardRootTransitionModel>> {}
export interface TemplateForwardSubstageTransition
	extends Instance<Infer<typeof TemplateForwardSubstageTransitionModel>> {}

export interface TemplateTransitionModel
	extends Infer<typeof TemplateTransitionModel> {}

export type TemplateRootTransition =
	| TemplateBackwardRootTransition
	| TemplateForwardRootTransition;
export type TemplateSubstageTransition =
	| TemplateBackwardRootTransition
	| TemplateForwardSubstageTransition;
export type TemplateTransition =
	| TemplateRootTransition
	| TemplateForwardSubstageTransition;
export type TemplateForwardTransition =
	| TemplateForwardRootTransition
	| TemplateForwardSubstageTransition;

export interface TransitionWithTarget<T extends TemplateStage>
	extends Omit<TemplateTransition, 'targetStage'> {
	targetStage: T & IStateTreeNode<InstanceReference<T>>;
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import BackLink from '../components/back-link/back-link.component';
import { Heading } from '../components/ui';
import EntityMetadataTemplateAddForm from './components/entity-metadata-template-add-form.component';


const MetadataTemplateCreationView = () => (
	<Container>
		<Row className="justify-content-center">
			<Col lg={ 8 }>
				<BackLink link=".."
						  title="Metadata Templates"
				/>
				<div className="d-flex justify-content-between">
					<Heading>
						Create a template
					</Heading>
				</div>
			</Col>
			<Col lg={ 8 }>
				<EntityMetadataTemplateAddForm />
			</Col>
		</Row>
	</Container>
);

export default MetadataTemplateCreationView;

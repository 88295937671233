import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const OwnerButton = styled.button`
	display: flex;
	border-radius: 4px;
	border: 1px solid transparent;
	pointer: cursor;
	align-items: center;

	&:hover {
		border: 1px solid ${colors.darkGrey};
		background-color: ${colors.lightestGrey};
		transition: all 0.25s;
	}
`;

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React from 'react';
import { Form } from 'reactstrap';
import { SecondaryButton } from '../../components/buttons.styled-components';
import { isInputStage, isStageActionable } from '../models';
import { SelectedTemplateStage } from '../template.contexts';
import { AddInputButtonContainer } from './template-stage-edit-form.styled-components';
import { TemplateStageInputSlotEditor } from './template-stage-edit-slots';

const InputSlotForm = () => {
	const currentStage = SelectedTemplateStage.presentValue;

	const addInputSlot = () => {
		if (isStageActionable(currentStage) && currentStage.addInputSlot) {
			currentStage.addInputSlot();
		}
	};

	const renderInputSlots = () => {
		if (isInputStage(currentStage)) {
			return currentStage.inputSlots.map((slot) => (
				<TemplateStageInputSlotEditor key={slot._id} slot={slot} />
			));
		} else {
			return null;
		}
	};

	return useObserver(() => (
		<Form>
			{renderInputSlots()}

			<AddInputButtonContainer>
				<SecondaryButton
					className="add-slot-button"
					type="button"
					onClick={addInputSlot}
				>
					<FontAwesomeIcon className="mr-1" icon={faPlus} /> Add input field
				</SecondaryButton>
			</AddInputButtonContainer>
		</Form>
	));
};

export default InputSlotForm;

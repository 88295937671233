import { Link } from '@reach/router';
import { NavItem } from 'reactstrap';
import styled, { StyledComponent } from 'styled-components';

import anchor from '../../theme/components/anchor';
import { secondaryButton } from '../../theme/components/secondary-button';

export const CreateWorkflowButton = styled(Link)`
	${secondaryButton};
	margin: 1.5em 0;
`;

export const FilterNavItem = styled(NavItem)`
	margin-top: 1em;

	&:first-of-type {
		margin-top: 0;
	}
`;

export const FilterNavButton: StyledComponent<'button', {}> = styled.button`
	${anchor};
`;

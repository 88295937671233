import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import {
	Card,
	DropdownMenu,
	DropdownToggle,
	Table,
	UncontrolledDropdown,
} from 'reactstrap';

import { Cell } from '../../../components/tables.styled-components';
import { DropDownMenuItem } from '../../../components/drop-down-menu.component';
import { GroupAvatarLg } from '../../../components/group-avatar.component';
import { useGroupStore } from '../../../stores';
import { useModalCreator } from '../../../stores/ModalStack';
import { UserGroup } from '../../../accounts/models/UserGroupModel';
import ConfirmationDialog from '../../../components/modals/confirmation-dialog.component';

import EditGroupDialog from './edit-group-dialog.component';

const UserGroupRow = ({ group }: { group: UserGroup }) => {
	const userGroupStore = useGroupStore();
	const modalStack = useModalCreator();

	// callbacks
	const showDeletionConfirmation = useCallback(
		(group: UserGroup) => {
			modalStack.addModal(
				<ConfirmationDialog
					header="Delete user group"
					onConfirm={() => userGroupStore.deleteUserGroup(group)}
				>
					<p>Are you sure you want to delete the group "{group.title}"?</p>
					<p>This action will not delete the users, only the group.</p>
				</ConfirmationDialog>
			);
		},
		[userGroupStore, modalStack]
	);

	const showEditGroupModal = useCallback(
		(group: UserGroup) =>
			modalStack.addModal(<EditGroupDialog selectedGroup={group} />),
		[modalStack]
	);

	return useObserver(() => (
		<tr>
			<Cell>{group.title}</Cell>
			<Cell>
				<GroupAvatarLg group={group} />
			</Cell>
			<Cell>{group.numUsers}</Cell>
			<td>
				<UncontrolledDropdown>
					<DropdownToggle color="white">
						<FontAwesomeIcon icon={faEllipsisV} />
					</DropdownToggle>
					<DropdownMenu>
						<DropDownMenuItem onClick={() => showEditGroupModal(group)}>
							Edit user group
						</DropDownMenuItem>
						<DropDownMenuItem
							className="danger"
							onClick={() => showDeletionConfirmation(group)}
						>
							Delete user group
						</DropDownMenuItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</td>
		</tr>
	));
};

const noUserGroup = () => (
	<tr>
		<Cell>No user groups yet</Cell>
	</tr>
);

const UserGroupsTable = () => {
	const userGroupStore = useGroupStore();

	const renderTable = useObserver(() => (
		<Table>
			<thead>
				<tr>
					<th>Name</th>
					<th></th>
					<th># of users</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{userGroupStore.allUserGroups.length
					? userGroupStore.allUserGroups.map((g) => (
							<UserGroupRow key={g._id} group={g} />
					  ))
					: noUserGroup()}
			</tbody>
		</Table>
	));

	return <Card className="h-100">{renderTable}</Card>;
};

export default UserGroupsTable;

import { Instance, types } from 'mobx-state-tree';
import { AssetCollection } from '../dam-asset-collections/models/AssetCollectionModel';

import { getStores } from '../stores';
import { WorkflowCollection } from '../workflow-collections/models/WorkflowCollectionModel';
import { isAssignable, Workflow, WorkflowStage } from '../workflows/models';
import { isRoleAtLeast, UserRole } from '../accounts/models/UserRole.model';

const UserPermissionsModelInferred = types
	.model('Permissions')
	.props({})
	.views((self) => ({
		get currentUser() {
			return getStores(self).users.currentUser;
		},
	}))
	// user global state
	.views((self) => ({
		get currentRole(): UserRole {
			return self.currentUser.role;
		},
		get isAdmin(): boolean {
			return self.currentUser.isAdmin;
		},
	}))
	// stage-related permissions
	.views((self) => ({
		get canViewTemplates(): boolean {
			return isRoleAtLeast(self.currentRole, UserRole.ProjectManager);
		},
		get canCreateWorkflow(): boolean {
			return isRoleAtLeast(self.currentRole, UserRole.ProjectManager);
		},
		get canCreateProject(): boolean {
			return isRoleAtLeast(self.currentRole, UserRole.ProjectManager);
		},
		canForceActivateStage(stage: WorkflowStage): boolean {
			const { isCreatedByCurrentUser } = stage.workflow as Workflow;
			return self.isAdmin || isCreatedByCurrentUser;
		},
		canBypassStage(stage: WorkflowStage): boolean {
			const { isCreatedByCurrentUser } = stage.workflow as Workflow;
			return self.isAdmin || isCreatedByCurrentUser;
		},
		canSetStageOwners(stage: WorkflowStage): boolean {
			return (
				isAssignable(stage) &&
				(self.isAdmin ||
					stage.isOwner(self.currentUser) ||
					stage.isInWorkflowCreatedByCurrentUser)
			);
		},
	}))
	// admin panel related permissions
	.views((self) => ({
		get canViewAdminPanel(): boolean {
			// TODO: confirm rules (RRR-142)
			return self.isAdmin;
		},
		get canViewReports(): boolean {
			return isRoleAtLeast(self.currentRole, UserRole.ProjectManager);
		},
		get canViewAccounts(): boolean {
			// TODO: confirm rules (RRR-143)
			return self.isAdmin;
		},
		get canViewUsers(): boolean {
			// TODO: confirm rules (RRR-144)
			return self.isAdmin;
		},
		get canViewUserGroups(): boolean {
			// TODO: confirm rules (RRR-145)
			return self.isAdmin;
		},
		get canViewThemes(): boolean {
			if (process.env.REACT_APP_ROME_THEME) {
				return false;
			}
			// TODO: confirm rules (RRR-146)
			return self.isAdmin;
		},
		get canViewMetadataTemplates(): boolean {
			// TODO: confirm rules (RRR-146)
			return self.isAdmin;
		},
		get canRefreshPreview(): boolean {
			return self.isAdmin;
		},
	}))
	// misc
	.views((self) => ({
		get canClearAllSavedQueries(): boolean {
			return self.isAdmin;
		},
		canEditWorkflowCollection(collection: WorkflowCollection): boolean {
			return self.isAdmin || collection.isOwner(self.currentUser);
		},
		canEditAssetCollection(collection: AssetCollection): boolean {
			return self.isAdmin || collection.isOwner(self.currentUser);
		},
	}));

export interface UserPermissionsModel
	extends Infer<typeof UserPermissionsModelInferred> {}

export const UserPermissionsModel: UserPermissionsModel = UserPermissionsModelInferred;

export interface UserPermissions extends Instance<UserPermissionsModel> {}

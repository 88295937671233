import { Col, Container, Row } from 'reactstrap';
import { useObserver } from 'mobx-react';
import React, { useState } from 'react';

import { Heading } from '../components/ui';
import { unwrapEvent } from '../common';
import { useDashboardStore } from '../stores';
import { WorkflowStage } from '../workflows/models';
import PageSubheading from '../components/page-subheading.component';
import SideBarFiltersNav from '../components/side-bar-filters/side-bar-filters-nav.component';
import themeStore from '../theme/models/ThemeStore';

import AssignmentsTable from './components/assignments-table.component';
import { compareStageStatus } from '../models/StageStatusModel';

const workflowTerm = themeStore._.workflow;

const filterNavOptions = [
	{
		label: 'All assignments',
		value: undefined,
	},
	{
		label: 'Active',
		value: 'active',
	},
	{
		label: 'Pipeline',
		value: 'queue',
	},
	{
		label: 'Completed',
		value: 'completed',
	},
];

const AssignmentsIndexView = () => {
	const dashboardStore = useDashboardStore();
	const [filter, setFilter] = useState();

	const render = () => {
		const ongoingStages = dashboardStore.recentWorkflows.flatMap(
			(wf) => wf.stagesOwnedByCurrentUser
		);

		const stagesResult: readonly WorkflowStage[] = filter
			? ongoingStages.filter(({ status }) => status === filter)
			: ongoingStages.sort(compareStageStatus);

		return (
			<Container>
				<Row>
					<Col md={3}>
						<PageSubheading text={workflowTerm} />
						<Heading>My Assignments</Heading>
						<SideBarFiltersNav
							setFilter={unwrapEvent(setFilter)}
							options={filterNavOptions}
						/>
					</Col>
					<Col md={9}>
						<AssignmentsTable stages={stagesResult} />
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default AssignmentsIndexView;

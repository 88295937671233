import { getId, lazyReference } from '../../common';
import { getStores } from '../../stores';
import { UserModel } from './UserModel';

export const UserReference = lazyReference({
	model: UserModel,
	getter(userId, parent) {
		const stores = getStores(parent);
		return stores.users.getOne(getId(userId));
	},
});

import React from 'react';
import { useDownshift } from './downshift-options.context';
import {
	ArrowIcon,
	DownshiftButton,
	XIcon,
} from './downshift-select.styled-components';
import { DeleteChipButton } from '../chip/chip.styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const DownshiftToggleButton = () => {
	const { downshift } = useDownshift();
	return (
		<DownshiftButton type="button" {...downshift.getToggleButtonProps()}>
			<ArrowIcon isOpen={downshift.isOpen} />
		</DownshiftButton>
	);
};

export const ClearSelectionButton = () => {
	const { downshift, select } = useDownshift();

	return (
		<DownshiftButton
			type="button"
			onClick={() => {
				select(undefined);
				downshift.reset({ isOpen: true, inputValue: '' });
			}}
		>
			<XIcon />
		</DownshiftButton>
	);
};

export const RemoveItemButton = <Itm extends Displayable>({
	item,
}: {
	item: Itm;
}) => {
	const { unselect } = useDownshift();

	if (typeof unselect !== 'function') {
		return null;
	}

	return (
		<DeleteChipButton onClick={() => unselect(item)}>
			<FontAwesomeIcon icon={faTimes} />
		</DeleteChipButton>
	);
};

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { navigate, RouteComponentProps } from '@reach/router';
import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import Moment from 'react-moment';
import { Col, Container, Row } from 'reactstrap';
import { isIdentifiableLoaded } from '../common/mobx.utils';
import BackLink from '../components/back-link/back-link.component';
import CircleButton from '../components/circle-button.component';
import CollectionActionDropdown from '../components/collection-action-dropdown';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../components/entity-details.styled-components';
import ConfirmationDialog from '../components/modals/confirmation-dialog.component';
import { Divider, Heading } from '../components/ui';
import EntityMetadataFields from '../metadata/components/entity-metadata-fields.component';
import NotificationModel from '../notifications/NotificationModel';
import notificationStore from '../notifications/NotificationStore';
import { usePermissions, useWorkflowCollectionStore } from '../stores';
import { useModalCreator } from '../stores/ModalStack';
import themeStore from '../theme/models/ThemeStore';
import WorkflowCard from '../workflows/components/workflow-card.component';
import EditCollectionWorkflowsDialog from './components/edit-workflow-collection-workflows-dialog.component';
import { WorkflowCollection } from './models/WorkflowCollectionModel';

// constants
const workflowTerm = themeStore._.workflow.toLowerCase();
const projectTerm = themeStore._.workflowCollection;

// interface
interface WorkflowCollectionDetailsProps extends RouteComponentProps {
	projectId: string;
}

// component
const WorkflowCollectionDetailsView = (
	props: WorkflowCollectionDetailsProps
) => {
	const { projectId } = props;

	const collectionStore = useWorkflowCollectionStore();
	const permissions = usePermissions();
	const modalStack = useModalCreator();

	const showEditWorkflowModal = useCallback(
		(collection: WorkflowCollection) =>
			modalStack.addModal(
				<EditCollectionWorkflowsDialog selectedCollection={collection} />
			),
		[modalStack]
	);

	const deleteCollection = useCallback(
		(collection: WorkflowCollection) => {
			collectionStore.deleteOne(collection);

			notificationStore.push(
				NotificationModel.create({
					text: `${projectTerm} deleted!`,
				})
			);

			return navigate(`/admin/workflow/projects`);
		},
		[collectionStore]
	);

	const showDeleteWorkflowModal = useCallback(
		(collection: WorkflowCollection) =>
			modalStack.addModal(
				<ConfirmationDialog
					header={`Delete "${collection.title}"`}
					onConfirm={() => deleteCollection(collection)}
				>
					<p>
						Are you sure you want to delete this {projectTerm.toLowerCase()}?
						This action cannot be undone.
					</p>
				</ConfirmationDialog>
			),
		[modalStack, deleteCollection]
	);

	const renderActionDropdown = (collection: WorkflowCollection) => {
		if (!permissions.canEditWorkflowCollection(collection)) {
			return null;
		}

		return (
			<CollectionActionDropdown
				collection={collection}
				collectionItems={collection.workflows}
				collectionTerm={projectTerm}
				editLink={`/admin/workflow/projects/${collection._id}/edit`}
				showDeleteModal={showDeleteWorkflowModal}
			/>
		);
	};

	const render = () => {
		const collection: Maybe<WorkflowCollection> = collectionStore.getOne(
			projectId
		);

		if (!isIdentifiableLoaded(collection)) {
			return null;
		}

		return (
			<Container>
				<Row>
					<Col
						xs={12}
						className="d-flex justify-content-between align-items-center"
					>
						<div>
							<BackLink link=".." title={`${projectTerm}s`} />
							<Heading>{collection.title}</Heading>
						</div>

						{renderActionDropdown(collection)}
					</Col>
					<Col xs={12}>
						<Divider />
					</Col>
					<Col md={3}>
						<EntityPropList className="mt-4">
							<EntityPropListItem>
								<EntityPropLabel>Created on</EntityPropLabel>
								<p>
									<Moment format="MMM DD, YYYY" date={collection.createdAt} />
								</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>Created by</EntityPropLabel>
								<p>{collection.createdBy.name}</p>
							</EntityPropListItem>
							<EntityMetadataFields metadata={collection.metadata} />
						</EntityPropList>
					</Col>
					<Col md={9}>
						<Row>
							{collection.workflows
								.filter(isIdentifiableLoaded)
								.map((workflow) => (
									<WorkflowCard key={workflow._id} workflow={workflow} />
								))}
							<Col
								lg={4}
								sm={6}
								className="mt-md-4 mt-5 d-flex justify-content-center align-items-center"
							>
								<CircleButton
									className="sm"
									icon={faPlus}
									onClick={() => showEditWorkflowModal(collection)}
									tooltip={`Add/remove ${workflowTerm}s`}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default WorkflowCollectionDetailsView;

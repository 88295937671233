import { AssetVersion } from '../models/AssetVersionModel';
import { APIClient } from '../../core/index';
import { useAPIClient } from '../../stores/index';
import windowModel from '../../models/WindowModel';
import { _logError } from '../../common/log';
import notificationStore from '../../notifications/NotificationStore';
import NotificationModel, {
	NotificationType,
} from '../../notifications/NotificationModel';

class ViewerService {
	private static readonly viewers = new WeakMap<APIClient, ViewerService>();

	public static getOrCreate(client: APIClient) {
		const cached = ViewerService.viewers.get(client);
		if (cached) {
			return cached;
		}
		const viewerService = new ViewerService(client);
		ViewerService.viewers.set(client, viewerService);
		return viewerService;
	}

	private constructor(private readonly client: APIClient) {}

	viewSingle = async (asset: AssetVersion) =>
		this.openViewerUrl(`assets/cf/view/${asset._id}`);

	compareTwo = async (assetA: AssetVersion, assetB: AssetVersion) =>
		this.openViewerUrl(`assets/cf/compare/${assetA._id}/${assetB._id}`);

	private openViewerUrl = async (url: string): Promise<void> => {
		try {
			windowModel.openInNewWindow(await this.client.get(url));
		} catch (err) {
			_logError(err);
			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: 'Unable to launch detailed viewer. Please try again later.',
				})
			);
		}
	};
}

export const useViewer = () => ViewerService.getOrCreate(useAPIClient());

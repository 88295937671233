import { range } from 'lodash';
import { useObserver } from 'mobx-react';
import React from 'react';
import { PaginationItem, PaginationLink } from 'reactstrap';
import { StyledPagination } from './store-paginator.styled-components';

export const StorePaginator = <T extends object>(props: {
	pages?: PagesState<T>;
	shouldPaginate?: boolean;
}) =>
	useObserver(() => {
		const { pages, shouldPaginate = true } = props;

		if (pages && shouldPaginate) {
			const { totalPages, currentPage } = pages;

			return (
				<StyledPagination aria-label="Asset index pagination">
					<PaginationItem>
						<PaginationLink
							previous
							onClick={() => pages.setCurrentPage(currentPage - 1)}
							disabled={currentPage === 1}
						/>
					</PaginationItem>

					{range(1, totalPages + 1).map((pageNumber) => (
						<PaginationItem
							key={pageNumber}
							active={pageNumber === currentPage}
						>
							<PaginationLink onClick={() => pages.setCurrentPage(pageNumber)}>
								{`${pageNumber}`}
							</PaginationLink>
						</PaginationItem>
					))}

					<PaginationItem>
						<PaginationLink
							next
							onClick={() => pages.setCurrentPage(currentPage + 1)}
							disabled={currentPage === totalPages}
						/>
					</PaginationItem>
				</StyledPagination>
			);
		}

		return null;
	});

import React, { MouseEventHandler } from 'react';
import { FilterNavButton, FilterNavItem } from './side-bar-filters-nav.styled-components';


interface SideBarFiltersNavItemProps {
	onClick: MouseEventHandler<HTMLButtonElement>;
	value: string | undefined;
	label: string;
	active: boolean;
}


const SideBarFilterNavItem = (props: SideBarFiltersNavItemProps) => {
	const { onClick, value, label, active } = props;

	return (
		<FilterNavItem>
			<FilterNavButton
				onClick={ onClick }
				value={ value }
				className={ active ? 'active' : '' }
			>
				{ label }
			</FilterNavButton>
		</FilterNavItem>
	);
};

export default SideBarFilterNavItem;


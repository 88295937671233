import React from 'react';
import { navigate } from '@reach/router';

import { _logWarning } from '../common/log';
import { AuthContext } from '../core/AuthProvider';
import Loading from '../components/loading.component';


const LoginCallback = () => {
	const auth = React.useContext(AuthContext);

	if (!auth.isAuthenticated) {
		auth.handleAuthentication()
			.then(() => {
				return navigate(`/admin`);
			})
			.catch(error => {
				_logWarning(error)
				return navigate(`/auth`, { state: { error } });
			});
	}

	return <Loading />;
};

export default LoginCallback;

import { clone } from 'mobx-state-tree';
import React, { useRef, useState } from 'react';
import { ModalBody } from 'reactstrap';
import { SelectedMetadata } from '../../../dam-assets/asset.contexts';
import { AssetUploadForm } from '../../../dam-assets/components/asset-upload-form.component';
import DialogModal from '../../../components/modals/dialog-modal.component';
import { useModalCloser } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';
import { WorkflowInputStage, WorkflowStageInputSlot } from '../../models';
import { SelectedInputSlot } from '../../workflow.contexts';

export interface UploadAssetDialogProps {
	stage: WorkflowInputStage;
	selectedSlot?: WorkflowStageInputSlot;
}

const UploadStageAssetDialog = (props: UploadAssetDialogProps) => {
	const { stage, selectedSlot } = props;

	const modalStack = useModalCloser();

	const [slot, setSlot] = useState<WorkflowStageInputSlot>(
		selectedSlot || stage.defaultInputSlot
	);

	const metadata = useRef(clone(slot.metadata));

	return (
		<DialogModal
			header={`Upload an ${themeStore._.asset.toLowerCase()} to "${
				stage.title
			}"`}
			modalSize="lg"
		>
			<SelectedInputSlot.Provider value={[slot, setSlot]}>
				<SelectedMetadata.Provider
					value={SelectedMetadata.asReadonly(metadata.current)}
				>
					<ModalBody>
						<AssetUploadForm afterSubmit={modalStack.closeModal} />
					</ModalBody>
				</SelectedMetadata.Provider>
			</SelectedInputSlot.Provider>
		</DialogModal>
	);
};

export default UploadStageAssetDialog;

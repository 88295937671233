import { useObserver } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { shouldShow } from '../../common';
import BackLink from '../../components/back-link/back-link.component';
import { Heading } from '../../components/ui';
import { TemplateStage, WorkflowTemplateSnapshotOut } from '../models';

import { SelectedTemplate, SelectedTemplateStage } from '../template.contexts';
import TemplateActions from './template-actions.component';
import TemplateEditorCanvas from './template-editor-canvas.component';
import TemplateEditorSidebar from './template-editor-sidebar';

import {
	LastActionLabel,
	Sidebar,
	TemplateHeader,
} from './template-editor.styled-components';
import TemplateStageEditForm from './template-stage-edit-form.component';
import { useTemplateStore } from '../../stores/index';
import debounce from 'lodash/debounce';
import { onSnapshot } from 'mobx-state-tree';

const AUTOSAVE_INTERVAL_MS = 1000;

const TemplateEditor = () => {
	const templateStore = useTemplateStore();
	const template = SelectedTemplate.presentValue;

	const [selectedStage, setSelectedStage] = useState<Maybe<TemplateStage>>(
		undefined
	);

	useEffect(() => {
		const autosaveTemplate = debounce(
			(snapshot: WorkflowTemplateSnapshotOut) => {
				if (snapshot.editingState === 'final') {
					// Remove the onSnapshot handler (defined below)
					// if template was finalized.
					disposer();
				}
				template.save(snapshot);
			},
			AUTOSAVE_INTERVAL_MS,
			{ leading: false, trailing: true }
		);
		const disposer = onSnapshot(template, (snapshot) => {
			template.setLastAction('Saving...');
			return autosaveTemplate(snapshot);
		});

		return disposer;
	}, [templateStore, template]);

	const render = () => (
		<SelectedTemplateStage.Provider value={[selectedStage, setSelectedStage]}>
			<Row className="p-0">
				<Col lg={9} md={8} className="p-0">
					<TemplateHeader>
						<div onClick={() => setSelectedStage(undefined)}>
							<BackLink link=".." title="Templates" />
							<Heading>{template.title}</Heading>
						</div>
						<div className="text-right pt-1">
							<LastActionLabel>{template.lastAction}</LastActionLabel>
							<TemplateActions template={template} />
						</div>
					</TemplateHeader>

					<TemplateEditorCanvas />
				</Col>
				<Sidebar lg={3} md={4} className="p-0">
					{shouldShow(selectedStage) ? (
						<TemplateStageEditForm />
					) : (
						<TemplateEditorSidebar />
					)}
				</Sidebar>
			</Row>
		</SelectedTemplateStage.Provider>
	);

	return useObserver(render);
};

export default TemplateEditor;

import { useObserver } from 'mobx-react';
import React from 'react';
import { Row } from 'reactstrap';

import { NoResultsWrapper } from '../../components/filters-list.styled-components';

import { WorkflowCollection } from '../models/WorkflowCollectionModel';

import WorkflowCollectionCard from './workflow-collection-card.component';

interface Props {
	collections?: ReadonlyArray<WorkflowCollection>;
}

const renderNoResults = () => (
	<Row>
		<NoResultsWrapper>
			<p>No results found</p>
		</NoResultsWrapper>
	</Row>
);

const FilteredWorkflowCollectionList = (props: Props) => {
	const renderCollectionList = (list: ReadonlyArray<WorkflowCollection>) =>
		list.map((collection) => (
			<WorkflowCollectionCard key={collection._id} collection={collection} />
		));

	const renderFilteredCollectionList = () => {
		const { collections } = props;
		return (
			<Row>
				{collections && collections.length
					? renderCollectionList(collections)
					: renderNoResults()}
			</Row>
		);
	};

	// component
	return useObserver(renderFilteredCollectionList);
};

export default FilteredWorkflowCollectionList;

import { useObserver } from 'mobx-react';
import React, { FormEvent } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { usePatchers } from '../../common';
import { unwrapEvent } from '../../common/hooks';
import { LabeledInput, SubmitButton } from '../../components/forms';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import themeStore from '../../theme/models/ThemeStore';
import { AssetCollection } from '../models/AssetCollectionModel';
import CollectionAssetSelect from './collection-asset-select.component';

interface AssetCollectionFormProps {
	selectedCollection: AssetCollection;
	onSubmit: (event: FormEvent) => Promise<unknown>;
}

const AssetCollectionForm = (props: AssetCollectionFormProps) => {
	const { selectedCollection, onSubmit } = props;
	const propertyPatcher = usePatchers(selectedCollection);

	const isFormValid = () => {
		return selectedCollection.title !== '';
	};

	return useObserver(() => (
		<Form>
			<Row form>
				<Col md={12}>
					<LabeledInput
						label="Title*"
						type="text"
						name="title"
						id="title"
						value={selectedCollection.title}
						onChange={unwrapEvent(propertyPatcher('title'))}
						required
						inputValid={selectedCollection.title !== ''}
						errorMsg="Title is required"
					/>
				</Col>
				<Col>
					<FormGroup>
						<CollectionAssetSelect collection={selectedCollection} />
					</FormGroup>
				</Col>
			</Row>

			<EntityMetadataForm metadata={selectedCollection.metadata} />

			<SubmitButton
				onClick={onSubmit}
				label={`Save ${themeStore._.assetCollection.toLowerCase()}`}
				disabled={!isFormValid()}
			/>
		</Form>
	));
};

export default AssetCollectionForm;

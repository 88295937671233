import { Link } from '@reach/router';
import styled from 'styled-components';
import { secondaryButton } from '../../theme/components/secondary-button';

export const CreateTemplateButton = styled(Link)`
	${secondaryButton};
	margin-bottom: 1em;

	@media only screen and (min-width: 768px) {
		margin-bottom: 0;
	}
`;

import { Instance, types } from 'mobx-state-tree';
import { loadingValue, throwErr } from '../../common';
import { getAuthProvider, getClient } from '../../core';
import { makeEntityStore } from '../../models/EntityStore.model';
import { User, UserModel, UserSnapshotIn } from './UserModel';
import { _logError } from '../../common/log';
import defaultAvatar from '../../assets/images/avatars/default-avatar.png';
import notificationStore from '../../notifications/NotificationStore';
import debounce from 'lodash/debounce';

// prettier-ignore
const UserStoreModelInferred = makeEntityStore<UserModel, User, UserSnapshotIn>(
	UserModel,
	'users',
	{
		_id: loadingValue,
		givenName: ' ',
		familyName: ' ',
		email: 'loading@romeportal.com',
		picture: defaultAvatar,
		createdAt: new Date(),
	},
)
	.props({
		_currentUser: types.safeReference(UserModel),
	})
	.actions((self) => ({
		setCurrent(user: User) {
			self.entities.set(user._id, user);
			self._currentUser = self.entities.get(user._id);
		},
		createPasswordResetUrl(userId: string): Promise<string> {
			return getClient(self).get(`auth/passwordreset/${userId}`);
		},
	}))
	.views((self) => {

		let isFetching = false;
		const fetchCurrentUser = () => {
			if (!isFetching) {
				isFetching = true;
				const client = getClient(self);
				client.get('auth/userinfo')
				      .then(self.setCurrent)
				      .catch(_logError)
				      .finally(() => isFetching = false);
			}
		};

		async function resendInvitation(user: User): Promise<void> {
			try {
				await getClient(self).post(`users/invite/${user._id}`);
				notificationStore.pushInfo('Invitation sent!');
			} catch (err) {
				_logError(err);
				notificationStore.pushError('Sorry, we could not send an invitation. Please try again later.');
			}
		}

		return ({
			get currentUser(): User {
				if (!getAuthProvider(self).isAuthenticated) {
					return throwErr('Attempted to get logged-in user, but is not logged in.');
				}
				if (self._currentUser) {
					return self._currentUser;
				} else {
					fetchCurrentUser();
					return self.$loadingValue;
				}
			},
			resendInvitation: debounce(resendInvitation, 1000, {leading: true, trailing: false}),
		});
	});

export interface UserStoreModel extends Infer<typeof UserStoreModelInferred> {}

export const UserStoreModel: UserStoreModel = UserStoreModelInferred;

export interface UserStore extends Instance<UserStoreModel> {}

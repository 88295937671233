import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import {
	UserGroup,
	UserGroupModel,
} from '../../accounts/models/UserGroupModel';
import { User, UserModel } from '../../accounts/models/UserModel';
import { doesNameableMatch } from '../../common';
import DownshiftMultiSelect, {
	DownshiftMultiSelectProps,
} from '../../components/downshift-select/downshift-multi-select.component';
import { BaseWorkflowOwnable } from '../../models/BaseWorkflowOwnableModel';

import {
	BaseWorkflowOwner,
	createGroupOwner,
	createUserOwner,
} from '../../models/BaseWorkflowOwnerModel';
import { useGroupStore, useUserStore } from '../../stores';
import themeStore from '../../theme/models/ThemeStore';

const OwnerSelect = ({ ownable }: { ownable: BaseWorkflowOwnable }) => {
	const userStore = useUserStore();
	const groupStore = useGroupStore();

	const addOwner = useCallback(
		(ownerEntity: Nullable<User | UserGroup>) => {
			if (UserModel.is(ownerEntity)) {
				ownable.addOwner(createUserOwner(ownerEntity._id));
			} else if (UserGroupModel.is(ownerEntity)) {
				ownable.addOwner(createGroupOwner(ownerEntity._id));
			}
		},
		[ownable]
	);

	const removeOwner = useCallback(
		(wo: BaseWorkflowOwner) => ownable.removeOwner(wo),
		[ownable]
	);

	const isNotOwner = useCallback(
		({ _id }: Identifiable) => !ownable.isOwner(_id),
		[ownable]
	);

	return useObserver(() => {
		const users = userStore.all;
		const groups = groupStore.allUserGroups;

		const selectProps: DownshiftMultiSelectProps<
			BaseWorkflowOwner,
			User | UserGroup
		> = {
			label: `${themeStore._.owner}s`,
			selectionState: {
				selection: ownable.owners,
				options: [...users, ...groups].filter(isNotOwner),
				searchPredicate: doesNameableMatch,
			},
			selectionActions: {
				select: addOwner,
				unselect: ownable.owners.length > 1 ? removeOwner : undefined,
			},
		};

		return <DownshiftMultiSelect {...selectProps} />;
	});
};

export default OwnerSelect;

import React, { ChangeEvent, PropsWithChildren } from 'react';

import { StyledLabel, StyledSelect } from './forms.styled-components';


// interface
export interface OptionProps {
	id: string;
	value: string;
}

export interface LabeledSelectProps {
	id: string;
	label: string;
	className?: string;
	name?: string;
	disabled?: boolean;
	valid?: boolean;
	invalid?: boolean;
	required?: boolean;
	value?: any;
	defaultValue?: any;
	onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}


// component
export const LabeledSelect = (props: PropsWithChildren<LabeledSelectProps>) => {
	const { id, label, children } = props;

	return (
		<>
			<StyledLabel for={ id }>
				{ label }
			</StyledLabel>
			<StyledSelect { ...props } >
				{ children }
			</StyledSelect>
		</>
	)
};

export default LabeledSelect;

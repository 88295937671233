import React from 'react';
import auth0, {
	Auth0DecodedHash,
	Auth0Error,
	AuthOptions,
	AuthorizeOptions,
	CrossOriginLoginOptions,
} from 'auth0-js';
import { navigate } from '@reach/router';
import { applySnapshot, Instance, types } from 'mobx-state-tree';
import { AuthUser, AuthUserModel } from '../models/AuthUserModel';
import { findAsObject, requiredValue, saveNode } from '../common';
import { computed } from 'mobx';
import { _logError, _logWarning } from '../common/log';

const domain = requiredValue(process.env.REACT_APP_AUTH0_DOMAIN);
const clientID = requiredValue(process.env.REACT_APP_AUTH0_CLIENT_ID);

const auth0Options: AuthOptions = {
	domain: domain,
	audience: `https://api.romeportal.com`, // maybe make this configurable
	clientID: clientID,
	redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
	responseType: 'id_token token',
	scope: 'openid profile email',
};

const auth0Instance = new auth0.WebAuth(auth0Options);

const authStorageKey = 'rome_auth';

export type Auth0Result = Required<
	Pick<
		Auth0DecodedHash,
		'accessToken' | 'idToken' | 'idTokenPayload' | 'expiresIn'
	>
>;
const hasRequiredFields = (adh: Auth0DecodedHash): adh is Auth0Result =>
	!!adh.accessToken &&
	!!adh.idToken &&
	!!adh.idTokenPayload &&
	!!adh.idTokenPayload.exp;

export class AuthProvider {
	@computed
	public get accessToken(): string | null {
		return this.authStore.accessToken;
	}

	@computed
	public get idToken(): string | null {
		return this.authStore.idToken;
	}

	@computed
	public get authUser(): AuthUser | null {
		return this.authStore.authUser;
	}

	@computed
	get isAuthenticated() {
		return this.authStore.isAuthenticated;
	}

	private sessionRenewal?: number;

	constructor(
		private readonly auth0Instance: auth0.WebAuth,
		public readonly authStore: AuthStore
	) {
		this.scheduleRenewal();
	}

	private scheduleRenewal = (): void => {
		if (this.authStore.isAuthenticated) {
			const expiryTolerance = 60000;
			this.sessionRenewal = setTimeout(
				this.renewSession,
				this.authStore.expiresAt - Date.now() - expiryTolerance
			);
		}
	};

	private renewSession = () =>
		this.auth0Instance.checkSession(
			{
				...auth0Options,
				usePostMessage: true,
			},
			(err: null | Auth0Error, response: any) => {
				if (err) {
					_logError(err);
				} else {
					this.authStore.setSession(response);
					this.scheduleRenewal();
				}
			}
		);

	signIn = (options?: AuthorizeOptions) =>
		this.auth0Instance.authorize(options);

	signInWithGoogle = () => this.signIn({ connection: 'google-oauth2' });

	signInWithEmailPassword = ({
		email,
		password,
	}: Pick<CrossOriginLoginOptions, 'email' | 'password'>) =>
		this.auth0Instance.login({ email, password }, (err) => _logError(err));

	handleAuthentication = () =>
		new Promise((resolve, reject) => {
			this.auth0Instance.parseHash((err, authResult) => {
				if (err) {
					return reject(err);
				}

				if (!authResult) {
					return reject(Error('No result from Auth0'));
				}

				if (!hasRequiredFields(authResult)) {
					_logWarning(authResult);
					return reject(Error('Missing Auth0 properties from result'));
				}

				return resolve(this.authStore.setSession(authResult));
			});
		});

	signOut = () => {
		this.authStore.clearSession();

		this.auth0Instance.logout({
			returnTo: window.location.origin,
		});

		return navigate('/auth');
	};
}

const AuthStoreModelInferred = types
	.model('AuthStore', {
		authUser: types.maybeNull(AuthUserModel),
		idToken: types.maybeNull(types.string),
		accessToken: types.maybeNull(types.string),
		refreshToken: types.maybeNull(types.string),
		expiresAt: types.optional(types.number, -1),
	})
	.views((self) => ({
		get isAuthenticated(): boolean {
			return !!self.idToken && !!self.authUser && Date.now() < self.expiresAt;
		},
	}))
	.actions((self) => ({
		setSession(tokens: Auth0Result): void {
			self.idToken = tokens.idToken;
			self.accessToken = tokens.accessToken;
			self.expiresAt = Date.now() + tokens.expiresIn * 1000;

			self.authUser = AuthUserModel.create({
				...tokens.idTokenPayload,
			});

			saveNode(authStorageKey, self);
		},
		clearSession(): void {
			applySnapshot(self, {});
			saveNode(authStorageKey, self);
		},
	}));

export interface AuthStoreModel extends Infer<typeof AuthStoreModelInferred> {}
export const AuthStoreModel: AuthStoreModel = AuthStoreModelInferred;

export interface AuthStore extends Instance<AuthStoreModel> {}

export let authStore: AuthStore;
try {
	authStore = AuthStoreModel.create(findAsObject(authStorageKey) ?? {});
} catch (err) {
	_logError(err);
	authStore = AuthStoreModel.create({});
}

export const authProvider = new AuthProvider(auth0Instance, authStore);
export const AuthContext = React.createContext(authProvider);

import { types } from 'mobx-state-tree';
import { enumValues, generateID } from '../common';

export enum TransitionType {
	'forward' = 'forward',
	'backward' = 'backward'
}

const titleForType: Record<TransitionType, string> = {
	[TransitionType.forward]: 'Approval',
	[TransitionType.backward]: 'Rejection',
};

export const BaseWorkflowTransitionTypeModel = types.enumeration(enumValues(TransitionType));

export interface WorkflowTransition extends Identifiable {
	readonly type: TransitionType;
}

export const isForward = (x: unknown): x is { type: TransitionType.forward } => (x as any).type === TransitionType.forward;
export const isBackward = (x: unknown): x is { type: TransitionType.backward } => (x as any).type === TransitionType.backward;

export const BaseWorkflowTransitionModel = types.model(
	'WorkflowTransition',
	{
		_id: types.optional(types.identifier, generateID),
		type: BaseWorkflowTransitionTypeModel,
	},
).views(self => ({
		get isForward(): boolean {
			return isForward(self);
		},
		get isBackward(): boolean {
			return isBackward(self);
		},
		get title(): string {
			return titleForType[self.type];
		},
	}),
);

import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { StageActionButtonProps } from '.';
import { useModalCreator } from '../../../stores/ModalStack';
import { isInputStage } from '../../models';
import {
	OutlinedCircleButton,
	StyledTooltip,
} from '../action-button.styled-components';
import UploadStageAssetDialog, {
	UploadAssetDialogProps,
} from './upload-stage-asset-dialog.component';

export const UploadStageAssetButton = (props: StageActionButtonProps) => {
	const { stage } = props;
	const modalStack = useModalCreator();

	const openAssetUploadModal = useCallback(
		({ stage, selectedSlot }: UploadAssetDialogProps) => {
			modalStack.addModal(
				<UploadStageAssetDialog stage={stage} selectedSlot={selectedSlot} />
			);
		},
		[modalStack]
	);

	if (!isInputStage(stage)) {
		return null;
	}

	return (
		<>
			<StyledTooltip target="fileUploadBtn">Upload an asset</StyledTooltip>
			<OutlinedCircleButton
				id="fileUploadBtn"
				color=""
				size="sm"
				onClick={() => openAssetUploadModal({ stage })}
			>
				<FontAwesomeIcon icon={faPaperclip} />
			</OutlinedCircleButton>
		</>
	);
};

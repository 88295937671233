import { flow, Instance } from 'mobx-state-tree';
import { generateID, getId, loadingValue } from '../../common';
import { getClient } from '../../core';
import { makeEntityStore } from '../../models/EntityStore.model';
import { RootStore } from '../../stores';
import {
	loadingTemplate,
	TemplateModel,
	TemplateState,
	WorkflowTemplate,
	WorkflowTemplateModel,
	WorkflowTemplateSnapshot,
} from './TemplateModel';

export const emptyTemplate = (rootStore: RootStore): WorkflowTemplate =>
	TemplateModel.create(
		{
			_id: generateID(),
			createdBy: loadingValue,
			title: '',
		},
		{ ...rootStore }
	);

const WorkflowTemplateStoreModelInferred = makeEntityStore<
	WorkflowTemplateModel,
	WorkflowTemplate,
	WorkflowTemplateSnapshot
>(TemplateModel, 'templates', loadingTemplate)
	.views((self) => ({
		get finalizedTemplates(): ReadonlyArray<WorkflowTemplate> {
			return self.all.filter((t) => t.editingState === TemplateState.final);
		},
	}))
	.actions((self) => ({
		cloneTemplate: flow(function* cloneTemplate(
			template: OrID<WorkflowTemplate>
		) {
			const response = yield getClient(self).post(
				`/templates/${getId(template)}/clone`
			);
			const createdTemplate = TemplateModel.create(response);
			self.entities.set(createdTemplate._id, createdTemplate);
			return createdTemplate;
		}) as (template: OrID<WorkflowTemplate>) => Promise<WorkflowTemplate>,
	}));

export interface WorkflowTemplateStoreModel
	extends Infer<typeof WorkflowTemplateStoreModelInferred> {}

export interface WorkflowTemplateStore
	extends Instance<WorkflowTemplateStoreModel> {}

export const WorkflowTemplateStoreModel: WorkflowTemplateStoreModel = WorkflowTemplateStoreModelInferred;

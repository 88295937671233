import { applyPatch, Instance, types } from 'mobx-state-tree';
import { AssetMetadataModel } from '../../dam-assets/models/AssetMetadataModel';
import { getParentWithTypeIn, includesCaseInsensitive } from '../../common';
import {
	TemplateSingleStageModel,
	TemplateSubstageModel,
} from './TemplateStageModel';

export const TemplateStageInputSlotModel = types
	.model('TemplateStageInputSlotModel', {
		_id: types.identifier,
		label: types.optional(types.string, ''),
		metadata: types.optional(AssetMetadataModel, {}),
	})
	.actions((self) => ({
		remove() {
			const parent = getParentWithTypeIn(self, [
				TemplateSingleStageModel,
				TemplateSubstageModel,
			]);
			parent.deleteInputSlot(self as TemplateStageInputSlot);
		},
		addMetadataField(field: string): void {
			self.metadata.fields.push(field);
		},
		removeMetadataField(field: string): void {
			const fieldIndex = self.metadata.fields.indexOf(field);

			if (fieldIndex > -1) {
				return applyPatch(self, {
					op: 'remove',
					path: `/metadata/fields/${fieldIndex}`,
				});
			}
		},
	}))
	.views((self) => ({
		includesMentionOf(value: string): boolean {
			return (
				includesCaseInsensitive(self.label, value) ||
				self.metadata.includesMentionOf(value)
			);
		},
	}));

export interface TemplateStageInputSlotModel
	extends Infer<typeof TemplateStageInputSlotModel> {}

export interface TemplateStageInputSlot
	extends Instance<TemplateStageInputSlotModel> {}

import React from 'react';
import { TabPane } from 'reactstrap';
import InputSlotForm from './input-slot-form.component';

import { AsideContent } from './template-stage-edit-form.styled-components';
import TemplateStageEditPanelProps from './template-stage-edit-panel-props';

const InputSlotsPanel = (props: TemplateStageEditPanelProps) => (
	<TabPane
		tabId={props.tabId}
		role="tabpanel"
		aria-labelledby={props.labelledBy}
	>
		<AsideContent>
			<InputSlotForm />
		</AsideContent>
	</TabPane>
);

export default InputSlotsPanel;

import { AssetVersion } from '../../models/AssetVersionModel';

export const typeMatcher = (type: string) => (asset: AssetVersion) =>
	asset.type === type;

export const typePrefixMatcher = (type: string) => {
	const typePrefixRegex = new RegExp(`^${type.replace(/\/+$/, '')}/[^/]+$`);
	return (asset: AssetVersion) => typePrefixRegex.test(asset.type);
};

export const extMatcher = (ext: string) => {
	const extRegex = new RegExp(`\\.${ext}$`);
	return (asset: AssetVersion) => extRegex.test(asset.fileName);
};

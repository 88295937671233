import styled from 'styled-components';

import { fsSmol, fsNormal } from '../../theme/ui/typography';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// styled components
export const StyledChip = styled.div`
	${fsNormal};
	display: inline-block;
	background-color: ${colors.darkerGrey};
	border-radius: 20px;
	color: ${colors.white};
	cursor: default;
	margin-bottom: 10px;
	margin-right: 10px;
	padding: 0.5em 1em;
`;

export const DeleteChipButton = styled.span`
	${fsSmol};
	background-color: ${colors.white};
	border-radius: 100%;
	color: ${colors.darkerGrey};
	cursor: pointer;
	margin-left: 5px;
	padding: 0.25em 0.5em;

	svg {
		&& {
			vertical-align: none;
		}
	}
`;

import { bottomQuartile, topQuartile } from '../../common/math';
import { ElementOffsets } from './ElementStore';
import { Point, point } from './utils';

export class SmartRect {
	public top: number;
	public bottom: number;
	public left: number;
	public width: number;
	public height: number;

	constructor(offsets: ElementOffsets) {
		this.top = offsets.offsetTop;
		this.left = offsets.offsetLeft;
		this.width = offsets.offsetWidth;
		this.height = offsets.offsetHeight;
		this.bottom = offsets.offsetTop + offsets.offsetHeight;
	}

	get centerX() {
		return this.left + this.width / 2;
	}

	get topCenter(): Point {
		return point(this.centerX, this.top);
	}

	get bottomCenter(): Point {
		return point(this.centerX, this.bottom);
	}

	get topLeft(): Point {
		return point(this.left, topQuartile(this.bottom, this.top));
	}

	get bottomLeft(): Point {
		return point(this.left, bottomQuartile(this.bottom, this.top));
	}
}

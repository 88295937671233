import { Instance, types } from 'mobx-state-tree';

const ReportModelInferred = types.model('Report', {
	_id: types.identifier,
	title: types.string,
});

export interface ReportModel extends Infer<typeof ReportModelInferred> {}

export const ReportModel: ReportModel = ReportModelInferred;

export interface Report extends Instance<ReportModel> {}

export interface HasReport {
	report: Report;
}

import { getParent, Instance, SnapshotIn, types } from 'mobx-state-tree';
import { UserGroup } from '../accounts/models/UserGroupModel';
import { User } from '../accounts/models/UserModel';
import { getId, noop, stubFalse } from '../common';
import { getStores } from '../stores';

export enum OwnerType {
	'AccountUser' = 'AccountUser',
	'AccountGroup' = 'AccountGroup',
}

const BaseWorkflowOwnerBase = types.model('BaseWorkflowOwnerBase', {
	_id: types.string,
});

const UserOwner = BaseWorkflowOwnerBase.named('UserOwner')
	.props({
		type: types.literal(OwnerType.AccountUser),
	})
	.views((self) => ({
		get user(): User {
			const userStore = getStores(getParent(self)).users;
			return userStore.getOne(self._id);
		},
		get name(): string {
			return this.user.name;
		},
		includesMentionOf(value: string): boolean {
			return this.user.includesMentionOf(value);
		},
	}));

const GroupOwner = BaseWorkflowOwnerBase.named('GroupOwner')
	.props({
		type: types.literal(OwnerType.AccountGroup),
	})
	.views((self) => ({
		get group(): UserGroup {
			const groupStore = getStores(getParent(self)).groups;
			const fetchedGroup = groupStore.getUserGroup(self._id);
			return fetchedGroup ?? groupStore.loadingUserGroup;
		},
		get name(): string {
			return this.group.title;
		},
		includesMentionOf(value: string): boolean {
			return this.group.includesMentionOf(value);
		},
	}));

const BaseWorkflowOwnerModelInferred = types.union(UserOwner, GroupOwner);

export interface BaseWorkflowOwnerModel
	extends Infer<typeof BaseWorkflowOwnerModelInferred> {}

export const BaseWorkflowOwnerModel: BaseWorkflowOwnerModel = BaseWorkflowOwnerModelInferred;

export type BaseWorkflowOwner = Instance<BaseWorkflowOwnerModel>;

export type BaseWorkflowOwnerSnapshot =
	| SnapshotIn<typeof GroupOwner>
	| SnapshotIn<typeof UserOwner>;

export const createUserOwner = (
	userId: string
): SnapshotIn<typeof UserOwner> => ({
	type: OwnerType.AccountUser,
	_id: userId,
});

export const createGroupOwner = (
	userId: string
): SnapshotIn<typeof GroupOwner> => ({
	type: OwnerType.AccountGroup,
	_id: userId,
});

export function areEqualOwners(
	woA: BaseWorkflowOwner,
	woB: BaseWorkflowOwner
): boolean {
	return woA.type === woB.type && getId(woA._id) === getId(woB._id);
}

export const stubbedOwnable = {
	/**
	 * Here mainly to avoid checking for stage types in components.
	 * Parallel stages aren't really "owned".
	 */
	get owners(): readonly BaseWorkflowOwner[] {
		return [];
	},
	isOwner: stubFalse,
	addOwner: noop,
	removeOwner: noop,
} as const;

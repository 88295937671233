import { applyPatch, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { loadingValue } from '../../common';
import { EntityMetadataModel } from '../../metadata/models/EntityMetadataModel';
import { tolerantDate } from '../../models/common';


/**
 * Metadata Template to be used as a placeholder while loading.
 */
export const loadingMetadataTemplate: MetadataTemplateSnapshot = {
	_id: loadingValue,
	title: 'Loading...',
};


export const MetadataTemplateModelInferred = EntityMetadataModel
	.named('MetadataTemplateModel')
	.props({
			_id: types.identifier,
			title: types.string,
		
			createdAt: types.optional(tolerantDate, () => new Date()),
		}
	).actions(self => ({
			addMetadataField(field: string): void {
				self.fields.push(field);
			},
			removeMetadataField(field: string): void {
				const fieldIndex = self.fields.indexOf(field);

				if (fieldIndex > -1) {
					return applyPatch(
						self,
						{
							op: 'remove',
							path: `/fields/${ fieldIndex }`,
						},
					);
				}
			}
		})
	);


export interface MetadataTemplateModel extends Infer<typeof MetadataTemplateModelInferred> {
}

export const MetadataTemplateModel: MetadataTemplateModel = MetadataTemplateModelInferred;

export interface MetadataTemplate extends Instance<MetadataTemplateModel> {
}

export interface MetadataTemplateSnapshot extends SnapshotIn<MetadataTemplateModel> {
}

export interface MetadataTemplateSnapshotOut extends SnapshotOut<MetadataTemplateModel> {
}

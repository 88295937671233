import React from 'react';
import themeStore from '../../theme/models/ThemeStore';

import { TemplateTransition } from '../models/TemplateTransitionModel';
import { PathBuilder } from './PathBuilder';
import { useObserver } from 'mobx-react';
import { PathAnchors } from './PathAnchors';
import { useElementStore } from './ElementStore';
import { shouldShowAll } from '../../common/mobx.utils';
const { colors } = themeStore.selectedTheme;

const strokeWidth = 2;

const svgAttrsForTransition = ({
	isForward,
}: TemplateTransition): React.SVGAttributes<SVGPathElement> => {
	return {
		stroke: isForward ? colors.darkerGrey : colors.danger,
		strokeWidth,
		fill: 'none',
		markerEnd: isForward ? `url(#marker-forward)` : `url(#marker-backward)`,
	};
};

interface EdgeProps {
	transition: TemplateTransition;
}

const Edge = ({ transition }: EdgeProps) => {
	const elementStore = useElementStore();

	return useObserver(() => {
		const { sourceStage, targetStage } = transition;

		if (shouldShowAll(sourceStage, targetStage)) {
			const sourceOffsets = elementStore.offsets.get(sourceStage._id);
			const targetOffsets = elementStore.offsets.get(targetStage._id);

			if (sourceOffsets && targetOffsets) {
				const { path, anchors } = PathBuilder.buildPath(
					sourceOffsets,
					targetOffsets,
					transition
				);

				return (
					<g>
						<path d={path} {...svgAttrsForTransition(transition)} />
						<PathAnchors anchors={anchors} />
					</g>
				);
			}
		}

		return null;
	});
};

export default Edge;

import { action, computed, ObservableSet, values } from 'mobx';

import NotificationModel, { NotificationModelProps } from './NotificationModel';

class NotificationStore {
	public readonly notificationSet: ObservableSet<
		NotificationModel
	> = new ObservableSet();

	// public api
	@computed
	get notifications() {
		return values(this.notificationSet);
	}

	@computed
	get visibleNotifications() {
		return this.notifications.filter((n) => n.shouldBeDisplayed);
	}

	@computed
	get numNotifications() {
		return this.notificationSet.size;
	}

	// methods
	@action
	push(model: NotificationModel | NotificationModelProps) {
		if (model instanceof NotificationModel) {
			this.notificationSet.add(model);
		} else {
			this.notificationSet.add(NotificationModel.create(model));
		}
	}
	@action
	pushError(text: string): void {
		this.push(NotificationModel.error(text));
	}

	@action
	pushWarning(text: string): void {
		this.push(NotificationModel.warning(text));
	}

	@action
	pushInfo(text: string): void {
		this.push(NotificationModel.info(text));
	}

	@action
	remove(model: NotificationModel) {
		this.notificationSet.delete(model);
	}
}

const notificationStore = new NotificationStore();

export default notificationStore;

import React from 'react';
import { DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { AssetCollection } from '../dam-asset-collections/models/AssetCollectionModel';
import { DamAsset } from '../dam-assets/models/DamAssetModel';
import { WorkflowCollection } from '../workflow-collections/models/WorkflowCollectionModel';
import { Workflow } from '../workflows/models';
import ActionsDropdownToggle from './action-dropdown-toggle.component';
import { DropDownMenuItem } from './drop-down-menu.component';
import { Link } from './ui';

interface Props {
	collection: WorkflowCollection | AssetCollection;
	collectionItems: Workflow[] | DamAsset[];
	collectionTerm: string;
	editLink: string;
	showDeleteModal: (collection: any) => void;
	extraClasses?: string;
}

const CollectionActionDropdown = ({
	collection,
	collectionItems,
	collectionTerm,
	editLink,
	showDeleteModal,
	extraClasses,
}: Props) => {
	return (
		<UncontrolledDropdown className={extraClasses}>
			<ActionsDropdownToggle caret color="light">
				Actions
			</ActionsDropdownToggle>
			<DropdownMenu right>
				<Link to={editLink}>
					<DropDownMenuItem>Edit {collectionTerm}</DropDownMenuItem>
				</Link>

				{collectionItems.length === 0 ? (
					<DropDownMenuItem
						className="danger"
						onClick={() => showDeleteModal(collection)}
					>
						Delete {collectionTerm}
					</DropDownMenuItem>
				) : null}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

export default CollectionActionDropdown;

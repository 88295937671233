import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import { Card } from 'reactstrap';

import { Subtitle } from '../../../components/ui';
import { useModalCreator } from '../../../stores/ModalStack';

import SideNavButton from '../side-nav-button.component';
import { PanelHeader } from '../user-details.styled-components';
import AddGroupDialog from './add-group-dialog.component';

import UserGroupsTable from './user-groups-table.component';


const UserGroupsPanel = () => {
	const modalStack = useModalCreator();

	const showAddGroupModal = useCallback(
		() => modalStack.addModal(<AddGroupDialog />),
		[modalStack],
	);

	const render = () => (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">
					User Groups
				</Subtitle>
				<SideNavButton
					onClick={ showAddGroupModal }
					label="Create User Group"
				/>
			</PanelHeader>
			<Card className="mt-4">
				<UserGroupsTable />
			</Card>
		</article>
	);

	return useObserver(render);
};

export default UserGroupsPanel;

import { getRoot, getSnapshot, Instance } from 'mobx-state-tree';
import { UserStore } from '../../accounts/models/UserStore';

import { generateID, loadingValue } from '../../common';
import { Filter } from '../../common/filters';
import { makeEntityStore } from '../../models/EntityStore.model';
import { RootStore } from '../../stores';

import {
	AssetCollection,
	AssetCollectionModel,
	AssetCollectionSnapshot,
} from './AssetCollectionModel';

export const emptyCollection = (rootStore: RootStore): AssetCollection =>
	AssetCollectionModel.create(
		{
			_id: generateID(),
			createdBy: loadingValue,
			title: '',
		},
		{ ...rootStore }
	);

const loadingCollection = AssetCollectionModel.create({
	_id: loadingValue,
	createdBy: loadingValue,
	title: 'Loading...',
});

const AssetCollectionStoreModelInferred = makeEntityStore<
	AssetCollectionModel,
	AssetCollection,
	AssetCollectionSnapshot
>(
	AssetCollectionModel,
	'collections',
	getSnapshot(loadingCollection),
	true
).views((self) => ({
	getArchived(): ReadonlyArray<AssetCollection> {
		// TODO: obtain archived collections (RRR-125)
		const archived: AssetCollection[] = [];
		return archived;
	},
	getCollaboratingWithCurrentUser(): ReadonlyArray<AssetCollection> {
		return self.all.filter((c) => c.isCurrentUserInvolved);
	},
	getCreatedByCurrentUser(): ReadonlyArray<AssetCollection> {
		const userStore: UserStore = getRoot<RootStore>(self).users;
		return self.all.filter((c) => c.createdBy === userStore.currentUser);
	},
	getFollowedByCurrentUser(): ReadonlyArray<AssetCollection> {
		// TODO: obtain collections which current user follows (RRR-128)
		const followed: AssetCollection[] = [];
		return followed;
	},
	getOwnedByCurrentUser(): ReadonlyArray<AssetCollection> {
		return self.all.filter((c) => c.isOwnedByCurrentUser);
	},
	filterCollections(
		filter: Filter | undefined
	): ReadonlyArray<AssetCollection> {
		const workflowGetters = {
			[Filter.ARCHIVED]: this.getArchived,
			[Filter.COLLABORATING]: this.getCollaboratingWithCurrentUser,
			[Filter.CREATED_BY]: this.getCreatedByCurrentUser,
			[Filter.FOLLOWING]: this.getFollowedByCurrentUser,
			[Filter.OWNED]: this.getOwnedByCurrentUser,
		};

		if (filter) {
			return workflowGetters[filter]();
		} else {
			return self.all;
		}
	},
}));

export interface AssetCollectionStoreModel
	extends Infer<typeof AssetCollectionStoreModelInferred> {}

export const AssetCollectionStoreModel: AssetCollectionStoreModel = AssetCollectionStoreModelInferred;

export interface AssetCollectionStore
	extends Instance<AssetCollectionStoreModel> {}

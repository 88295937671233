import { DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import dropDownMenuItem from '../theme/components/drop-down-menu';
import themeStore from '../theme/models/ThemeStore';
import { fsNormal } from '../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

const ActionsDropdownToggle = styled(DropdownToggle)`
	${dropDownMenuItem}

	&& {
		${fsNormal}
		border-color: ${colors.secondary};
		color: ${colors.secondary};
		padding: 8px 16px;
	}

	&:hover {
		&& {
			border-color: ${colors.secondary};
			background-color: ${colors.secondary} !important;
			color: ${colors.white};
		}
	}
`;

export default ActionsDropdownToggle;

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useObserver } from 'mobx-react';
import { resolveIdentifier } from 'mobx-state-tree';
import Moment from 'react-moment';

import { Divider, Heading } from '../components/ui';
import { EntityPropLabel } from '../components/entity-details.styled-components';
import { isIdentifiableLoaded } from '../common';
import { useAssetStore, useWorkflowStore } from '../stores';

import { AssetVersion, AssetVersionModel } from './models/AssetVersionModel';
import { DamAsset } from './models/DamAssetModel';
import AssetDetailsTabSet, {
	VersionTabs,
} from './components/asset-details-sidebar/asset-details-tab-set.component';
import AssetPreview from './components/asset-preview/asset-preview.component';
import LoadingAssetState from './components/loading-asset-state.component';

// interface
interface Props {
	workflowId: string;
	versionId: string;
}

// component
const VersionDetailsView = (props: Props) => {
	const workflowStore = useWorkflowStore();
	const assetStore = useAssetStore();

	// render methods
	const renderView = (version: AssetVersion, damAsset?: DamAsset) => (
		<Row>
			<Col xs={12} className="d-flex justify-content-between">
				<Heading>{version.fileName}</Heading>
			</Col>
			<Col md={8}>
				<Divider />
				<Row className="justify-content-between">
					<Col>
						<EntityPropLabel>ID: {version._id}</EntityPropLabel>
					</Col>
					<Col className="text-right">
						<EntityPropLabel>
							<Moment format="MM/DD/YYYY, h:MM A" date={version.createdAt} />{' '}
							version
						</EntityPropLabel>
					</Col>
				</Row>
				<AssetPreview asset={version} />
			</Col>
			<Col md={4}>
				<AssetDetailsTabSet
					asset={version}
					damAsset={damAsset}
					tabs={VersionTabs}
				/>
			</Col>
		</Row>
	);

	const render = () => {
		let version: Maybe<AssetVersion>;
		const workflow = workflowStore.getOne(props.workflowId);

		if (isIdentifiableLoaded(workflow)) {
			version = resolveIdentifier(AssetVersionModel, workflow, props.versionId);
		}

		if (isIdentifiableLoaded(version)) {
			const damAsset = assetStore.getDAMAssetWithAssetVersion(version);
			return renderView(version, damAsset);
		} else {
			return <LoadingAssetState />;
		}
	};

	return <Container>{useObserver(render)}</Container>;
};

export default VersionDetailsView;

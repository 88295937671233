import { observer } from 'mobx-react';
import { applyPatch } from 'mobx-state-tree';
import React from 'react';
import { Col } from 'reactstrap';

import { LabeledInput } from '../../components/forms';

import { SelectedTemplateStage } from '../template.contexts';

const ExpectedDurationInput = observer(() => {
	const [currentStage] = SelectedTemplateStage.usePresent();

	const onChange = (event: any) => {
		let { value } = event.target;
		value = parseInt(value, 10) || 0;

		applyPatch(currentStage, {
			op: 'replace',
			path: `/expectedDurationHrs`,
			value,
		});
	};

	return (
		<Col lg={5} md={6} sm={7} className="m-0 p-0">
			<LabeledInput
				label="Expected duration in hours"
				type="number"
				name="expectedDurationHrs"
				id="stageDuration"
				value={currentStage.expectedDurationHrs?.toString() ?? ''}
				onChange={onChange}
				placeholder="0"
			/>
		</Col>
	);
});

export default ExpectedDurationInput;

import { Link } from '@reach/router';
import styled from 'styled-components';

import { fsNormal } from '../theme/ui/typography';
import themeStore from '../theme/models/ThemeStore';
import anchor from '../theme/components/anchor';

const { colors } = themeStore.selectedTheme;

export const Cell = styled.td`
	${fsNormal};
	color: ${colors.darkestGrey};
`;

export const CellLink = styled(Link)`
	${anchor};
	color: ${colors.primary};
	text-decoration: underline;

	&:hover {
		text-decoration: none;
		color: ${colors.primaryHighlighted};
	}
`;

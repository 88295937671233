import styled from 'styled-components';

import { primaryButton } from '../theme/components/primary-button';
import { secondaryButton } from '../theme/components/secondary-button';
import themeStore from '../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const PrimaryButton = styled.button`
	${primaryButton};
`;

export const SecondaryButton = styled.button`
	${secondaryButton};
`;

export const WarningButton = styled.button`
	${primaryButton};
	background-color: ${colors.warning};

	&:hover {
		background-color: ${colors.warning};
		color: ${colors.secondary};
		filter: brightness(1.375);
	}
`;

export const DangerButton = styled.button`
	${primaryButton};
	background-color: ${colors.danger};

	&:hover {
		background-color: ${colors.danger};
		filter: brightness(1.375);
	}
`;

export const OutlineButton = styled.button`
	${primaryButton};
	background-color: transparent;
	border: 1px solid ${colors.secondary};
	color: ${colors.secondary};

	&:hover {
		background-color: ${colors.secondary};
	}

	&:disabled {
		background-color: transparent;
		opacity: 0.5;

		&:hover {
			color: ${colors.secondary};
			background-color: transparent;
		}
	}
`;

export const MutedButton = styled.button`
	${primaryButton};
	background-color: ${colors.darkerGrey};
	color: ${colors.white};

	&:hover {
		background-color: ${colors.darkestGrey};
	}
`;

export const RemoveButton = styled.button`
	${secondaryButton};
	background-color: transparent !important;
	color: ${colors.primary} !important;
	height: 24px;
	line-height: 0;
	padding: 0;
	vertical-align: middle;
	width: 24px;
	margin-left: 10px;

	&:hover {
		background-color: ${colors.danger} !important;
		color: ${colors.white} !important;
	}
`;

import { useObserver } from 'mobx-react';
import React from 'react';
import { Card } from 'reactstrap';

import { Subtitle } from '../../../components/ui';
import { PanelHeader } from '../user-details.styled-components';
import ThemeTable from './theme-table.component';


const ThemePanel = () => {
	const render = () => (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">
					Theme
				</Subtitle>
			</PanelHeader>
			<Card className="mt-4">
				<ThemeTable />
			</Card>
		</article>
	);

	return useObserver(render);
};

export default ThemePanel;

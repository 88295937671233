import React from 'react';
import { Router } from '@reach/router';
import styled from 'styled-components';

import { getRGBfromHEX } from '../common';
import { ViewWrapper as ViewWrapperBase } from '../components/ui';
import Footer from '../components/footer.component';
import Login from '../views/login.view';
import themeStore from '../theme/models/ThemeStore';


const { images, colors } = themeStore.selectedTheme;
const rgb = getRGBfromHEX(colors.secondary);


const ViewWrapper = styled(ViewWrapperBase)`
	background-size: cover;
	background-image:
		linear-gradient(
			to top,
			rgba(${ rgb[0] }, ${ rgb[1] }, ${ rgb[2] }, 0.7) 0%,
			rgba(${ rgb[0] }, ${ rgb[1] }, ${ rgb[2] }, 0.1) 100%
		),
		url(${ images.loginBackground });
`;


const Auth = () => {
	return (
		<ViewWrapper>
			<Router>
				<Login path='login' default />
			</Router>
			<Footer />
		</ViewWrapper>
	);
};

export default Auth;

import { types } from 'mobx-state-tree';
import { enumValues } from '../common';
import { WorkflowStage } from '../workflows/models/WorkflowStageModel';

export enum StageStatus {
	queue = 'queue',
	active = 'active',
	roadblocked = 'roadblocked',
	completed = 'completed',
}

export const StageStatusModel = types.enumeration(
	'StageStatus',
	enumValues(StageStatus)
);

const workflowStageOrdering: Record<StageStatus, number> = [
	StageStatus.active,
	StageStatus.roadblocked,
	StageStatus.queue,
	StageStatus.completed,
].reduce((acc, status, idx) => {
	acc[status] = idx;
	return acc;
}, {} as Record<StageStatus, number>);

export const compareStageStatus = (
	a: WorkflowStage,
	b: WorkflowStage
): number => {
	return workflowStageOrdering[a.status] - workflowStageOrdering[b.status];
};
export const orderStagesByStatus = (stages: WorkflowStage[]): WorkflowStage[] =>
	stages.sort(compareStageStatus);

export const stageBadgeColorMap: Record<StageStatus, string> = {
	[StageStatus.queue]: 'dark',
	[StageStatus.active]: 'info',
	[StageStatus.roadblocked]: 'danger',
	[StageStatus.completed]: 'success',
};

export function isActionableStatus(status: StageStatus): boolean {
	return status === StageStatus.active || status === StageStatus.roadblocked;
}

type StatusMap = Readonly<Record<StageStatus, StageStatus>>;

export const statusForRoadblockToggle: StatusMap = {
	[StageStatus.roadblocked]: StageStatus.active,
	[StageStatus.active]: StageStatus.roadblocked,
	[StageStatus.queue]: StageStatus.queue,
	[StageStatus.completed]: StageStatus.completed,
};

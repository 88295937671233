import React from 'react';
import { Card, Table } from 'reactstrap';

import { Cell } from '../../components/tables.styled-components';
import themeStore from '../../theme/models/ThemeStore';
import { WorkflowStage } from '../../workflows/models';

import AssignmentTableRow from './assignment-table-row.component';

const stageTerm = themeStore._.stage;
const workflowTerm = themeStore._.workflow;

interface Props {
	stages: ReadonlyArray<WorkflowStage>;
}

const renderNoAssignmentRow = () => (
	<tr>
		<Cell className="pt-4">You currently have no assignments.</Cell>
	</tr>
);

const AssignmentsTable = (props: Props) => {
	const render = () => (
		<Card>
			<Table>
				<thead>
					<tr>
						<th>{`${stageTerm}`}</th>
						<th>Expected duration</th>
						<th>{`${stageTerm} Status`}</th>
						<th>{workflowTerm}</th>
					</tr>
				</thead>
				<tbody>
					{props.stages.length
						? props.stages.map((stage) => (
								<AssignmentTableRow key={stage._id} stage={stage} />
						  ))
						: renderNoAssignmentRow()}
				</tbody>
			</Table>
		</Card>
	);

	return render();
};

export default AssignmentsTable;

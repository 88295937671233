import { useObserver } from 'mobx-react';
import React, { FormEvent } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { unwrapEvent, usePatchers } from '../../common';
import { LabeledInput, SubmitButton } from '../../components/forms';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import { MetadataTemplate } from '../models/EntityMetadataTemplateModel';

interface EntityMetadataTemplateEditFormProps {
	selectedMetadataTemplate: MetadataTemplate;
	onSubmit: (event: FormEvent) => Promise<unknown>;
	displayTemplateField?: boolean;
}

const EntityMetadataTemplateEditForm = (
	props: EntityMetadataTemplateEditFormProps
) => {
	const { selectedMetadataTemplate, onSubmit, displayTemplateField } = props;
	const metadataTemplate = selectedMetadataTemplate;

	const propertyPatcher = usePatchers(metadataTemplate);
	return useObserver(() => {
		const isFormValid = () => {
			return metadataTemplate.title !== '';
		};

		return (
			<Form>
				<Row form>
					<Col md={12}>
						<LabeledInput
							label="Title*"
							type="text"
							name="title"
							id="title"
							value={metadataTemplate.title}
							onChange={unwrapEvent(propertyPatcher('title'))}
							required
							inputValid={metadataTemplate.title !== ''}
							errorMsg="Title is required"
						/>
					</Col>
				</Row>

				<EntityMetadataForm
					metadata={metadataTemplate}
					displayTemplateField={displayTemplateField}
				/>

				<SubmitButton
					label="Save Metadata Template"
					disabled={!isFormValid()}
					onClick={onSubmit}
				/>
			</Form>
		);
	});
};

export default EntityMetadataTemplateEditForm;

import React from 'react';
import { Redirect } from '@reach/router';
import { useObserver } from 'mobx-react';

import { appRoutes } from './app.routes';
import { AuthContext } from '../core/AuthProvider';
import { isIdentifiableLoaded } from '../common';
import { useUserStore } from '../stores';
import { ViewWrapper } from '../components/ui';
import AdminNavigation from '../components/admin-navigation/admin-navigation.component';
import DialogPortal from '../components/modals/dialog-portal.component';
import errorMonitor from '../third-parties/error-monitor.service';
import Footer from '../components/footer.component';
import Loading from '../components/loading.component';
import NotificationsContainer from '../notifications/notifications-container.component';

const MainView = () => (
	<ViewWrapper>
		<AdminNavigation />
		{appRoutes}
		<Footer />
		<NotificationsContainer />
		<DialogPortal />
	</ViewWrapper>
);

const Admin = () => {
	const auth = React.useContext(AuthContext);
	const userStore = useUserStore();

	return useObserver(() => {
		if (!auth.isAuthenticated) {
			return <Redirect to="/auth" noThrow />;
		}

		errorMonitor.setUser(userStore.currentUser);

		return isIdentifiableLoaded(userStore.currentUser) ? (
			<MainView />
		) : (
			<Loading />
		);
	});
};

export default Admin;

import ThemeModel from '../models/ThemeModel';
import { toRGBA } from '../../common/color.utils';

// main colors
const primary = '#000000';
const primaryHighlighted = '#604b3d';

const secondary = '#597f71';
const secondaryHighlighted = '#5e7c7f';

// status colors
const pipeline = primary;

// a11y
const focus = toRGBA(primary, 0.5);

export const treehouseTheme = ThemeModel.create({
	_id: 'treehouse',
	title: 'Treehouse',
	images: {
		desktopLogo: '/themes/treehouse/logo-desktop.png',
		mobileLogo: '/themes/treehouse/logo-mobile.png',
		loginBackground: '/themes/treehouse/login-bg.jpg',
	},
	colors: {
		primary,
		primaryHighlighted,
		secondary,
		secondaryHighlighted,
		pipeline,
		focus,
	},
});

import React from 'react';
import { Progress } from 'reactstrap';

import { percent } from '../../common/math';
import {
	WorkflowHealth,
	workflowHealthColors,
} from '../../workflows/models/WorkflowModel';
import { ProgressContainer } from './workflow-health.styled-components';

interface WorkflowHealthBarsProps {
	healthy: number;
	overdue: number;
	roadblocked: number;
	activeWorkflowCount: number;
}

const WorkflowHealthBars = (props: WorkflowHealthBarsProps) => {
	const render = () => (
		<ProgressContainer>
			<Progress multi>
				{Object.values(WorkflowHealth).map((status: WorkflowHealth) => (
					<Progress
						bar
						key={status}
						color={workflowHealthColors[status]}
						value={percent(props[status], props.activeWorkflowCount)}
					/>
				))}
			</Progress>
		</ProgressContainer>
	);

	return render();
};

export default WorkflowHealthBars;

export enum StageType {
	'single' = 'single',
	'parallel' = 'parallel',
	'substage' = 'substage',
}

export enum StageActionType {
	approval = 'approval',
	input = 'input',
}

import { navigate } from '@reach/router';
import React, { FormEvent } from 'react';

import { _logError } from '../../common/log';
import { useEditingFlow } from '../../common';
import NotificationModel, {
	NotificationType,
} from '../../notifications/NotificationModel';
import notificationStore from '../../notifications/NotificationStore';
import themeStore from '../../theme/models/ThemeStore';

import { AssetCollection } from '../models/AssetCollectionModel';

import AssetCollectionForm from './asset-collection-form.component';

const collectionTerm = themeStore._.assetCollection;

interface Props {
	collection: AssetCollection;
}

const EditAssetCollectionForm = (props: Props) => {
	const { collection } = props;

	const [, getAssetCollectionSnapshot, setLastKnownGood] = useEditingFlow(
		collection
	);

	const onSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedWorkflow = await collection.save(
				getAssetCollectionSnapshot()
			);
			setLastKnownGood(updatedWorkflow);

			notificationStore.push(
				NotificationModel.create({
					text: `${collectionTerm} saved!`,
				})
			);

			return navigate(`/admin/dam/collections/${updatedWorkflow._id}`);
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text:
						`An issue occurred while updating ${collectionTerm.toLowerCase()}. ` +
						`Please try again later.`,
				})
			);
		}
	};

	return (
		<AssetCollectionForm selectedCollection={collection} onSubmit={onSubmit} />
	);
};

export default EditAssetCollectionForm;

import { Container } from 'reactstrap';
import React from 'react';
import { useObserver } from 'mobx-react';
import { useTemplateStore } from '../stores';
import { LoadingRow } from '../components/loading.component';
import TemplateEditor from './components/template-editor.component';
import TemplateFinalDetails from './components/template-final-details.component';
import { SelectedTemplate } from './template.contexts';

interface WorkflowTemplateDetailProps {
	workflowTemplateId: string;
}

const TemplateDetailsView = ({
	workflowTemplateId,
}: WorkflowTemplateDetailProps) => {
	const templateStore = useTemplateStore();

	return useObserver(() => {
		const template = templateStore.getOne(workflowTemplateId);

		if (!template) {
			return (
				<Container>
					<LoadingRow />
				</Container>
			);
		}

		return (
			<SelectedTemplate.Provider value={SelectedTemplate.asReadonly(template)}>
				{template.isDraft ? <TemplateEditor /> : <TemplateFinalDetails />}
			</SelectedTemplate.Provider>
		);
	});
};

export default TemplateDetailsView;

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ReportsTable from './reports-table.component';
import themeStore from '../theme/models/ThemeStore';

const ReportsIndexView = () => (
	<Container>
		<Row>
			<Col md={3} className="mb-md-0 mb-4">
				<h1>{themeStore._.report}s</h1>
			</Col>

			<Col md={9}>
				<ReportsTable />
			</Col>
		</Row>
	</Container>
);

export default ReportsIndexView;

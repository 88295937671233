import React from 'react';
import { useObserver } from 'mobx-react';
import styled from 'styled-components';

import Notification from './notification.component';
import notificationStore from './NotificationStore';


// styled components
const Container = styled.div`
	position: fixed;
	z-index: 1;
	right: 2em;
	top: 6.5em;
`;


// component
const NotificationsContainer = () => {
	const notifications = useObserver(
		() => notificationStore.visibleNotifications.map(n => (
			<Notification key={ n.id } model={ n } />
		)),
	);

	return <Container>{ notifications }</Container>;
};

export default NotificationsContainer;

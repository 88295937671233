import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';
import styled from 'styled-components';

import { fsNormal, fsSmol } from '../../theme/ui/typography';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const CardContainer = styled.article`
	&:hover {
		.card {
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.125);
			transform: scale(1.0125);
			transition: transform 1ms ease-in-out;
		}
	}

	&.selected {
		.card {
			border: 2px solid ${colors.success};
		}
	}
`;

export const FilePreview = styled.div`
	background: ${colors.grey};
	overflow: hidden;
	padding: 1.25em 0 0;
`;

export const FileTitle = styled.div`
	font-size: 0.875rem;
	margin: 0;
	overflow: hidden;
	padding-right: 1.25rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: auto;
`;

export const FileSizeLabel = styled.div`
	${fsSmol};
	min-width: 50px;
	text-align: right;
`;

export const FileIcon = styled.span`
	position: relative;
	color: ${colors.darkerGrey};
	font-size: 1.0625rem;
	margin: auto 0.625rem auto auto;
	top: 10px;
`;

export const PrivacyLabel = styled.div`
	${fsNormal};
	display: inline-block;
`;

export const StyledLink = styled(Link)`
	color: ${colors.primary};
	text-decoration: none;

	&:hover {
		color: ${colors.primaryHighlighted};
		text-decoration: none;
	}
`;

export const OverlayButton = styled.button`
	position: absolute;
	background: transparent;
	border: none;
	height: 100%;
	left: 0;
	margin: 0;
	opacity: 0;
	top: 0;
	width: 100%;
	z-index: 1;
`;

export const CheckMarkIcon = styled(FontAwesomeIcon)`
	position: absolute;
	color: ${colors.success};
	right: 30px;
	top: 25px;
	z-index: 1;
`;

export const CardTitleLine = styled.div`
	display: flex;
	align-items: start;
	justify-content: space-between;
	width: 100%;
`;

import { Card, CardHeader } from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const theme = themeStore.selectedTheme;

export const PhaseIndicator = styled.div`
	background-color: ${(props: { background: string }) => props.background};
	border: 1px solid ${theme.colors.darkGrey};
	height: 20px;
	width: 20px;
	border-radius: 100%;
	margin-right: 5px;
	flex: none;
`;

export const PhaseKeyCard = styled(Card)`
	max-width: 165px;
	position: sticky !important;
	top: 20px;
	left: 30px !important;
	z-index: 3 !important;
`;

export const PhaseKeyCardHeader = styled(CardHeader)`
	display: flex;
	justify-content: space-between;
	cursor: pointer;
`;

import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { tolerantDate } from '../../models/common';


const QueryModelInferred = types
	.model('Query')
	.props({
		_id: types.identifier,
		type: types.string,
		value: types.string,
		createdAt: tolerantDate,

		// FIXME: cant figure out how to assign user ref at creation. also, is it useful?
		// createdBy: UserReference,
	});

export interface QueryModel extends Infer<typeof QueryModelInferred> {}
export const QueryModel: QueryModel = QueryModelInferred;
export interface Query extends Instance<QueryModel> {}
export interface QuerySnapshotIn extends SnapshotIn<QueryModel> {}

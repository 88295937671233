import { navigate } from '@reach/router';
import { useObserver } from 'mobx-react';
import React, { FormEvent } from 'react';
import { Col, Container, Row } from 'reactstrap';

import BackLink from '../components/back-link/back-link.component';
import { LoadingRow } from '../components/loading.component';
import { Heading } from '../components/ui';
import NotificationModel from '../notifications/NotificationModel';
import notificationStore from '../notifications/NotificationStore';
import { useTemplateStore } from '../stores';
import EditTemplateForm from './components/edit-template-form.component';

const TemplateEditView = ({
	workflowTemplateId,
}: {
	workflowTemplateId: string;
}) => {
	const templateStore = useTemplateStore();

	const updateTemplate = (event: FormEvent) => {
		event.preventDefault();
		templateStore.updateOne(templateStore.getOne(workflowTemplateId));

		notificationStore.push(
			NotificationModel.create({
				text: 'Template saved!',
			})
		);

		return navigate(`.`);
	};

	return useObserver(() => {
		const template = templateStore.getOne(workflowTemplateId);

		if (!template) {
			return (
				<Container>
					<LoadingRow />
				</Container>
			);
		}

		return (
			<Container>
				<Row className="justify-content-center">
					<Col lg={8}>
						<BackLink link=".." title="Template Editor" />
						<div className="d-flex justify-content-between">
							<Heading>Edit {template.title}</Heading>
						</div>
					</Col>
					<Col lg={8}>
						<EditTemplateForm
							onSubmit={updateTemplate}
							selectedTemplate={template}
							buttonLabel="Save changes"
						/>
					</Col>
				</Row>
			</Container>
		);
	});
};

export default TemplateEditView;

import { Card as CardBase, CardBody } from 'reactstrap';
import styled from 'styled-components';

import themeStore from '../../../theme/models/ThemeStore';

import {
	MultiStageCardStyles,
	SingleStageCardStyles,
} from './template-stage-card.styles';

const { colors } = themeStore.selectedTheme;

// styled components
export const Card = styled(CardBase)`
	${SingleStageCardStyles};
`;

export const MultiStageCard = styled(Card)`
	${MultiStageCardStyles};
`;

export const MultiStageCardTitle = styled.p`
	margin-top: -30px;
`;

export const OwnerAvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	align-self: center;
`;

export const FlexCardBody = styled(CardBody)`
	display: flex;
	flex-wrap: wrap;
`;

export const CardTab = styled.span`
	position: absolute;
	top: 5px;
	right: 5px;
	color: ${colors.darkestGrey};
	font-size: 14px;

	&:before {
		position: absolute;
		background: ${colors.grey};
		content: '';
		height: 89px;
		right: -60px;
		top: -60px;
		transform: rotate(45deg);
		width: 89px;
		z-index: -1;
	}
`;

export const ParallelSubstageWrapper = styled.div`
	display: block;
	position: unset;
`;

export const SingleStageCard = styled(Card)`
	&& {
		background-color: ${(props) => props.background};
		box-shadow: 0 0.125rem 1.5625rem rgba(0, 0, 0, 0.1) !important;
	}
`;

export const AvatarListContainer = styled.div`
	position: relative;
	height: 24px;
	top: 8px;
`;

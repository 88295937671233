import { flow, Instance, types } from 'mobx-state-tree';
import { values } from 'mobx';
import {
	convertToMapSnapshot,
	generateID,
	getId,
	getRandomDarkColor,
	makeEntityFetcherFor,
} from '../../common';
import { getClient } from '../../core';
import {
	loadingUserGroup,
	UserGroup,
	UserGroupModel,
	UserGroupSnapshotIn,
} from './UserGroupModel';
import { getStores } from '../../stores/index';

const groupPath = (groupId: string) => `/groups/${groupId}`;

const emptyGroup = (): UserGroup =>
	UserGroupModel.create({
		_id: generateID(),
		title: '',
		color: getRandomDarkColor(),
		members: [],
	});

const UserGroupStoreModelInferred = types
	.model('UserGroupStore', {
		entities: types.map(UserGroupModel),
		loadingUserGroup: types.optional(UserGroupModel, loadingUserGroup),
		uncreated: types.maybe(UserGroupModel),
	})
	.volatile((self) => ({}))
	.actions((self) => ({
		buildEmptyGroup() {
			self.uncreated = emptyGroup();
			return self.uncreated;
		},
		addOne(group: UserGroup | UserGroupSnapshotIn) {
			self.entities.set(group._id, group);
		},
		addMany(groups: Array<UserGroup | UserGroupSnapshotIn>) {
			self.entities.merge(convertToMapSnapshot(groups));
		},
		createUserGroup: flow(function* createGroup(
			group: Partial<UserGroupSnapshotIn>
		) {
			const client = getClient(self);

			const newGroup = yield client.post('groups', group);

			self.entities.set(newGroup._id, newGroup);

			return newGroup;
		}) as (group: Partial<UserGroupSnapshotIn>) => Promise<UserGroup>,
		updateUserGroup: flow(function* updateGroup(
			group: UserGroup | UserGroupSnapshotIn
		) {
			const client = getClient(self);

			const updatedGroup = yield client.patch(groupPath(group._id), group);

			self.entities.set(updatedGroup._id, updatedGroup);

			return self.entities.get(updatedGroup._id);
		}) as (group: UserGroup | UserGroupSnapshotIn) => Promise<UserGroup>,
		deleteUserGroup: flow(function* deleteGroup(group: UserGroup | string) {
			const client = getClient(self);
			const groupId = getId(group);

			yield client.delete(groupPath(groupId));

			self.entities.delete(groupId);
		}) as (groupId: UserGroup | string) => Promise<void>,
	}))
	.views((self) => {
		const groupFetcher = makeEntityFetcherFor(self as any, 'groups');

		return {
			getUserGroup(id: string): UserGroup | undefined {
				groupFetcher(id);

				// if we have it, return, otherwise return undefined
				return self.entities.get(id);
				// since views are reactive, this expression will be re-evaluated if observed,
				// once the group arrives in the groups map.
			},
			get allUserGroups(): ReadonlyArray<UserGroup> {
				groupFetcher();
				return values(self.entities);
			},
		};
	})
	.views((self) => ({
		get numGroups() {
			return self.entities.size;
		},
		get groupsForCurrentUser() {
			const currentUser = getStores(self).users.currentUser;
			return self.allUserGroups.filter((group) =>
				group.members.some((m) => m._id === currentUser._id)
			);
		},
	}));

export interface UserGroupStoreModel
	extends Infer<typeof UserGroupStoreModelInferred> {}
export const UserGroupStoreModel: UserGroupStoreModel = UserGroupStoreModelInferred;
export interface UserGroupStore extends Instance<UserGroupStoreModel> {}

import React from 'react';
import styled from 'styled-components';
import { UserGroup } from '../accounts/models/UserGroupModel';

import themeStore from '../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;


// interfaces
interface GroupAvatarCircleProps {
	background: string;
}

interface GroupAvatarProps {
	group: UserGroup
}


// styled components
const GroupAvatarCircle = styled.div<GroupAvatarCircleProps>`
	display: inline-flex;
  	width: 1.5em;
    height: 1.5em;
	border-radius: 50%;
	background-color: ${ props => props.background };
	color: white;
	align-items: center;
	justify-content: center;
	border: 1px solid ${ colors.white };
	margin-right: -.5em;
`;

const GroupAvatarCircleLg = styled(GroupAvatarCircle)`
	width: 2.5em;
    height: 2.5em;
	margin-right: 0;
`;


// components
const GroupAvatar = (props: GroupAvatarProps) => (
	<GroupAvatarCircle background={ props.group.color }>
		<small>{ props.group.title.charAt(0) }</small>
	</GroupAvatarCircle>
);

export const GroupAvatarLg = (props: GroupAvatarProps) => (
	<GroupAvatarCircleLg background={ props.group.color }>
		<p className="mb-0">{ props.group.title.charAt(0) }</p>
	</GroupAvatarCircleLg>
);

export default GroupAvatar;


import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Heading } from '../components/ui';
import { useUserStore } from '../stores';
import PageSubheading from '../components/page-subheading.component';

import AssignmentsPanel from './components/assignments-panel.component';
import RecentWorkflowTable from './components/recent-workflows-table.component';
import RoadblocksPanel from './components/roadblocks-panel.component';
import SavedQueriesPanel from './components/saved-queries-panel.component';
import WorkflowDueDatesCalendar from './components/workflow-due-dates-calendar.component';
import WorkflowHealthPanel from './components/workflow-health-panel.component';
import { useObserver } from 'mobx-react';

const WorkflowDashboard = () => {
	const userStore = useUserStore();

	return useObserver(() => (
		<Container>
			<Row>
				{/* header */}
				<Col xs={12}>
					<PageSubheading text="Dashboard" />
					<Heading>Welcome back, {userStore.currentUser.givenName}!</Heading>
				</Col>

				{/* top row */}
				<Col lg={8} md={12} className="mt-lg-0 mt-4">
					<RecentWorkflowTable />
				</Col>
				<Col lg={4} md={6} className="mt-lg-0 mt-4">
					<AssignmentsPanel link={'/admin/workflow/assignments'} />
				</Col>

				{/* 2nd row */}
				<Col lg={4} md={6} className="mt-4">
					<WorkflowHealthPanel />
				</Col>
				<Col lg={4} md={6} className="mt-4">
					<WorkflowDueDatesCalendar />
				</Col>
				<Col lg={4} md={6} className="mt-4">
					<RoadblocksPanel link={'/admin/workflow/roadblocks'} />
				</Col>

				{/* 3rd row */}
				<Col lg={4} md={6} className="mt-4">
					<SavedQueriesPanel />
				</Col>
			</Row>
		</Container>
	));
};

export default WorkflowDashboard;

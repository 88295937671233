import { useObserver } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { LabeledSelect, SubmitButton } from '../../../components/forms';
import { useModalCreator } from '../../../stores/ModalStack';
import { Workflow, WorkflowStatus } from '../../models';
import { SelectedWorkflow } from '../../workflow.contexts';
import UpdateWorkflowStatusDialog from './update-workflow-status-dialog.component';

const workflowStatus: Record<string, WorkflowStatus> = {
	pipeline: WorkflowStatus.pipeline,
	active: WorkflowStatus.active,
	paused: WorkflowStatus.paused,
	cancelled: WorkflowStatus.cancelled,
};

const canOwnerActOnWorkflow = (workflow: Workflow) => {
	return workflow.isOwnedByCurrentUser || workflow.isCreatedByCurrentUser;
};

const UpdateWorkflowStatusForm = () => {
	const workflow = SelectedWorkflow.presentValue;
	const modalStack = useModalCreator();
	const [status, setStatus] = useState(workflow.status);

	const openWorkflowStatusModal = useCallback(
		(e) => {
			e.preventDefault();

			modalStack.addModal(
				<UpdateWorkflowStatusDialog workflow={workflow} status={status} />
			);
		},
		[status, workflow, modalStack]
	);

	const renderStatusOptions = useObserver(() => (
		<>
			{workflow.canBeActivated && canOwnerActOnWorkflow(workflow) ? (
				<option value={WorkflowStatus.active}>Active</option>
			) : null}
			{workflow.canBePaused && canOwnerActOnWorkflow(workflow) ? (
				<option value={WorkflowStatus.paused}>Paused</option>
			) : null}
			{workflow.canBeCancelled && canOwnerActOnWorkflow(workflow) ? (
				<option value={WorkflowStatus.cancelled}>Cancelled</option>
			) : null}
		</>
	));

	return (
		<Form>
			<FormGroup>
				<LabeledSelect
					id="workflowStatus"
					label=""
					name="status"
					required
					defaultValue=""
					onChange={(e) => setStatus(workflowStatus[e.target.value])}
				>
					<option disabled value="">
						Select a new status
					</option>
					{renderStatusOptions}
				</LabeledSelect>
			</FormGroup>
			<SubmitButton
				onClick={openWorkflowStatusModal}
				label="Update status"
				className="mt-0"
			/>
		</Form>
	);
};

export default UpdateWorkflowStatusForm;

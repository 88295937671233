import { faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';

import { Divider, Heading } from '../../components/ui';
import { EntityPropLabel } from '../../components/entity-details.styled-components';
import { useModalCreator } from '../../stores/ModalStack';
import BackLink from '../../components/back-link/back-link.component';
import themeStore from '../../theme/models/ThemeStore';

import { AssetVersion } from '../models/AssetVersionModel';
import { DamAsset } from '../models/DamAssetModel';

import { ShareAssetButton } from './asset-details.styled-components';
import AssetDetailsTabSet, {
	DetailsTabs,
} from './asset-details-sidebar/asset-details-tab-set.component';
import AssetPreview from './asset-preview/asset-preview.component';
import RelatedAssetsRow from './related-assets-row';
import ShareSingleAssetDialog from './share-dialogs/share-single-asset-dialog.component';

const assetTerm = themeStore._.asset;

interface CommonAssetVersionDisplayProps {
	version: AssetVersion;
	damAsset?: DamAsset;
}

const CommonAssetVersionDisplay = (props: CommonAssetVersionDisplayProps) => {
	const { version, damAsset } = props;

	const fileSource = damAsset || version;

	const modalStack = useModalCreator();

	const showShareModal = useCallback(() => {
		modalStack.addModal(<ShareSingleAssetDialog asset={fileSource} />);
	}, [fileSource, modalStack]);

	return (
		<Row>
			<Col xs={12} className="d-flex justify-content-between">
				<div>
					<BackLink link=".." title="Assets" />
					<Heading>{version.title || version.fileName}</Heading>
				</div>
			</Col>
			<Col md={8}>
				<Divider />
				<Row className="justify-content-between">
					<Col>
						<EntityPropLabel className="mr-4">
							ID: {version._id}
						</EntityPropLabel>

						{/* TODO: add asset view count (RRR-136) */}
						{/*<EntityPropLabel className="mr-4">*/}
						{/*	<FontAwesomeIcon*/}
						{/*		icon={ faEye }*/}
						{/*		className="mr-2"*/}
						{/*	/>*/}
						{/*	{ asset.views } views*/}
						{/*</EntityPropLabel>*/}

						{/* TODO: add asset download count (RRR-137) */}
						{/*<EntityPropLabel className="mr-4">*/}
						{/*	<FontAwesomeIcon*/}
						{/*		icon={ faDownload }*/}
						{/*		className="mr-2"*/}
						{/*	/>*/}
						{/*	{ asset.downloads } downloads*/}
						{/*</EntityPropLabel>*/}
					</Col>
					<Col className="text-right">
						{/* TODO: enable adding to collection (RRR-124) */}
						{/*<EntityPropLabel className="mr-4">*/}
						{/*	<FontAwesomeIcon*/}
						{/*		icon={ faPlusSquare }*/}
						{/*		className="mr-2"*/}
						{/*	/>*/}
						{/*	Add to Collection*/}
						{/*</EntityPropLabel>*/}

						<ShareAssetButton onClick={showShareModal}>
							<FontAwesomeIcon icon={faShare} className="mr-2" />
							Share {assetTerm.toLowerCase()}
						</ShareAssetButton>
					</Col>
				</Row>

				<AssetPreview asset={fileSource} />

				<RelatedAssetsRow asset={damAsset} />
			</Col>
			<Col md={4}>
				<AssetDetailsTabSet
					asset={version}
					damAsset={damAsset}
					tabs={DetailsTabs}
				/>
			</Col>
		</Row>
	);
};

export default CommonAssetVersionDisplay;

import { when } from 'mobx';
import { useObserver } from 'mobx-react';
import React, {
	MouseEvent,
	useCallback,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import { unwrapMouseEvent, UnwrappedMouseEvent } from '../../common/hooks';
import { isTemplateStage, TemplateRootStage, TemplateStage } from '../models';
import BackingSvg from '../svg/BackingSVG';
import { canvasRootClass, useElementStore } from '../svg/ElementStore';
import { SelectedTemplate } from '../template.contexts';
import TemplateStageCard from './stage-cards/template-stage-card.component';
import StageCreationButton from './stage-creation-button.component';
import {
	CanvasContent,
	CanvasWrapper,
	StageWrapper,
} from './template-editor.styled-components';
import TemplatePhaseKey from './template-phase-key.component';

interface EditorCanvasState {
	newStageId: string;

	isDraggable: boolean;
	isDragging: boolean;

	dragDeltaY: number;
	mousePressY: number;

	scrollOnTopPress: number;
	scrollTop: number;

	classList: string;
}

const initialClassList = `container ${canvasRootClass}`;

const initialCanvasState: EditorCanvasState = {
	newStageId: '',

	isDraggable: false,
	isDragging: false,

	dragDeltaY: 0,
	mousePressY: 0,
	scrollOnTopPress: 0,
	scrollTop: 0,

	classList: initialClassList,
};

const classListForState = (state: EditorCanvasState): string => {
	let classList = initialClassList;

	if (state.isDraggable) {
		classList += ' draggable';
	}
	if (state.isDragging) {
		classList += ' dragging';
	}
	return classList;
};

const TemplateEditorCanvas = () => {
	const template = SelectedTemplate.presentValue;

	const canvasWrapper = useRef<HTMLDivElement>(null);
	const canvasContent = useRef<HTMLDivElement>(null);

	const [newStageId, setNewStageId] = useState('');

	const elementStore = useElementStore();

	const [canvasState, dispatch] = useReducer(function canvasStateReducer(
		state: EditorCanvasState,
		event: UnwrappedMouseEvent | TemplateStage | null
	): EditorCanvasState {
		const parent = canvasWrapper.current;
		const canvas = canvasContent.current;

		if (parent && canvas) {
			state.isDraggable = parent.offsetHeight < canvas.offsetHeight;

			if (isTemplateStage(event)) {
				const stageId = event._id;
				setNewStageId(stageId);

				when(
					() => elementStore.elements.has(stageId),
					() => {
						parent.scrollTop = elementStore.elements.get(stageId)!.offsetTop;
						setNewStageId('');
					}
				);
			} else if (event) {
				switch (
					event.type as keyof GlobalEventHandlersEventMap | 'stagecreation'
				) {
					case 'mousemove':
						if (!state.isDragging) {
							return state;
						}

						state.dragDeltaY = state.mousePressY - event.clientY;
						parent.scrollTop = state.scrollOnTopPress + state.dragDeltaY;

						break;

					case 'mousedown':
						if (state.isDraggable && !state.isDragging && event.button === 0) {
							state.isDragging = true;
							state.mousePressY = event.clientY;
							state.dragDeltaY = 0;
							state.scrollOnTopPress = parent.scrollTop;
						}
						break;

					case 'mouseup':
					case 'mouseleave':
						if (state.isDragging) {
							state.isDragging = false;
							state.mousePressY = 0;
							state.dragDeltaY = 0;
						}
						break;
				}
			}
		} else {
			state.isDraggable = false;
		}

		const newClassList = classListForState(state);
		if (newClassList !== state.classList) {
			return { ...state, classList: newClassList };
		} else {
			return state;
		}
	},
	initialCanvasState);

	useEffect(
		() =>
			when(
				() => !!canvasWrapper.current && !!canvasContent.current,
				() => dispatch(null)
			),
		[canvasWrapper, canvasContent, template.stages]
	);

	const stages = useObserver(() =>
		template.stages.map((stage: TemplateRootStage) => (
			<StageWrapper key={stage._id}>
				<TemplateStageCard
					stage={stage}
					editable={true}
					peeking={newStageId === stage._id}
				/>
				<StageCreationButton stage={stage} onCreate={dispatch} />
			</StageWrapper>
		))
	);

	const dispatchEvent = useCallback(
		(event: MouseEvent) => {
			if (event.type === 'mousemove') {
				// Only prevent default for 'move' in order to prevent text selection.
				event.preventDefault();
			}
			dispatch(unwrapMouseEvent(event));
		},
		[dispatch]
	);

	return (
		<CanvasWrapper ref={canvasWrapper}>
			<TemplatePhaseKey template={template} />

			<CanvasContent
				ref={canvasContent}
				className={canvasState.classList}
				onMouseDown={dispatchEvent}
				onMouseUp={dispatchEvent}
				onMouseMove={dispatchEvent}
				onMouseLeave={dispatchEvent}
			>
				{stages}
				<BackingSvg />
			</CanvasContent>
		</CanvasWrapper>
	);
};

export default TemplateEditorCanvas;

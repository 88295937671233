import { useObserver } from 'mobx-react';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Heading } from '../components/ui';
import { useWorkflowStore } from '../stores';
import PageSubheading from '../components/page-subheading.component';
import themeStore from '../theme/models/ThemeStore';

import RoadblocksTable from './components/roadblocks-table.component';

const workflowTerm = themeStore._.workflow;

const RoadblocksIndexView = () => {
	const workflowStore = useWorkflowStore();

	const render = () => {
		return (
			<Container>
				<Row>
					<Col md={3}>
						<PageSubheading text={workflowTerm} />
						<Heading>Roadblocks</Heading>
					</Col>
					<Col md={9}>
						<RoadblocksTable roadblocks={workflowStore.activeRoadblocks} />
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default RoadblocksIndexView;

import { Instance, types } from 'mobx-state-tree';
import {
	Account,
	AccountModel,
	AccountSnapshotIn,
	loadingAccount,
} from './AccountModel';
import { values } from 'mobx';
import { getClient } from '../../core';
import { convertToMapSnapshot, makeEntityFetcherFor } from '../../common';

const AccountStoreModelInferred = types
	.model('AccountStore', {
		entities: types.map(AccountModel),
		_currentAccount: types.safeReference(AccountModel),
		loadingAccount: types.optional(AccountModel, loadingAccount),
	})
	.actions((self) => ({
		addOne(account: Account | AccountSnapshotIn) {
			self.entities.set(account._id, account);
		},
		addMany(accounts: Array<Account | AccountSnapshotIn>) {
			self.entities.merge(convertToMapSnapshot(accounts));
		},
		updateAccount(account: Account | AccountSnapshotIn) {
			self.entities.set(account._id, account);
		},
		async deleteAccount(accountId: string) {
			await getClient(self).delete(accountId);

			self.entities.delete(accountId);
		},
		setCurrentAccount(account: Account) {
			self.entities.set(account._id, account);
			self._currentAccount = account;
			return account;
		},
	}))
	.views((self) => {
		const accountFetcher = makeEntityFetcherFor(self as any, 'accounts');

		return {
			getAccount(id: string): Account | undefined {
				accountFetcher(id);

				// if we have it, return, otherwise return undefined
				return self.entities.get(id);
				// since views are reactive, this expression will be re-evaluated if observed,
				// once the account arrives in the accounts map.
			},
			get allAccounts(): ReadonlyArray<Account> {
				accountFetcher();
				return values(self.entities);
			},
		};
	})
	.views((self) => ({
		get currentAccount(): Account {
			if (self._currentAccount) {
				return self._currentAccount;
			}
			throw Error('Attempted to get logged-in account, but is not logged in.');
		},
	}));

export interface AccountStoreModel
	extends Infer<typeof AccountStoreModelInferred> {}
export const AccountStoreModel: AccountStoreModel = AccountStoreModelInferred;
export interface AccountStore extends Instance<AccountStoreModel> {}

import { Instance, types } from 'mobx-state-tree';
import { nonEmptyStringWithDefault } from '../../models/common';
import { ExtractProps } from 'mobx-state-tree/dist/types/complex-types/model';

/**
 * @deprecated
 * Instead, access the property on the ThemeTermsModel instance.
 */
export enum Terms {
	baseWorkflow = 'baseWorkflow',
	workflow = 'workflow',
	workflowCollection = 'workflowCollection',
	job = 'job',
	phase = 'phase',
	stage = 'stage',
	owner = 'owner',
	assetBase = 'assetBase',
	asset = 'asset',
	assetCollection = 'assetCollection',
	queue = 'queue',
}

export const ThemeTermsModel = types.model('ThemeTerms', {
	baseWorkflow: nonEmptyStringWithDefault('Workflow'),
	template: nonEmptyStringWithDefault('Template'),
	workflow: nonEmptyStringWithDefault('Workflow'),
	workflowCollection: nonEmptyStringWithDefault('Project'),
	job: nonEmptyStringWithDefault('Job'),
	phase: nonEmptyStringWithDefault('Phase'),
	stage: nonEmptyStringWithDefault('Stage'),
	owner: nonEmptyStringWithDefault('Stakeholder'),
	assetBase: nonEmptyStringWithDefault('DAM'),
	asset: nonEmptyStringWithDefault('Asset'),
	assetCollection: nonEmptyStringWithDefault('Collection'),
	queue: nonEmptyStringWithDefault('Pipeline'),
	report: nonEmptyStringWithDefault('Report'),
});

export interface ThemeTermsModel extends Infer<typeof ThemeTermsModel> {}
export interface ThemeTerms extends Instance<ThemeTermsModel> {}

export type ThemeTerm = keyof ExtractProps<ThemeTermsModel>;

export default ThemeTermsModel;

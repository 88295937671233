import greys from '../../styles/common/_Greys.scss';
import { types } from 'mobx-state-tree';
import { nonEmptyStringWithDefault } from '../../models/common';
import { toRGBA } from '../../common/color.utils';
import { mapValues } from 'lodash';

export const ThemeGreysModel = types.model(
	'ThemeGreys',
	mapValues(greys, (v) => nonEmptyStringWithDefault(v))
);

// main colors
const primary = '#3c3f42';
const primaryHighlighted = '#767d80';

const secondary = '#416c65';
const secondaryHighlighted = '#62a094';

// messaging colors
const danger = '#dc3545';
const warning = '#dadc35';
const success = '#28a745';
const info = '#17a2b8';

// status colors
const active = info;
const healthy = success;
const overdue = warning;
const blocked = danger;
const pipeline = primary;

// a11y
const focus = toRGBA(primary, 0.6);

export const ThemeColorsModel = types.model('ThemeColors', {
	primary: nonEmptyStringWithDefault(primary),
	primaryHighlighted: nonEmptyStringWithDefault(primaryHighlighted),

	secondary: nonEmptyStringWithDefault(secondary),
	secondaryHighlighted: nonEmptyStringWithDefault(secondaryHighlighted),

	danger: nonEmptyStringWithDefault(danger),
	warning: nonEmptyStringWithDefault(warning),
	success: nonEmptyStringWithDefault(success),
	active: nonEmptyStringWithDefault(active),

	healthy: nonEmptyStringWithDefault(healthy),
	overdue: nonEmptyStringWithDefault(overdue),
	blocked: nonEmptyStringWithDefault(blocked),
	pipeline: nonEmptyStringWithDefault(pipeline),

	focus: nonEmptyStringWithDefault(focus),
});

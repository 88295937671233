import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { _logError } from '../../../common/log';
import { RemoveButton } from '../../../components/buttons.styled-components';
import ConfirmationDialog from '../../../components/modals/confirmation-dialog.component';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useModalCreator } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';
import { WorkflowCollection } from '../../../workflow-collections/models/WorkflowCollectionModel';
import { Workflow } from '../../models';
import { isIdentifiableLoaded } from '../../../common/mobx.utils';

interface Props {
	workflow: Workflow;
}

const RemoveFromWorkflowCollectionButton = (props: Props) => {
	const { workflow } = props;

	const modalStack = useModalCreator();

	const workflowTerm = themeStore._.workflow.toLowerCase();
	const collectionTerm = themeStore._.workflowCollection.toLowerCase();

	let workflowCollection: Maybe<WorkflowCollection>;
	if (isIdentifiableLoaded(workflow.workflowCollection)) {
		workflowCollection = workflow.workflowCollection;
	}

	const onSubmit = useCallback(async () => {
		try {
			await workflow.removeFromCollection();

			notificationStore.push({
				text: `Removed from ${collectionTerm}!`,
			});
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An error occurred while removing this ${workflowTerm} from "${workflowCollection?.title}". Please try again later.`,
				})
			);
		}
	}, [collectionTerm, workflowTerm, workflow, workflowCollection]);

	const showModal = useCallback(
		() =>
			modalStack.addModal(
				<ConfirmationDialog
					header={`Remove from "${workflowCollection?.title}"`}
					onConfirm={onSubmit}
				>
					<p>
						Are you sure you want to remove this {workflowTerm} from the "
						{workflowCollection?.title}" {collectionTerm}?
					</p>
				</ConfirmationDialog>
			),
		[collectionTerm, workflowTerm, modalStack, onSubmit, workflowCollection]
	);

	return (
		<RemoveButton id={`workflowDelete${workflow._id}`} onClick={showModal}>
			<UncontrolledTooltip target={`workflowDelete${workflow._id}`}>
				Remove from {collectionTerm}
			</UncontrolledTooltip>
			<FontAwesomeIcon icon={faTimes} />
		</RemoveButton>
	);
};

export default RemoveFromWorkflowCollectionButton;

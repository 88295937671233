import { useObserver } from 'mobx-react';
import React from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import { StyledComponent } from 'styled-components';

import { formatDuration, renderNull } from '../../common';
import {
	ListColumnCreationOptions,
	makeColumns,
} from '../../components/list-column.component';
import OwnerAvatarList from '../../components/owner-avatar-list.component';
import StageStatusPill from '../../components/stage-status-pill.component';
import themeStore from '../../theme/models/ThemeStore';

import { WorkflowParallelStageModel, WorkflowStage } from '../models';
import { SelectedWorkflow, SelectedWorkflowStage } from '../workflow.contexts';
import { Td, Tr, TrSub, TrSubChild } from './stage-table.styled-components';
import { StageType } from '../../workflow-templates/models/StageTypes';

const { selectedTheme } = themeStore;

const columnOptions: ListColumnCreationOptions<WorkflowStage>[] = [
	{ label: 'Name', prop: 'title' },
	{
		label: 'Status',
		prop: (s: WorkflowStage) => <StageStatusPill status={s.status} />,
	},
	{
		label: 'Duration',
		prop: (s: WorkflowStage) => formatDuration(s.expectedDurationHrs),
	},
	{ label: 'Due', prop: renderNull },
	{
		label: `${themeStore._.owner}(s)`,
		prop: (s: WorkflowStage) => <OwnerAvatarList owners={s.owners} size="md" />,
	},
];

const stageTableColumns = makeColumns(
	columnOptions.map((c) => {
		c.cellWrapper = Td;
		return c;
	})
);

interface StageRowProps {
	stage: WorkflowStage;
	wrapper?: StyledComponent<any, any>;
}

const wrapperForStage = (stage: WorkflowStage) => {
	switch (stage.type) {
		case StageType.parallel:
		case StageType.single:
			return Tr;
		case StageType.substage:
			return stage.isGroupStart ? TrSub : TrSubChild;
	}
};

const StageRow = ({ stage }: StageRowProps) => {
	const [selectedStage, setSelectedStage] = SelectedWorkflowStage.usePresent();

	return useObserver(() => {
		const Wrapper = wrapperForStage(stage);

		return (
			<Wrapper
				onClick={() => setSelectedStage(stage)}
				className={`${stage === selectedStage ? 'selected' : ''} ${
					WorkflowParallelStageModel.is(stage) ? 'parallel-stage' : ''
				}`}
			>
				{stageTableColumns.map((c) => c.render(stage))}
			</Wrapper>
		);
	});
};

// component
const StageTable = () => {
	const workflow = SelectedWorkflow.presentValue;

	const rows = useObserver(() =>
		workflow.flatStages.map((stage: WorkflowStage) => (
			<tbody key={stage._id} id={stage._id} className="border-0">
				<StageRow stage={stage} />
			</tbody>
		))
	);

	return (
		<Card className="h-100">
			<CardHeader>
				<h2 className="mb-0">{selectedTheme.terms.stage} Overview</h2>
			</CardHeader>
			<Table className="mb-0">
				<thead>
					<tr>{stageTableColumns.map((c) => c.renderHeader())}</tr>
				</thead>
				{rows}
			</Table>
		</Card>
	);
};

export default StageTable;

import { RouteComponentProps } from '@reach/router';
import { useObserver } from 'mobx-react';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CommonAssetVersionDisplay from '../dam-assets/components/common-asset-version-display.component';
import LoadingAssetState from '../dam-assets/components/loading-asset-state.component';
import { Workflow } from './models';

interface WorkflowAssetVersionDetailsProps extends RouteComponentProps {
	versionId?: string;
	workflow: Workflow;
}

const WorkflowAssetVersionDetailsView = (
	props: WorkflowAssetVersionDetailsProps
) => {
	const { versionId, workflow } = props;

	const render = () => {
		if (!versionId) {
			return <LoadingAssetState />;
		}

		const version = workflow.loadAssetVersion(versionId);

		return (
			<Container>
				<Row className="justify-content-center m-md-4">
					<Col md={12}>
						{version ? (
							<CommonAssetVersionDisplay version={version} />
						) : (
							<LoadingAssetState />
						)}
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default WorkflowAssetVersionDetailsView;

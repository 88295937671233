import React from 'react';
import { useObserver } from 'mobx-react';
import styled from 'styled-components';

import { SelectedTemplate } from '../template.contexts';
import themeStore from '../../theme/models/ThemeStore';
import Edge from './Edge';

const { colors } = themeStore.selectedTheme;

// styled components
const StyledSVG = styled.svg`
	position: absolute;
	left: 0;
	overflow-x: auto;
	overflow-y: visible;
	pointer-events: none;
	top: 0;
	z-index: 1;
`;

const svgDefs = (
	<defs>
		<marker
			id="marker-forward"
			markerWidth="6"
			markerHeight="6"
			refX="3"
			refY="3"
			markerUnits="strokeWidth"
			orient="auto"
		>
			<path d="M0,0 L6,0 L3,4 z" fill={colors.darkerGrey} />
		</marker>
		<marker
			id="marker-backward"
			markerWidth="6"
			markerHeight="6"
			refX="3"
			refY="3"
			markerUnits="strokeWidth"
			orient="auto"
		>
			<path d="M0,0 L0,6 L4,3 z" fill={colors.danger} />
		</marker>
	</defs>
);

// component
const BackingSvg = () => {
	const template = SelectedTemplate.presentValue;

	const renderEdges = useObserver(() =>
		template.allTransitions.map((transition) => (
			<Edge key={transition._id} transition={transition} />
		))
	);

	return (
		<StyledSVG width="100%" height="100%">
			{svgDefs}
			{renderEdges}
		</StyledSVG>
	);
};

export default BackingSvg;

import { Form, ModalBody } from 'reactstrap';
import React, { useState } from 'react';

import { _logError } from '../../../common/log';
import {
	LabeledSelect,
	StyledInput,
	StyledLabel,
	SubmitButton,
} from '../../../components/forms';
import { useModalCloser } from '../../../stores/ModalStack';
import { unwrapEvent, useStateFromEvent } from '../../../common';
import DialogModal from '../../../components/modals/dialog-modal.component';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';

import { WorkflowActionableStage } from '../../models';

// component
const RejectStageDialog = ({ stage }: { stage: WorkflowActionableStage }) => {
	const modalStack = useModalCloser();

	const [message, setMessage] = useStateFromEvent('');

	const transitions = stage.backwardTransitions;
	const hasMultipleTransitions = transitions.length > 1;

	const [transitionId, setTransitionId] = useState(
		hasMultipleTransitions ? '-1' : transitions[0]._id
	);

	// methods
	const isFormValid = () => {
		const hasMessage = message.length > 0;
		if (hasMultipleTransitions) {
			return transitionId !== undefined && hasMessage;
		} else {
			return hasMessage;
		}
	};

	const onSubmit = async () => {
		try {
			await stage.takeTransition(transitionId, message);
			modalStack.closeModal();
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text:
						'Unable to apply the rejection, an issue occurred while saving. Please try again later.',
				})
			);

			modalStack.closeModal();
		}
	};

	// render methods
	const renderTargetStageInfo = () => {
		const transition = transitions[0];

		return (
			<p className="mt-2">
				Rejecting this stage will lead to "{transition.targetStage.title}".
			</p>
		);
	};

	const renderStageSelect = () => (
		<fieldset className="mt-2">
			<legend className="sr-only">Rejection Message</legend>
			<LabeledSelect
				id="transitionSelect"
				label="Please select to which stage the rejection leads."
				name="targetTransition"
				required
				value={transitionId}
				onChange={unwrapEvent(setTransitionId)}
			>
				<option disabled value="-1">
					Select a target stage
				</option>
				{transitions.map((t) => (
					<option key={t._id} value={t._id}>
						{t.targetStage.title}
					</option>
				))}
			</LabeledSelect>
		</fieldset>
	);

	const renderMessageField = () => (
		<fieldset className="mt-4">
			<legend className="sr-only">Rejection Message</legend>
			<StyledLabel className="mb-2" for="rejectionMessage">
				Please provide some context about the rejection.*
			</StyledLabel>
			<StyledInput
				id="rejectionMessage"
				type="textarea"
				name="message"
				value={message}
				placeholder="Write a message..."
				onChange={setMessage}
				required={true}
			/>
		</fieldset>
	);

	return (
		<DialogModal header={`Rejecting stage "${stage.title}"`}>
			<ModalBody>
				<Form>
					{hasMultipleTransitions
						? renderStageSelect()
						: renderTargetStageInfo()}
					{renderMessageField()}

					<SubmitButton
						onClick={onSubmit}
						disabled={!isFormValid()}
						label="Submit"
					/>
				</Form>
			</ModalBody>
		</DialogModal>
	);
};

export default RejectStageDialog;

import Downshift, { ControllerStateAndHelpers, GetInputPropsOptions } from 'downshift';
import React, { useCallback } from 'react';
import { DownshiftMultiSelectProps } from './downshift-multi-select.component';
import DownshiftSelect from './downshift-select.component';
import { DownshiftTagInputField } from './downshift-tag-input.component';
import { DownshiftReducer } from './downshift.interfaces';


// component
const DownshiftMultiFreeform = <Item extends Displayable>(props: DownshiftMultiSelectProps<Item, string>) => {

	const inputProps = (downshift: ControllerStateAndHelpers<string>) => {
		const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				event.stopPropagation();
				select(downshift.inputValue);
				downshift.setState({inputValue: ''});
			}

			const length = selectionState.selection.length;

			if (unselect && length && event.key === 'Backspace' && !downshift.inputValue?.length) {
				unselect(selectionState.selection[length - 1])
			}
		};

		return {
			onKeyDown: handleInputKeyDown
		} as GetInputPropsOptions;
	};

	const {
		selectionActions: { select, unselect },
		selectionState,
		renderInput = () => <DownshiftTagInputField { ...props } inputProps={ inputProps } />,
	} = props;

	const stateReducer: DownshiftReducer<string> = useCallback(
		(state, changes) => {
			switch (changes.type) {
				case Downshift.stateChangeTypes.keyDownEnter:
					select(changes.inputValue);

					return {
						...changes,
						highlightedIndex: state.highlightedIndex,
						isOpen: !!selectionState.options.length,
						inputValue: '',
					};

				case Downshift.stateChangeTypes.changeInput:
				case Downshift.stateChangeTypes.clickButton:
					return {
						...changes,
						isOpen: !!selectionState.options.length,
					};

				case Downshift.stateChangeTypes.mouseUp:
					if (!changes.isOpen) {
						// Clear input when clicking away.
						// Default behavior is to bring back the last value.
						return { ...changes, inputValue: '' };
					} else {
						return changes;
					}

				default:
					return changes;
			}
		},
		[select, selectionState.options.length],
	);

	return <DownshiftSelect { ...props }
							renderInput={ renderInput }
							stateReducer={ stateReducer } />;
};

export default DownshiftMultiFreeform;

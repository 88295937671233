import { getName } from './mobx.utils';

export function includesCaseInsensitive(
	haystack: string,
	needle?: string | null
) {
	return (haystack ?? '').toLowerCase().includes((needle ?? '').toLowerCase());
}

export const stringMatcherFor = (needle?: string | null) => (
	haystack: string
) => includesCaseInsensitive(haystack, needle);

export const matcherForKeys = <T>(...keysToSearch: Array<keyof T>) => (
	obj: T,
	value: string
): boolean =>
	keysToSearch.some((k) => {
		const prop = obj[k];
		return typeof prop === 'string' && includesCaseInsensitive(prop, value);
	});

export const doesNameableMatch = (
	input: Nullable<string>,
	x: MaybeNull<Displayable>
): boolean => includesCaseInsensitive(getName(x), input);

export function capitalizeFirstLetter(s: string) {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export function asPercent(x: number, precision = 1): string {
	return `${(100 * x).toFixed(precision)}%`;
}

export function humanReadableFileSizeSI(bytes: number): string {
	const units = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

	const exponent = Math.floor(Math.log(bytes) / Math.log(1000));
	const significand = (bytes / Math.pow(1000, exponent)).toFixed(1);

	return `${significand} ${units[exponent]}B`;
}

export function nl2br(s: string): string {
	return s.replace(/\r?\n/g, '<br/>');
}

export const isNotEmpty = (s: string): boolean => !!s.length;

export function formatDuration(durationInHours: number): string {
	const hoursPerDay = 24;

	if (durationInHours >= hoursPerDay) {
		const numDays = (durationInHours / hoursPerDay).toFixed(0);
		return `${numDays} day(s)`;
	}

	const suffix = durationInHours <= 1 ? 'hr' : 'hrs';
	return `${durationInHours}${suffix}`;
}

export function sanitizeForDOMElementId(s: string): string {
	// see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id
	return s.replace(/[^A-Za-z0-9]/g, '');
}

export const ensureStartingSlash = (x: string) =>
	x.startsWith('/') ? x : `/${x}`;

export const camelCaseToSentenceCase = (s: string) => {
	return s.split(/(?=[A-Z])/).join(' ');
};

export const buildClassList = (
	baseClassName: string,
	additionalClassNames?: string
) => {
	if (additionalClassNames) {
		return `${baseClassName} ${additionalClassNames}`;
	} else {
		return baseClassName;
	}
};

export const isValidEmail = (v: string) => {
	/*
	 * W3 e-mail validation regex:
	 * https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)
	 */
	const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	return emailRegex.test(v);
};

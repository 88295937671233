import React from 'react';
import { Card, Table } from 'reactstrap';
import { HasReport } from './ReportModel';
import DownloaderButton from '../components/downloader-button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useReportsStore } from '../stores/index';
import { useObserver } from 'mobx-react';
import { NoResultsRow } from '../components/no-results-row.component';
import themeStore from '../theme/models/ThemeStore';

const ReportRow = ({ report }: HasReport) => {
	return (
		<tr>
			<td>{report.title}</td>
			<td>
				<DownloaderButton url={`workflow-reports/${report._id}`}>
					<FontAwesomeIcon icon={faDownload} className="mr-2" />
					<span>Download {themeStore._.report}</span>
				</DownloaderButton>
			</td>
		</tr>
	);
};

const ReportsTable = () => {
	const reportsStore = useReportsStore();

	return useObserver(() => {
		const reports = reportsStore.all;

		return (
			<Card>
				<Table>
					<thead>
						<tr>
							<th>Name</th>
							<th>{/* Button Column */}</th>
						</tr>
					</thead>
					<tbody>
						{reports.length ? (
							reports.map((r) => <ReportRow key={r._id} report={r} />)
						) : (
							<NoResultsRow term="report" />
						)}
					</tbody>
				</Table>
			</Card>
		);
	});
};

export default ReportsTable;

import React, { useRef } from 'react';
import { useObserver } from 'mobx-react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { _logWarning } from '../../common/log';
import select from '../../theme/components/select';
import themeStore from '../../theme/models/ThemeStore';

import { LabeledSelectProps, OptionProps } from './labeled-select.component';
import { StyledInput, StyledLabel } from './forms.styled-components';

const { colors } = themeStore.selectedTheme;

// styled components
const Container = styled.div`
	position: relative;
`;

const StyledDataListInput = styled(StyledInput)`
	${select};
	cursor: auto;
`;

const ClearInputButton = styled.button`
	position: absolute;
	background-color: transparent;
	border: 0;
	bottom: 0;
	color: ${colors.black};
	cursor: pointer;
	height: 32px;
	right: 24px;
	width: 30px;
`;

// interface
interface LabeledDataListProps extends LabeledSelectProps {
	data: ReadonlyArray<OptionProps>;
}

// component
export const LabeledDataList = (props: LabeledDataListProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const clearInput = () => {
		if (inputRef && inputRef.current && inputRef.current.value) {
			inputRef.current.value = '';
		} else {
			_logWarning('Unable to clear input, reference may be undefined.');
		}
	};

	// render methods
	const renderClearButton = () =>
		// FIXME: does not render
		inputRef && inputRef.current ? (
			<ClearInputButton onClick={clearInput}>
				<FontAwesomeIcon icon={faTimes} />
			</ClearInputButton>
		) : null;

	const render = () => (
		<Container className={`labeled-data-list ${props.className}`}>
			<StyledLabel for={props.id}>{props.label}</StyledLabel>
			<StyledDataListInput
				ref={inputRef}
				id={props.id}
				list={`${props.id}List`}
				{...props}
			/>
			{renderClearButton()}
			<datalist id={`${props.id}List`}>
				{props.data.map((o: OptionProps) => (
					<option key={o.value} value={o.value}>
						{o.value}
					</option>
				))}
			</datalist>
		</Container>
	);

	return useObserver(render);
};

export default LabeledDataList;

import {
	getSnapshot,
	Instance,
	resolveIdentifier,
	SnapshotIn,
	types,
} from 'mobx-state-tree';
import {
	getId,
	LazyReference,
	lazyReference,
	loadingValue,
} from '../../common';
import { getClient } from '../../core';
import { getStores } from '../../stores';
import {
	Workflow,
	WorkflowActionableStageReference,
	WorkflowModel,
	WorkflowStageInputSlotReference,
} from '../../workflows/models';
import {
	$loadingAssetVersion,
	AssetVersion,
	AssetVersionModel,
} from './AssetVersionModel';
import { _logError } from '../../common/log';

function throwMissingVersion(
	workflowId: OrID<Workflow>,
	versionId: OrID<AssetVersion>
): never {
	throw new Error(
		`Version ${getId(versionId)} does not exist in ${getId(workflowId)}`
	);
}

/**
 * note this reference type is only valid for DamAssets
 */
const assetVersionReference = lazyReference({
	model: AssetVersionModel,
	getter(versionId, parent) {
		if (!versionId) {
			return $loadingAssetVersion;
		}

		const workflowId = parent.workflowId;
		if (!workflowId) {
			return $loadingAssetVersion;
		}

		const workflowStore = getStores(parent).workflows;
		const workflow = workflowStore.findOne(getId(workflowId));

		if (workflow) {
			return (
				resolveIdentifier(AssetVersionModel, parent, versionId) ??
				throwMissingVersion(workflowId, versionId)
			);
		} else {
			return $loadingAssetVersion;
		}
	},
});

/**
 * Asset to be used as a placeholder while loading.
 */
export const $loadingDamAsset: DamAssetSnapshot = {
	...getSnapshot($loadingAssetVersion),
	workflowId: loadingValue,
	stageId: loadingValue,
	slotId: loadingValue,
	versionId: loadingValue,
	signedURL: loadingValue,
};

const DamAssetModelInferred = AssetVersionModel.named('DamAsset')
	.props({
		workflowId: types.maybe(
			lazyReference({
				model: WorkflowModel,
				getter(workflowId, parent) {
					const stores = getStores(parent);
					return stores.workflows.getOne(getId(workflowId));
				},
			})
		),
		stageId: types.maybe(WorkflowActionableStageReference),
		slotId: types.maybe(WorkflowStageInputSlotReference),
		versionId: types.maybe(assetVersionReference),
		signedURL: types.string,
	})
	.views((self) => ({
		get url() {
			return getClient(self).buildPath(`/assets/${self._id}/data`);
		},
		get relatedDamAssets(): Promise<string[]> {
			if (!self.workflowId) {
				return Promise.resolve([]);
			}
			const idsPromise = getClient(self).get(
				`/assets/by-workflow/${getId(self.workflowId)}`
			);
			return idsPromise
				.then((ids: string[]) => ids.filter((id) => id !== self._id))
				.catch((err) => {
					_logError(err);
					return [];
				});
		},
	}));

export interface DamAssetModel extends Infer<typeof DamAssetModelInferred> {}

export const DamAssetModel: DamAssetModel = DamAssetModelInferred;

export interface DamAssetSnapshot extends SnapshotIn<DamAssetModel> {}
export interface DamAsset extends Instance<DamAssetModel> {}

export interface HasDamAsset {
	asset: DamAsset;
}

export const DamAssetReference: LazyReference<DamAsset> = lazyReference({
	model: DamAssetModel,
	getter(assetId, parent) {
		const stores = getStores(parent);
		return stores.assets.getOne(getId(assetId));
	},
});

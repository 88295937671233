import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

import themeStore from '../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// styled components
const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100vh;

	@keyframes run {
		0% {
			color: ${colors.lightGrey};
			left: -90px;
		}
		50% {
			color: ${colors.darkestGrey};
		}
		100% {
			color: ${colors.lightGrey};
			left: 90px;
		}
	}
`;

const LoadingDot = styled.div`
	position: relative;
	font-size: 25px;
	animation: ease-in-out infinite alternate;
	animation-name: run;
	animation-duration: 1.2s;
`;

// interfaces
interface Props {
	label?: string;
}

// components
export const Loading = () => (
	<LoadingWrapper>
		<LoadingDot>•</LoadingDot>
	</LoadingWrapper>
);

export const LoadingRow = (props: Props) => {
	const label = props.label ? props.label : 'Loading...';

	return (
		<Row>
			<Col xs={12}>
				<p>{label}</p>
			</Col>
		</Row>
	);
};

export default Loading;

import React from 'react';
import { useObserver } from 'mobx-react';
import { getParentOfType } from 'mobx-state-tree';
import { humanReadableFileSizeSI } from '../../../common';
import { CollaboratorAvatarLg, SmallText } from '../../../components/ui';
import { AssetUploadEvent, StageEvent } from '../../../models/StageEventModel';
import { EventType } from '../../../models/StageEventTypeModel';
import themeStore from '../../../theme/models/ThemeStore';
import { WorkflowStageModel } from '../../models';

import {
	AssetVersionLink,
	EventDetails,
	MessageContainer,
	StageTitle,
	TimelineItem,
	TimelineList,
} from './timeline.styled-components';
import { workflowRoute } from '../../workflow-routes';
import { getCreationDateStr } from '../../../models/CreatableEntityModel';

interface AssetLinkProps {
	event: AssetUploadEvent;
}

interface TimelineLogProps {
	event: StageEvent;
	displayStageTitle?: boolean;
}

interface TimelineProps {
	events: ReadonlyArray<StageEvent>;
	displayStageTitle?: boolean;
}

const AssetLink = ({ event }: AssetLinkProps) => (
	<AssetVersionLink
		to={`${workflowRoute(event.workflow)}/assets/${event.version._id}`}
	>
		{event.fileName} ({humanReadableFileSizeSI(event.version.fileSizeBytes)})
	</AssetVersionLink>
);

export const TimelineLog = (props: TimelineLogProps) => {
	const { event, displayStageTitle } = props;
	const isUploadEvent = event.type === EventType.assetUpload;
	const parent = getParentOfType(event, WorkflowStageModel);

	const render = () => (
		<TimelineItem>
			<article>
				{displayStageTitle ? (
					<StageTitle>
						{themeStore._.stage}: {parent.title}
					</StageTitle>
				) : null}
				<EventDetails className="text-muted">
					<CollaboratorAvatarLg
						image={event.createdBy.picture}
						className="mr-2"
					/>
					<MessageContainer>
						{event.createdBy.name} {event.asString}
						{isUploadEvent ? (
							<AssetLink event={event as AssetUploadEvent} />
						) : null}
						<SmallText className="my-1">{getCreationDateStr(event)}</SmallText>
						{event.message ? (
							<span className="text-dark d-block">{event.message}</span>
						) : (
							''
						)}
					</MessageContainer>
				</EventDetails>
			</article>
		</TimelineItem>
	);

	return useObserver(render);
};

export const Timeline = (props: TimelineProps) => {
	const { events, displayStageTitle } = props;

	return (
		<TimelineList>
			{events.map((event: StageEvent) => (
				<TimelineLog
					key={event._id}
					event={event}
					displayStageTitle={displayStageTitle}
				/>
			))}
		</TimelineList>
	);
};

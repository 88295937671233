import { Container } from 'reactstrap';
import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

import { AuthContext } from '../core';
import { focus } from '../theme/ui/ui';
import themeStore from '../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// styled components
const FooterContainer = styled.footer`
	display: flex;
	position: absolute;
	align-items: center;
	background-color: ${colors.white};
	border-top: 1px solid ${colors.grey};
	bottom: 0;
	height: 5em;
	margin-top: 5em;
	width: 100%;
`;

const StyledLink = styled(Link)`
	color: ${colors.primary};

	&:hover {
		color: ${colors.primaryHighlighted};
	}

	&:focus {
		${focus};
	}
`;

// component
const Footer = () => {
	const auth = React.useContext(AuthContext);

	const renderTermsLink = () => (
		<p className="text-muted float-right">
			<StyledLink to="/admin/terms">Terms &amp; Conditions</StyledLink>
		</p>
	);

	const render = () => (
		<FooterContainer>
			<Container className="clearfix">
				<p className="text-muted float-left">
					© {new Date().getFullYear()} Altavia Group
				</p>
				{auth.isAuthenticated ? renderTermsLink() : null}
			</Container>
		</FooterContainer>
	);

	return render();
};

export default Footer;

import React from 'react';
import { Router } from '@reach/router';
import WorkflowDashboard from '../dashboard/dashboard.view';
import AssetsIndexView from '../dam-assets/assets-index.view';
import AssetDetailsView from '../dam-assets/asset-details.view';
import EditDAMAssetView from '../dam-assets/edit-dam-asset.view';
import VersionsComparisonView from '../dam-assets/versions-comparison.view';
import AssetCollectionsIndexView from '../dam-asset-collections/asset-collections-index.view';
import AssetCollectionDetailsView from '../dam-asset-collections/asset-collection-details.view';
import AssetCollectionEditView from '../dam-asset-collections/asset-collection-edit.view';
import AssetCollectionCreationView from '../dam-asset-collections/asset-collection-creation.view';
import TemplatesIndexView from '../workflow-templates/template-index.view';
import TemplateDetailsView from '../workflow-templates/template-details.view';
import TemplateEditView from '../workflow-templates/template-edit-view';
import TemplateCreationView from '../workflow-templates/template-creation.view';
import WorkflowsIndexView from '../workflows/workflows-index.view';
import VersionDetailsView from '../dam-assets/version-details.view';
import WorkflowDetailsView from '../workflows/workflow-details.view';
import WorkflowCreationView from '../workflows/workflow-creation.view';
import WorkflowCollectionsIndexView from '../workflow-collections/workflow-collections-index.view';
import WorkflowCollectionDetailsView from '../workflow-collections/workflow-collection-details.view';
import WorkflowCollectionEditView from '../workflow-collections/workflow-collection-edit.view';
import WorkflowCollectionCreationView from '../workflow-collections/workflow-collection-creation.view';
import AssignmentsIndexView from '../assignments/assignments-index.view';
import RoadblocksIndexView from '../roadblocks/roadblocks-index.view';
import ReportsIndexView from '../reports/reports-index.view';
import QueriesIndexView from '../queries/queries-index.view';
import AdminDashboardView from '../admin-dashboard/admin-dashboard.view';
import UserDetailsView from '../admin-dashboard/user-details.view';
import MetadataTemplateDetailsView from '../metadata-templates/metadata-template-details.view';
import MetadataTemplateCreationView from '../metadata-templates/metadata-template-creation.view';
import EditProfile from '../views/edit-profile.view';
import NotFound from '../views/not-found.view';
import TermsView from '../terms-and-conditions/terms.view';

const RouterGroup = ({ children }) => <>{children}</>;

export const appRoutes = (
	<Router>
		<WorkflowDashboard path="/" />

		<RouterGroup path="dam">
			<RouterGroup path="assets">
				<AssetsIndexView path="/" />
				<AssetDetailsView path=":assetId" />
				<EditDAMAssetView path=":assetId/edit" />
				<VersionsComparisonView path="comparison/:workflowAId/:versionAId/:workflowBId/:versionBId" />
			</RouterGroup>

			<RouterGroup path="collections">
				<AssetCollectionsIndexView path="/" />
				<AssetCollectionDetailsView path=":collectionId" />
				<AssetCollectionEditView path=":collectionId/edit" />
				<AssetCollectionCreationView path="new" />
			</RouterGroup>
		</RouterGroup>

		<RouterGroup path="workflow">
			<WorkflowDashboard path="/" />

			<RouterGroup path="templates">
				<TemplatesIndexView path="/" />
				<TemplateDetailsView path=":workflowTemplateId" />
				<TemplateEditView path=":workflowTemplateId/edit" />
				<TemplateCreationView path="new" />
			</RouterGroup>

			<RouterGroup path="workflows">
				<WorkflowsIndexView path="/" />
				<VersionDetailsView path=":workflowId/asset-versions/:versionId" />
				<WorkflowDetailsView path=":workflowId/*" />
				<WorkflowCreationView path="new" />
			</RouterGroup>

			<RouterGroup path="projects">
				<WorkflowCollectionsIndexView path="/" />
				<WorkflowCollectionDetailsView path=":projectId" />
				<WorkflowCollectionEditView path=":projectId/edit" />
				<WorkflowCollectionCreationView path="new" />
			</RouterGroup>

			<RouterGroup path="assignments">
				<AssignmentsIndexView path="/" />
			</RouterGroup>

			<RouterGroup path="roadblocks">
				<RoadblocksIndexView path="/" />
			</RouterGroup>
		</RouterGroup>

		<RouterGroup path="reports">
			<ReportsIndexView path="/" />
		</RouterGroup>

		<RouterGroup path="saved-queries">
			<QueriesIndexView path="/" />
		</RouterGroup>

		<RouterGroup path="dashboard">
			<AdminDashboardView path="/*" />
			<UserDetailsView path="/users/:userId" />
			<MetadataTemplateDetailsView path="/metadata-templates/:metadataTemplateId" />
			<MetadataTemplateCreationView path="/metadata-templates/new" />
		</RouterGroup>

		<RouterGroup path="terms">
			<TermsView path="/" />
		</RouterGroup>

		<EditProfile path="profile" />
		<EditProfile path="profile" />
		<NotFound default />
	</Router>
);

import {
	applyPatch,
	destroy,
	flow,
	getRoot,
	getSnapshot,
	IAnyStateTreeNode,
	Instance,
	SnapshotIn,
	SnapshotOut,
	types,
} from 'mobx-state-tree';
import { UserStore } from '../../accounts/models/UserStore';

import {
	capitalizeFirstLetter,
	enumKeys,
	generateID,
	getArrayParent,
	includesCaseInsensitive,
	lastSavedMessage,
	lazyReference,
	loadingValue,
	previousArrayItem,
} from '../../common/index';
import { _logError } from '../../common/log';
import { getClient } from '../../core/index';
import { BaseWorkflowModel } from '../../models/BaseWorkflowCommonModel';
import { EntityMetadataModel } from '../../metadata/models/EntityMetadataModel';
import { getStores, RootStore } from '../../stores/index';
import { StageActionType, StageType } from './StageTypes';
import { BaseWorkflowPhase } from '../../models/BaseWorkflowPhaseModel';
import NotificationModel, {
	NotificationType,
} from '../../notifications/NotificationModel';
import notificationStore from '../../notifications/NotificationStore';

import {
	isInputStage,
	TemplateParallelStage,
	TemplateParallelStageModel,
	TemplateRootStage,
	TemplateRootStageModel,
	TemplateSingleStage,
	TemplateSingleStageModel,
	TemplateStage,
	TemplateSubstage,
	TemplateSubstageModel,
} from './TemplateStageModel';
import { TemplateTransition } from './TemplateTransitionModel';

export enum TemplateState {
	draft = 'draft',
	final = 'final',
	archived = 'archived',
}

/**
 * Template to be used as a placeholder while loading.
 */
export const loadingTemplate: WorkflowTemplateSnapshot = {
	_id: loadingValue,
	createdAt: new Date(),
	createdBy: loadingValue,
};

const TemplateModelInferred = BaseWorkflowModel.named('WorkflowTemplate')
	.props({
		stages: types.array(TemplateRootStageModel),

		timesUsed: types.optional(types.number, 0),

		editingState: types.optional(
			types.enumeration(
				'WorkflowTemplateEditingState',
				enumKeys(TemplateState)
			),
			TemplateState.draft
		),

		metadata: types.optional(EntityMetadataModel, {}),
	})
	.volatile((self) => ({
		lastAction: self.updatedAt ? lastSavedMessage(self.updatedAt) : '',
	}))
	.actions((self) => ({
		setLastAction(lastAction: string): void {
			self.lastAction = lastAction;
		},

		save: flow(function* (snapshot = getSnapshot(self)) {
			const client = getClient(self);
			try {
				const updated = yield client.put(`/templates/${self._id}`, snapshot);
				self.lastAction = lastSavedMessage(new Date(updated.updatedAt));
				return updated;
			} catch (err) {
				_logError(err);
				self.lastAction = `Error saving: ${err.toString().substr(0, 100)}`;
			}
		}) as (
			snapshot?: WorkflowTemplateSnapshotOut
		) => Promise<WorkflowTemplateSnapshot>,

		addStage<T extends TemplateRootStage>(
			from: TemplateRootStage,
			newStage: T
		): T {
			const { forwardTransition } = from;

			if (forwardTransition) {
				// We need to splice the new stage in between
				newStage.addForwardTransitionTo(forwardTransition.targetStage);
				forwardTransition.targetStage = newStage;
			} else {
				// Adding to the last stage, just add a transition
				from.addForwardTransitionTo(newStage);
			}

			applyPatch(self, {
				op: 'add',
				path: `/stages/${self.stages.indexOf(from) + 1}`,
				value: newStage,
			});
			return newStage;
		},
	}))
	.views((self) => ({
		get displayState() {
			return capitalizeFirstLetter(self.editingState);
		},
		get isDraft() {
			return self.editingState === TemplateState.draft;
		},
		get isFinalized() {
			return self.editingState === TemplateState.final;
		},
		get isArchived() {
			return self.editingState === TemplateState.archived;
		},
		get isUntitled() {
			return includesCaseInsensitive(self.title, 'untitled');
		},
		get hasUntitledStages() {
			let response = false;
			self.stages.forEach((s) => {
				if (includesCaseInsensitive(s.title, 'untitled')) {
					response = true;
				}
			});
			return response;
		},
		get hasUnlabeledInputSlots() {
			let response = false;
			self.stages.forEach((stage) => {
				if (isInputStage(stage)) {
					stage.inputSlots.forEach((slot) => {
						if (
							includesCaseInsensitive(slot.label, 'untitled') ||
							includesCaseInsensitive(slot.label, 'unlabeled')
						) {
							response = true;
						}
					});
				}
			});
			return response;
		},
	}))
	.actions((self) => ({
		addSingleStage(
			from: TemplateRootStage,
			stageActionType: StageActionType
		): TemplateSingleStage {
			const newStage = TemplateSingleStageModel.create({
				type: StageType.single,
				_id: generateID(),
			});

			if (stageActionType === StageActionType.input) {
				newStage.addInputSlot();
			}

			return self.addStage<TemplateSingleStage>(from, newStage);
		},
		addParallelStage(from: TemplateRootStage): TemplateParallelStage {
			const newStage = TemplateParallelStageModel.create({
				type: StageType.parallel,
				_id: generateID(),
			});
			return self.addStage<TemplateParallelStage>(from, newStage);
		},
		deleteStage(stage: TemplateStage): void {
			if (TemplateSubstageModel.is(stage)) {
				this.deleteSubstage(stage);
			} else {
				this.deleteRootStage(stage);
			}
		},
		deleteRootStage(stage: TemplateRootStage): void {
			const { nextStage, previousStage } = stage;

			if (nextStage) {
				if (previousStage) {
					// Splice out this stage from the transition.
					// (There *must* be a forward transition because we found this previous stage)
					const transitionToCurrent: TemplateTransition = previousStage.forwardTransition!;

					destroy(transitionToCurrent);
					previousStage.addForwardTransitionTo(nextStage);
				} else {
					// Next stage is now the initial stage
					nextStage.initial = true;
				}
			} else if (previousStage) {
				// There was no next stage. Previous stage is now final.
				previousStage.final = true;
			} else {
				// If there was no next OR previous stage,
				// we shouldn't even be here (there's only one 1 stage)
				return;
			}

			destroy(stage);
		},
		addSubstage(
			from: TemplateSubstage | TemplateParallelStage
		): TemplateSubstage {
			if (TemplateSubstageModel.is(from)) {
				return from.parentStage.addSubstage(from);
			} else {
				return from.addSubstageGroup();
			}
		},
		deleteSubstage(substage: TemplateSubstage): void {
			const substageGroup = getArrayParent(substage);

			if (substageGroup.length <= 1) {
				return destroy(substageGroup);
			}

			const nextSubstage = substage.nextSubstage;
			if (nextSubstage) {
				const previousSubstage = previousArrayItem(substage);
				if (previousSubstage) {
					previousSubstage.addSubstageTransitionTo(nextSubstage);
				}
			}

			return destroy(substage);
		},
	}))
	.views((self) => ({
		get flatStages(): readonly TemplateStage[] {
			return self.stages.flatMap((stage: TemplateRootStage) => {
				if (TemplateParallelStageModel.is(stage)) {
					return [stage, ...stage.flatStages];
				}
				return stage;
			});
		},
		get allTransitions(): readonly TemplateTransition[] {
			return this.flatStages.flatMap(
				(stage: TemplateStage) => stage.transitions as TemplateTransition[]
			);
		},
		stagePointingTo(targetStage: TemplateRootStage): Maybe<TemplateRootStage> {
			return self.stages.find((s) => s.nextStage === targetStage);
		},
	}))
	.views((self) => ({
		get isOwnedByCurrentUser() {
			const userStore: UserStore = getRoot<RootStore>(self).users;
			const { currentUser } = userStore;
			return self.isOwner(currentUser._id);
		},
		getStagesBefore(stage: TemplateStage): ReadonlyArray<TemplateRootStage> {
			let searchStage: TemplateRootStage;

			if (TemplateSubstageModel.is(stage)) {
				searchStage = stage.parentStage;
			} else if (stage.initial) {
				return [];
			} else {
				searchStage = stage;
			}
			const stageIdx = self.stages.indexOf(searchStage);

			if (stageIdx > 0) {
				return self.stages.slice(0, stageIdx);
			}
			return [];
		},
		includesMentionOf(value: string): boolean {
			return (
				self.createdBy.includesMentionOf(value) ||
				includesCaseInsensitive(self.title, value) ||
				includesCaseInsensitive(self.editingState, value)
			);
		},
	}))
	.actions((self) => ({
		addPhase(phase: BaseWorkflowPhase): void {
			if (self.phases.some((p) => p.title === phase.title)) {
				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.WARNING,
						text: `"${phase.title}" already exists`,
					})
				);
			} else {
				self.phases.push(phase);
			}
		},
		removePhase(phase: BaseWorkflowPhase): void {
			destroy(phase);
		},
	}));

export interface WorkflowTemplateModel
	extends Infer<typeof TemplateModelInferred> {}

export const TemplateModel: WorkflowTemplateModel = TemplateModelInferred;

export interface WorkflowTemplate extends Instance<WorkflowTemplateModel> {}

export interface WorkflowTemplateSnapshot
	extends SnapshotIn<WorkflowTemplateModel> {}

export interface WorkflowTemplateSnapshotOut
	extends SnapshotOut<WorkflowTemplateModel> {}

export const TemplateReference = lazyReference<WorkflowTemplate>({
	model: TemplateModel,
	getter(identifier: string, parent: IAnyStateTreeNode) {
		return getStores(parent).templates.getOne(identifier);
	},
});

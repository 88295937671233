import { Col, Container, Row } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React, { useEffect } from 'react';

import { builtInWorkflowCollectionPredicates, Filter } from '../common/filters';
import { Heading } from '../components/ui';
import { indexViewStore } from '../index-view/models/IndexViewStore';
import { QueryTypes } from '../queries/models/QueryStore';
import { unwrapEvent } from '../common';
import { ViewType } from '../index-view/models/IndexViewModel';
import FilteredCollectionList from './components/filtered-workflow-collection-list.component';
import IndexViewToggler from '../index-view/components/index-view-toggler.component';
import PageSubheading from '../components/page-subheading.component';
import SaveQueryButton from '../components/save-query-button.component';
import SearchBar from '../components/search-bar/search-bar.component';
import SideBarFiltersNav from '../components/side-bar-filters/side-bar-filters-nav.component';
import { StorePaginator } from '../components/store-paginator/store-paginator.component';
import { useWorkflowCollectionStore } from '../stores';
import themeStore from '../theme/models/ThemeStore';
import windowModel from '../models/WindowModel';
import WorkflowCollectionListTable from './components/workflow-collection-list-table.component';

import { NewCollectionButton } from './components/workflow-collections-index.styled-components';
import { PermissionsCheck } from '../accounts/permissions-check.component';
import { useFilteredStore } from '../common/FilteredStore';

// constants
const workflowCollectionTerm = themeStore._.workflowCollection;
const baseWorkflowTerm = themeStore._.baseWorkflow;

const filterNavOptions = [
	{
		label: `All ${workflowCollectionTerm?.toLowerCase()}s`,
		value: undefined,
	},
	{
		label: 'Created by me',
		value: Filter.CREATED_BY,
	},
];

// component
const WorkflowCollectionsIndexView = () => {
	const workflowCollectionStore = useWorkflowCollectionStore();
	const filteredCollectionStore = useFilteredStore(
		workflowCollectionStore,
		builtInWorkflowCollectionPredicates
	);

	const workflowCollectionsViewOptions = indexViewStore.workflowCollections;

	useEffect(() => {
		const searchTerm = windowModel.getURLSearchParam('q');
		if (searchTerm) {
			filteredCollectionStore.setQuery(searchTerm);
		}
	}, [filteredCollectionStore]);

	const render = () => {
		const CollectionsListRenderer =
			indexViewStore.workflowCollections.viewType === ViewType.grid
				? FilteredCollectionList
				: WorkflowCollectionListTable;

		return (
			<Container>
				<Row>
					<Col lg={2} md={3} className="mb-md-0 mb-4">
						<PageSubheading text={baseWorkflowTerm} />
						<Heading>{workflowCollectionTerm}s</Heading>

						<PermissionsCheck check="canCreateProject">
							<NewCollectionButton to="./new">
								<FontAwesomeIcon icon={faPlus} className="mr-2" />
								New {workflowCollectionTerm?.toLowerCase()}
							</NewCollectionButton>
						</PermissionsCheck>

						<SideBarFiltersNav
							setFilter={unwrapEvent(filteredCollectionStore.setFilter)}
							options={filterNavOptions}
						/>
					</Col>

					<Col lg={10} md={9}>
						<Row className="mb-4">
							<Col md={9}>
								<SearchBar
									placeholder={`Search all ${workflowCollectionTerm?.toLowerCase()}s...`}
									onChange={unwrapEvent(filteredCollectionStore.setQuery)}
								/>
							</Col>
							<Col md={3} className="mt-md-0 mt-3">
								<SaveQueryButton
									className="float-md-right float-none"
									searchTerm={filteredCollectionStore.searchQuery}
									type={QueryTypes.WORKFLOW_COLLECTIONS}
								/>
							</Col>
						</Row>
						<hr />

						<div className="d-flex justify-content-end">
							<IndexViewToggler view={workflowCollectionsViewOptions} />
						</div>

						<CollectionsListRenderer
							collections={filteredCollectionStore.filteredEntities}
						/>

						<StorePaginator
							pages={filteredCollectionStore.pages}
							shouldPaginate={filteredCollectionStore.shouldPaginate}
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default WorkflowCollectionsIndexView;

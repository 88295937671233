import React from 'react';
import { RefinementListProvided } from 'react-instantsearch-core';
import { connectRefinementList } from 'react-instantsearch-dom';
import {
	Badge,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	Label,
	UncontrolledDropdown,
} from 'reactstrap';
import RenderWhen from '../render-when.component';
import { StyledListItem } from './refinement-list.styled-components';

interface RefinementListProps extends RefinementListProvided {
	label: string;
	searchable?: boolean;
}

const RefinementList = (props: RefinementListProps) => (
	<RenderWhen when={props.items.length > 0}>
		<UncontrolledDropdown setActiveFromChild direction="down">
			<DropdownToggle
				tag="a"
				className={`nav-link ${
					props.currentRefinement?.length ? '' : 'text-muted'
				}`}
				caret
			>
				{props.label}
			</DropdownToggle>
			<DropdownMenu>
				<RenderWhen when={props.searchable && props.items.length > 5}>
					<StyledListItem>
						<Input
							type="search"
							placeholder="Search filters..."
							onChange={(event) =>
								props.searchForItems(event.currentTarget.value)
							}
						/>
					</StyledListItem>
				</RenderWhen>
				{props.items.map((item) => (
					<StyledListItem key={item.label}>
						<FormGroup check>
							<Label check className="d-flex justify-content-between">
								<Input
									type="checkbox"
									onChange={(event) => {
										event.preventDefault();
										props.refine(item.value);
									}}
									checked={item.isRefined}
								/>
								<span>{item.label}</span>
								<Badge color="light" pill>
									{item.count}
								</Badge>
							</Label>
						</FormGroup>
					</StyledListItem>
				))}
			</DropdownMenu>
		</UncontrolledDropdown>
	</RenderWhen>
);

const CustomRefinementList = connectRefinementList(RefinementList);
export default CustomRefinementList;

import React from 'react';
import { Table } from 'reactstrap';

import { Cell } from '../../../components/tables.styled-components';
import { useAccountStore } from '../../../stores';

const renderNoResultsRow = () => (
	<tr>
		<Cell className="pt-4">No accounts yet</Cell>
	</tr>
);

const AccountsTable = () => {
	const accountStore = useAccountStore();
	const { allAccounts } = accountStore;

	// render methods
	const renderAccountList = () => (
		<>
			{allAccounts.map((account) => (
				<tr>
					<Cell key={account._id}>{account.title}</Cell>
				</tr>
			))}
		</>
	);

	const render = () => (
		<Table>
			<thead>
				<tr>
					<th>Name</th>
				</tr>
			</thead>
			<tbody>
				{allAccounts && allAccounts.length
					? renderAccountList()
					: renderNoResultsRow()}
			</tbody>
		</Table>
	);

	return render();
};

export default AccountsTable;

import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';

import { DamAsset } from '../../dam-assets/models/DamAssetModel';
import { doesAssetMatch } from '../../dam-assets/models/AssetStore';
import { useAssetStore } from '../../stores';
import DownshiftMultiSelect, {
	DownshiftMultiSelectProps,
} from '../../components/downshift-select/downshift-multi-select.component';
import themeStore from '../../theme/models/ThemeStore';

import { AssetCollection } from '../models/AssetCollectionModel';

const assetPluralTerm = `${themeStore._.asset}s`;

const CollectionAssetSelect = ({
	                               collection,
                               }: {
	collection: AssetCollection;
}) => {
	const assetStore = useAssetStore();

	const addAsset = useCallback(
		(asset: Nullable<DamAsset>) => {
			if (asset) {
				collection.addAsset(asset);
			}
		},
		[collection]
	);

	const removeAsset = useCallback(
		(asset: DamAsset) => collection.removeAsset(asset),
		[collection]
	);

	const doesNotAlreadyContain = useCallback(
		(asset: DamAsset) => collection.assets.indexOf(asset) < 0,
		[collection]
	);

	return useObserver(() => {
		const selectProps: DownshiftMultiSelectProps<DamAsset, DamAsset> = {
			label: assetPluralTerm,
			selectionState: {
				selection: collection.assets,
				options: assetStore.all.filter(doesNotAlreadyContain),
				searchPredicate: doesAssetMatch,
			},
			selectionActions: {
				select: addAsset,
				unselect: removeAsset,
			},
		};

		return <DownshiftMultiSelect {...selectProps} />;
	});
};

export default CollectionAssetSelect;

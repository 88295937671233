import React, { ReactElement } from 'react';
import { WorkflowParallelStageModel } from '../../models';
import { SelectedWorkflowStage } from '../../workflow.contexts';
import { CardDetails } from './workflow-stage-card.styled-components';

interface StageCardDetailsProps {
	children?: ReactElement;
}

const StageCardDetails = (props: StageCardDetailsProps) => {
	const stage = SelectedWorkflowStage.presentValue;
	const { children } = props;

	if (!WorkflowParallelStageModel.is(stage)) {
		return <CardDetails>{children}</CardDetails>;
	} else return null;
};

export default StageCardDetails;

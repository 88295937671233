import {
	getPropertyMembers,
	getSnapshot,
	getType,
	IAnyModelType,
	Instance,
	isStateTreeNode,
	SnapshotIn,
	SnapshotOut,
} from 'mobx-state-tree';
import { useState } from 'react';
import { asValidSnapshot } from './mobx.utils';
import {
	IValidationContext,
	IValidationResult,
} from 'mobx-state-tree/dist/core/type/type-checker';
import { action, computed, observable, toJS } from 'mobx';
import { ExtractProps } from 'mobx-state-tree/dist/types/complex-types/model';

export interface MstEditor<T extends AMT> extends MstModelEditor<T> {}

/**
 * Takes either an MST model instance, or a snapshot AND a specified type.
 */
export function useMstEditor<T extends AMT>(
	instance: Instance<T>,
	_type?: T
): MstEditor<T>;
export function useMstEditor<T extends AMT>(
	snapshot: Partial<SnapshotIn<T>>,
	type: T
): MstEditor<T>;
export function useMstEditor<T extends AMT>(
	instance: Instance<T>,
	type?: T
): MstEditor<T> {
	// Make sure the editor is only initialized once.
	const [editor] = useState(() => new MstModelEditor(instance, type));
	return editor;
}

type AMT = IAnyModelType;
type InputKey<T extends AMT> = string & keyof ExtractProps<T>;

type Patchers<T extends AMT> = {
	[K in InputKey<T>]: (v: SnapshotIn<T>[K]) => void;
};

type Validators<T extends AMT> = {
	[K in InputKey<T>]: (
		v: SnapshotIn<T>[K],
		ctx: IValidationContext
	) => IValidationResult;
};

type PathErrors<T extends AMT> = {
	[K in InputKey<T>]?: IValidationResult;
};

class MstModelEditor<T extends AMT> {
	@observable
	public readonly editableInstance: Partial<SnapshotIn<T>> = {};

	@observable
	public readonly pathErrors: Readonly<PathErrors<T>> = {} as any;

	@computed
	public get validSnapshot(): Maybe<SnapshotOut<T>> {
		// ⚠️ `toJS` is used because MST wants plain objects:
		// it will throw if an observable object is passed as a snapshot.
		const maybeValidSnapshot = toJS(this.editableInstance);
		return asValidSnapshot(maybeValidSnapshot, this.modelType);
	}

	public readonly patchers: Readonly<Patchers<T>>;

	private readonly modelType: T;
	private readonly validators: Validators<T>;

	constructor(instance: Instance<T>, _type?: T);
	constructor(snapshot: Partial<SnapshotIn<T>>, type: T);
	constructor(target: Instance<T> | Partial<SnapshotIn<T>>, type: T) {
		if (isStateTreeNode(target)) {
			this.modelType = getType(target) as T;
			this.editableInstance = { ...getSnapshot(target) };
		} else {
			this.modelType = type;
			this.editableInstance = { ...target };
		}

		({
			patchers: this.patchers,
			validators: this.validators,
		} = this.extractFromType());
	}

	@action
	private patchPath<K extends InputKey<T>>(k: K, v: SnapshotIn<T>[K]) {
		this.editableInstance[k] = v;
		(this.pathErrors as PathErrors<T>)[k] = this.validators[k](v, []);
	}

	private extractFromType(): {
		validators: Validators<T>;
		patchers: Patchers<T>;
	} {
		const { properties } = getPropertyMembers(this.modelType);

		return Object.keys(properties).reduce(
			(acc, key: string & InputKey<T>) => {
				acc.patchers[key] = this.patchPath.bind(this, key);

				const propType = properties[key];
				acc.validators[key] = propType.validate.bind(propType);

				return acc;
			},
			{
				validators: {} as Validators<T>,
				patchers: {} as Patchers<T>,
			}
		);
	}
}

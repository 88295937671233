import React from 'react';
import styled from 'styled-components';

import { Subheading } from './ui';


// styled components
const StyledSubheading = styled(Subheading)`
	font-size: 11px;
`;


// interface
interface PageSubheadingProps {
	text: string;
}


// component
const PageSubheading = (props: PageSubheadingProps) => {
	return (
		<StyledSubheading className="text-muted">
			{ props.text }
		</StyledSubheading>
	)
}

export default PageSubheading;

import ThemeModel from '../models/ThemeModel';
import { toRGBA } from '../../common/color.utils';

// main colors
const primary = '#443738';
const primaryHighlighted = '#925041';

const secondary = '#ce0954';
const secondaryHighlighted = '#f10954';

// status colors
const pipeline = primary;

// a11y
const focus = toRGBA(primary, 0.5);

export const kdpTheme = ThemeModel.create({
	_id: 'kdp',
	title: 'KDP',
	images: {
		desktopLogo: '/themes/kdp/logo-desktop.svg',
		mobileLogo: '/themes/kdp/logo-mobile.svg',
		loginBackground: '/themes/kdp/login-bg.jpg',
	},
	colors: {
		primary,
		primaryHighlighted,
		secondary,
		secondaryHighlighted,
		pipeline,
		focus,
	},
});

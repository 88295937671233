import {
	applySnapshot,
	flow,
	getParentOfType,
	IAnyModelType,
	Instance,
	SnapshotOut,
	types,
} from 'mobx-state-tree';
import { UserReference } from '../../accounts/models/UserReference';

import { getClient } from '../../core';
import {
	getParentWithTypeIn,
	humanReadableFileSizeSI,
	includesCaseInsensitive,
	loadingValue,
} from '../../common';
import { optionalString, tolerantDate, urlString } from '../../models/common';
import {
	Workflow,
	WorkflowModel,
	WorkflowSingleStageModel,
	WorkflowStageInputSlotModel,
	WorkflowSubstageModel,
} from '../../workflows/models';
import { AssetMetadata, AssetMetadataModel } from './AssetMetadataModel';
import { _logError } from '../../common/log';

const AssetVersionModelInferred = types
	.model('AssetVersion', {
		_id: types.identifier,

		path: types.string,
		previewURL: types.maybe(urlString),
		signedURL: types.maybe(urlString),

		fileName: types.string,
		title: optionalString,

		type: optionalString,
		fileSizeBytes: types.optional(types.integer, 0),
		md5: optionalString,

		metadata: types.optional(AssetMetadataModel, {}),

		createdAt: types.optional(tolerantDate, () => new Date()),
		createdBy: types.maybe(UserReference),

		privacy: types.maybe(
			types.enumeration('AssetPrivacy', ['Public', 'Readonly'])
		),

		downloads: types.optional(types.number, 0),
		views: types.optional(types.number, 0),
	})
	.views((self) => ({
		get size(): string {
			return humanReadableFileSizeSI(self.fileSizeBytes);
		},
		get url(): string {
			const slot = getParentOfType(self, WorkflowStageInputSlotModel);
			const stage = getParentWithTypeIn(slot, [
				WorkflowSingleStageModel,
				WorkflowSubstageModel,
			]);
			const workflow = getParentOfType(stage, WorkflowModel);

			const path: string = `/workflows/${workflow._id}/stages/${stage._id}/assets/${slot._id}/v/${self._id}`;

			return getClient(self).buildPath(path);
		},
		includesMentionOf(value: string): boolean {
			return (
				includesCaseInsensitive(self.title, value) ||
				includesCaseInsensitive(self.fileName, value) ||
				includesCaseInsensitive(self.type, value) ||
				self.createdBy?.includesMentionOf(value) ||
				self.metadata.includesMentionOf(value)
			);
		},
		isSame(asset: AssetVersion, tolerantMatch?: boolean): boolean {
			if (tolerantMatch !== undefined && tolerantMatch) {
				return asset.fileName === self.fileName && asset.type === self.type;
			} else {
				return (
					asset._id === self._id &&
					asset.fileName === self.fileName &&
					asset.createdAt === self.createdAt &&
					asset.createdBy === self.createdBy &&
					asset.type === self.type
				);
			}
		},
	}))
	.views((self) => ({
		getNewSignedURL(): Promise<string> {
			const url = self.url;
			if (!url) {
				return Promise.resolve('');
			}

			return getClient(self).getForRedirect(self.url);
		},
		get _appUrl(): string {
			try {
				const wf: Workflow = getParentOfType(
					self,
					WorkflowModel as IAnyModelType
				);
				return `/admin/workflow/workflows/${wf._id}/assets/${self._id}`;
			} catch (error) {
				_logError(error);
				// Return a URL to current page (i.e. noop)
				return '.';
			}
		},
	}))
	.actions((self) => ({
		async downloadAssetFile(): Promise<void> {
			const signedURL = await self.getNewSignedURL();
			window.open(
				signedURL,
				self._id /* todo add features to hide resulting window? */
			);
		},
		save: flow(function* save(assetSnapshot: SnapshotOut<typeof self>) {
			const client = getClient(self);
			const updatedAsset = yield client.patch(
				`/assets/${self._id}`,
				assetSnapshot
			);

			applySnapshot(self, updatedAsset);
			return updatedAsset;
		}),
	}));

export interface AssetVersionModel
	extends Infer<typeof AssetVersionModelInferred> {}

export const AssetVersionModel: AssetVersionModel = AssetVersionModelInferred;

export interface AssetVersion extends Instance<AssetVersionModel> {}

export interface HasAssetVersion {
	asset: AssetVersion;
}

export interface AssetVersionUploadData {
	message: string;
	metadata: AssetMetadata;
}

/**
 * Asset to be used as a placeholder while loading.
 */
export const $loadingAssetVersion: AssetVersion = AssetVersionModel.create({
	_id: loadingValue,
	path: loadingValue,
	fileName: 'Loading...',
	fileSizeBytes: 0,
	createdBy: loadingValue,
});

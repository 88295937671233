import { getParentOfType } from 'mobx-state-tree';
import React from 'react';
import { buildStageUrl } from '../../../common/links';
import {
	EntityPropLabel,
	EntityPropListItem,
} from '../../../components/entity-details.styled-components';
import { useWorkflowStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';
import {
	Workflow,
	WorkflowActionableStage,
	WorkflowModel,
} from '../../../workflows/models';
import { AssetVersion } from '../../models/AssetVersionModel';
import { StyledLink } from './asset-details-tab-set.styled-components';

const stageTerm = themeStore._.stage;

const AssetWorkflowProps = (props: { asset: AssetVersion }) => {
	const { asset } = props;

	const workflowStore = useWorkflowStore();

	let workflow: Maybe<Workflow> = undefined;
	let stage: Maybe<WorkflowActionableStage> = undefined;

	// get workflow
	try {
		workflow = getParentOfType(asset, WorkflowModel);
	} catch (error) {
		return null;
	}

	// get stage
	try {
		stage = workflowStore.getStageWithAsset(asset);
	} catch (error) {
		return null;
	}

	// render list item
	return workflow && stage ? (
		<EntityPropListItem>
			<EntityPropLabel>Added in {stageTerm.toLowerCase()}</EntityPropLabel>
			<StyledLink to={buildStageUrl(workflow, stage)}>
				{workflow.title} ({stage.title})
			</StyledLink>
		</EntityPropListItem>
	) : null;
};

export default AssetWorkflowProps;

import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'reactstrap';
import PageSubheading from '../components/page-subheading.component';
import { StorePaginator } from '../components/store-paginator/store-paginator.component';
import { Heading, ScrollCard } from '../components/ui';
import { useTemplateStore } from '../stores';
import themeStore from '../theme/models/ThemeStore';
import { CreateTemplateButton } from './components/template-index.styled-components';
import TemplateListTable from './components/template-list-table.component';
import { useObserver } from 'mobx-react';

const baseWorkflowTerm = themeStore._.baseWorkflow;
const TemplatesIndexView = () => {
	const templateStore = useTemplateStore();

	const render = () => (
		<Container>
			<Row>
				<Col md={3}>
					<PageSubheading text={baseWorkflowTerm} />
					<Heading>Templates</Heading>

					<CreateTemplateButton to="./new">
						<FontAwesomeIcon icon={faPlus} className="mr-2" />
						New template
					</CreateTemplateButton>
				</Col>

				<Col md={9}>
					<ScrollCard>
						<TemplateListTable />
					</ScrollCard>

					{templateStore.pages.totalPages > 1 ? (
						<StorePaginator pages={templateStore.pages} />
					) : null}
				</Col>
			</Row>
		</Container>
	);

	return useObserver(render);
};

export default TemplatesIndexView;

import React, { useCallback } from 'react';
import DownshiftSingleSelect, {
	DownshiftSingleSelectProps,
} from '../../components/downshift-select/downshift-single-select.component';
import { useObserver } from 'mobx-react';
import {
	BaseWorkflowPhase,
	doesPhaseMatch,
} from '../../models/BaseWorkflowPhaseModel';
import { SelectedTemplate, SelectedTemplateStage } from '../template.contexts';
import { applyPatch } from 'mobx-state-tree';

const TemplateStagePhaseSelect = () => {
	const template = SelectedTemplate.presentValue;
	const stage = SelectedTemplateStage.presentValue;

	const removePhase = useCallback(() => {
		applyPatch(stage, {
			op: 'replace',
			path: '/phase',
			value: undefined,
		});
	}, [stage]);

	const selectPhase = useCallback(
		(phase: Nullable<BaseWorkflowPhase>) => {
			if (phase) {
				applyPatch(stage, {
					op: 'replace',
					path: '/phase',
					value: phase._id,
				});
			} else {
				removePhase();
			}
		},
		[removePhase, stage]
	);

	return useObserver(() => {
		const selectProps: DownshiftSingleSelectProps<
			BaseWorkflowPhase,
			BaseWorkflowPhase
		> = {
			label: 'Phase',
			selectionState: {
				selection: stage.phase,
				options: template.phases,
				searchPredicate: doesPhaseMatch,
			},
			selectionActions: {
				select: selectPhase,
				unselect: stage.phase && removePhase,
			},
		};

		return <DownshiftSingleSelect {...selectProps} />;
	});
};

export default TemplateStagePhaseSelect;

import { useObserver } from 'mobx-react';
import { applyPatch } from 'mobx-state-tree';
import React, { useCallback } from 'react';
import { DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import ActionsDropdownToggle from '../../components/action-dropdown-toggle.component';
import { DropDownMenuItem } from '../../components/drop-down-menu.component';
import ConfirmationDialog from '../../components/modals/confirmation-dialog.component';
import WarningModal from '../../components/modals/warning-modal.component';
import { UnstyledLink } from '../../dashboard/components/assignment-panel.styled-components';
import { useModalCreator } from '../../stores/ModalStack';
import themeStore from '../../theme/models/ThemeStore';

import { TemplateState, WorkflowTemplate } from '../models';
import { WarningListItem } from './template-stage-edit-form.styled-components';

interface TemplateOwnersListProps {
	template: WorkflowTemplate;
}

const canFinalizeTemplate = (template: WorkflowTemplate) => {
	// TODO: add more conditions as needed
	return !(
		template.isUntitled ||
		template.hasUntitledStages ||
		template.hasUnlabeledInputSlots
	);
};

const TemplateActions = (props: TemplateOwnersListProps) => {
	const modalStack = useModalCreator();

	const showFinalizeModal = useCallback(() => {
		if (canFinalizeTemplate(props.template)) {
			const finalizeTemplate = () =>
				applyPatch(props.template, {
					op: 'replace',
					path: `/editingState`,
					value: TemplateState.final,
				});

			modalStack.addModal(
				<ConfirmationDialog
					header="Finalize Template"
					onConfirm={finalizeTemplate}
				>
					<p>
						Are you certain you want to finalize this template? You won't be
						able to edit it in the future.
					</p>
				</ConfirmationDialog>
			);
		} else {
			modalStack.addModal(
				<WarningModal header="Warning" buttonLabel="OK">
					<p>It is not currently possible to finalize this template.</p>
					<p>Please address the issues below and try again.</p>
					<ul>
						{props.template.isUntitled ? (
							<WarningListItem>
								Template has not been given a name.
							</WarningListItem>
						) : null}
						{props.template.hasUntitledStages ? (
							<WarningListItem>
								Not all {themeStore._.stage.toLowerCase()}s have been given a
								title.
							</WarningListItem>
						) : null}
						{props.template.hasUnlabeledInputSlots ? (
							<WarningListItem>
								Not all input labels have been labeled.
							</WarningListItem>
						) : null}
					</ul>
				</WarningModal>
			);
		}
	}, [props.template, modalStack]);

	const renderComponent = () => (
		<UncontrolledDropdown size="sm" className="mt-2">
			<ActionsDropdownToggle caret color="light">
				Actions
			</ActionsDropdownToggle>
			<DropdownMenu right>
				<DropDownMenuItem className="secondary" onClick={showFinalizeModal}>
					Finalize
				</DropDownMenuItem>
				<DropDownMenuItem>
					<UnstyledLink to="./edit">Edit details</UnstyledLink>
				</DropDownMenuItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);

	return useObserver(renderComponent);
};

export default TemplateActions;

import styled from 'styled-components';


export interface AvatarProps {
	image: string;
}

export const Avatar = styled.div<AvatarProps>`
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background-color: white;
  background-image: url('${ props => props.image }');
  background-size: cover;
  background-position: center center;
`;

import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';

import App from './App';
import errorMonitor from './third-parties/error-monitor.service';

errorMonitor.initialize();

ReactDOM.render(<App />, document.getElementById('root'));

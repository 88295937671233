import { ThemeColorsModel, ThemeGreysModel } from './ThemeColorsModel';
import ThemeImagesModel from './ThemeImagesModel';
import ThemeTermsModel from './ThemeTermsModel';
import { Instance, types } from 'mobx-state-tree';

export const ThemeModel = types.model('Theme', {
	_id: types.identifier,
	title: types.string,
	colors: types.optional(types.compose(ThemeGreysModel, ThemeColorsModel), {}),
	images: types.optional(ThemeImagesModel, {}),
	terms: types.optional(ThemeTermsModel, {}),
});

export interface ThemeModel extends Infer<typeof ThemeModel> {}
export interface Theme extends Instance<ThemeModel> {}

export default ThemeModel;

import React from 'react';
import { Form, TabPane } from 'reactstrap';

import { AsideContent } from './template-stage-edit-form.styled-components';
import TransitionSelect from './transition-select.component';
import TemplateStageEditPanelProps from './template-stage-edit-panel-props';

// component
const TransitionsPanel = (props: TemplateStageEditPanelProps) => {
	return (
		<TabPane
			tabId={props.tabId}
			role="tabpanel"
			aria-labelledby={props.labelledBy}
		>
			<AsideContent>
				<Form>
					<TransitionSelect />
				</Form>
			</AsideContent>
		</TabPane>
	);
};

export default TransitionsPanel;

import React from 'react';
import { Redirect, Router } from '@reach/router';
import { ThemeProvider } from 'styled-components';

import { authProvider, AuthContext } from './core';
import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';
import GlobalStyle from './theme/GlobalStyle';
import LoginCallback from './views/login-callback';
import { themeStore } from './theme/models/ThemeStore';

import './styles/App.scss';

const App = () => (
	<ThemeProvider theme={themeStore.selectedTheme}>
		<GlobalStyle />
		<AuthContext.Provider value={authProvider}>
			<Router>
				<Redirect noThrow from="/" to="/admin" />
				<AdminLayout path="admin/*" />
				<AuthLayout path="auth/*" />
				<LoginCallback path="callback" />
			</Router>
		</AuthContext.Provider>
	</ThemeProvider>
);

export default App;

import { navigate } from '@reach/router';
import { getSnapshot } from 'mobx-state-tree';
import React, { FormEvent, useCallback, useRef } from 'react';
import { useStores, useTemplateStore } from '../../stores';
import { emptyTemplate } from '../models';
import EditTemplateForm from './edit-template-form.component';

const AddTemplateForm = () => {
	const templateStore = useTemplateStore();

	const templateRef = useRef(emptyTemplate(useStores()));

	const addTemplate = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const createdWorkflow = await templateStore.createOne(
				getSnapshot(templateRef.current)
			);

			return navigate(`/admin/workflow/templates/${createdWorkflow._id}/`);
		},
		[templateStore]
	);

	return (
		<EditTemplateForm
			selectedTemplate={templateRef.current}
			onSubmit={addTemplate}
			buttonLabel="Next step →"
		/>
	);
};
export default AddTemplateForm;

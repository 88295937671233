import { useObserver } from 'mobx-react';
import { applyPatch } from 'mobx-state-tree';
import React, { FormEvent, useCallback, useEffect } from 'react';
import { Col, Form, FormFeedback, Row } from 'reactstrap';
import {
	doesNameableMatch,
	isIdentifiableLoaded,
	usePatchers,
} from '../../common';
import { unwrapEvent } from '../../common/hooks';
import DownshiftSingleSelect from '../../components/downshift-select/downshift-single-select.component';
import { LabeledInput, SubmitButton } from '../../components/forms';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import { useTemplateStore } from '../../stores';
import themeStore from '../../theme/models/ThemeStore';
import { WorkflowTemplate } from '../../workflow-templates/models';
import { Workflow } from '../models/WorkflowModel';
import { CustomDatePicker } from './workflow-details-tab-set/details-panel.styled-components';
import { WorkflowForCreationModel } from '../models/WorkflowStore';

const workflowTerm = themeStore._.workflow;

interface EditWorkflowFormProps {
	workflow: Workflow;
	onSubmit: (event: FormEvent) => Promise<unknown>;
	templateUsed: string;
}

const EditWorkflowForm = (props: EditWorkflowFormProps) => {
	const { workflow, onSubmit, templateUsed } = props;

	const templateStore = useTemplateStore();
	const propertyPatcher = usePatchers(workflow);

	const patchTemplateUsed = useCallback(
		(template: Maybe<Nullable<WorkflowTemplate>>) => {
			if (template) {
				applyPatch(workflow, {
					op: 'replace',
					path: `/templateUsed`,
					value: template._id,
				});
				workflow.metadata.applyTemplateMetadata(template.metadata);
			} else {
				// todo there is probably a cleaner way to achieve this.
				if (WorkflowForCreationModel.is(workflow)) {
					applyPatch(workflow, {
						op: 'replace',
						path: `/templateUsed`,
						value: undefined,
					});
				} else {
					return false;
				}
			}
		},
		[workflow]
	);

	useEffect(() => {
		// if templateUsed is passed in, apply metadata
		if (templateUsed) {
			workflow.metadata.applyTemplateMetadata(workflow.templateUsed.metadata);
		}
	}, [workflow, templateUsed]);

	return useObserver(() => {
		const isFormValid =
			workflow.title !== '' && isIdentifiableLoaded(workflow.templateUsed);

		return (
			<Form>
				<Row form>
					<Col md={12}>
						<LabeledInput
							label="Title*"
							type="text"
							name="title"
							id="title"
							value={workflow.title}
							onChange={unwrapEvent(propertyPatcher('title'))}
							required
							inputValid={workflow.title !== ''}
							errorMsg="Title is required"
						/>
					</Col>
					<Col md={6}>
						<p className="my-1">Due date</p>
						<CustomDatePicker
							selected={workflow.dueDate}
							placeholderText="Click to select a date"
							onChange={propertyPatcher('dueDate')}
							className="mb-3 w-100"
						/>
					</Col>
					<Col md={12}>
						<DownshiftSingleSelect
							label={`${workflowTerm} Template*`}
							placeholder="Search by name..."
							selectionState={{
								selection: workflow.templateUsed,
								options: [...templateStore.finalizedTemplates],
								searchPredicate: doesNameableMatch,
							}}
							selectionActions={{
								select: patchTemplateUsed,
							}}
						/>
						<FormFeedback
							invalid="true"
							className={
								isIdentifiableLoaded(workflow.templateUsed)
									? 'd-none'
									: 'd-block'
							}
						>
							{workflowTerm} template is required
						</FormFeedback>
					</Col>
				</Row>

				<EntityMetadataForm metadata={workflow.metadata} />

				<SubmitButton
					onClick={onSubmit}
					label={`Save ${workflowTerm.toLowerCase()}`}
					disabled={!isFormValid}
				/>
			</Form>
		);
	});
};

export default EditWorkflowForm;

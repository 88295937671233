import React, { HTMLProps, useState } from 'react';
import styled from 'styled-components';

import { buildClassList } from '../../common/index';
import defaultAsset from '../../assets/images/default-asset.jpg';
import { HasAssetVersion } from '../models/AssetVersionModel';

// styled components
const DefaultAsset = styled.div`
	background: url(${defaultAsset}) center center / cover no-repeat;
	height: 172px;
	margin-top: -1.25em;
`;

const Thumbnail = styled.img`
	display: table;
	box-shadow: 0 0.125rem 0.5625rem rgba(0, 0, 0, 0.13);
	height: 150px;
	margin-left: auto;
	margin-right: auto;
	width: auto;
`;

// interface
interface Props {
	className?: string;
	src?: string;
	alt?: string;
}

// component
const AssetCardThumbnail = (
	props: HasAssetVersion & HTMLProps<HTMLImageElement>
) => {
	const [isValid, setValid] = useState(true);

	const {
		asset,
		src = asset.previewURL,
		alt = asset.fileName,
		className,
	} = props;

	if (asset.type === 'application/zip') {
		return <DefaultAsset />;
	}

	if (isValid && src) {
		return (
			<Thumbnail
				onError={() => setValid(false)}
				className={buildClassList('card-thumbnail', className)}
				src={src}
				alt={alt}
			/>
		);
	} else {
		return <DefaultAsset />;
	}
};

export default AssetCardThumbnail;

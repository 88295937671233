import { Filter } from './filters';
import { action, computed, observable } from 'mobx';
import { EntityStore } from './mobx.utils';
import { IAnyModelType, Instance } from 'mobx-state-tree';
import { useState } from 'react';
import { chunk, debounce } from 'lodash';

type EntityFilter<T> = Maybe<Filter | Predicate<T>>;

class FilteredStore<M extends IAnyModelType> {
	@observable
	private state = {
		predicate: undefined as Maybe<Filter | Predicate<Instance<M>>>,
		query: '',
		currentPage: this.store.pages.currentPage - 1,
	};

	private readonly matchesQuery = (c: {
		includesMentionOf(x: string): boolean;
	}) => c.includesMentionOf(this.state.query);

	constructor(
		private readonly store: EntityStore<M>,
		private readonly filters: Record<string, Predicate<Instance<M>>>,
		private readonly minimumQueryLength = 2
	) {}

	@computed
	public get predicate(): Maybe<Filter | Predicate<Instance<M>>> {
		return this.state.predicate;
	}

	@computed
	public get shouldPaginate(): boolean {
		return this.pages.totalPages > 1;
	}

	@computed
	public get searchQuery(): string {
		return this.state.query;
	}

	@computed
	private get entityPages(): Instance<M>[][] {
		const pageLength = Math.max(this.store.currentPage.length, 12);
		return chunk(this.filteredEntities, pageLength);
	}

	@computed
	public get pages(): PagesState<Instance<M>> {
		if (!this.predicate) {
			return this.store.pages;
		}

		return {
			totalPages: this.entityPages.length,
			currentPage: this.state.currentPage,
			setCurrentPage: this.setCurrentPage,
		};
	}

	@action
	setCurrentPage = (page: number) => {
		this.state.currentPage = page;
	};

	@computed
	get currentEntityPage(): readonly Instance<M>[] {
		if (!this.predicate) {
			return this.store.currentPage;
		}
		return this.entityPages[this.state.currentPage];
	}

	@computed
	public get filteredEntities(): ReadonlyArray<Instance<M>> {
		if (!this.predicate) {
			return this.store.currentPage;
		}

		const predicate =
			typeof this.predicate === 'function'
				? this.predicate
				: this.filters[this.predicate];

		return this.store.all?.filter(predicate) || [];
	}

	@action
	public setFilter = (filter: EntityFilter<Instance<M>>): void => {
		if (this.state.predicate !== filter) {
			this.state.predicate = filter;
			this.state.currentPage = 0;
		}
	};

	@action
	private _setQuery = (query: string): void => {
		this.state.query = query;
		this.state.currentPage = 0;
		this.state.predicate =
			query?.length > this.minimumQueryLength ? this.matchesQuery : undefined;
	};

	public setQuery = debounce(this._setQuery, 100);
}

export function useFilteredStore<M extends IAnyModelType>(
	store: EntityStore<M>,
	filters: Record<string, Predicate<Instance<M>>>
) {
	const [filteredStore] = useState(() => new FilteredStore<M>(store, filters));
	return filteredStore;
}

import { applyPatch, Instance, SnapshotIn, types } from 'mobx-state-tree';
import { getId, includesCaseInsensitive, loadingValue } from '../../common';
import { tolerantDate } from '../../models/common';
import { User } from './UserModel';
import { UserReference } from './UserReference';

const UserGroupModelInferred = types
	.model('UserGroup')
	.props(
		{
			_id: types.identifier,
			title: types.string,
			color: types.string,
			createdAt: types.optional(tolerantDate, () => new Date()),
			members: types.array(UserReference),
		},
	).views(self => ({
			userIndex(user: User): number {
				return self.members.indexOf(user);
			},
			isMember(user: User): boolean {
				return this.userIndex(user) >= 0;
			},
			get numUsers() {
				return self.members.length;
			},
			includesMentionOf(value: string): boolean {
				return includesCaseInsensitive(self.title, value);
			},
		}),
	).actions(self => ({
			addMember(user: User | string) {
				self.members.push(getId(user));
			},
			removeMember(user: User): void {
				const userIdx = self.userIndex(user);

				if (userIdx >= 0) {
					return applyPatch(
						self,
						{
							op: 'remove',
							path: `/members/${ userIdx }`,
						},
					);
				}
			},
		}),
	);

export interface UserGroupModel extends Infer<typeof UserGroupModelInferred> {}
export const UserGroupModel: UserGroupModel = UserGroupModelInferred;

export interface UserGroup extends Instance<UserGroupModel> {}
export interface UserGroupSnapshotIn extends SnapshotIn<UserGroupModel> {}

/**
 * Group to be used as a placeholder while loading.
 */
export const loadingUserGroup: UserGroupSnapshotIn = {
	_id: loadingValue,
	title: 'Loading User Group...',
	color: loadingValue,
	createdAt: new Date(),
};

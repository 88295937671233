import { css } from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';

import { ffHeading, ffTextBody } from './typography';


const { colors } = themeStore.selectedTheme;


// typography
export const heading1 = css`
	${ ffHeading };
`;

export const heading2 = css`
	${ ffHeading };
`;

export const heading3 = css`
	${ ffHeading };
`;

export const heading4 = css`
	${ ffHeading };
`;

export const heading5 = css`
	${ ffHeading };
`;

export const heading6 = css`
	${ ffHeading };
`;

export const textBody = css`
	${ ffTextBody };
`;


// a11y
export const focus = css`
	outline: 4px solid ${ colors.focus }  !important;
	transition: outline 0.1s linear !important;

	&::-moz-focus-inner {
		border: 0;
	}
`;

export const boxShadowFocus = css`
	box-shadow: 0 0 0 4px ${ colors.focus } !important;
	transition: box-shadow 0.1s linear !important;
	outline: 0 !important;

	&::-moz-focus-inner {
		border: 0;
	}
`;


// animations
export const spinAnim = css`
	@keyframes spinAnim {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

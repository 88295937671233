import { types } from 'mobx-state-tree';
import { enumValues } from '../../common/enum.utils';

export enum UserStatus {
	invited = 'invited',
	active = 'active',
	disabled = 'disabled',
}

export const userStatusBadgeColors: Record<UserStatus, string> = {
	[UserStatus.disabled]: 'danger',
	[UserStatus.active]: 'success',
	[UserStatus.invited]: 'info',
};

const userStatuses = enumValues(UserStatus);

export const userStatusModel = types.enumeration('UserStatus', userStatuses);

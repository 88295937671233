import { Col, Container, Row } from 'reactstrap';
import { useObserver } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { LoadingRow } from '../../../components/loading.component';
import { resolveIdentifierWithTypeIn, shouldShow } from '../../../common';
import themeStore from '../../../theme/models/ThemeStore';

import {
	WorkflowParallelStageModel,
	WorkflowSingleStageModel,
	WorkflowStage,
	WorkflowSubstageModel,
} from '../../models';
import {
	SelectedWorkflow,
	SelectedWorkflowStage,
} from '../../workflow.contexts';

import StageTable from '../stage-table.component';

import WorkflowDisabledMask from './workflow-disabled-mask.component';
import StageDetailCard from './workflow-stage-card.component';

const stagePluralTerm = `${themeStore._.stage.toLowerCase()}s`;

const StageIndexPanel = () => {
	const workflow = SelectedWorkflow.presentValue;

	const [selectedStage, setSelectedStage] = useState(
		workflow.activeStage as WorkflowStage
	);

	useEffect(() => {
		const hash = window.location.hash.replace('#', '');
		if (hash) {
			const resolved = resolveIdentifierWithTypeIn(
				[
					WorkflowSingleStageModel,
					WorkflowParallelStageModel,
					WorkflowSubstageModel,
				],
				workflow,
				hash
			);

			if (resolved) {
				window.history.replaceState(
					null,
					document.title,
					window.location.pathname
				);
				setSelectedStage(resolved);
			}
		}
	}, [workflow]);

	const renderStageAccessLogs = () =>
		shouldShow(selectedStage) ? <StageDetailCard /> : null;

	const renderDetails = () => (
		<Row>
			<Col lg={6}>
				<StageTable />
			</Col>
			<Col lg={6}>{renderStageAccessLogs()}</Col>
		</Row>
	);

	return useObserver(() => (
		<Container>
			<SelectedWorkflowStage.Provider value={[selectedStage, setSelectedStage]}>
				<WorkflowDisabledMask workflow={workflow} />
				{workflow ? (
					renderDetails()
				) : (
					<LoadingRow label={`Loading ${stagePluralTerm}...`} />
				)}
			</SelectedWorkflowStage.Provider>
		</Container>
	));
};

export default StageIndexPanel;

import { types } from 'mobx-state-tree';

import { nonEmptyStringWithDefault } from './common';
import { BaseWorkflowPhaseModel } from './BaseWorkflowPhaseModel';
import { CreatableModel } from './CreatableEntityModel';
import { BaseWorkflowOwnableModel } from './BaseWorkflowOwnableModel';

const BaseWorkflowModelInferred = types
	.compose(CreatableModel, BaseWorkflowOwnableModel)
	.named('BaseWorkflow')
	.props(
		{
			_id: types.identifier,

			title: nonEmptyStringWithDefault(''),

			phases: types.array(BaseWorkflowPhaseModel),
		},
	);

export interface BaseWorkflowModel extends Infer<typeof BaseWorkflowModelInferred> {}

export const BaseWorkflowModel: BaseWorkflowModel = BaseWorkflowModelInferred;

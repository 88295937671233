import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { Container, StyledLink } from './back-link.styled-components';


// interface
interface BackLinkProps {
	link: string;
	title: string;
}


// component
const BackLink = (props: BackLinkProps) => (
	<Container>
		<StyledLink to={ props.link }>
			<FontAwesomeIcon
				icon={ faArrowLeft }
				className="button-icon mr-2"
			/>
				<span className="button-label">Back to { props.title }</span>
		</StyledLink>
	</Container>
);

export default BackLink;

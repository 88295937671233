import { values } from 'mobx';
import { getSnapshot, Instance, types } from 'mobx-state-tree';
import moment from 'moment';

import { generateID } from '../../common';
import storageModel from '../../models/StorageModel';

import { Query, QueryModel, QuerySnapshotIn } from './QueryModel';
import { getCreationDateOf } from '../../models/CreatableEntityModel';

export enum QueryTypes {
	ASSETS = 'assets',
	WORKFLOWS = 'workflows',
	ASSET_COLLECTIONS = 'collections',
	WORKFLOW_COLLECTIONS = 'projects',
}

const SAVED_QUERIES = 'savedQueries';

const QueryStoreModelInferred = types
	.model('QueryStore', {
		entities: types.map(QueryModel),
	})
	.actions((self) => ({
		add(query: Query | QuerySnapshotIn) {
			self.entities.set(query._id, query);
		},
		addNew(value: string, type: string, saveToStorage: boolean = true) {
			const query: Query = QueryModel.create({
				_id: generateID(),
				createdAt: moment(new Date()).toISOString(),
				type: type,
				value: value,
			});
			this.add(query);

			// TODO: save query to backend instead of localstorage (RRR-120)
			if (saveToStorage) {
				storageModel.set(
					SAVED_QUERIES,
					JSON.stringify(getSnapshot(self.entities))
				);
			}
		},
	}))
	.views((self) => ({
		get numQueries(): number {
			return self.entities.size;
		},
		get allQueries(): ReadonlyArray<Query> {
			return values(self.entities);
		},
		get reverseChronologicalQueries(): ReadonlyArray<Query> {
			return this.allQueries
				.slice()
				.sort(
					(a, b) =>
						getCreationDateOf(b).valueOf() - getCreationDateOf(a).valueOf()
				);
		},
	}))
	.actions((self) => ({
		parse() {
			const jsonFromStorageModel = storageModel.get(SAVED_QUERIES);
			if (!jsonFromStorageModel) {
				// clear entities
				if (self.entities.size) {
					self.entities.forEach((q: Query) => {
						self.entities.delete(q._id);
					});
				}
				return;
			}

			// parse storage to entities
			const jsonEntities = JSON.stringify(self.entities);
			if (jsonEntities !== jsonFromStorageModel) {
				const snapshots = JSON.parse(jsonFromStorageModel);
				Object.entries(snapshots).forEach((s: any) => {
					const data = s[1];
					const model = QueryModel.create({
						_id: data._id,
						createdAt: moment(data.createdAt).toISOString(),
						type: data.type,
						value: data.value,
					});

					self.add(model);
				});
			}
		},
		clear() {
			storageModel.remove(SAVED_QUERIES);
			this.parse();
		},
	}));

export interface QueryStoreModel
	extends Infer<typeof QueryStoreModelInferred> {}

export const QueryStoreModel: QueryStoreModel = QueryStoreModelInferred;

export interface QueryStore extends Instance<QueryStoreModel> {}

import styled from 'styled-components';
import { Input, Label } from 'reactstrap';

import { fsNormal } from '../../theme/ui/typography';
import inputField from '../../theme/components/input-field';
import select from '../../theme/components/select';
import themeStore from '../../theme/models/ThemeStore';


const { colors } = themeStore.selectedTheme;


export const StyledLabel = styled(Label)`
	${ fsNormal };
	margin-bottom: 0.25em;
	margin-top: 0.5em;
`;

export const StyledInput = styled(Input)`
	${ inputField };
`;

export const StyledSelect = styled.select`
	${ select };
	display: block;
	background-color: ${ colors.white };
	width: 100%;
`;

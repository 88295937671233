import { css } from 'styled-components';

import { focus } from '../ui/ui';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const anchor = css`
	display: inline-block;
	background-color: transparent;
	border-radius: 0;
	border: none;
	color: ${colors.primary};
	cursor: pointer;
	margin: 0;
	padding: 0;
	text-decoration: none;

	&:hover {
		background-color: transparent;
		color: ${colors.primaryHighlighted};
		text-decoration: underline;
	}

	&:focus {
		${focus};
	}
`;

export const underlinedAnchor = css`
	${anchor};
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
`;

export default anchor;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import { QueryTypes } from '../queries/models/QueryStore';
import { useQueryStore } from '../stores';
import NotificationModel from '../notifications/NotificationModel';
import notificationStore from '../notifications/NotificationStore';

import { SecondaryButton } from './buttons.styled-components';

interface Props {
	className?: string;
	searchTerm: string;
	type: QueryTypes;
}

const SaveQueryButton = ({ searchTerm, type, className }: Props) => {
	const queryStore = useQueryStore();

	const saveQuery = () => {
		queryStore.addNew(searchTerm, type);

		notificationStore.push(
			NotificationModel.create({
				text: `Saved query "${searchTerm}".`,
			})
		);
	};

	const render = () => (
		<SecondaryButton
			className={`save-query-button ${className}`}
			onClick={saveQuery}
			disabled={searchTerm === ''}
		>
			<FontAwesomeIcon icon={faSave} className="mr-2" />
			<span className="button-label">Save query</span>
		</SecondaryButton>
	);

	return render();
};

export default SaveQueryButton;

import { action, autorun, computed, observable, values } from 'mobx';
import { useCallback, useEffect } from 'react';
import { debounce, minBy, pick } from 'lodash';
import { throwErr } from '../../common/fn.utils';
import windowModel from '../../models/WindowModel';

export const canvasRootClass = 'canvas-content';
const isRootLevel = (el: HTMLElement) =>
	el.offsetParent?.classList.contains(canvasRootClass);

export interface ElementOffsets {
	offsetTop: number;
	offsetLeft: number;
	offsetWidth: number;
	offsetHeight: number;
}

const offsetProps: ReadonlyArray<keyof ElementOffsets> = [
	'offsetTop',
	'offsetLeft',
	'offsetHeight',
	'offsetWidth',
] as const;

const adjustOffsets = (
	child: ElementOffsets,
	{ offsetTop, offsetLeft }: ElementOffsets
) => {
	child.offsetTop += offsetTop;
	child.offsetLeft += offsetLeft;
	return child;
};

const toOffsets = (el: HTMLElement): ElementOffsets => {
	const parent = el.offsetParent;
	if (!parent) {
		return throwErr('No offset parent?');
	}
	if (!(parent instanceof HTMLElement)) {
		return throwErr('Invalid parent');
	}

	const offsets = pick(el, offsetProps);

	if (isRootLevel(el)) {
		return offsets;
	} else {
		return adjustOffsets(offsets, toOffsets(parent));
	}
};

class ElementStore {
	public readonly elements = observable.map<string, HTMLElement>({});
	public readonly offsets = observable.map<string, ElementOffsets>({});

	constructor() {
		// Since this is a singleton, this _should_ not cause a memory leak
		autorun(() => {
			// triggers updates on window resize because values are observed (mobx)
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			windowModel.dimensions;
			this.doRefresh();
		});
	}

	@action
	setElementFor(stageId: string, el: HTMLElement | null): void {
		if (el) {
			this.elements.set(stageId, el);
			this.offsets.set(stageId, toOffsets(el));
		} else {
			this.elements.delete(stageId);
			this.offsets.delete(stageId);
		}
		this.refresh();
	}

	/**
	 * Wrap in an animation frame – this will wait for the browser to paint new offsets.
	 */
	refresh = debounce(() => void requestAnimationFrame(this.doRefresh), 50);

	@action
	private doRefresh = () =>
		this.elements.forEach((el, id) => this.offsets.set(id, toOffsets(el)));

	@computed
	get leftmostX(): number {
		const leftmost = minBy(values(this.offsets), 'offsetLeft');
		return leftmost?.offsetLeft || 0;
	}
}

export const elementStore = new ElementStore();

export const useElementStore = () => elementStore;

export const useRegisterElement = (identifier: string) => {
	// Refresh the element store on component dismount:
	useEffect(() => () => elementStore.refresh(), []);

	return useCallback(
		(el: HTMLElement | null) => elementStore.setElementFor(identifier, el),
		[identifier]
	);
};

import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import { getParentOfType, hasParentOfType } from 'mobx-state-tree';
import React from 'react';
import Moment from 'react-moment';
import { Container } from 'reactstrap';
import { isIdentifiableLoaded } from '../../../common';

import { buildComparisonUrl, buildVersionUrl } from '../../../common/links';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../components/entity-details.styled-components';
import {
	WorkflowStageInputSlot,
	WorkflowStageInputSlotModel,
} from '../../../workflows/models';

import { AssetTabsProps } from './asset-details-tab-set.component';
import {
	FullWidthLinkButton,
	StyledLink,
	StyledSummary,
} from './asset-details-tab-set.styled-components';
import { AssetVersion } from '../../models/AssetVersionModel';

const VersionsPanel = (props: AssetTabsProps) => {
	const { asset } = props;

	// render methods
	const renderComponent = (slot: WorkflowStageInputSlot) => {
		const { versions } = slot;

		// versions are listed chronologically (old to recent)
		// reverse order to have most recent listed first
		const sortedVersion = versions.slice().reverse();

		return (
			<EntityPropList>
				{sortedVersion.map((v: AssetVersion) => (
					<EntityPropListItem key={v._id}>
						<details>
							<StyledSummary>
								Added on{' '}
								<Moment format="MM/DD/YYYY, h:MM A" date={v.createdAt} />
							</StyledSummary>
							<EntityPropList className="mt-2">
								<EntityPropListItem>
									<EntityPropLabel>File name</EntityPropLabel>
									<p>{v.fileName}</p>
								</EntityPropListItem>
								<EntityPropListItem>
									<EntityPropLabel>Added by</EntityPropLabel>
									<p>{v.createdBy?.name}</p>
								</EntityPropListItem>
								<EntityPropListItem>
									<EntityPropLabel>Added on</EntityPropLabel>
									<Moment format="MM/DD/YYYY" date={v.createdAt} />
								</EntityPropListItem>
								<EntityPropListItem>
									<EntityPropLabel>File size</EntityPropLabel>
									<p>{v.size}</p>
								</EntityPropListItem>
								<EntityPropListItem>
									<EntityPropLabel>File type</EntityPropLabel>
									<p>{v.type}</p>
								</EntityPropListItem>
								<EntityPropListItem>
									<EntityPropLabel>ID</EntityPropLabel>
									<p>{v._id}</p>
								</EntityPropListItem>
								<EntityPropListItem>
									<EntityPropLabel>Link</EntityPropLabel>
									<StyledLink to={buildVersionUrl(v)}>{v.fileName}</StyledLink>
								</EntityPropListItem>
								{v._id !== asset._id ? (
									<FullWidthLinkButton
										className="mt-4"
										to={buildComparisonUrl(asset, v)}
									>
										<FontAwesomeIcon icon={faCopy} className="mr-2" />
										Compare with this version
									</FullWidthLinkButton>
								) : null}
							</EntityPropList>
						</details>
					</EntityPropListItem>
				))}
			</EntityPropList>
		);
	};

	const render = () => {
		let slot: Maybe<WorkflowStageInputSlot>;

		if (
			isIdentifiableLoaded(asset) &&
			hasParentOfType(asset, WorkflowStageInputSlotModel)
		) {
			slot = getParentOfType(asset, WorkflowStageInputSlotModel);
		}

		return (
			<Container className="p-md-4 py-3">
				{isIdentifiableLoaded(asset) && isIdentifiableLoaded(slot) ? (
					renderComponent(slot!)
				) : (
					<p>No past versions</p>
				)}
			</Container>
		);
	};

	return useObserver(render);
};

export default VersionsPanel;

type MirroredEnum<E extends string> = {
	[K in E]: E
}

export function enumKeys<E extends object>(x: E): Array<keyof E> {
	return Object.keys(x) as Array<keyof E>;
}

export function enumValues<T extends string>(x: MirroredEnum<T>): Array<T> {
	return Object.values(x);
}

import {
	faCloudUploadAlt,
	faCopy,
	faDownload,
	faPlusSquare,
	faShare,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import { useObserver } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AssetVersion } from '../models/AssetVersionModel';
import { buildClassList } from '../../common/index';
import { featureFlags } from '../../core/FeatureFlags';
import { useModalCreator } from '../../stores/ModalStack';
import { useViewer } from '../services/viewer.service';
import CompareAssetsDialog from './compare-assets-dialog.component';
import NotificationModel, {
	NotificationType,
} from '../../notifications/NotificationModel';
import notificationStore from '../../notifications/NotificationStore';
import ShareMultipleAssetsDialog from './share-dialogs/share-multiple-assets-dialog.component';
import ShareSingleAssetDialog from './share-dialogs/share-single-asset-dialog.component';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;
const assetTerm = themeStore._.asset.toLowerCase();
const pluralAssetsTerm = `${assetTerm}s`;

export enum AssetSelectionTool {
	VIEW = 'view',
	COMPARE = 'compare',
	DOWNLOAD = 'download',
	SELECTION = 'selection',
	SHARE = 'share',
	TRASH = 'trash',
}

export const defaultAssetSelectionTools = [
	AssetSelectionTool.VIEW,
	AssetSelectionTool.COMPARE,
	AssetSelectionTool.DOWNLOAD,
	AssetSelectionTool.SHARE,
	AssetSelectionTool.TRASH,
];

// interfaces
export interface AssetSelectionToolbarClickEvent {
	tool: AssetSelectionTool;
	value?: any;
}

interface Props {
	tools: readonly AssetSelectionTool[];
	className?: string;
	onClick?: (event: AssetSelectionToolbarClickEvent) => void;
	selectedAssets: readonly AssetVersion[];
}

// styled components
export const Toolbar = styled.div`
	display: inline-flex;
`;

// TODO: extract to reusable component (see also IndexViewToggler)
export const ToolbarButton = styled.button`
	background: transparent;
	border-radius: 3px;
	border: none;
	color: ${colors.darkGrey};
	margin-left: 6px;

	&.active {
		color: ${colors.darkestGrey};
	}

	&:hover {
		color: ${colors.darkestGrey};
	}
`;

// component
const AssetSelectionToolbar = (props: Props) => {
	const { tools, className, onClick, selectedAssets } = props;

	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const modalCreator = useModalCreator();
	const assetViewer = useViewer();

	// methods
	const isViewToolAvailable = () => {
		return (
			featureFlags.assetSelectionToolbar.view &&
			tools.includes(AssetSelectionTool.VIEW) &&
			selectedAssets.length === 1 &&
			isSelectingAssets
		);
	};

	const isCompareToolAvailable = () => {
		return (
			featureFlags.assetSelectionToolbar.compare &&
			tools.includes(AssetSelectionTool.COMPARE) &&
			selectedAssets.length === 2 &&
			isSelectingAssets
		);
	};

	const isDownloadToolAvailable = () => {
		// tool not available in props => quick exit
		if (!tools.includes(AssetSelectionTool.DOWNLOAD)) {
			return false;
		}

		if (isSelectingAssets) {
			if (selectedAssets.length === 1) {
				// downloading single asset (prevent falsy)
				return featureFlags.assetSelectionToolbar.downloadSingle;
			} else if (selectedAssets.length > 1) {
				// downloading multiple assets (prevent falsy)
				return featureFlags.assetSelectionToolbar.downloadMultiple;
			}
			return false;
		} else {
			return false;
		}
	};

	const isShareToolAvailable = () => {
		// tool not available in props => quick exit
		if (!tools.includes(AssetSelectionTool.SHARE)) {
			return false;
		}

		if (isSelectingAssets) {
			if (selectedAssets.length === 1) {
				// sharing single asset (prevent falsy)
				return featureFlags.assetSelectionToolbar.shareSingle;
			} else if (selectedAssets.length > 1) {
				// sharing multiple assets (prevent falsy)
				return featureFlags.assetSelectionToolbar.shareMultiple;
			}
			return false;
		} else {
			return false;
		}
	};

	const isTrashToolAvailable = () => {
		return (
			featureFlags.assetSelectionToolbar.trash &&
			tools.includes(AssetSelectionTool.TRASH) &&
			selectedAssets.length &&
			isSelectingAssets
		);
	};

	// callbacks
	const dispatchClick = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (onClick) {
				onClick(event);
			}
		},
		[onClick]
	);

	const toggleSelectingState = useCallback(
		(isSelecting: boolean) => {
			setSelectingAssets(isSelecting);
			dispatchClick({
				tool: AssetSelectionTool.SELECTION,
				value: isSelecting,
			});
		},
		[dispatchClick, setSelectingAssets]
	);

	const launchAssetViewer = useCallback(async () => {
		await assetViewer.viewSingle(selectedAssets[0]);
		dispatchClick({ tool: AssetSelectionTool.VIEW });
	}, [assetViewer, dispatchClick, selectedAssets]);

	const showCompareModal = useCallback(() => {
		modalCreator.addModal(<CompareAssetsDialog assets={selectedAssets} />);
		dispatchClick({ tool: AssetSelectionTool.COMPARE });
	}, [dispatchClick, modalCreator, selectedAssets]);

	const downloadAssets = useCallback(() => {
		if (selectedAssets.length === 1) {
			// download single asset
			const asset: AssetVersion = selectedAssets[0];
			asset.downloadAssetFile().catch(() => {
				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text: `An issue occurred while downloading the ${assetTerm}. Please try again later.`,
					})
				);
			});
		} else {
			// TODO: download selected assets (RRR-225)
		}
		dispatchClick({ tool: AssetSelectionTool.DOWNLOAD });
	}, [dispatchClick, selectedAssets]);

	const showShareModal = useCallback(() => {
		if (selectedAssets.length === 1) {
			// share single asset
			const asset: AssetVersion = selectedAssets[0];
			modalCreator.addModal(<ShareSingleAssetDialog asset={asset} />);
		} else {
			// share multiple assets
			// TODO: enable sharing multiple assets at once (rrr-252)
			modalCreator.addModal(
				<ShareMultipleAssetsDialog assets={selectedAssets} />
			);
		}
		dispatchClick({ tool: AssetSelectionTool.SHARE });
	}, [dispatchClick, modalCreator, selectedAssets]);

	const deleteAssets = useCallback(() => {
		// TODO: trash selected assets (RRR-224)
		notificationStore.push(
			NotificationModel.create({
				type: NotificationType.ERROR,
				text: 'Unable to delete assets at this time. Please try again later.',
			})
		);
		dispatchClick({ tool: AssetSelectionTool.TRASH });
	}, [dispatchClick]);

	const onKeyUp = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				toggleSelectingState(false);
			}
		},
		[toggleSelectingState]
	);

	useEffect(() => {
		// set up
		document.addEventListener('keyup', onKeyUp);

		return () => {
			// clean up
			document.removeEventListener('keyup', onKeyUp);
		};
	}, [onKeyUp]);

	// render methods
	const renderSelectAssetsButton = () => (
		<>
			<ToolbarButton
				id="selectAssetsButton"
				onClick={() => toggleSelectingState(true)}
			>
				<FontAwesomeIcon icon={faPlusSquare} />
			</ToolbarButton>
			<UncontrolledTooltip target="selectAssetsButton">
				Select {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderCancelSelectionButton = () => (
		<>
			<ToolbarButton
				id="cancelStateButton"
				onClick={() => toggleSelectingState(false)}
			>
				<FontAwesomeIcon icon={faTimes} />
			</ToolbarButton>
			<UncontrolledTooltip target="cancelStateButton">
				Cancel selecting {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderViewerButton = () => (
		<>
			<ToolbarButton id="launchViewerButton" onClick={launchAssetViewer}>
				<FontAwesomeIcon icon={faCloudUploadAlt} />
			</ToolbarButton>
			<UncontrolledTooltip target="launchViewerButton">
				Launch {assetTerm} in detailed viewer
			</UncontrolledTooltip>
		</>
	);

	const renderCompareButton = () => (
		<>
			<ToolbarButton id="compareAssetsButton" onClick={showCompareModal}>
				<FontAwesomeIcon icon={faCopy} />
			</ToolbarButton>
			<UncontrolledTooltip target="compareAssetsButton">
				Compare {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderDownloadButton = () => (
		<>
			<ToolbarButton id="downloadAssetsButton" onClick={downloadAssets}>
				<FontAwesomeIcon icon={faDownload} />
			</ToolbarButton>
			<UncontrolledTooltip target="downloadAssetsButton">
				Download {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderShareButton = () => (
		<>
			<ToolbarButton id="shareAssetsButton" onClick={showShareModal}>
				<FontAwesomeIcon icon={faShare} />
			</ToolbarButton>
			<UncontrolledTooltip target="shareAssetsButton">
				Share {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderTrashButton = () => (
		<>
			<ToolbarButton id="deleteAssetsButton" onClick={deleteAssets}>
				<FontAwesomeIcon icon={faTrash} />
			</ToolbarButton>
			<UncontrolledTooltip target="deleteAssetsButton">
				Delete {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const render = () => (
		<Toolbar className={buildClassList('assets-toolbar', className)}>
			{isViewToolAvailable() ? renderViewerButton() : null}
			{isCompareToolAvailable() ? renderCompareButton() : null}
			{isDownloadToolAvailable() ? renderDownloadButton() : null}
			{isShareToolAvailable() ? renderShareButton() : null}
			{isTrashToolAvailable() ? renderTrashButton() : null}
			{!isSelectingAssets ? renderSelectAssetsButton() : null}
			{isSelectingAssets ? renderCancelSelectionButton() : null}
		</Toolbar>
	);

	return useObserver(render);
};

export default AssetSelectionToolbar;

import React, { useCallback } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { UserAvatar } from '../admin-dashboard/components/user-details.styled-components';
import EditUserForm from '../admin-dashboard/components/users/edit-user-form.component';
import { camelCaseToSentenceCase } from '../common';
import { _logError } from '../common/log';
import { Heading, Subheading } from '../components/ui';
import NotificationModel, {
	NotificationType,
} from '../notifications/NotificationModel';
import notificationStore from '../notifications/NotificationStore';
import { useUserStore } from '../stores';
import { UserSnapshotIn } from '../accounts/models/UserModel';

const EditProfile = () => {
	const userStore = useUserStore();

	const currentUser = userStore.currentUser;

	const onSubmit = useCallback(
		async (updatedUser: UserSnapshotIn) => {
			try {
				await userStore.updateOne(updatedUser);

				notificationStore.push(
					NotificationModel.create({
						text: 'Profile saved!',
					})
				);
			} catch (error) {
				_logError(error);

				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text: `An issue occurred while updating your profile. Please try again later.`,
					})
				);
			}
		},
		[userStore]
	);

	return (
		<Container>
			<Row>
				<Col md={2}>
					<Subheading>Edit</Subheading>
					<Heading>My profile</Heading>
				</Col>
				<Col md={10}>
					<Card>
						<CardBody>
							<Row>
								<Col
									lg={3}
									className="d-flex justify-content-center align-items-center flex-column"
								>
									<UserAvatar image={currentUser.picture} />
									<h2>{currentUser.name}</h2>
									<p>{camelCaseToSentenceCase(currentUser.role)}</p>
								</Col>
								<Col lg={9} className="p-4">
									<EditUserForm
										selectedUser={currentUser}
										onSubmit={onSubmit}
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default EditProfile;

import styled from 'styled-components';
import { DropdownToggle, NavbarBrand, NavLink } from 'reactstrap';

import { focus } from '../../theme/ui/ui';
import themeStore from '../../theme/models/ThemeStore';

const { images } = themeStore.selectedTheme;

export const LogoContainer = styled(NavbarBrand)`
	&:focus {
		${focus};
	}
`;

export const AppLogo = styled.div`
	background: url(${images.desktopLogo}) left center / contain no-repeat;
	height: 3em;
	margin-bottom: 10px;
	margin-top: 10px;
	width: 7em;

	@media only screen and (max-width: 768px) {
		background: url(${images.mobileLogo}) center center / contain no-repeat;
		height: 3em;
		width: 3em;
	}
`;

export const StyledNavLink = styled(NavLink)`
	&:focus {
		${focus};
	}
`;

export const AvatarMenuToggle = styled(DropdownToggle)`
	&:after {
		position: relative;
		top: -8px;
	}

	&:focus {
		${focus};
	}
`;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

import { StyledCircleButton } from '../../components/circle-button.component';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// main component
export const ActionButtonContainer = styled.div`
	display: inline-block;
	margin-left: 1em;
`;

export const ActionButton = styled(Button)`
	&& {
		border: 1px solid ${colors.darkestGrey};
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.btn-secondary {
		&& {
			background-color: ${colors.secondary};
			border: 1px solid ${colors.secondary};

			&:hover {
				background-color: ${colors.secondaryHighlighted};
				&& {
					border: 1px solid ${colors.secondaryHighlighted};
				}
			}
		}
	}

	&.success:hover {
		background-color: ${colors.success};
		&& {
			border: 1px solid ${colors.success};
			color: ${colors.white};
		}
	}

	&.danger:hover {
		background-color: ${colors.danger};
		&& {
			border: 1px solid ${colors.danger};
			color: ${colors.white} !important;
		}
	}

	&.solid:hover {
		background-color: ${colors.darkestGrey};

		&& {
			border: 1px solid ${colors.darkestGrey};
			color: ${colors.white};
		}
	}
`;

// other components
export const IconWithRightMargin = styled(FontAwesomeIcon)`
	margin-right: 8px;

	&.completed {
		color: ${colors.success};
	}
`;

export const OutlinedCircleButton = styled(StyledCircleButton)`
	&& {
		border: 1px solid ${colors.darkestGrey};

		&:hover {
			color: ${colors.white};
		}
	}

	&.roadblocked {
		background-color: ${colors.danger};
		border: 1px solid ${colors.danger} !important;
		color: ${colors.white};
		width: auto;

		&& {
			border-radius: 20px;
		}
	}
`;

export const StyledTooltip = styled(UncontrolledTooltip)`
	.tooltip {
		pointer-events: none;
	}
`;

import { Router } from '@reach/router';
import { useObserver } from 'mobx-react';
import React from 'react';
import { Col, Container, Nav, Row } from 'reactstrap';

import { FilterNavItem } from '../components/side-bar-filters/side-bar-filters-nav.styled-components';
import { Heading } from '../components/ui';
import { usePermissions } from '../stores';
import { UserPermissions } from '../models/UserPermissionsModel';
import MetadataTemplatesIndexView from '../metadata-templates/metadata-templates-index.view';
import PageSubheading from '../components/page-subheading.component';

import { AdminNavLink } from './components/admin-dashboard-styled-components';
import AccountsPanel from './components/accounts/accounts-panel.component';
import ThemePanel from './components/theme/theme-panel.component';
import UserGroupsPanel from './components/user-groups/user-groups-panel.component';
import UsersPanel from './components/users/users-panel.component';

const buildNavOptions = (permissions: UserPermissions) => {
	let navOptions = [];

	if (permissions.canViewUsers) {
		const usersRoute = 'users';
		navOptions.push({
			label: 'Users',
			value: usersRoute,
			route: <UsersPanel path={usersRoute} key={usersRoute} default />,
		});
	}

	if (permissions.canViewUserGroups) {
		const userGroupsRoute = 'user-groups';
		navOptions.push({
			label: 'User Groups',
			value: userGroupsRoute,
			route: <UserGroupsPanel path={userGroupsRoute} key={userGroupsRoute} />,
		});
	}

	if (permissions.canViewAccounts) {
		const accountsRoute = 'accounts';
		navOptions.push({
			label: 'Accounts',
			value: accountsRoute,
			route: <AccountsPanel path={accountsRoute} key={accountsRoute} />,
		});
	}

	if (permissions.canViewMetadataTemplates) {
		const metadataRoute = 'metadata-templates';
		navOptions.push({
			label: 'Metadata Templates',
			value: metadataRoute,
			route: (
				<MetadataTemplatesIndexView path={metadataRoute} key={metadataRoute} />
			),
		});
	}

	if (permissions.canViewThemes) {
		const themesRoute = 'themes';
		navOptions.push({
			label: 'Themes',
			value: themesRoute,
			route: <ThemePanel path={themesRoute} key={themesRoute} />,
		});
	}

	return navOptions;
};

const AdminDashboardView = () => {
	const permissions = usePermissions();

	const isSelected = (link: string) => {
		return window.location.pathname.includes(link) ? 'selected' : '';
	};

	return useObserver(() => {
		const navOptions = buildNavOptions(permissions);

		return (
			<Container>
				<Row>
					<Col md={2}>
						<PageSubheading text="Dashboard" />
						<Heading>Admin</Heading>
						<Nav vertical className="mt-3">
							{navOptions.map(({ label, value }) => (
								<FilterNavItem key={value}>
									<AdminNavLink
										to={`./${value}`}
										className={isSelected(`${value}`)}
									>
										{label}
									</AdminNavLink>
								</FilterNavItem>
							))}
						</Nav>
					</Col>

					<Col md={10}>
						<Router primary={false}>{navOptions.map((o) => o.route)}</Router>
					</Col>
				</Row>
			</Container>
		);
	});
};

export default AdminDashboardView;

import {
	applyPatch,
	applySnapshot,
	flow,
	Instance,
	SnapshotIn,
	SnapshotOut,
	types,
} from 'mobx-state-tree';
import { UserReference } from '../../accounts/models/UserReference';
import {
	DamAsset,
	DamAssetReference,
} from '../../dam-assets/models/DamAssetModel';
import { getId, includesCaseInsensitive } from '../../common';
import { getClient } from '../../core';
import { EntityMetadataModel } from '../../metadata/models/EntityMetadataModel';
import { BaseWorkflowOwnableModel } from '../../models/BaseWorkflowOwnableModel';
import {
	BaseWorkflowOwner,
	BaseWorkflowOwnerModel,
} from '../../models/BaseWorkflowOwnerModel';
import { CreatableModel } from '../../models/CreatableEntityModel';

const CollectionModelInferred = types
	.compose(CreatableModel, BaseWorkflowOwnableModel)
	.named('AssetCollection')
	.props({
		_id: types.identifier,
		createdBy: types.maybe(UserReference),
		title: types.string,
		owners: types.array(BaseWorkflowOwnerModel),
		assets: types.array(DamAssetReference),
		metadata: types.optional(EntityMetadataModel, {}),
	})
	.views((self) => ({
		ownerIndex(owner: OrID<BaseWorkflowOwner>): number {
			const ownerId: string = getId(owner);
			return self.owners.findIndex(({ _id }) => _id === ownerId);
		},
	}))
	.views((self) => ({
		isOwner(ownerId: OrID<BaseWorkflowOwner>): boolean {
			return self.ownerIndex(ownerId) > -1;
		},
		get isCurrentUserInvolved(): boolean {
			return self.isOwnedByCurrentUser || self.isCreatedByCurrentUser;
		},
		includesMentionOf(value: string): boolean {
			return (
				includesCaseInsensitive(self.title, value) ||
				self.owners.some((o) => o.includesMentionOf(value))
			);
		},
	}))
	.actions((self) => ({
		save: flow(function* save(
			assetCollectionSnapshot: SnapshotOut<typeof self>
		) {
			const client = getClient(self);
			const updatedAssetCollection = yield client.patch(
				`/collections/${self._id}`,
				assetCollectionSnapshot
			);

			applySnapshot(self, updatedAssetCollection);
			return updatedAssetCollection;
		}),
		addAsset(asset: DamAsset | string) {
			self.assets.push(getId(asset));
		},
		removeAsset(asset: DamAsset): void {
			const assetIdx = self.assets.indexOf(asset);

			if (assetIdx >= 0) {
				return applyPatch(self, {
					op: 'remove',
					path: `/assets/${assetIdx}`,
				});
			}
		},
	}));

export interface AssetCollectionModel
	extends Infer<typeof CollectionModelInferred> {}

export const AssetCollectionModel: AssetCollectionModel = CollectionModelInferred;

export interface AssetCollection extends Instance<AssetCollectionModel> {}

export interface AssetCollectionSnapshot
	extends SnapshotIn<AssetCollectionModel> {}

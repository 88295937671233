import React, { FormEvent, useCallback } from 'react';
import { ModalBody } from 'reactstrap';
import { UserGroup } from '../../../accounts/models/UserGroupModel';
import { useEditingFlow } from '../../../common';
import { _logError } from '../../../common/log';
import DialogModal from '../../../components/modals/dialog-modal.component';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useGroupStore } from '../../../stores';

import { useModalCloser } from '../../../stores/ModalStack';

import EditGroupForm from './edit-group-form.component';

// interface
interface Props {
	selectedGroup: UserGroup;
}

// component
const EditGroupDialog = (props: Props) => {
	const modalStack = useModalCloser();
	const groupStore = useGroupStore();

	const [, getGroupSnapshot, setLastKnownGood] = useEditingFlow(
		props.selectedGroup
	);

	const onFormSubmit = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();

			try {
				const updatedGroup = await groupStore.updateUserGroup(
					getGroupSnapshot()
				);
				setLastKnownGood(updatedGroup);

				modalStack.closeModal();

				notificationStore.push(
					NotificationModel.create({
						text: 'User group saved!',
					})
				);
			} catch (error) {
				_logError(error);
				modalStack.closeModal();

				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text: `An issue occurred while updating user group. Please try again later.`,
					})
				);
			}
		},
		[getGroupSnapshot, setLastKnownGood, groupStore, modalStack]
	);

	return (
		<DialogModal header="Edit group">
			<ModalBody>
				<EditGroupForm
					selectedGroup={props.selectedGroup}
					onSubmit={onFormSubmit}
				/>
			</ModalBody>
		</DialogModal>
	);
};

export default EditGroupDialog;

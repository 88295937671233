import styled from 'styled-components';
import { Input } from 'reactstrap';

import { Avatar as AvatarBase } from '../../components/avatar.component';
import themeStore from '../../theme/models/ThemeStore';


const { colors } = themeStore.selectedTheme;


export const CommentContainer = styled.div`
	display: flex;
	background-color: ${ colors.white };
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	bottom: 0;
	box-shadow: 0px -3px 14px rgba(1,1,1,0.075), 0 0 5px rgba(1,1,1,0.075);
	left: 0;
	padding: 1em;
	position: absolute;
	right: 0;
`;

export const Avatar = styled(AvatarBase)`
	height: 30px;
	margin-right: 10px;
	width: 32px;
`;

export const CommentInput = styled(Input)`
	max-height: 100px;
	min-height: 30px;
`;

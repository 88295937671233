import React, { useCallback } from 'react';
import { faFastForward } from '@fortawesome/free-solid-svg-icons';

import { buildClassList } from '../../../common';
import { StageStatus } from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';
import { usePermissions } from '../../../stores';

import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from '../action-button.styled-components';

import { StageActionButtonProps } from '.';
import BypassStageDialog from './bypass-stage-dialog.component';

export const BypassStageButton = (props: StageActionButtonProps) => {
	const { className, stage } = props;
	const modalStack = useModalCreator();
	const permissions = usePermissions();

	const openBypassModal = useCallback(() => {
		modalStack.addModal(<BypassStageDialog stage={stage} />);
	}, [stage, modalStack]);

	if (
		!permissions.canBypassStage(stage) ||
		!stage.nextStage ||
		stage.status === StageStatus.completed
	) {
		return null;
	}

	const buttonId = `bypassStage${stage._id}`;
	return (
		<ActionButtonContainer
			className={buildClassList('stage-action bypass-stage', className)}
		>
			<StyledTooltip target={buttonId}>Bypass "{stage.title}"</StyledTooltip>
			<ActionButton
				id={buttonId}
				color=""
				size="sm"
				className="action-button bypass-stage-button danger"
				onClick={openBypassModal}
			>
				<IconWithRightMargin icon={faFastForward} />
				Bypass
			</ActionButton>
		</ActionButtonContainer>
	);
};

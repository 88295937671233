import {
	getEnv,
	getParent,
	getRoot,
	getType,
	IAnyModelType,
	IAnyStateTreeNode,
	isModelType,
	isRoot,
	IStateTreeNode,
	types,
} from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import {
	AccountStore,
	AccountStoreModel,
} from '../accounts/models/AccountStore';
import {
	UserGroupStore,
	UserGroupStoreModel,
} from '../accounts/models/UserGroupStore';
import { UserStore, UserStoreModel } from '../accounts/models/UserStore';
import {
	AssetCollectionStore,
	AssetCollectionStoreModel,
} from '../dam-asset-collections/models/AssetCollectionStore';
import { AssetStore, AssetStoreModel } from '../dam-assets/models/AssetStore';
import {
	APIClient,
	AuthProvider,
	AuthStore,
	authStore,
	AuthStoreModel,
	RootEnv,
	rootEnv,
} from '../core';
import { FileUploadProvider } from '../core/FileUploadProvider';
import {
	IndexViewStore,
	IndexViewStoreModel,
} from '../index-view/models/IndexViewStore';
import {
	MetadataTemplateStore,
	MetadataTemplateStoreModel,
} from '../metadata-templates/models/EntityMetadataTemplateStore';
import { QueryStore, QueryStoreModel } from '../queries/models/QueryStore';
import {
	WorkflowTemplateStore,
	WorkflowTemplateStoreModel,
} from '../workflow-templates/models';
import {
	WorkflowCollectionStore,
	WorkflowCollectionStoreModel,
} from '../workflow-collections/models/WorkflowCollectionStore';
import { WorkflowStore, WorkflowStoreModel } from '../workflows/models';
import {
	UserPermissions,
	UserPermissionsModel,
} from '../models/UserPermissionsModel';
import {
	DashboardStore,
	DashboardStoreModel,
} from '../dashboard/dashboard.store';
import { ReportStore, ReportStoreModel } from '../reports/Report.store';

const RootStoreInferred = types.model('RootStore', {
	accounts: AccountStoreModel,
	assets: AssetStoreModel,
	auth: AuthStoreModel,
	assetCollections: AssetCollectionStoreModel,
	groups: UserGroupStoreModel,
	workflows: WorkflowStoreModel,
	workflowCollections: WorkflowCollectionStoreModel,
	queries: QueryStoreModel,
	templates: WorkflowTemplateStoreModel,
	users: UserStoreModel,
	indexViews: IndexViewStoreModel,
	metadataTemplates: MetadataTemplateStoreModel,
	permissions: UserPermissionsModel,
	dashboard: DashboardStoreModel,
	reports: ReportStoreModel,
});

interface RootStoreModel extends Infer<typeof RootStoreInferred> {}

const RootStoreModel: RootStoreModel = RootStoreInferred;

export interface RootStore {
	accounts: AccountStore;
	assets: AssetStore;
	auth: AuthStore;
	assetCollections: AssetCollectionStore;
	groups: UserGroupStore;
	workflows: WorkflowStore;
	workflowCollections: WorkflowCollectionStore;
	queries: QueryStore;
	templates: WorkflowTemplateStore;
	users: UserStore;
	indexViews: IndexViewStore;
	metadataTemplates: MetadataTemplateStore;
	permissions: UserPermissions;
	dashboard: DashboardStore;
	reports: ReportStore;
}

const rootStore: RootStore = RootStoreModel.create(
	{
		accounts: {},
		assets: {},
		auth: authStore,
		assetCollections: {},
		groups: {},
		workflows: {},
		workflowCollections: {},
		queries: {},
		templates: {},
		users: {},
		indexViews: {},
		metadataTemplates: {},
		permissions: {},
		dashboard: {},
		reports: {},
	},
	rootEnv
);

const RootContext = createContext(rootStore);

export function useStores(): RootStore {
	return useContext(RootContext);
}

export function useRootEnv(): RootEnv {
	return getEnv(useStores());
}

const getModelNode = (
	node: IAnyStateTreeNode
): IStateTreeNode<IAnyModelType> => {
	const type = getType(node);
	return isModelType(type) ? node : getModelNode(getParent(node));
};

/**
 * @param self {IAnyStateTreeNode} - Node to get the stores for.
 *
 * NOTE: This State Tree node MUST either:
 * 1. Be part of a `RootStore` tree.
 * 2. Have a `RootStore` object passed in as the `env` on creation.
 *
 * When a node is the root, we assume it is a detached node.
 * The env must be passed in on creation of "new" entities.
 */
export function getStores(self: IAnyStateTreeNode): RootStore {
	const modelNode = getModelNode(self);
	return isRoot(modelNode)
		? getEnv<RootStore>(modelNode)
		: getRoot<RootStore>(modelNode);
}

export function useAuth(): AuthProvider {
	return useRootEnv().authProvider;
}

export function useFileUpload(): FileUploadProvider {
	return useRootEnv().uploadProvider;
}

/**
 * Should only be used sparingly by components.
 * ONLY use this directly in a component if the call's result has no side-effects.
 */
export function useAPIClient(): APIClient {
	return useRootEnv().apiClient;
}

export function useQueryStore(): QueryStore {
	return useStores().queries;
}

export function useUserStore(): UserStore {
	return useStores().users;
}

export function useGroupStore(): UserGroupStore {
	return useStores().groups;
}

export function useTemplateStore(): WorkflowTemplateStore {
	return useStores().templates;
}

export function useAccountStore(): AccountStore {
	return useStores().accounts;
}

export function useAssetStore(): AssetStore {
	return useStores().assets;
}

export function useAssetCollectionStore(): AssetCollectionStore {
	return useStores().assetCollections;
}

export function useWorkflowStore(): WorkflowStore {
	return useStores().workflows;
}

export function useWorkflowCollectionStore(): WorkflowCollectionStore {
	return useStores().workflowCollections;
}

export function useIndexViewStore(): IndexViewStore {
	return useStores().indexViews;
}

export function useMetadataTemplateStore(): MetadataTemplateStore {
	return useStores().metadataTemplates;
}

export function usePermissions(): UserPermissions {
	return useStores().permissions;
}

export function useDashboardStore(): DashboardStore {
	return useStores().dashboard;
}

export function useReportsStore(): ReportStore {
	return useStores().reports;
}

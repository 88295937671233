import { castToSnapshot, Instance, types } from 'mobx-state-tree';
import {
	WorkflowModel,
	WorkflowSnapshotIn,
} from '../workflows/models/WorkflowModel';
import { getClient } from '../core/index';
import { getStores } from '../stores/index';
import { _logError } from '../common/log';
import { getId } from '../common/mobx.utils';

const dashboardStoreModelInferred = types
	.model('DashboardStore')
	.props({
		_recentWorkflows: types.array(
			types.safeReference(WorkflowModel, { acceptsUndefined: false })
		),
	})
	.actions((self) => ({
		syncRecentWorkflows: function syncRecentWorkflows(
			workflows: WorkflowSnapshotIn[]
		) {
			getStores(self).workflows.addMany(workflows);
			self._recentWorkflows = castToSnapshot(workflows.map(getId));
		},
	}))
	.views((self) => {
		let isFetching = false;
		let hasFetched = false;

		const fetchRecent = async () => {
			try {
				isFetching = true;
				const workflows = await getClient(self).get('dashboard');
				self.syncRecentWorkflows(workflows);
				hasFetched = true;
			} finally {
				isFetching = false;
			}
		};

		return {
			get recentWorkflows() {
				if (!hasFetched && !isFetching) {
					fetchRecent().catch(_logError);
				}
				return self._recentWorkflows;
			},
		};
	});

export interface DashboardStoreModel
	extends Infer<typeof dashboardStoreModelInferred> {}

export const DashboardStoreModel: DashboardStoreModel = dashboardStoreModelInferred;

export interface DashboardStore extends Instance<DashboardStoreModel> {}

import rg4js from 'raygun4js';

import { requiredProdValue } from '../common';
import { User } from '../accounts/models/UserModel';

class ErrorMonitor {
	isInit = false;

	public initialize() {
		if (!process.env.REACT_APP_RAYGUN_API_KEY) {
			return;
		}

		rg4js('apiKey', requiredProdValue(process.env.REACT_APP_RAYGUN_API_KEY));
		rg4js('enableCrashReporting', true);
		rg4js('enablePulse', true);

		this.isInit = true;
	}

	public setUser(user: User) {
		if (!process.env.REACT_APP_RAYGUN_API_KEY) {
			return;
		}

		if (!this.isInit) {
			throw new Error('Unable to set user before the error logging service is initialized.');
		}

		rg4js('setUser', {
			identifier: user._id,
			isAnonymous: false,
			email: user.email,
			fullName: user.name,
		});
	}

	public report(content: any) {
		if (!process.env.REACT_APP_RAYGUN_API_KEY) {
			return;
		}

		if (!this.isInit) {
			throw new Error('Unable to log errors before the service is initialized.');
		}

		// irony
		try {
			rg4js('send', { error: content });
		} catch (error) {
			console.error('Unable to save error log.', error);
		}
	}
}

const errorMonitor = new ErrorMonitor();
export default errorMonitor;

import errorMonitor from '../third-parties/error-monitor.service';

interface LogOptions {
	save?: boolean;
	display?: boolean;
}

const defaultErrorOptions = Object.freeze({
	save: true,
	display: true,
});

const defaultWarningOptions = Object.freeze({
	save: false,
	display: true,
});

export function _logError(
	error: any,
	options: LogOptions = defaultErrorOptions
) {
	log(console.error.bind(console), error, options);
}

export function _logWarning(
	message: any,
	options: LogOptions = defaultWarningOptions
) {
	log(console.warn.bind(console), message, options);
}

function log(
	logMethod: (content: any) => void,
	content: any,
	options: LogOptions
) {
	const shouldDisplayInConsole =
		process.env.NODE_ENV === 'development' || options.display;

	if (shouldDisplayInConsole) {
		logMethod(content);
	}

	// check whether to save log or not
	if (options && options.save !== undefined) {
		errorMonitor.report(content);
	}
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Heading } from '../components/ui';

import { useQueryStore } from '../stores';

import QueriesTable from './components/queries-table.component';

const QueriesIndexView = () => {
	const queryStore = useQueryStore();
	queryStore.parse();

	const render = () => (
		<Container className="queries-index-view">
			<Row>
				<Col md={3}>
					<Heading>Saved Queries</Heading>
				</Col>
				<Col md={9}>
					<QueriesTable queries={queryStore.allQueries} />
				</Col>
			</Row>
		</Container>
	);

	return render();
};

export default QueriesIndexView;

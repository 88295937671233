import { getRandomInt } from './math';
import { throwErr } from './fn.utils';

export const randomHue = () => getRandomInt(360);


const pastelShade = (h: number) => `hsl(${ h }, 100%, 95%)`;
const darkShade = (h: number) => `hsl(${ h }, 100%, 30%)`;

export const toRGBA = (hex: string, alpha: number): string => {
	const [r, g, b] = getRGBfromHEX(hex);
	return `rgba(${ r },${ g },${ b },${ alpha })`;
};

export const getRandomPastel = () => pastelShade(randomHue());
export const getRandomDarkColor = () => darkShade(randomHue());


export function getRGBfromHEX(string: string): number[] {
	const hex = string.replace(/[^0-9a-f]/ig, '');

	switch (hex.length) {
	case 3:
		return hex.split('').map(x => parseInt(`${ x }${ x }`, 16));
	case 6:
		return hex.match(/[0-9a-f]{2}/ig)!.map(x => parseInt(x, 16));
	default:
		return throwErr(`Invalid hex color ${ string }`);
	}
}

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback } from 'react';
import SubmitButton from '../../../components/forms/submit-button.component';
import { AssetVersion } from '../../models/AssetVersionModel';
import { useViewer } from '../../services/viewer.service';

const DetailedViewButton = ({ asset }: { asset: AssetVersion }) => {
	const viewer = useViewer();

	const onClick = useCallback(() => viewer.viewSingle(asset), [viewer, asset]);

	return (
		<SubmitButton
			type="button"
			className="mt-0 mb-4"
			label="Launch detailed view"
			icon={faExternalLinkAlt}
			onClick={onClick}
		/>
	);
};

export default DetailedViewButton;

import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { Query } from '../../queries/models/QueryModel';
import { useQueryStore } from '../../stores';
import SmallCTALink from '../../components/small-cta-link.component';

import SavedQueryItem from './saved-query-item.component';

// styled components
const List = styled.ul`
	padding-left: 0;
	list-style: none;
`;

const ListItem = styled.li`
	margin-bottom: 16px;
`;

// component
const SavedQueriesPanel = () => {
	const queryStore = useQueryStore();
	const maxItems = 5;

	queryStore.parse();

	const renderNoQuery = () => <p>No recent search queries.</p>;

	const renderQueryList = (queries: ReadonlyArray<Query>) => (
		<List className="saved-queries-list">
			{queries.map((query, index) =>
				index < maxItems ? (
					<ListItem className="list-item" key={query._id}>
						<SavedQueryItem query={query} />
					</ListItem>
				) : null
			)}
		</List>
	);

	const render = () => (
		<>
			<Card className="h-100">
				<CardHeader>
					<h2 className="mb-0">
						<FontAwesomeIcon icon={faSearch} className="mr-2" />
						Recent Queries
					</h2>
				</CardHeader>
				<CardBody>
					{queryStore.numQueries
						? renderQueryList(queryStore.reverseChronologicalQueries)
						: renderNoQuery()}
				</CardBody>
				<CardFooter className="bg-white border-top-0">
					<div className="float-right">
						<SmallCTALink
							link="/admin/saved-queries"
							label="View all saved queries"
						/>
					</div>
				</CardFooter>
			</Card>
		</>
	);

	return useObserver(render);
};

export default SavedQueriesPanel;

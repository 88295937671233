import { RouteComponentProps, Router } from '@reach/router';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { Col, Container, Nav, Row } from 'reactstrap';
import BackLink from '../components/back-link/back-link.component';

import { LoadingRow } from '../components/loading.component';
import OwnerAvatarList from '../components/owner-avatar-list.component';
import WorkflowHealthFlag from '../dashboard/components/workflow-health-flag.component';
import { useWorkflowStore } from '../stores';
import themeStore from '../theme/models/ThemeStore';
import AccessLogsPanel from './components/workflow-details-tab-set/access-logs-panel.component';
import AssetsPanel from './components/workflow-details-tab-set/assets-tab-panel.component';
import WorkflowDetailPanel from './components/workflow-details-tab-set/details-panel.component';
import {
	Link,
	WorkflowTitle,
} from './components/workflow-details.styled-components';
import StageIndexPanel from './components/workflow-stage-details/workflow-stage-index-panel.component';
import WorkflowAssetVersionDetailsView from './workflow-asset-version-details.view';
import { SelectedWorkflow } from './workflow.contexts';

const Assets = ({ children }: { children: ReactElement[] }) => <>{children}</>;

interface WorkflowDetailsViewProps extends RouteComponentProps {
	workflowId: string;
}

const WorkflowDetailsView = observer((props: WorkflowDetailsViewProps) => {
	const workflowStore = useWorkflowStore();
	const workflowTerm = themeStore._.workflow;

	const workflow = workflowStore.findOne(props.workflowId);

	const isSelected = (link: string) => {
		return window.location.pathname.includes(link) ? 'selected' : '';
	};

	const workflowDetail = workflow ? (
		<Row>
			<Col xs={12}>
				<div className="mb-4">
					<BackLink link=".." title={`${workflowTerm}s`} />
					<div className="d-flex justify-content-between">
						<div className="d-flex align-items-center">
							<WorkflowTitle className="mr-2 d-inline">
								{workflow.title}
							</WorkflowTitle>
							<WorkflowHealthFlag workflow={workflow} />
						</div>
						<div>
							<OwnerAvatarList owners={workflow.involvedOwners} />
						</div>
					</div>
				</div>
			</Col>
			<Col xs={12}>
				<Nav tabs>
					<Link to="stages" className={isSelected('stages')}>
						{themeStore._.stage}s
					</Link>
					<Link to="details" className={isSelected('details')}>
						{workflowTerm} Details
					</Link>
					{workflow.inputSlots && workflow.inputSlots.length ? (
						<Link to="assets" className={isSelected('assets')}>
							{themeStore._.asset}s
						</Link>
					) : null}
					<Link to="logs" className={isSelected('logs')}>
						Access Logs
					</Link>
				</Nav>
			</Col>
			<Col xs={12} className="mt-4">
				<SelectedWorkflow.Provider
					value={SelectedWorkflow.asReadonly(workflow)}
				>
					<Router primary={false}>
						<StageIndexPanel path="stages" default />
						<WorkflowDetailPanel path="details" />
						<Assets path="assets">
							<AssetsPanel path="/" />
							<WorkflowAssetVersionDetailsView
								path=":versionId"
								workflow={workflow}
							/>
						</Assets>
						<AccessLogsPanel path="logs" />
					</Router>
				</SelectedWorkflow.Provider>
			</Col>
		</Row>
	) : (
		<LoadingRow label={`Loading ${workflowTerm.toLowerCase()}...`} />
	);

	return <Container>{workflowDetail}</Container>;
});

export default WorkflowDetailsView;

import React from 'react';
import { useObserver } from 'mobx-react';

import { getId } from '../../common';
import themeStore from '../../theme/models/ThemeStore';

import { DownshiftBaseProps, InDownshift } from './downshift.interfaces';
import { ItemOption, ItemOptionList } from './downshift-select.styled-components';
import { useDownshift } from './downshift-options.context';


const { colors } = themeStore.selectedTheme;


interface DownshiftOptionItemProps<Option extends Displayable> extends InDownshift<any, Option> {
	itemOption: Option;
	index: number;
}


const DownshiftOptionListItem = <Option extends Displayable>(props: DownshiftOptionItemProps<Option>) => {
	const { itemOption, index, downshift } = props;

	const {
		getItemProps,
		highlightedIndex,
	} = downshift;

	return useObserver(() => (
		<ItemOption
			tabindex="0"
			{ ...getItemProps({
				key: getId(itemOption),
				index,
				item: itemOption,
				style: {
					backgroundColor: (highlightedIndex === index)
						? colors.lightestGrey
						: colors.white,
				},
			}) }
		>
			{ downshift.itemToString(itemOption) }
		</ItemOption>
	));
};

interface DownshiftOptionsListProps<Option extends Displayable> extends Pick<DownshiftBaseProps<any, Option>, 'renderOption'> {
}

export const DownshiftOptionsList = <Item extends Displayable, Option extends Displayable>(props: DownshiftOptionsListProps<Option>) => {
	const { downshift, options, searchPredicate } = useDownshift<Item, Option>();
	const {
		getMenuProps,
		isOpen,
	} = downshift;

	return useObserver(() => {

		const list = options.filter((item) => searchPredicate(downshift.inputValue, item));

		return (
			isOpen
				? <ItemOptionList { ...getMenuProps() }>
					{
						list.length > 0 ?
							list.map((item, index) => (
								<DownshiftOptionListItem downshift={ downshift }
								                         key={ getId(item) }
								                         itemOption={ item }
								                         index={ index } { ...props } />
							))
							: <ItemOption><i>No options</i></ItemOption>
					}
				</ItemOptionList>
				: null
		);
	});
};

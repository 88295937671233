import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Collapse,
	Container,
	DropdownMenu,
	Nav,
	Navbar,
	NavbarToggler,
	NavItem,
	UncontrolledDropdown,
} from 'reactstrap';
import { useAuth, useUserStore } from '../../stores';
import themeStore from '../../theme/models/ThemeStore';
import {
	AppLogo,
	AvatarMenuToggle,
	LogoContainer,
	StyledNavLink,
} from './admin-navigation.styled-components';
import { Avatar } from '../avatar.component';
import { DropDownMenuItem, DropDownToggle } from '../drop-down-menu.component';
import { Link } from '../ui';
import {
	initializeIntercom,
	shutdownIntercom,
} from '../../third-parties/intercom.service';
import { useToggler } from '../../common/hooks';
import { PermissionsCheck } from '../../accounts/permissions-check.component';

const AdminNavigation = () => {
	const auth = useAuth();
	const userStore = useUserStore();

	const [isOpen, toggle] = useToggler(false);

	useEffect(() => {
		// todo Fix email in user schema
		initializeIntercom(userStore.currentUser as any);
		return () => shutdownIntercom();
	}, [auth.isAuthenticated, userStore]);

	const render = () => {
		const currentUser = userStore.currentUser;

		return (
			<Navbar color="white" light expand="md">
				<Container>
					<LogoContainer tag={Link} to="/">
						<AppLogo />
					</LogoContainer>
					<NavbarToggler onClick={toggle} />
					<Collapse isOpen={isOpen} navbar>
						<Nav className="mr-auto" navbar>
							<UncontrolledDropdown nav inNavbar>
								<DropDownToggle nav caret>
									{themeStore._.baseWorkflow}
								</DropDownToggle>
								<DropdownMenu>
									<PermissionsCheck check="canViewTemplates">
										<DropDownMenuItem tag={Link} to="workflow/templates">
											Templates
										</DropDownMenuItem>
									</PermissionsCheck>

									<DropDownMenuItem tag={Link} to="workflow/workflows">
										{`${themeStore._.workflow}s`}
									</DropDownMenuItem>
									<DropDownMenuItem tag={Link} to="workflow/projects">
										{`${themeStore._.workflowCollection}s`}
									</DropDownMenuItem>
									<DropDownMenuItem tag={Link} to="workflow/assignments">
										My Assignments
									</DropDownMenuItem>
									<DropDownMenuItem tag={Link} to="workflow/roadblocks">
										Roadblocks
									</DropDownMenuItem>
								</DropdownMenu>
							</UncontrolledDropdown>
							<UncontrolledDropdown nav inNavbar>
								<DropDownToggle nav caret>
									{themeStore._.assetBase}
								</DropDownToggle>
								<DropdownMenu>
									<DropDownMenuItem tag={Link} to="dam/assets">
										{`${themeStore._.asset}s`}
									</DropDownMenuItem>
									<DropDownMenuItem tag={Link} to="dam/collections">
										{`${themeStore._.assetCollection}s`}
									</DropDownMenuItem>
								</DropdownMenu>
							</UncontrolledDropdown>

							<PermissionsCheck check="canViewReports">
								<NavItem>
									<StyledNavLink tag={Link} to="reports">
										{themeStore._.report}s
									</StyledNavLink>
								</NavItem>
							</PermissionsCheck>
						</Nav>
						<Nav className="ml-auto" navbar>
							<PermissionsCheck check="canViewAdminPanel">
								<NavItem>
									<StyledNavLink tag={Link} to="dashboard">
										Admin
									</StyledNavLink>
								</NavItem>
							</PermissionsCheck>

							<UncontrolledDropdown nav inNavbar>
								<AvatarMenuToggle nav caret>
									<Avatar image={currentUser.avatar} />
								</AvatarMenuToggle>
								<DropdownMenu right>
									<DropDownMenuItem tag={Link} to="profile">
										<small>
											<FontAwesomeIcon
												icon={faCog}
												className="text-muted mr-2"
											/>
										</small>
										Edit profile
									</DropDownMenuItem>
									<DropDownMenuItem divider />
									<DropDownMenuItem onClick={auth.signOut}>
										<small>
											<FontAwesomeIcon
												icon={faSignOutAlt}
												className="text-muted mr-2"
											/>
										</small>
										Logout
									</DropDownMenuItem>
								</DropdownMenu>
							</UncontrolledDropdown>
							{/*<NavItem>*/}
							{/*	<NavLink tag={Link} to="#">*/}
							{/*		<img src={cart}*/}
							{/*		     className="Cart"*/}
							{/*		     alt="cart"*/}
							{/*		/>*/}
							{/*	</NavLink>*/}
							{/*</NavItem>*/}
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		);
	};

	return useObserver(render);
};

export default AdminNavigation;

import { PointPath } from './PathBuilder';

export class Point {
	constructor(
		public readonly x: number,
		public readonly y: number,
		public color?: string
	) {}

	toString() {
		return `(${this.x}, ${this.y})`;
	}
}

export function point(x: number, y: number, color?: string): Point {
	return new Point(x, y, color);
}

export function center(rect: ClientRect): Point {
	return {
		x: rect.left + rect.width / 2,
		y: rect.top + rect.height / 2,
	};
}

const midpoint = ({ x: x1, y: y1 }: Point, { x: x2, y: y2 }: Point): Point =>
	point(x1 + (x2 - x1) / 2, y1 + (y2 - y1) / 2);

export function buildPathFromPoints(pts: readonly Point[]): PointPath {
	const anchors: Point[] = pts.slice();

	const path = pts.reduce((acc, p, i) => {
		if (i === 0) {
			return `M ${p.x},${p.y}`;
		}

		if (i === pts.length - 1) {
			return acc;
		}

		const prev = pts[i - 1];
		const next = pts[i + 1];

		const midpoint1 = midpoint(prev, p);
		const midpoint2 = midpoint(p, next);

		anchors.push(midpoint1, midpoint2);

		return `${acc} L${midpoint1.x},${midpoint1.y} L${p.x},${p.y} ${midpoint2.x},${midpoint2.y} `;
	}, '');

	return { path, anchors };
}

import React from 'react';

import { StageStatus } from '../../../models/StageStatusModel';
import themeStore from '../../../theme/models/ThemeStore';

import { WorkflowActionableStage } from '../../models';

import StageDialog from './stage-dialog.component';

const stageTerm = themeStore._.stage;

const ForceActivateStageDialog = (props: {
	stage: WorkflowActionableStage;
}) => (
	<StageDialog
		header={`Force activate stage "${props.stage.title}"`}
		context="Please add some context about why this stage is being forcibly activated."
		onClick={(message: string) =>
			props.stage.updateStatus(StageStatus.active, message)
		}
		warning="Are you sure you want to force activate this stage?"
		successMessage={`${stageTerm} activated!`}
	/>
);

export default ForceActivateStageDialog;

import React, { PropsWithChildren } from 'react';
import { usePermissions } from '../stores/index';
import { UserPermissions } from '../models/UserPermissionsModel';
import { useObserver } from 'mobx-react';

type StringKeyOf<T> = Extract<keyof T, string>;
type ObjectCheck<T> = (x: T) => boolean;

type ObjectCheckProps<T> = {
	[K in keyof T]: T[K] extends boolean
		? { check: K; on?: undefined }
		: T[K] extends ObjectCheck<infer X>
		? { check: K; on: X }
		: never;
}[StringKeyOf<T>];

type ParamOf<F> = F extends (x: infer X) => boolean ? X : never;

export const PermissionsCheck = <P extends ObjectCheckProps<UserPermissions>>(
	props: PropsWithChildren<P>
) => {
	const permissions = usePermissions();

	return useObserver(() => {
		const checker = permissions[props.check];

		let canRender: boolean;

		if (typeof checker === 'boolean') {
			canRender = checker;
		} else {
			// todo figure out how to properly type this param
			const { on } = props as { on: ParamOf<typeof checker> };
			canRender = checker(on as any);
		}

		return canRender ? <>{props.children}</> : null;
	});
};

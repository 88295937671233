import React, { PropsWithChildren } from 'react';
import { get } from 'lodash';

export const featureFlags = {
	assetSelectionToolbar: {
		// NOTE: available in assets index and workflow > assets tab
		view: true,
		compare: true,
		downloadSingle: true,
		downloadMultiple: false, // TODO: RRR-225
		shareSingle: true,
		shareMultiple: false, // TODO: RRR-252
		trash: false, // TODO: RRR-224
	},
	assetSharing: {
		shareToUser: false,
		shareToEmail: false,
	},
	deleteUser: false,
	assetCollections: {
		detailsView: {
			toolbar: {
				edit: true,
				delete: false, // TODO: RRR-209
			},
		},
	},
} as const;

export const RequiresFeatureFlag = ({
	flag,
	children,
}: PropsWithChildren<{ flag: string }>) =>
	get(featureFlags, flag, false) ? <>{children}</> : null;

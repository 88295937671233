import { useObserver } from 'mobx-react';
import React from 'react';
import Moment from 'react-moment';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { camelCaseToSentenceCase } from '../common';
import { _logError } from '../common/log';
import BackLink from '../components/back-link/back-link.component';
import GroupAvatar from '../components/group-avatar.component';

import { LoadingRow } from '../components/loading.component';
import NotificationModel, {
	NotificationType,
} from '../notifications/NotificationModel';
import notificationStore from '../notifications/NotificationStore';
import { useGroupStore, useUserStore } from '../stores';

import {
	CardBodyFlex,
	UserAvatar,
} from './components/user-details.styled-components';
import EditUserForm from './components/users/edit-user-form.component';
import { UserSnapshotIn } from '../accounts/models/UserModel';

interface UserDetailsViewProps {
	userId: string;
}

const UserDetailsView = (props: UserDetailsViewProps) => {
	const { userId } = props;
	const userStore = useUserStore();
	const groupStore = useGroupStore();
	const user = userStore.getOne(userId);

	const onSubmit = async (userSnapshot: UserSnapshotIn) => {
		try {
			await userStore.updateOne(userSnapshot);

			notificationStore.push(
				NotificationModel.create({
					text: 'User saved!',
				})
			);
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred while updating user. Please try again later.`,
				})
			);
		}
	};

	const userDetail = useObserver(() => {
		const groups = groupStore.groupsForCurrentUser;

		return user ? (
			<Row>
				<Col xs={12} className="mb-2">
					<BackLink link="/admin/dashboard" title="Admin Dashboard" />
				</Col>
				<Col md={4} className="mt-4 mt-md-0">
					<Card className="h-100">
						<CardBodyFlex>
							<UserAvatar image={user.picture} />
							<h1>{user.name}</h1>
							<p>{camelCaseToSentenceCase(user.role)}</p>
						</CardBodyFlex>
					</Card>
				</Col>
				<Col md={8} className="mt-4 mt-md-0">
					<Card className="h-100">
						<CardHeader>
							<h2 className="mb-0">User details</h2>
						</CardHeader>
						<CardBody>
							<EditUserForm selectedUser={user} onSubmit={onSubmit} />
						</CardBody>
					</Card>
				</Col>
				<Col md={6} className="mt-4">
					<Card className="h-100">
						<CardHeader>
							<h2 className="mb-0">User groups</h2>
						</CardHeader>
						<CardBody>
							{groups.map((g) => (
								<div className="mb-2" key={g._id}>
									<GroupAvatar group={g} />
									<p className="d-inline ml-3">{g.title}</p>
								</div>
							))}
						</CardBody>
					</Card>
				</Col>
				<Col md={6} className="mt-4">
					<Card className="h-100">
						<CardHeader>
							<h2 className="mb-0">User activity</h2>
						</CardHeader>
						<CardBody>
							<ul>
								<li>
									<p>
										Added <Moment format="MMM DD, YYYY" date={user.createdAt} />
									</p>
								</li>
							</ul>
						</CardBody>
					</Card>
				</Col>
			</Row>
		) : (
			<LoadingRow label="Loading user..." />
		);
	});

	return <Container>{userDetail}</Container>;
};

export default UserDetailsView;

import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { CreatableModel } from '../../models/CreatableEntityModel';

export const WorkflowStageCommentModel = CreatableModel.named('WorkflowStageComment').props(
	{
		message: types.string,
	},
);

export interface WorkflowStageComment extends Instance<typeof WorkflowStageCommentModel> {
}

export interface NewComment {
	message: string;
}

export interface CommentSnapshot extends SnapshotIn<typeof WorkflowStageCommentModel> {
}

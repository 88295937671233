import {
	applySnapshot,
	flow,
	getParentOfType,
	Instance,
	types,
} from 'mobx-state-tree';
import { AssetMetadataModel } from '../../dam-assets/models/AssetMetadataModel';
import {
	AssetVersionModel,
	AssetVersionUploadData,
} from '../../dam-assets/models/AssetVersionModel';
import { getClient } from '../../core';
import { WorkflowModel } from './WorkflowModel';
import { EventType } from '../../models/StageEventTypeModel';
import {
	getParentWithTypeIn,
	IdentifiableNode,
	lazyReference,
	loadingValue,
} from '../../common';
import { WorkflowSingleStageModel } from './WorkflowStageModel';
import { WorkflowSubstageModel } from './WorkflowSubstageModel';

export const WorkflowStageInputSlotModel = types
	.model('WorkflowStageInputSlotModel', {
		_id: types.identifier,
		label: types.optional(types.string, ''),
		versions: types.array(AssetVersionModel),
		metadata: types.optional(AssetMetadataModel, {}),
	})
	.actions((self) => ({
		uploadAsset: flow(function* uploadAsset(
			file: File,
			data: AssetVersionUploadData
		) {
			const client = getClient(self);
			const stage: IdentifiableNode = getParentWithTypeIn(self, [
				WorkflowSingleStageModel,
				WorkflowSubstageModel,
			]);
			const workflow: IdentifiableNode = getParentOfType(stage, WorkflowModel);

			const newStage = yield client.postFile(
				`/workflows/${workflow._id}/stages/${stage._id}/assets/${self._id}`,
				file,
				{ type: EventType.assetUpload, ...data }
			);

			applySnapshot(workflow, newStage);
		}),
	}));

const loadingSlot: WorkflowStageInputSlot = WorkflowStageInputSlotModel.create({
	_id: loadingValue,
	label: 'Loading...',
	versions: [],
	metadata: {},
});

export const WorkflowStageInputSlotReference = lazyReference({
	model: WorkflowStageInputSlotModel,
	getter(id: string, parent) {
		const slot = (parent as any).stageId.inputSlots?.find(
			({ _id }: Identifiable) => _id === id
		);
		return slot || loadingSlot;
	},
});

export interface WorkflowStageInputSlotModel
	extends Infer<typeof WorkflowStageInputSlotModel> {}

export interface WorkflowStageInputSlot
	extends Instance<WorkflowStageInputSlotModel> {}

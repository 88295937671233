import { Form, TabPane } from 'reactstrap';
import { useObserver } from 'mobx-react';
import React from 'react';

import { LabeledInput } from '../../components/forms';
import themeStore from '../../theme/models/ThemeStore';

import { SelectedTemplateStage } from '../template.contexts';
import { TemplateParallelStageModel } from '../models';

import { AsideContent } from './template-stage-edit-form.styled-components';
import ExpectedDurationInput from './expected-duration-input.component';
import StageOwnerSelect from './stage-owner-select.component';
import TemplateStageEditPanelProps from './template-stage-edit-panel-props';
import TemplateStagePhaseSelect from './template-stage-phase-select';

const stageTerm = themeStore._.stage.toLowerCase();

// component
const BasicsTabPanel = (props: TemplateStageEditPanelProps) => {
	const currentStage = SelectedTemplateStage.presentValue;
	const { onChange } = props;

	const renderExpectedDurationBlock = () =>
		TemplateParallelStageModel.is(currentStage) ? null : (
			<ExpectedDurationInput />
		);

	const renderInstructionsField = () =>
		TemplateParallelStageModel.is(currentStage) ? null : (
			<div className="mt-3">
				<LabeledInput
					label="Instructions"
					type="textarea"
					name="instructions"
					id="stageInstructions"
					placeholder={`Add ${stageTerm} instructions...`}
					value={currentStage.instructions}
					onChange={onChange}
					rows="4"
				/>
			</div>
		);

	const renderStageOwnerBlock = () =>
		TemplateParallelStageModel.is(currentStage) ? null : <StageOwnerSelect />;

	const renderForm = () => (
		<Form>
			<LabeledInput
				label="Title"
				type="text"
				name="title"
				id="stageTitle"
				value={currentStage.title}
				onChange={onChange}
			/>

			<TemplateStagePhaseSelect />

			{renderInstructionsField()}

			{renderExpectedDurationBlock()}

			{renderStageOwnerBlock()}
		</Form>
	);

	const render = () => (
		<TabPane
			tabId={props.tabId}
			role="tabpanel"
			aria-labelledby={props.labelledBy}
		>
			<AsideContent>{renderForm()}</AsideContent>
		</TabPane>
	);

	return useObserver(render);
};

export default BasicsTabPanel;

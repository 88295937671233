import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React from 'react';

import { useWorkflowStore } from '../../stores';
import { WorkflowHealth } from '../../workflows/models/WorkflowModel';
import themeStore from '../../theme/models/ThemeStore';

import { MegaTitle } from './workflow-health-panel.styled-components';
import WorkflowHealthBars from './workflow-health-bars';
import WorkflowHealthIndicator from './workflow-health-indicator.component';

const workflowTerm = themeStore._.workflow;

const WorkflowHealthPanel = () => {
	const workflowStore = useWorkflowStore();

	return useObserver(() => {
		const {
			activeWorkflows,
			healthyWorkflows,
			overdueWorkflows,
			roadblockedWorkflows,
		} = workflowStore;

		return (
			<Card className="h-100">
				<CardHeader>
					<h2 className="mb-0">
						<FontAwesomeIcon icon={faHeartbeat} className="mr-2" />
						{workflowTerm} Health
					</h2>
				</CardHeader>
				<CardBody>
					<Row className="mt-5">
						<Col xs={6}>
							<MegaTitle>{activeWorkflows.length}</MegaTitle>
							<p className="text-muted pb-5">
								Active {workflowTerm.toLowerCase()}s
							</p>
						</Col>
					</Row>
					<WorkflowHealthBars
						healthy={healthyWorkflows.length}
						overdue={overdueWorkflows.length}
						roadblocked={roadblockedWorkflows.length}
						activeWorkflowCount={activeWorkflows.length}
					/>
					<Row className="mt-3">
						<Col>
							<WorkflowHealthIndicator
								numWorkflows={healthyWorkflows.length}
								status={WorkflowHealth.healthy}
							/>
						</Col>
						<Col>
							<WorkflowHealthIndicator
								numWorkflows={overdueWorkflows.length}
								status={WorkflowHealth.overdue}
							/>
						</Col>
						<Col>
							<WorkflowHealthIndicator
								numWorkflows={roadblockedWorkflows.length}
								status={WorkflowHealth.roadblocked}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	});
};

export default WorkflowHealthPanel;

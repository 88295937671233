import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { enumValues } from '../../common';

export enum ViewType {
	'grid' = 'grid',
	'list' = 'list'
}

const IndexViewModelInferred = types
	.model('IndexView')
	.props({
		viewType: types.optional(types.enumeration(enumValues(ViewType)), ViewType.grid),
	}).actions(self => ({
		setType(type: ViewType) {
			self.viewType = type;
		}
	}));

export interface IndexViewModel extends Infer<typeof IndexViewModelInferred> {
}

export const IndexViewModel: IndexViewModel = IndexViewModelInferred;

export interface IndexView extends Instance<IndexViewModel> {
}

export interface IndexViewSnapshotIn extends SnapshotIn<IndexViewModel> {
}

import { Card, CardFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useObserver } from 'mobx-react';
import Moment from 'react-moment';
import React, { useCallback } from 'react';
import {
	faCheckCircle,
	faFile,
	faFileArchive,
	faFileImage,
	faFilePdf,
	faFilePowerpoint,
	faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { CollaboratorAvatarMd } from '../../components/ui';

import { AssetVersion } from '../models/AssetVersionModel';

import {
	CardContainer,
	CardTitleLine,
	CheckMarkIcon,
	FileIcon,
	FilePreview,
	FileSizeLabel,
	FileTitle,
	OverlayButton,
	PrivacyLabel,
	StyledLink,
} from './asset-card.styled-components';
import AssetCardThumbnail from './asset-card-thumbnail.component';
import { getCreationDateOf } from '../../models/CreatableEntityModel';

interface AssetCardProps {
	assetVersion: AssetVersion;
	isLinkEnabled?: boolean;
	isSelected?: boolean;
	onClick?: (asset: AssetVersion) => void;
	type?: string;
}

const assetIconMap: Record<string, IconDefinition> = Object.freeze({
	'application/pdf': faFilePdf,
	'application/postscript': faFilePdf,
	'application/x-font-otf': faFileArchive,
	'application/x-font-ttf': faFileArchive,
	'application/zip': faFileArchive,
	'image/jpg': faFileImage,
	'image/png': faFileImage,
	'image/tiff': faFileImage,
	'image/vnd.adobe.photoshop': faFileImage,
	ai: faFileImage,
	psd: faFileImage,
	ppt: faFilePowerpoint,
	doc: faFileWord,
	docx: faFileWord,
});
const defaultAssetIcon = faFile;

const buildDamAssetUrl = (asset: AssetVersion) => {
	return `/admin/dam/assets/${asset._id}`;
};

const UploadedAssetCard = (props: AssetCardProps) => {
	const {
		assetVersion: asset,
		isLinkEnabled = true,
		isSelected,
		onClick,
	} = props;

	const clickCallBack = useCallback(() => {
		if (onClick) {
			onClick(asset);
		}
	}, [asset, onClick]);

	const buildClassList = () => {
		let classList = `asset-card`;
		if (isSelected) {
			classList = `${classList} selected`;
		}
		return classList;
	};

	const createdByAvatar = asset.createdBy ? (
		<CollaboratorAvatarMd image={asset.createdBy.picture} />
	) : null;

	const render = () => (
		<CardContainer className={buildClassList()}>
			<StyledLink to={asset._appUrl}>
				<Card className="h-100">
					<FilePreview>
						<AssetCardThumbnail className="file-thumbnail" asset={asset} />
					</FilePreview>
					<CardFooter className="bg-white">
						<CardTitleLine>
							<FileTitle>{asset.title || asset.fileName}</FileTitle>
							{createdByAvatar}
						</CardTitleLine>
						<small>
							Uploaded <Moment fromNow date={getCreationDateOf(asset)} />
						</small>
					</CardFooter>
				</Card>
			</StyledLink>

			{!isLinkEnabled ? <OverlayButton onClick={clickCallBack} /> : null}
			{isSelected ? <CheckMarkIcon icon={faCheckCircle} size="lg" /> : null}
		</CardContainer>
	);

	return useObserver(render);
};

const DamAssetCard = (props: AssetCardProps) => {
	const {
		assetVersion: asset,
		isLinkEnabled = true,
		isSelected,
		onClick,
	} = props;

	const clickCallBack = useCallback(() => {
		if (onClick) {
			onClick(asset);
		}
	}, [asset, onClick]);

	const render = () => (
		<CardContainer
			className={isSelected ? 'asset-card selected' : 'asset-card'}
		>
			<StyledLink to={buildDamAssetUrl(asset)}>
				<Card className="h-100 mt-2">
					<FilePreview>
						<AssetCardThumbnail className="file-thumbnail" asset={asset} />
					</FilePreview>
					<CardFooter className="bg-white">
						<CardTitleLine>
							<FileTitle>{asset.title || asset.fileName}</FileTitle>
							<FileSizeLabel>{asset.size}</FileSizeLabel>
						</CardTitleLine>
						<FileIcon>
							<FontAwesomeIcon
								icon={assetIconMap[asset.type] || defaultAssetIcon}
							/>
							<PrivacyLabel className="ml-2">{asset.privacy}</PrivacyLabel>
						</FileIcon>
					</CardFooter>
				</Card>
			</StyledLink>
			{!isLinkEnabled ? <OverlayButton onClick={clickCallBack} /> : null}
			{isSelected ? <CheckMarkIcon icon={faCheckCircle} size="lg" /> : null}
		</CardContainer>
	);

	return useObserver(render);
};
const AssetCard = (props: AssetCardProps) => {
	if (props.type === 'uploaded') {
		return <UploadedAssetCard {...props} />;
	}
	return <DamAssetCard {...props} />;
};

export default AssetCard;

import { Instance, types } from 'mobx-state-tree';
import moment from 'moment';
import { AssetVersion } from '../../dam-assets/models/AssetVersionModel';
import { generateID, getParentWithTypeIn, loadingValue } from '../../common';
import { makeEntityStore } from '../../models/EntityStore.model';
import { RootStore } from '../../stores';
import { TemplateReference } from '../../workflow-templates/models';

import {
	loadingWorkflow,
	Workflow,
	WorkflowModel,
	WorkflowSnapshotIn,
	WorkflowStatus,
} from './WorkflowModel';
import {
	WorkflowActionableStage,
	WorkflowSingleStageModel,
} from './WorkflowStageModel';
import { WorkflowSubstageModel } from './WorkflowSubstageModel';
import { mapValues } from 'lodash';
import { Roadblock } from './RoadblockModel';

export const WorkflowForCreationModel = WorkflowModel.named(
	'WorkflowForCreation'
).props({
	templateUsed: types.optional(types.maybe(TemplateReference), undefined, [
		undefined,
		null,
		'',
	]),
});

export const emptyWorkflow = (
	rootStore: RootStore,
	templateId: string
): Instance<typeof WorkflowForCreationModel> =>
	WorkflowForCreationModel.create(
		{
			_id: generateID(),
			createdBy: loadingValue,
			templateUsed: templateId,
			title: '',
		},
		{ ...rootStore }
	);

export const WorkflowStoreModelInferred = makeEntityStore<
	WorkflowModel,
	Workflow,
	WorkflowSnapshotIn
>(WorkflowModel, 'workflows', loadingWorkflow)
	.views((self) => ({
		get byStatus(): Record<WorkflowStatus, Workflow[]> {
			return self.all.reduce((acc, wf) => {
				acc[wf.status].push(wf);
				return acc;
			}, mapValues(WorkflowStatus, () => []) as Record<WorkflowStatus, Workflow[]>);
		},
	}))
	.views((self) => ({
		get overdueWorkflows(): Workflow[] {
			return self.byStatus[WorkflowStatus.active].filter((w) => w.isOverdue);
		},
		get activeWorkflows(): Workflow[] {
			return self.byStatus[WorkflowStatus.active];
		},
		get roadblockedWorkflows(): Workflow[] {
			return self.byStatus[WorkflowStatus.active].filter(
				(w) => w.isRoadblocked
			);
		},
	}))
	.views((self) => ({
		get roadblocks(): Roadblock[] {
			return self.all.flatMap(({ roadblocks }: Workflow) => roadblocks);
		},
		get activeRoadblocks(): Roadblock[] {
			return self.activeWorkflows.flatMap(
				({ roadblocks }: Workflow) => roadblocks
			);
		},
		get healthyWorkflows(): Workflow[] {
			return self.activeWorkflows.filter(
				(wf) => !(wf.isRoadblocked || wf.isOverdue)
			);
		},
	}))
	.views((self) => {
		return {
			getWorkflowsWithDueDate(date: Date): Workflow[] {
				const targetDate = moment(date);
				return self.all.filter(
					(workflow) =>
						workflow.dueDate && targetDate.isSame(workflow.dueDate, 'day')
				);
			},
			getStageWithAsset(asset: AssetVersion): WorkflowActionableStage {
				return getParentWithTypeIn(asset, [
					WorkflowSingleStageModel,
					WorkflowSubstageModel,
				]);
			},
		};
	});

export interface WorkflowStoreModel
	extends Infer<typeof WorkflowStoreModelInferred> {}

export const WorkflowStoreModel: WorkflowStoreModel = WorkflowStoreModelInferred;

export interface WorkflowStore extends Instance<WorkflowStoreModel> {}

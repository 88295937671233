import { Instance } from 'mobx-state-tree';
import { generateID } from '../../common';
import { makeEntityStore } from '../../models/EntityStore.model';
import { RootStore } from '../../stores';
import { loadingMetadataTemplate, MetadataTemplate, MetadataTemplateModel } from './EntityMetadataTemplateModel';


export const emptyMetadataTemplate = (rootStore: RootStore): MetadataTemplate => MetadataTemplateModel.create({
	_id: generateID(),
	title: '',
}, { ...rootStore });


const MetadataTemplateStoreModelInferred = makeEntityStore(
	MetadataTemplateModel,
	'metadata-template',
	loadingMetadataTemplate,
).views(self => ({
	get allMetadataTemplates(): ReadonlyArray<MetadataTemplate> {
		return self.all;
	},
}));

export interface MetadataTemplateStoreModel extends Infer<typeof MetadataTemplateStoreModelInferred> {
}

export interface MetadataTemplateStore extends Instance<MetadataTemplateStoreModel> {
}

export const MetadataTemplateStoreModel: MetadataTemplateStoreModel = MetadataTemplateStoreModelInferred;

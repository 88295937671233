import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';
import { Card, CardBody } from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// assignment-panel
export const List = styled.ul`
	padding-left: 0;
	list-style: none;
	margin-bottom: 0;
`;

export const ListItem = styled.li`
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0;
	}
`;

// assignment-item
export const UnstyledLink = styled(Link)`
	color: ${colors.black};
	text-decoration: none;
	line-height: 1;

	&:hover {
		color: ${colors.black};
		text-decoration: none;
	}
`;

export const TaskContainer = styled.div`
	display: inline-block;
	margin-left: 15px;
`;

export const Detail = styled.p`
	font-size: 12px;
	color: ${colors.darkerGrey};
	margin-bottom: 0;
`;

export const TaskCard = styled(Card)`
	&:hover {
		transform: scale(1.05, 1.05);
		transition: transform ease-out 200ms;
	}
`;

export const TaskCardBody = styled(CardBody)`
	&& {
		padding: 10px;
	}
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
	font-size: 13px;
	color: ${colors.darkerGrey};
`;

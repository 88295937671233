import { types } from 'mobx-state-tree';
import { enumValues } from '../../common/enum.utils';

export enum UserRole {
	SuperAdmin = 'SuperAdmin',
	ProjectManager = 'ProjectManager',
	User = 'User',
}

const userRoles = enumValues(UserRole);

export const userRolesModel = types.enumeration(userRoles);

const sortedRoles = [
	UserRole.User,
	UserRole.ProjectManager,
	UserRole.SuperAdmin,
];

export const isRoleAtLeast = (role: UserRole, required: UserRole) =>
	sortedRoles.indexOf(role) >= sortedRoles.indexOf(required);

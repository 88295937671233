import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Moment from 'react-moment';
import {
	Card,
	DropdownMenu,
	DropdownToggle,
	Table,
	UncontrolledDropdown,
} from 'reactstrap';

import { buildSavedQueryUrl } from '../../common/links';
import { DropDownMenuItem } from '../../components/drop-down-menu.component';
import { Cell, CellLink } from '../../components/tables.styled-components';
import ClearSavedQueriesButton from '../../dashboard/components/clear-saved-queries-button.component';

import { Query } from '../models/QueryModel';

interface Props {
	queries: ReadonlyArray<Query>;
}

const renderNoQueriesRow = () => (
	<tr>
		<td colSpan={3}>
			<p>You currently have no saved queries.</p>
		</td>
	</tr>
);

const QueriesTable = (props: Props) => {
	const renderQueryRow = (query: Query) => (
		<tr key={query._id}>
			<Cell>
				<CellLink to={buildSavedQueryUrl(query)}>{query.value}</CellLink>
			</Cell>
			<Cell>{query.type}</Cell>
			<Cell>
				<Moment format="MM/DD/YYYY" date={query.createdAt} />
			</Cell>
			<Cell>
				<UncontrolledDropdown>
					<DropdownToggle color="white">
						<FontAwesomeIcon icon={faEllipsisV} />
					</DropdownToggle>
					<DropdownMenu>
						<DropDownMenuItem>Edit query</DropDownMenuItem>
						<DropDownMenuItem className="danger">Delete query</DropDownMenuItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</Cell>
		</tr>
	);

	return (
		<>
			<Card>
				<Table>
					<thead>
						<tr>
							<th>Search Term</th>
							<th>Context</th>
							<th>Creation Date</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{props.queries.length
							? props.queries.map((query) => renderQueryRow(query))
							: renderNoQueriesRow()}
					</tbody>
				</Table>
			</Card>
			{props.queries.length ? (
				<ClearSavedQueriesButton className="mt-4 float-right" />
			) : null}
		</>
	);
};

export default QueriesTable;

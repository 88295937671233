import { action, computed, observable } from 'mobx';

interface Dimensions {
	width: number;
	height: number;
}

class WindowModel {
	@observable
	width = window.innerWidth;

	@observable
	height = window.innerHeight;

	@computed.struct
	get dimensions(): Dimensions {
		return {
			width: this.width,
			height: this.height,
		};
	}

	constructor() {
		window.addEventListener('resize', () => this.updateDimensions());
	}

	@action
	private updateDimensions() {
		this.width = window.innerWidth;
		this.height = window.innerHeight;
	}

	public getURLSearchParam(name: string) {
		const searchParams = new URLSearchParams(window.location.search);
		return searchParams.get(name);
	}

	public reload() {
		window.location.reload();
	}

	public openInNewWindow(url: string, target = '_blank') {
		window.open(url, target);
	}
}

const windowModel = new WindowModel();
export default windowModel;

import React, { ReactNode, RefObject } from 'react';
import { ButtonProps } from 'reactstrap';
import { StyledButton } from './submit-button.styled-components';

export const ManagedButton = (
	props: ButtonProps & {
		label: string;
		buttonIcon?: ReactNode;
		tooltip?: (buttonRef: RefObject<HTMLElement>) => ReactNode;
	}
) => {
	const { label, buttonIcon = null, ...buttonProps } = props;

	return (
		<StyledButton {...buttonProps}>
			{buttonIcon}
			{label}
		</StyledButton>
	);
};

export default ManagedButton;

import { Badge } from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../theme/models/ThemeStore';
import { fsSmol } from '../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

export const EntityPropList = styled.ul`
	font-size: 14px;
	list-style: none;
	margin-bottom: 0;
	padding: inherit;

	@media only screen and (min-width: 768px) {
		padding: 0;
	}
`;

export const EntityPropListItem = styled.li`
	margin-top: 15px;

	&:first-of-type {
		margin-top: 0;
	}
`;

export const EntityPropLabel = styled.div`
	${fsSmol};
	color: ${colors.darkerGrey};
`;

export const TagBadge = styled(Badge)`
	&& {
		background-color: ${colors.lightGrey};
		color: ${colors.black};
		padding: 8px;
		font-size: 14px;
	}
	margin: 10px 10px 0 0;
`;

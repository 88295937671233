import React, { useCallback } from 'react';
import { Form, FormGroup, ModalBody } from 'reactstrap';
import { getId, useEditingFlow } from '../../common';
import { _logError } from '../../common/log';
import { SubmitButton } from '../../components/forms';
import DialogModal from '../../components/modals/dialog-modal.component';
import NotificationModel, {
	NotificationType,
} from '../../notifications/NotificationModel';
import notificationStore from '../../notifications/NotificationStore';
import { useWorkflowCollectionStore } from '../../stores';
import { useModalCloser } from '../../stores/ModalStack';
import themeStore from '../../theme/models/ThemeStore';
import { WorkflowCollection } from '../models/WorkflowCollectionModel';
import CollectionWorkflowSelect from './collection-workflow-select.component';
import { difference } from 'lodash';
import { applyPatch, resolveIdentifier } from 'mobx-state-tree';
import { WorkflowModel } from '../../workflows/models/WorkflowModel';

const EditCollectionWorkflowsDialog = ({
	selectedCollection,
}: {
	selectedCollection: WorkflowCollection;
}) => {
	const modalStack = useModalCloser();
	const collectionStore = useWorkflowCollectionStore();
	const [, getCollectionSnapshot, setLastKnownGood] = useEditingFlow(
		selectedCollection
	);

	const onFormSubmit = useCallback(async () => {
		try {
			const updatedCollection = await collectionStore.updateOne(
				getCollectionSnapshot()
			);

			const { workflows: workflowsBefore } = setLastKnownGood(
				updatedCollection
			);
			const { workflows: workflowsAfter } = updatedCollection;

			const beforeIds = workflowsBefore.map(getId);
			const afterIds = workflowsAfter.map(getId);

			const added = difference(beforeIds, afterIds);
			const removed = difference(beforeIds, afterIds);

			added.forEach((wid) => {
				const workflow = resolveIdentifier(WorkflowModel, collectionStore, wid);
				if (workflow) {
					applyPatch(workflow, {
						op: 'replace',
						path: '/workflowCollection',
						value: selectedCollection._id,
					});
				}
			});
			removed.forEach((wid) => {
				const workflow = resolveIdentifier(WorkflowModel, collectionStore, wid);
				if (workflow) {
					applyPatch(workflow, {
						op: 'replace',
						path: '/workflowCollection',
						value: undefined,
					});
				}
			});

			modalStack.closeModal();

			notificationStore.push(
				NotificationModel.create({
					text: `${themeStore._.workflowCollection} saved!`,
				})
			);
		} catch (error) {
			_logError(error);
			modalStack.closeModal();

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred while saving ${themeStore._.workflowCollection.toLowerCase()}. Please try again later.`,
				})
			);
		}
	}, [
		selectedCollection,
		getCollectionSnapshot,
		setLastKnownGood,
		collectionStore,
		modalStack,
	]);

	return (
		<DialogModal header={`Add ${themeStore._.workflow.toLowerCase()}s`}>
			<ModalBody>
				<Form>
					<FormGroup>
						<CollectionWorkflowSelect collection={selectedCollection} />
					</FormGroup>
					<SubmitButton onClick={onFormSubmit} label="Save" />
				</Form>
			</ModalBody>
		</DialogModal>
	);
};

export default EditCollectionWorkflowsDialog;

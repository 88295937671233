import { Card, Col } from 'reactstrap';
import { useObserver } from 'mobx-react';
import moment from 'moment';
import React, { useCallback } from 'react';

import { Cell, CellLink } from '../../components/tables.styled-components';
import {
	ListColumn,
	makeColumns,
} from '../../components/list-column.component';
import { ScrollTable } from '../../components/ui';
import ListTable from '../../components/list-table.component';
import themeStore from '../../theme/models/ThemeStore';

import { AssetVersion } from '../models/AssetVersionModel';

// constants
const assetTerm = themeStore._.asset.toLowerCase();
const assetListColumns: ListColumn<AssetVersion>[] = makeColumns([
	{
		label: 'Name',
		prop: (a: AssetVersion) => <CellLink to={`./${a._id}`}>{a.title}</CellLink>,
	},
	{
		label: 'Created At',
		prop: (asset) => moment(asset.createdAt).format('YYYY-MM-DD'),
	},
	{
		label: 'File size',
		prop: 'size',
	},
	{
		label: 'File type',
		prop: 'type',
	},
]);

// interface
interface Props {
	assets: readonly AssetVersion[];
	isSelectingAssets?: boolean;
	onAssetSelected?: (assets: AssetVersion) => void;
	selectedAssets?: readonly AssetVersion[];
}

// component
const AssetListTable = (props: Props) => {
	const { assets, isSelectingAssets, onAssetSelected, selectedAssets } = props;

	const checkboxCallBack = useCallback(
		(asset: AssetVersion) => {
			if (onAssetSelected) {
				onAssetSelected(asset);
			}
		},
		[onAssetSelected]
	);

	const renderStaticList = () => (
		<ListTable
			columns={assetListColumns}
			rows={assets}
			noResultsLabel={`No ${assetTerm.toLowerCase()}s yet`}
		/>
	);

	const renderSelectableRows = () =>
		assets.map((asset) => (
			<tr key={asset._id}>
				{assetListColumns.map((column) => column.render(asset))}
				<Cell>
					<input
						type="checkbox"
						value={asset._id}
						checked={selectedAssets?.includes(asset)}
						onChange={() => checkboxCallBack(asset)}
					/>
				</Cell>
			</tr>
		));

	const renderSelectableList = () => (
		<ScrollTable>
			<thead>
				<tr>
					{assetListColumns.map((c) => c.renderHeader())}
					<th>Select</th>
				</tr>
			</thead>
			<tbody>
				{assets.length ? (
					renderSelectableRows()
				) : (
					<tr>
						<Cell className="pt-4">{`No ${assetTerm}s`}</Cell>
					</tr>
				)}
			</tbody>
		</ScrollTable>
	);

	const render = () => (
		<Col md={12}>
			<Card className="mt-3">
				{isSelectingAssets ? renderSelectableList() : renderStaticList()}
			</Card>
		</Col>
	);

	return useObserver(render);
};

export default AssetListTable;

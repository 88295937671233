import React, { PropsWithChildren } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';

import { useModalCloser } from '../../stores/ModalStack';

import DialogModal from './dialog-modal.component';


interface Props {
	header: string;
	buttonLabel: string;
}


const WarningModal = (props: PropsWithChildren<Props>) => {
	const modalStack = useModalCloser();
	const { buttonLabel, children, header } = props;

	const render = () => (
		<DialogModal header={ header }>
			<ModalBody>
				{ children }
			</ModalBody>
			<ModalFooter>
				<Button
					size="sm"
					onClick={ modalStack.closeModal }
				>
					{ buttonLabel }
				</Button>
			</ModalFooter>
		</DialogModal>
	);

	return render();
};

export default WarningModal;

import { applySnapshot, flow, getParentOfType, types } from 'mobx-state-tree';
import { StageStatusModel } from '../../models/StageStatusModel';
import {
	getStores } from '../../stores';
import {WorkflowTransition,
	WorkflowTransitionModel,
} from './WorkflowTransitionModel';
import { StageEventModel } from '../../models/StageEventModel';
import { BaseWorkflowPhaseReference } from '../../models/BaseWorkflowPhaseModel';
import { IdentifiableNode } from '../../common';
import { WorkflowModel } from './WorkflowModel';
import { isBackward } from '../../models/BaseWorkflowTransitionModel';
import { getClient } from '../../core';

export const WorkflowStageBaseModel = types
	.model('WorkflowStage', {
		_id: types.identifier,
		title: types.string,

		status: StageStatusModel,

		transitions: types.array(types.late(() => WorkflowTransitionModel)),

		events: types.array(StageEventModel),

		phase: BaseWorkflowPhaseReference,
	})
	.views((self) => ({
		get workflow(): IdentifiableNode {
			return getParentOfType(self, WorkflowModel);
		},
		get backwardTransitions(): WorkflowTransition[] {
			return self.transitions.filter(isBackward);
		},
		isCreatorOfWorkflow(userId: OrID<Identifiable>): boolean {
			const workflow = getParentOfType(self, WorkflowModel);
			return workflow.createdBy._id === userId;
		},
		get isInWorkflowCreatedByCurrentUser(): boolean {
			const { currentUser } = getStores(self).users;
			return this.isCreatorOfWorkflow(currentUser._id);
		},
	}))
	.actions((self) => ({
		performStageAction: flow(
			// Todo define actionRequest types
			function* performStageAction(actionRequest: object) {
				const client = getClient(self);
				const workflowAfterAction = yield client.put(
					`/workflows/${self.workflow._id}/stages/${self._id}`,
					actionRequest
				);
				applySnapshot(self.workflow, workflowAfterAction);
			}
		),
	}));

import { CalendarTileProperties } from 'react-calendar';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React, { useState } from 'react';

import themeStore from '../../theme/models/ThemeStore';

import { StyledCalendar } from './workflow-due-dates-calendar.styled-components';
import WorkflowDueDatesCalendarTile from './workflow-due-dates-calendar-tile.component';

const workflowTerm = themeStore._.workflow;

// components
const WorkflowDueDatesCalendar = () => {
	const [date] = useState(new Date());

	const buildTileContent = (options: CalendarTileProperties) => {
		return (
			<WorkflowDueDatesCalendarTile date={options.date} view={options.view} />
		);
	};

	const render = () => (
		<Card className="workflow-dure-date-calendar h-100">
			<CardHeader>
				<h2 className="mb-0">
					<FontAwesomeIcon icon={faCalendarCheck} className="mr-2" />
					{workflowTerm} Due Dates
				</h2>
			</CardHeader>
			<CardBody>
				<StyledCalendar
					value={date}
					tileContent={buildTileContent}
					tileClassName="calendar-tile"
					tileDisabled={() => {
						return true;
					}}
				/>
			</CardBody>
		</Card>
	);

	return useObserver(render);
};

export default WorkflowDueDatesCalendar;

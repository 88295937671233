import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Heading } from '../components/ui';
import { indexViewStore } from '../index-view/models/IndexViewStore';
import { QueryTypes } from '../queries/models/QueryStore';
import { unwrapEvent } from '../common';
import { ViewType } from '../index-view/models/IndexViewModel';
import { builtinWorkflowPredicates, WFilter } from '../common/filters';
import IndexViewToggler from '../index-view/components/index-view-toggler.component';
import PageSubheading from '../components/page-subheading.component';
import SaveQueryButton from '../components/save-query-button.component';
import SearchBar from '../components/search-bar/search-bar.component';
import SideBarFiltersNav from '../components/side-bar-filters/side-bar-filters-nav.component';
import themeStore from '../theme/models/ThemeStore';
import windowModel from '../models/WindowModel';

import { CreateWorkflowButton } from './components/workflow-index.styled-components';
import WorkflowCardGrid from './components/workflow-card-grid.component';
import WorkflowListTable from './components/workflow-list-table.component';
import { PermissionsCheck } from '../accounts/permissions-check.component';
import { StorePaginator } from '../components/store-paginator/store-paginator.component';
import { useFilteredStore } from '../common/FilteredStore';
import { useWorkflowStore } from '../stores/index';

const workflowTerm = themeStore._.workflow;
const workflowLowerCaseTerm = workflowTerm.toLowerCase();

const filterNavOptions = [
	{
		label: `All ${workflowLowerCaseTerm}s`,
		value: undefined,
	},
	{
		label: 'Active',
		value: WFilter.ACTIVE,
	},
	{
		label: 'Paused',
		value: WFilter.PAUSED,
	},
	{
		label: 'Cancelled',
		value: WFilter.CANCELLED,
	},
	{
		label: 'Created by me',
		value: WFilter.CREATED_BY,
	},
	{
		label: 'Collaborating',
		value: WFilter.COLLABORATING,
	},
];

const WorkflowsIndexView = () => {
	const filteredWorkflowStore = useFilteredStore(
		useWorkflowStore(),
		builtinWorkflowPredicates
	);
	const workflowsViewOptions = indexViewStore.workflows;

	useEffect(() => {
		const queryValue = windowModel.getURLSearchParam('q');
		if (queryValue) {
			filteredWorkflowStore.setQuery(queryValue);
		}
	}, [filteredWorkflowStore]);

	const render = () => {
		const WorkflowsRenderer =
			indexViewStore.workflows.viewType === ViewType.grid
				? WorkflowCardGrid
				: WorkflowListTable;

		return (
			<Container>
				<Row>
					<Col lg={2} md={3} className="mb-md-0 mb-4">
						<PageSubheading text={themeStore._.baseWorkflow} />
						<Heading>{`${workflowTerm}s`}</Heading>

						<PermissionsCheck check="canCreateWorkflow">
							<CreateWorkflowButton to="./new">
								<FontAwesomeIcon icon={faPlus} className="mr-2" />
								New {workflowLowerCaseTerm}
							</CreateWorkflowButton>
						</PermissionsCheck>

						<SideBarFiltersNav
							filter={filteredWorkflowStore.predicate}
							setFilter={unwrapEvent(filteredWorkflowStore.setFilter)}
							options={filterNavOptions}
						/>
					</Col>

					<Col lg={10} md={9}>
						<Row className="mb-4">
							<Col md={9}>
								<SearchBar
									placeholder={`Search all ${workflowLowerCaseTerm}s...`}
									onChange={unwrapEvent(filteredWorkflowStore.setQuery)}
								/>
							</Col>
							<Col md={3} className="mt-md-0 mt-3">
								<SaveQueryButton
									className="float-md-right float-none"
									searchTerm={filteredWorkflowStore.searchQuery}
									type={QueryTypes.WORKFLOWS}
								/>
							</Col>
						</Row>
						<hr />
						<div className="d-flex justify-content-end">
							<IndexViewToggler view={workflowsViewOptions} />
						</div>

						<WorkflowsRenderer
							workflows={filteredWorkflowStore.currentEntityPage}
						/>
						<StorePaginator
							pages={filteredWorkflowStore.pages}
							shouldPaginate={filteredWorkflowStore.shouldPaginate}
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default WorkflowsIndexView;

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { unwrapEvent } from '../common';

import { builtInAssetCollectionPredicates, Filter } from '../common/filters';
import PageSubheading from '../components/page-subheading.component';
import SaveQueryButton from '../components/save-query-button.component';
import SearchBar from '../components/search-bar/search-bar.component';
import SideBarFiltersNav from '../components/side-bar-filters/side-bar-filters-nav.component';
import { StorePaginator } from '../components/store-paginator/store-paginator.component';
import { Heading } from '../components/ui';
import windowModel from '../models/WindowModel';
import { QueryTypes } from '../queries/models/QueryStore';
import { useAssetCollectionStore } from '../stores';
import themeStore from '../theme/models/ThemeStore';
import AssetCollectionListTable from './components/asset-collection-list-table.component';

import { NewCollectionButton } from './components/asset-collections-index.styled-components';
import { useFilteredStore } from '../common/FilteredStore';

// constants
const label = themeStore._.assetCollection;

const filterNavOptions = [
	{
		label: `All ${label?.toLowerCase()}s`,
		value: undefined,
	},
	{
		label: 'Created by me',
		value: Filter.CREATED_BY,
	},
];

// component
const AssetCollectionsIndexView = () => {
	const assetCollectionStore = useAssetCollectionStore();
	const filteredCollectionStore = useFilteredStore(
		assetCollectionStore,
		builtInAssetCollectionPredicates
	);

	/*
	 Disabled until we assign a cover image to collections.
	 It is way too chatty to load the first asset for each collection.
	*/
	// const assetCollectionsViewOptions = indexViewStore.assetCollections;

	useEffect(() => {
		const searchTerm = windowModel.getURLSearchParam('q');
		if (searchTerm) {
			filteredCollectionStore.setQuery(searchTerm);
		}
	}, [filteredCollectionStore]);

	const render = () => (
		<Container>
			<Row>
				<Col lg={2} md={3} className="mb-md-0 mb-4">
					<PageSubheading text={themeStore._.assetBase} />
					<Heading>{label}s</Heading>

					<NewCollectionButton to="./new">
						<FontAwesomeIcon icon={faPlus} className="mr-2" />
						New {label?.toLowerCase()}
					</NewCollectionButton>

					<SideBarFiltersNav
						setFilter={unwrapEvent(filteredCollectionStore.setFilter)}
						options={filterNavOptions}
					/>
				</Col>

				<Col lg={10} md={9}>
					<Row className="mb-4">
						<Col md={9}>
							<SearchBar
								placeholder={`Search all ${label?.toLowerCase()}s...`}
								onChange={unwrapEvent(filteredCollectionStore.setQuery)}
							/>
						</Col>
						<Col md={3} className="mt-md-0 mt-3">
							<SaveQueryButton
								className="float-md-right float-none"
								searchTerm={filteredCollectionStore.searchQuery}
								type={QueryTypes.ASSET_COLLECTIONS}
							/>
						</Col>
					</Row>
					<hr />
					<div className="d-flex justify-content-end">
						{/*<IndexViewToggler view={assetCollectionsViewOptions} />*/}
					</div>

					<AssetCollectionListTable
						collections={filteredCollectionStore.filteredEntities}
						noResultsLabel="No results"
					/>
					<StorePaginator
						pages={filteredCollectionStore.pages}
						shouldPaginate={filteredCollectionStore.shouldPaginate}
					/>
				</Col>
			</Row>
		</Container>
	);

	return useObserver(render);
};

export default AssetCollectionsIndexView;

import { getParent, IMaybe, Instance, IReferenceType, resolveIdentifier, types } from 'mobx-state-tree';
import { getName, includesCaseInsensitive } from '../common';

export const BaseWorkflowPhaseModel = types.model(
	'BaseWorkflowPhase',
	{
		_id: types.identifier,
		title: types.optional(types.string, 'Untitled Phase'),
		color: types.optional(types.string, '#fff')
	},
);

export interface BaseWorkflowPhaseModel extends Infer<typeof BaseWorkflowPhaseModel> {
}

export interface BaseWorkflowPhase extends Instance<typeof BaseWorkflowPhaseModel> {
}

export const doesPhaseMatch = (input: Nullable<string>, p: BaseWorkflowPhase) => includesCaseInsensitive(getName(p), input);

export const BaseWorkflowPhaseReference: IMaybe<IReferenceType<BaseWorkflowPhaseModel>> = types.safeReference(
	BaseWorkflowPhaseModel,
	{
		get(identifier: string, parent: any /* WorkflowRootStage */) {
			return resolveIdentifier(BaseWorkflowPhaseModel, getParent(parent, 1), identifier) as BaseWorkflowPhase;
		},
		set(phase: BaseWorkflowPhase): string {
			return phase._id;
		},
	},
);

import { useObserver } from 'mobx-react';
import React from 'react';
import { Card } from 'reactstrap';

import { Subtitle } from '../../../components/ui';
import { PanelHeader } from '../user-details.styled-components';
import AccountsTable from './accounts-table.component';
//import SideNavButton from '../side-nav-button.component';

const AccountsPanel = () => {
	const render = () => (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">Accounts</Subtitle>
				{/* TODO: Implement adding Accounts */}
				{/*<SideNavButton*/}
				{/*	label="Add Account"*/}
				{/*/>*/}
			</PanelHeader>
			<Card className="mt-4">
				<AccountsTable />
			</Card>
		</article>
	);

	return useObserver(render);
};

export default AccountsPanel;

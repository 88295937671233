import { values } from 'mobx';
import { flow, getParentOfType, types } from 'mobx-state-tree';
import { UserGroup } from '../../accounts/models/UserGroupModel';
import { User } from '../../accounts/models/UserModel';
import { throwErr } from '../../common/fn.utils';
import { _logError } from '../../common/log';
import { BaseWorkflowOwnableModel } from '../../models/BaseWorkflowOwnableModel';
import { optionalString } from '../../models/common';
import { isRoadblockUpdate, StageEvent } from '../../models/StageEventModel';
import { EventType } from '../../models/StageEventTypeModel';
import { StageStatus } from '../../models/StageStatusModel';
import themeStore from '../../theme/models/ThemeStore';
import { Roadblock } from './RoadblockModel';
import { WorkflowModel } from './WorkflowModel';
import { WorkflowStageBaseModel } from './WorkflowStageBaseModel';
import {
	NewComment,
	WorkflowStageComment,
	WorkflowStageCommentModel,
} from './WorkflowStageCommentModel';
import {
	WorkflowStageInputSlot,
	WorkflowStageInputSlotModel,
} from './WorkflowStageInputSlot';
import { WorkflowStage } from './WorkflowStageModel';

export const WorkflowActionableStageCommonModel = types
	.compose(WorkflowStageBaseModel, BaseWorkflowOwnableModel)
	.named('WorkflowActionableStage')
	.props({
		expectedDurationHrs: types.optional(types.number, 24),

		instructions: optionalString,

		comments: types.array(WorkflowStageCommentModel),

		inputSlots: types.maybe(types.array(WorkflowStageInputSlotModel)),
	})
	.views((self) => ({
		get defaultInputSlot(): WorkflowStageInputSlot {
			if (self.inputSlots?.length) {
				return self.inputSlots[0];
			}
			return throwErr(
				`Trying to upload ${themeStore._.asset.toLowerCase()} for non-input stage?`
			);
		},
		get roadblocks(): ReadonlyArray<Roadblock> {
			if (self.status === StageStatus.roadblocked) {
				const roadblockEvent: Maybe<StageEvent> = self.events.reduce(
					(lastRoadblock: Maybe<StageEvent>, event: StageEvent) =>
						isRoadblockUpdate(event) ? event : lastRoadblock,
					undefined
				);

				if (roadblockEvent) {
					return [{ stage: self as WorkflowStage, event: roadblockEvent }];
				}

				_logError(`${self._id} is in roadblock status but has no event`);
			}
			return [];
		},
		get isCompleted(): boolean {
			return self.status === StageStatus.completed;
		},
		get firstStageIsActivated(): boolean {
			const workflow = getParentOfType(self, WorkflowModel);
			const firstStage = values(workflow.stages)[0];
			return firstStage.status !== StageStatus.queue;
		},
	}))
	.actions((self) => ({
		updateStatus: flow(function* updateStatus(
			newStatus: StageStatus,
			message: string
		) {
			yield self.performStageAction({
				type: EventType.statusChange,
				newStatus,
				message,
			});
		}),
		addComment: flow(function* addComment(comment: NewComment) {
			yield self.performStageAction({
				type: EventType.comment,
				message: comment.message,
			});
		}),
		deleteComment(comment: WorkflowStageComment) {
			self.comments.splice(self.comments.indexOf(comment), 1);
		},
		takeTransition: flow(function* takeTransition(
			transitionId: string,
			message: string
		) {
			yield self.performStageAction({
				type: EventType.backwardTransition,
				transitionId,
				message,
			});
		}),
		setOwner: flow(function* setOwner(newOwners: Nullable<User | UserGroup>[]) {
			if (newOwners) {
				yield self.performStageAction({
					type: EventType.setOwner,
					newOwners,
				});
			}
		}),
	}));

import { Col, Container, Row } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Divider } from '../../components/ui';
import BackLink from '../../components/back-link/back-link.component';
import themeStore from '../../theme/models/ThemeStore';
import { SelectedTemplate, SelectedTemplateStage } from '../template.contexts';
import { TemplateRootStage, TemplateStage } from '../models';
import BackingSvg from '../svg/BackingSVG';
import {
	ButtonLink,
	FinalTemplateCanvas,
	FinalTemplateKeyWrapper,
	InfoButton,
	TemplateHeader,
	TemplateTitle,
} from './template-final-details.styled-components';
import { StageWrapper } from './template-editor.styled-components';
import { canvasRootClass } from '../svg/ElementStore';
import TemplateCloneButton from './template-clone-button';
import TemplateFinalDetailsCard from './template-final-details-card.component';
import TemplateStageCard from './stage-cards/template-stage-card.component';
import TemplatePhaseKey from './template-phase-key.component';

const workflowTerm = themeStore._.workflow;

// Todo make structure match editor component more closely.

const TemplateFinalDetails = () => {
	const [template] = SelectedTemplate.usePresent();

	const [selectedStage, setSelectedStage] = useState<Maybe<TemplateStage>>(
		undefined
	);

	// render methods
	const render = () => (
		<SelectedTemplateStage.Provider value={[selectedStage, setSelectedStage]}>
			<Container>
				<Row>
					<Col xs={12}>
						<BackLink link=".." title={`${themeStore._.workflow} Templates`} />
						<TemplateHeader>
							<TemplateTitle onClick={() => setSelectedStage(undefined)}>
								{template.title}
								{selectedStage ? <InfoButton /> : null}
							</TemplateTitle>
							<div>
								<TemplateCloneButton />
								<ButtonLink
									to={`/admin/workflow/workflows/new#${template._id}`}
								>
									<FontAwesomeIcon icon={faPlus} className="mr-2" />
									Create a {workflowTerm.toLowerCase()} from this template
								</ButtonLink>
							</div>
						</TemplateHeader>
						<Divider />
					</Col>
					<Col md={9}>
						<FinalTemplateKeyWrapper>
							<TemplatePhaseKey template={template} />
						</FinalTemplateKeyWrapper>
						<FinalTemplateCanvas className={canvasRootClass}>
							{template.stages.map((stage: TemplateRootStage) => (
								<StageWrapper key={stage._id}>
									<TemplateStageCard stage={stage} editable={false} />
								</StageWrapper>
							))}
							<BackingSvg />
						</FinalTemplateCanvas>
					</Col>
					<Col md={3}>
						<TemplateFinalDetailsCard />
					</Col>
				</Row>
			</Container>
		</SelectedTemplateStage.Provider>
	);

	return render();
};

export default TemplateFinalDetails;

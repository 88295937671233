import React from 'react';
import styled from 'styled-components';
import { Badge } from 'reactstrap';

import { capitalizeFirstLetter, buildClassList } from '../common';
import { WorkflowStatus, workflowStatusColors } from '../workflows/models';
import themeStore from '../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const Pill = styled(Badge)`
	color: ${colors.white};

	&& {
		padding: 4px 6px;
		font-size: 11px;
	}

	&.badge-active {
		background-color: ${colors.active};
	}
	&.badge-overdue {
		background-color: ${colors.overdue};
	}
	&.badge-blocked {
		background-color: ${colors.blocked};
	}
	&.badge-pipeline {
		background-color: ${colors.pipeline};
	}
	&.badge-completed {
		background-color: ${colors.success};
	}
`;

const WorkflowStatusPill = (props: {
	status: WorkflowStatus;
	className?: string;
}) => {
	const render = () => (
		<Pill
			pill
			className={buildClassList('workflow-status-pill', props.className)}
			color={workflowStatusColors[props.status]}
		>
			{capitalizeFirstLetter(props.status)}
		</Pill>
	);

	return render();
};

export default WorkflowStatusPill;

export * from './array.utils';
export * from './mobx.utils';
export * from './enum.utils';
export * from './fn.utils';
export * from './hooks';
export * from './math';
export * from './messages';
export * from './react.utils';
export * from './storage';
export * from './string.utils';

export * from './NullableContext';
export { getRGBfromHEX } from './color.utils';
export { getRandomDarkColor } from './color.utils';
export { getRandomPastel } from './color.utils';

export const loadingValue = '$loading';

export const isProd = process.env.NODE_ENV === 'production';

export const requiredValue = <T>(v: Maybe<T>): T => {
	if (!v) {
		throw new Error('Missing required environment variable');
	}
	return v;
};

export const requiredProdValue = <T>(v: Maybe<T>): Maybe<T> => {
	if (isProd) {
		return requiredValue(v);
	}
	return v;
};

import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { SecondaryButton } from '../../components/buttons.styled-components';
import { useModalCreator } from '../../stores/ModalStack';
import { usePermissions, useQueryStore } from '../../stores';
import ConfirmationDialog from '../../components/modals/confirmation-dialog.component';
import NotificationModel from '../../notifications/NotificationModel';
import notificationStore from '../../notifications/NotificationStore';

interface Props {
	className?: string;
}

const ClearSavedQueriesButton = (props: Props) => {
	const modalStack = useModalCreator();
	const permissions = usePermissions();
	const queryStore = useQueryStore();

	const clearQueries = useCallback(() => {
		queryStore.clear();

		notificationStore.push(
			NotificationModel.create({
				text: 'Queries cleared!',
			})
		);
	}, [queryStore]);

	const showDeletionConfirmation = useCallback(() => {
		modalStack.addModal(
			<ConfirmationDialog
				header="Delete all saved queries"
				onConfirm={clearQueries}
			>
				<p>Are you sure you want to delete all saved queries?</p>
				<p>This action cannot be reverted.</p>
			</ConfirmationDialog>
		);
	}, [clearQueries, modalStack]);

	// render methods
	const render = () => {
		return permissions.canClearAllSavedQueries && queryStore.numQueries ? (
			<SecondaryButton
				className={`clear-queries-button ${props.className}`}
				onClick={showDeletionConfirmation}
			>
				<FontAwesomeIcon icon={faTrash} />
				<span className="ml-2">Delete all queries</span>
			</SecondaryButton>
		) : null;
	};

	return render();
};

export default ClearSavedQueriesButton;

import { getRoot, getSnapshot, Instance } from 'mobx-state-tree';
import { UserStore } from '../../accounts/models/UserStore';

import { generateID, loadingValue } from '../../common';
import { Filter } from '../../common/filters';
import { makeEntityStore } from '../../models/EntityStore.model';
import { RootStore } from '../../stores';

import {
	WorkflowCollection,
	WorkflowCollectionModel,
} from './WorkflowCollectionModel';

export const emptyCollection = (rootStore: RootStore): WorkflowCollection =>
	WorkflowCollectionModel.create(
		{
			_id: generateID(),
			createdBy: loadingValue,
			title: '',
		},
		{ ...rootStore }
	);

const loadingCollection = WorkflowCollectionModel.create({
	_id: loadingValue,
	createdBy: loadingValue,
	title: 'Loading...',
});

const WorkflowCollectionStoreModelInferred = makeEntityStore<
	WorkflowCollectionModel,
	WorkflowCollection
>(WorkflowCollectionModel, 'projects', getSnapshot(loadingCollection)).views(
	(self) => ({
		getArchived(): ReadonlyArray<WorkflowCollection> {
			// TODO: obtain archived collections (RRR-130)
			const archived: WorkflowCollection[] = [];
			return archived;
		},
		getCollaboratingWithCurrentUser(): ReadonlyArray<WorkflowCollection> {
			return self.all.filter((c) => c.isCurrentUserInvolved);
		},
		getCreatedByCurrentUser(): ReadonlyArray<WorkflowCollection> {
			const userStore: UserStore = getRoot<RootStore>(self).users;
			return self.all.filter((c) => c.createdBy === userStore.currentUser);
		},
		getFollowedByCurrentUser(): ReadonlyArray<WorkflowCollection> {
			// TODO: obtain collections which current user follows (RRR-131)
			const followed: WorkflowCollection[] = [];
			return followed;
		},
		getOwnedByCurrentUser(): ReadonlyArray<WorkflowCollection> {
			return self.all.filter((c) => c.isOwnedByCurrentUser);
		},
		filterCollections(
			filter: Filter | undefined
		): ReadonlyArray<WorkflowCollection> {
			const workflowGetters = {
				[Filter.ARCHIVED]: this.getArchived,
				[Filter.COLLABORATING]: this.getCollaboratingWithCurrentUser,
				[Filter.CREATED_BY]: this.getCreatedByCurrentUser,
				[Filter.FOLLOWING]: this.getFollowedByCurrentUser,
				[Filter.OWNED]: this.getOwnedByCurrentUser,
			};

			if (filter) {
				return workflowGetters[filter]();
			} else {
				return self.all;
			}
		},
	})
);

export interface WorkflowCollectionStoreModel
	extends Infer<typeof WorkflowCollectionStoreModelInferred> {}

export const WorkflowCollectionStoreModel: WorkflowCollectionStoreModel = WorkflowCollectionStoreModelInferred;

export interface WorkflowCollectionStore
	extends Instance<WorkflowCollectionStoreModel> {}

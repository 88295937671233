import React, { ReactNode, useEffect, useState } from 'react';

type OrOutput<F extends (...args: any) => any> = F | ReturnType<F>;

function outputOf<Args>(x: OrOutput<(a: Args) => ReactNode>, a: Args): ReactNode {
	return typeof x === 'function' ? x(a) : x;
}

export interface AsyncDispatcherProps<T> {
	loader: () => Promise<T>;
	children: {
		loading?: OrOutput<() => ReactNode>;
		success: OrOutput<(x: T) => ReactNode>;
		error: OrOutput<(err: Error) => ReactNode>;
	};
}

const AsyncDispatcher = <T extends any>({ loader, children }: AsyncDispatcherProps<T>) => {
	const [output, setOutput] = useState<ReactNode>(children.loading ?? 'Loading...');

	useEffect(
		() => void loader()
			.then((result: T) => setOutput(outputOf(children.success, result)))
			.catch((err: Error) => setOutput(outputOf(children.error, err))),
		[children, loader],
	);

	return (<>{ output }</>);
};

export default AsyncDispatcher;

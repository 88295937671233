import styled from 'styled-components';
import { Input } from 'reactstrap';

import { focus } from '../../theme/ui/ui';
import themeStore from '../../theme/models/ThemeStore';


const { colors } = themeStore.selectedTheme;


export const StyledInput = styled(Input)`
	border: none !important;
	color: ${ colors.darkGrey };
`;

export const SearchButton = styled.button`
	background-color: ${ colors.white };
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	border: none;
	color: ${ colors.secondary };
	z-index: 1;

	&:focus {
		${ focus };
	}
`;

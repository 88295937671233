import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import { Card } from 'reactstrap';
import { Subtitle } from '../../../components/ui';
import { useUserStore } from '../../../stores';
import { PanelHeader } from '../user-details.styled-components';
import UserTable from './user-table.component';
import SideNavButton from '../side-nav-button.component';
import AddUserDialog from './add-user-dialog.component';
import { useModalCreator } from '../../../stores/ModalStack';

const UsersPanel = () => {
	const userStore = useUserStore();
	const modalStack = useModalCreator();

	const showAddUserModal = useCallback(
		() => modalStack.addModal(<AddUserDialog />),
		[modalStack]
	);

	const render = () => (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">Users</Subtitle>

				{/*TODO: Add button back when inviting users is enabled (RRR-198)*/}
				<SideNavButton onClick={showAddUserModal} label="Invite User" />
			</PanelHeader>
			<Card className="mt-4">
				<UserTable users={userStore.all} />
			</Card>
		</article>
	);

	return useObserver(render);
};

export default UsersPanel;

import styled from 'styled-components';
import { Link as LinkBase } from '@reach/router';

import { Heading } from '../../components/ui';
import tabButton from '../../theme/components/tab-button';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const WorkflowTitle = styled(Heading)`
	margin-bottom: 0;
	padding-bottom: 0;
`;

export const Link = styled(LinkBase)`
	${tabButton};
	color: ${colors.primary};

	&:hover {
		color: ${colors.primary};
	}

	&.selected {
		border-bottom: 2px solid ${colors.primary} !important;
	}
`;

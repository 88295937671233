import { ReactElement } from 'react';

/**
 * Constrained part of React.JSXConstructor<P>
 */
export interface JsxFunction<P = unknown> {
	(props: P): ReactElement | string | null;
}

export const renderNull = () => null;

import { isProd, requiredValue } from '../common';
import { getCreationDateOf } from '../models/CreatableEntityModel';

declare global {
	interface Window {
		intercomSettings?: IntercomSettings;
		Intercom?: (...args: unknown[]) => unknown;
	}
}

interface IntercomUser {
	_id: string;
	name: string;
	email: string;
	createdAt: Date;
}

interface IntercomSettings {
	app_id: string;
	name: string;
	user_id: string;
	email: string;
	created_at: string;
}

export const initializeIntercom = (user: IntercomUser): void => {
	// Allow explicitly disabling Intercom.
	if (process.env.REACT_APP_INTERCOM_APP_ID === '0') {
		return;
	}

	// Required in production, present if set in development.
	if (isProd || process.env.REACT_APP_INTERCOM_APP_ID) {
		// Must also be set on `window`:
		const intercomSettings = (window.intercomSettings = {
			app_id: requiredValue(process.env.REACT_APP_INTERCOM_APP_ID),

			user_id: user._id,
			name: user.name,
			email: user.email,

			// signup date as a unix timestamp
			created_at: Math.round(
				getCreationDateOf(user).getTime() / 1000
			).toString(),
		});

		loadIntercom(intercomSettings);
	}
};

export const shutdownIntercom = () =>
	void window.Intercom?.call(null, 'shutdown');

/**
 * Adapted from Intercom boot script.
 * @param intercomSettings {IntercomSettings}
 */
// @ts-ignore @formatter:off
// prettier-ignore
function loadIntercom(intercomSettings){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + intercomSettings.app_id;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}}

import React from 'react';
import { Container } from 'reactstrap';
import { IAnyStateTreeNode, resolveIdentifier } from 'mobx-state-tree';
import { useObserver } from 'mobx-react';

import { Heading } from '../components/ui';
import { useWorkflowStore } from '../stores';

import { AssetVersionModel } from './models/AssetVersionModel';
import { AssetPreviewDiff } from './components/comparison-preview.component';
import { AssetComparisonTable } from './components/comparison-props-table.component';
import { RouteComponentProps } from '@reach/router';

const getAssetVersion = (node: IAnyStateTreeNode, id?: string) => {
	if (id) {
		return resolveIdentifier(AssetVersionModel, node, id);
	}
};

// component
const VersionsComparisonView = (
	props: RouteComponentProps<{
		workflowAId: string;
		versionAId: string;
		workflowBId: string;
		versionBId: string;
	}>
) => {
	const workflowStore = useWorkflowStore();

	const render = () => {
		const workflowA = workflowStore.findOne(props.workflowAId);
		const workflowB = workflowStore.findOne(props.workflowBId);

		if (!workflowA || !workflowB) {
			return null;
		}

		const versionA = getAssetVersion(workflowA, props.versionAId);
		const versionB = getAssetVersion(workflowB, props.versionBId);

		if (!versionA || !versionB) {
			return null;
		}

		return (
			<Container>
				<div className="d-block">
					<Heading>Versions comparison</Heading>
				</div>
				<div className="d-block">
					<AssetPreviewDiff versionA={versionA} versionB={versionB} />
				</div>
				<div className="d-block">
					<AssetComparisonTable
						workflowA={workflowA}
						versionA={versionA}
						workflowB={workflowB}
						versionB={versionB}
					/>
				</div>
			</Container>
		);
	};

	return useObserver(render);
};

export default VersionsComparisonView;

interface RefinementItem {
	label: string;
	value: string[];
	count: number;
	isRefined: boolean;
}

export interface RefinementSettings {
	attribute: string;
	label: string;
	searchable: boolean;
	limit: number;
	transformItems?: (items: Array<RefinementItem>) => Array<RefinementItem>;
}

enum existingFileTypes {
	'application/pdf' = 'PDF (.pdf)',
	'application/zip' = 'ZIP Archive (.zip)',
	'image/jpeg' = 'JPEG image (.jpg, .jpeg)',
	'application/x-zip-compressed' = 'Compressed ZIP (.zip)',
	'application/x-font-otf' = 'OTF Font (.otf)',
	'application/postscript' = 'PostScript (.ps)',
	'binary/octet-stream' = 'Binary file',
	'image/vnd.adobe.photoshop' = 'Adobe Photoshop (.psd)',
	'image/tiff' = 'TIFF image (.tiff)',
	'application/x-font-ttf' = 'TTF Font (.ttf)',
}

const isKnownMimeType = (
	mimetype: string
): mimetype is keyof typeof existingFileTypes => {
	return mimetype in existingFileTypes;
};

const transformMimeType = (item: string): string => {
	if (!isKnownMimeType(item)) {
		return item;
	} else {
		return existingFileTypes[item];
	}
};

const transformFileType = (
	items: Array<RefinementItem>
): Array<RefinementItem> => {
	return items.map((item) => {
		item.label = transformMimeType(item.label);
		return item;
	});
};

// Hardcoded for now, todo make configurable.
export const damAssetRefinementSettings: readonly RefinementSettings[] = [
	{
		attribute: 'type',
		label: 'File Type',
		limit: 24,
		transformItems: transformFileType,
	},
	{
		attribute: 'metadata.Printer',
		label: 'Printer',
		searchable: true,
	},
	{
		attribute: 'metadata.Dieline',
		label: 'Dieline',
		searchable: true,
	},
	{
		attribute: 'metadata.Brand',
		label: 'Brand',
		searchable: true,
	},
	{
		attribute: 'metadata.SKU Name',
		label: 'Variety',
		searchable: true,
	},
	{
		attribute: 'metadata.Count',
		label: 'Count',
	},
].map((settings) => {
	const defaults = {
		searchable: false,
		limit: 12,
	};
	return Object.assign(defaults, settings) as RefinementSettings;
});

import { _logError, _logWarning } from "../common/log";

const warning = '`window.localStorage` may not be available in this browser.';


class StorageModel {
	isAvailable: boolean = false;
	storage: Maybe<Storage>;

	constructor() {
		this.isAvailable = this.isStorageAvailable();
		if (this.isAvailable) {
			this.storage = window.localStorage;
		} else {
			_logWarning(warning);
		}
	}

	isStorageAvailable() {
		// see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
		let storage;

		try {
			storage = window.localStorage;
			let x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);

			return true;

		} catch(error) {
			// note: mdn has more checks, but are they necessary here? to be determined...
			_logError(error);

			return false;
		}
	}

	get(key: string) {
		if (!this.isAvailable) {
			_logWarning(`Unable to get item "${ key }" from local storage, ${ warning }`);
			return;
		}

		return this.storage!.getItem(key);
	}

	set(key: string, value: string) {
		if (!this.isAvailable) {
			_logWarning(`Unable to set "${ key }" item to local storage, ${ warning }`);
			return;
		}

		this.storage!.setItem(key, value);
	}

	remove(key: string) {
		if (!this.isAvailable) {
			_logWarning(`Unable to remove item "${ key }" from local storage, ${ warning }`);
			return;
		}

		this.storage!.removeItem(key);
	}
}

const storageModel = new StorageModel();
export default storageModel;

import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import BackLink from '../components/back-link/back-link.component';
import { Heading } from '../components/ui';
import themeStore from '../theme/models/ThemeStore';
import AddAssetCollectionForm from './components/add-asset-collection-form.component';

const AssetCollectionCreationView = () => (
	<Container>
		<Row className="justify-content-center">
			<Col lg={8}>
				<BackLink link=".." title={`${themeStore._.assetCollection}s`} />
				<div className="d-flex justify-content-between">
					<Heading>
						Create a {themeStore._.assetCollection.toLowerCase()}
					</Heading>
				</div>
			</Col>
			<Col lg={8}>
				<AddAssetCollectionForm />
			</Col>
		</Row>
	</Container>
);

export default AssetCollectionCreationView;

import { useObserver } from 'mobx-react';
import { applyPatch } from 'mobx-state-tree';
import React, { ChangeEvent, useCallback } from 'react';
import { Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import { SecondaryButton } from '../../components/buttons.styled-components';

import { LabeledInput } from '../../components/forms';
import DialogModal from '../../components/modals/dialog-modal.component';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import { useModalCloser } from '../../stores/ModalStack';

import { TemplateStageInputSlot } from '../models';

export interface TemplateStageInputSlotEditorProps {
	slot: TemplateStageInputSlot;
}

const TemplateStageInputSlotEditorModal = ({
	slot,
}: TemplateStageInputSlotEditorProps) => {
	const modalStack = useModalCloser();

	const setSlotProperty = useCallback(
		({ target: { name, value } }: ChangeEvent<any>) =>
			applyPatch(slot, {
				op: 'replace',
				path: `/${name}`,
				value,
			}),
		[slot]
	);

	return useObserver(() => (
		<DialogModal header={`Editing "${slot.label}" input slot`} modalSize="lg">
			<ModalBody>
				<Row>
					<Col>
						<LabeledInput
							id={slot._id}
							label="Input slot label"
							type="text"
							name="label"
							value={slot.label}
							onChange={setSlotProperty}
						/>

						<EntityMetadataForm metadata={slot.metadata} />
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<SecondaryButton onClick={modalStack.closeModal}>
					Save and close
				</SecondaryButton>
			</ModalFooter>
		</DialogModal>
	));
};

export default TemplateStageInputSlotEditorModal;

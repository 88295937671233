import { NavItem } from 'reactstrap';
import styled, { StyledComponent } from 'styled-components';

import { focus } from '../../theme/ui/ui';
import { fsNormal } from '../../theme/ui/typography';
import anchor from '../../theme/components/anchor';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const FilterNavItem = styled(NavItem)`
	margin-top: 1em;

	&:first-of-type {
		margin-top: 0;
	}

	&:last-of-type {
		margin-bottom: 2em;
	}
`;

export const FilterNavButton: StyledComponent<'button', {}> = styled.button`
	${anchor}
	${fsNormal}
	border-bottom: 1px solid transparent;

	&:focus {
		${focus}
	}

	&:hover {
		border-bottom: 1px solid ${colors.primaryHighlighted};
		text-decoration: none;
	}

	&.active {
		border-bottom: 1px solid ${colors.primaryHighlighted};
		color: ${colors.primaryHighlighted};

		&:hover {
			border-bottom: 1px solid transparent;
		}
	}
`;

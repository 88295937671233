import { CardBody } from 'reactstrap';
import styled from 'styled-components';

import { Avatar } from '../../components/avatar.component';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const UserAvatar = styled(Avatar)`
	min-height: 5em;
	min-width: 5em;
	margin-bottom: 1.5em;
`;

export const CardBodyFlex = styled(CardBody)`
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
`;

export const PanelHeader = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 1.5em;
	padding-top: 1.5em;
	border-top: 1px solid ${colors.grey};

	@media only screen and (min-width: 768px) {
		flex-direction: row;
		align-items: flex-end;
		border-top: none;
		margin-top: 0;
		padding-top: 0;
	}
`;

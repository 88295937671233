import { Link as LinkBase } from '@reach/router';
import { CardHeader } from 'reactstrap';
import styled from 'styled-components';

import { fsSmol } from '../../theme/ui/typography';

interface WorkflowCardHeaderProps {
	image: string
}

export const WorkflowCardHeader = styled(CardHeader)<WorkflowCardHeaderProps>`
	background-image: url(${ props => props.image });
	background-position: center center;
	background-size: cover;
	height: 160px;
`;

export const CardFlag = styled.div`
	position: absolute;
	right: 0;

	.badge {
		padding: 5px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
`;

export const WorkflowTitle = styled.h6`
	font-size: 15px;
	margin: 0;
	overflow: hidden;
	padding-right: 1.25rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: auto;
`;

export const Link = styled(LinkBase)`
	color: black;
	text-decoration: none;

	&:hover {
		color: black;
		text-decoration: none;

		.card {
			box-shadow: 0 0.5rem 1rem rgba(0,0,0,.125);
			transform: scale(1.0125);
			transition: transform 1ms ease-in-out;
		}
	}
`;

export const CardBodyItem = styled.div`
	${ fsSmol };
	margin-bottom: .5em;
	margin-top: .5em;
`;

import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import Moment from 'react-moment';

import { buildSavedQueryUrl } from '../../common/links';
import { fsMedium } from '../../theme/ui/typography';
import { Query } from '../../queries/models/QueryModel';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

interface Props {
	query: Query;
}

const Heading = styled.h1`
	color: ${colors.darkestGrey};
	font-size: 16px;
	margin-bottom: 0;
`;

const StyledLink = styled(Link)`
	${fsMedium};
	color: ${colors.primary};
	margin-top: 4px;

	&:hover {
		color: ${colors.primaryHighlighted};
	}
`;

const SavedQueryItem = ({ query }: Props) => {
	const render = () => (
		<article>
			<Heading>"{query.value}"</Heading>
			<StyledLink to={buildSavedQueryUrl(query)}>
				Created in {query.type}, on{' '}
				<Moment format="MM/DD/YYYY" date={query.createdAt} />
			</StyledLink>
		</article>
	);

	return render();
};

export default SavedQueryItem;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { AssetCollection } from '../dam-asset-collections/models/AssetCollectionModel';
import { MetadataTemplate } from '../metadata-templates/models/EntityMetadataTemplateModel';
import NotificationModel from '../notifications/NotificationModel';
import notificationStore from '../notifications/NotificationStore';
import { useModalCreator } from '../stores/ModalStack';
import { WorkflowCollection } from '../workflow-collections/models/WorkflowCollectionModel';
import { RemoveButton } from './buttons.styled-components';
import ConfirmationDialog from './modals/confirmation-dialog.component';

interface DeleteEntityButtonProps {
	entity: WorkflowCollection | AssetCollection | MetadataTemplate;
	deleteOne: (
		entity: WorkflowCollection | AssetCollection | MetadataTemplate
	) => void;
	entityName: string;
}

const DeleteEntityButton = (props: DeleteEntityButtonProps) => {
	const modalStack = useModalCreator();

	const { entity, deleteOne, entityName } = props;

	const deleteEntity = useCallback(
		(entity) => {
			deleteOne(entity);

			notificationStore.push(
				NotificationModel.create({
					text: `${entityName} was deleted!`,
				})
			);
		},
		[deleteOne, entityName]
	);

	const showModal = useCallback(
		() =>
			modalStack.addModal(
				<ConfirmationDialog
					header={`Delete "${entity.title}"`}
					onConfirm={() => deleteEntity(entity)}
				>
					<p>
						Are you sure you want to delete this {entityName.toLowerCase()}?
						This action cannot be undone.
					</p>
				</ConfirmationDialog>
			),
		[entity, modalStack, deleteEntity, entityName]
	);

	const render = () => (
		<RemoveButton id={`entityDelete${entity._id}`} onClick={showModal}>
			<UncontrolledTooltip target={`entityDelete${entity._id}`}>
				Delete {entityName}
			</UncontrolledTooltip>
			<FontAwesomeIcon icon={faTimes} />
		</RemoveButton>
	);

	return useObserver(render);
};

export default DeleteEntityButton;

import { useObserver } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { InfiniteHitsProvided } from 'react-instantsearch-core';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { isIdentifiableLoaded } from '../../common/mobx.utils';
import { CustomClearRefinements } from '../../components/algolia/clear-refinements';
import CustomRefinementList from '../../components/algolia/refinement-list.component';
import SubmitButton from '../../components/forms/submit-button.component';
import Loading from '../../components/loading.component';
import RenderWhen from '../../components/render-when.component';
import IndexViewToggler from '../../index-view/components/index-view-toggler.component';
import { ViewType } from '../../index-view/models/IndexViewModel';
import { indexViewStore } from '../../index-view/models/IndexViewStore';
import { useAssetStore } from '../../stores/index';
import { damAssetRefinementSettings } from '../dam-asset-refinements';
import { AssetVersion } from '../models/AssetVersionModel';
import AssetCardGrid from './asset-card-grid.component';
import AssetListTable from './asset-list-table.component';
import AssetSelectionToolbar, {
	AssetSelectionTool,
	AssetSelectionToolbarClickEvent,
	defaultAssetSelectionTools,
} from './asset-selection-toolbar.component';

interface AlgoliaDAMAsset {
	objectID: string;
	fileName: string;
	type: string;
	metadata?: {
		tags: string[];
		values: Record<string, string>;
	};
	createdAt: Date;
}

const Tools = styled(Col)`
	display: flex;
	justify-content: flex-end;
`;

const NoAssetResults = () => (
	<Col lg={12}>
		<p>No results</p>
	</Col>
);

const AssetInfiniteHits = (props: InfiniteHitsProvided<AlgoliaDAMAsset>) => {
	const assetStore = useAssetStore();
	const assetsViewOptions = indexViewStore.assets;

	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const [selectedAssets, setSelectedAssets] = useState<Array<AssetVersion>>([]);

	const toolbarCallBack = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (event.tool === AssetSelectionTool.SELECTION) {
				const isSelecting: boolean = event.value;
				setSelectingAssets(isSelecting);
				if (!isSelecting) {
					// empty selected assets if selecting is turned off
					setSelectedAssets([]);
				}
			}
		},
		[setSelectingAssets]
	);

	const assetSelectionCallBack = useCallback(
		(asset: AssetVersion) => {
			const index = selectedAssets.indexOf(asset);
			if (index > -1) {
				// remove from selection list
				setSelectedAssets((selectedAssets) =>
					selectedAssets.filter((a) => a._id !== asset._id)
				);
			} else {
				// add to selection list
				setSelectedAssets((selectedAssets) => selectedAssets.concat(asset));
			}
		},
		[selectedAssets]
	);

	// render methods
	const renderAssetCardGrid = (assets: readonly AssetVersion[]) => (
		<AssetCardGrid
			assets={assets}
			areLinks={!isSelectingAssets}
			isSelectionAvailable={true}
			onAssetSelected={assetSelectionCallBack}
			selectedAssets={selectedAssets}
		/>
	);

	const renderAssetList = (assets: readonly AssetVersion[]) => (
		<AssetListTable
			assets={assets}
			isSelectingAssets={isSelectingAssets}
			onAssetSelected={assetSelectionCallBack}
			selectedAssets={selectedAssets}
		/>
	);

	const renderAssets = (
		assets: readonly AssetVersion[],
		viewType: ViewType = ViewType.grid
	) => {
		if (!assets?.length) {
			return props.hits?.length ? <Loading /> : <NoAssetResults />;
		}

		switch (viewType) {
			case ViewType.grid:
				return renderAssetCardGrid(assets);
			case ViewType.list:
				return renderAssetList(assets);
		}
	};

	const render = () => {
		const assetList = props.hits
			.map(({ objectID }) => assetStore.findOne(toAssetId(objectID)))
			.filter(isIdentifiableLoaded);

		return (
			<Row className="align-items-baseline">
				<Col sm={9} xs={8}>
					<Row>
						{damAssetRefinementSettings.map((refinementSettings) => (
							<CustomRefinementList
								key={refinementSettings.attribute}
								{...refinementSettings}
							/>
						))}
						<CustomClearRefinements />
					</Row>
				</Col>
				<Tools sm={3} xs={4}>
					<AssetSelectionToolbar
						tools={defaultAssetSelectionTools}
						className="asset-index-toolbar mr-4"
						onClick={toolbarCallBack}
						selectedAssets={selectedAssets}
					/>
					<IndexViewToggler view={assetsViewOptions} />
				</Tools>

				{renderAssets(assetList, indexViewStore.assets.viewType)}

				<RenderWhen when={props.hasMore}>
					<Col xs={12} className="text-center">
						<SubmitButton label="Show more" onClick={props.refineNext} />
					</Col>
				</RenderWhen>
			</Row>
		);
	};

	return useObserver(render);
};

const assetIdPrefix = 'DAMAsset_';
const toAssetId = (s: string) => s.replace(assetIdPrefix, '');

const CustomAssetHits = connectInfiniteHits(AssetInfiniteHits);
export default CustomAssetHits;

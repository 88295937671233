import { Card, CardFooter, CardHeader, Table } from 'reactstrap';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import Moment from 'react-moment';
import React from 'react';

import { Cell, CellLink } from '../../components/tables.styled-components';
import { useDashboardStore } from '../../stores';
import { HasWorkflow } from '../../workflows/models';
import SmallCTALink from '../../components/small-cta-link.component';
import themeStore from '../../theme/models/ThemeStore';
import WorkflowStatusPill from '../../components/workflow-status-pill.component';

import WorkflowCompletionPieChart from './workflow-completion-pie-chart.component';
import WorkflowHealthFlag from './workflow-health-flag.component';
import { allWorkflowsRoute } from '../../workflows/workflow-routes';
import { NoResultsRow } from '../../components/no-results-row.component';

const workflowPluralTerm = `${themeStore._.workflow.toLowerCase()}s`;

const WorkflowDate = ({ date }: { date: Nullable<Date> }) =>
	date ? <Moment format="MMM DD" date={date} /> : <>TBD</>;

const RecentWorkflowRow = ({ workflow }: HasWorkflow) => (
	<tr>
		<Cell>
			<CellLink to={`./workflow/workflows/${workflow._id}/stages`}>
				{workflow.title}
			</CellLink>
		</Cell>

		<Cell>
			<WorkflowDate date={workflow.startDate} />
		</Cell>

		<Cell>
			<WorkflowDate date={workflow.dueDate} />
		</Cell>

		<Cell>
			<WorkflowStatusPill status={workflow.status} />
		</Cell>

		<Cell>
			<div className="d-flex">
				<WorkflowCompletionPieChart workflow={workflow} />
			</div>
		</Cell>

		<Cell>
			<WorkflowHealthFlag workflow={workflow} whenInactive={<p>—</p>} />
		</Cell>
	</tr>
);

const RecentWorkflowTable = ({ max = 5 }: { max?: number }) => {
	const dashboardStore = useDashboardStore();

	const renderComponent = () => {
		const workflows = dashboardStore.recentWorkflows;

		return (
			<Card className="recent-workflows-table h-100">
				<CardHeader>
					<h2 className="mb-0">
						<FontAwesomeIcon icon={faChartLine} className="mr-2" />
						Recent {workflowPluralTerm}
					</h2>
				</CardHeader>

				<Table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Start</th>
							<th>Due</th>
							<th>Status</th>
							<th>Completion</th>
							<th>Health</th>
						</tr>
					</thead>

					<tbody>
						{workflows.length ? (
							workflows
								.slice(0, max)
								.map((workflow) => (
									<RecentWorkflowRow key={workflow._id} workflow={workflow} />
								))
						) : (
							<NoResultsRow term="workflow" />
						)}
					</tbody>
				</Table>

				<CardFooter className="bg-white">
					<div className="float-right">
						<SmallCTALink
							link={allWorkflowsRoute}
							label={`View all ${workflowPluralTerm}`}
						/>
					</div>
				</CardFooter>
			</Card>
		);
	};

	return useObserver(renderComponent);
};

export default RecentWorkflowTable;

import { Card, CardBody, Col } from 'reactstrap';
import { useObserver } from 'mobx-react';
import Moment from 'react-moment';
import React from 'react';
import defaultProduct from '../../assets/images/default-product.jpg';
import themeStore from '../../theme/models/ThemeStore';
import WorkflowHealthFlag from '../../dashboard/components/workflow-health-flag.component';
import WorkflowStatusPill from '../../components/workflow-status-pill.component';

import { listActiveStageNames, Workflow } from '../models';

import {
	CardBodyItem,
	CardFlag,
	Link,
	WorkflowCardHeader,
	WorkflowTitle,
} from './workflow-card.styled-components';
import { isIdentifiableLoaded } from '../../common/mobx.utils';

interface WorkflowCardProps {
	workflow: Workflow;
}

const WorkflowCard = ({ workflow }: WorkflowCardProps) => {
	return useObserver(() => (
		<Col lg={4} sm={6} className="my-3">
			<Link to={`/admin/workflow/workflows/${workflow._id}/stages`}>
				<Card className="h-100">
					<WorkflowCardHeader image={defaultProduct}>
						<CardFlag>
							<WorkflowHealthFlag workflow={workflow} />
						</CardFlag>
					</WorkflowCardHeader>

					<CardBody className="bg-white">
						<div>
							<WorkflowTitle className="mb-3">{workflow.title}</WorkflowTitle>

							{isIdentifiableLoaded(workflow.workflowCollection) ? (
								<CardBodyItem>
									<em>In {themeStore._.workflowCollection.toLowerCase()}: </em>
									{workflow.workflowCollection.title}
								</CardBodyItem>
							) : null}

							{workflow.allActiveStages.length ? (
								<CardBodyItem>
									<em>Active {themeStore._.stage.toLowerCase()}(s): </em>
									{listActiveStageNames(workflow)}
								</CardBodyItem>
							) : null}

							{workflow.dueDate ? (
								<CardBodyItem>
									<em>Due date: </em>
									<Moment format="MM/DD" date={workflow.dueDate} />
								</CardBodyItem>
							) : null}

							<CardBodyItem>
								Status:
								<WorkflowStatusPill className="ml-2" status={workflow.status} />
							</CardBodyItem>
						</div>
					</CardBody>
				</Card>
			</Link>
		</Col>
	));
};

export default WorkflowCard;

import { useObserver } from 'mobx-react';
import React from 'react';
import { Card } from 'reactstrap';
import DeleteEntityButton from '../../components/delete-entity-button.component';
import {
	ListColumn,
	makeColumns,
} from '../../components/list-column.component';
import ListTable from '../../components/list-table.component';
import OwnerList from '../../components/owner-list.component';

import { CellLink } from '../../components/tables.styled-components';
import { usePermissions, useWorkflowCollectionStore } from '../../stores';
import themeStore from '../../theme/models/ThemeStore';

import { WorkflowCollection } from '../models/WorkflowCollectionModel';

// constants
const projectTerm = themeStore._.workflowCollection;

// interface
interface Props {
	collections: readonly WorkflowCollection[];
}

// component
const WorkflowCollectionListTable = (props: Props) => {
	const { collections } = props;

	const permissions = usePermissions();
	const collectionStore = useWorkflowCollectionStore();

	const collectionListColumns: ListColumn<WorkflowCollection>[] = makeColumns([
		{
			label: 'Name',
			prop: (c: WorkflowCollection) => (
				<CellLink to={`./${c._id}`}>{c.title}</CellLink>
			),
		},
		{
			label: `${themeStore._.owner}(s)`,
			prop: (c: WorkflowCollection) => <OwnerList entity={c} />,
		},
		{
			label: `${themeStore._.workflow} count`,
			prop: (c: WorkflowCollection) => <p>{c.workflows.length}</p>,
		},
		{
			label: '',
			prop: (c: WorkflowCollection) =>
				c.workflows.length === 0 && permissions.canEditWorkflowCollection(c) ? (
					<DeleteEntityButton
						entity={c}
						deleteOne={collectionStore.deleteOne}
						entityName={projectTerm}
					/>
				) : null,
		},
	]);

	const render = () => (
		<Card className="mt-2">
			<ListTable
				columns={collectionListColumns}
				rows={collections ? collections : []}
				noResultsLabel={`No ${projectTerm.toLowerCase()}s yet`}
			/>
		</Card>
	);

	return useObserver(render);
};

export default WorkflowCollectionListTable;

import { useObserver } from 'mobx-react';
import React from 'react';
import Moment from 'react-moment';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../components/entity-details.styled-components';
import EntityMetadataFields from '../../metadata/components/entity-metadata-fields.component';
import { BaseWorkflowPhase } from '../../models/BaseWorkflowPhaseModel';
import themeStore from '../../theme/models/ThemeStore';
import { WorkflowTemplate } from '../models';
import { PhaseIndicator } from './template-phase-key.styled-components';

export const TemplateBaseFinalDetails = ({
	template,
}: {
	template: WorkflowTemplate;
}) => {
	return useObserver(() => (
		<EntityPropList>
			<EntityPropListItem>
				<EntityPropLabel>Created on</EntityPropLabel>
				<p>
					<Moment format="MMM DD, YYYY" date={template.createdAt} />
				</p>
			</EntityPropListItem>
			<EntityPropListItem>
				<EntityPropLabel>Created by</EntityPropLabel>
				<p>{template.createdBy.name}</p>
			</EntityPropListItem>
			<EntityPropListItem>
				<EntityPropLabel>Template #</EntityPropLabel>
				<p>{template._id}</p>
			</EntityPropListItem>

			<EntityMetadataFields metadata={template.metadata} />

			{template.phases.length ? (
				<EntityPropListItem>
					<EntityPropLabel>{themeStore._.phase}s</EntityPropLabel>
					{template.phases.map((phase: BaseWorkflowPhase) => (
						<div key={phase._id} className="d-flex">
							<PhaseIndicator background={phase.color} />
							<p>{phase.title}</p>
						</div>
					))}
				</EntityPropListItem>
			) : null}
		</EntityPropList>
	));
};

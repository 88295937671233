import { Link } from '@reach/router';
import styled from 'styled-components';

import { focus } from '../../theme/ui/ui';
import { fsNormal } from '../../theme/ui/typography';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// styled components
export const Container = styled.div`
	padding-bottom: 0.5em;
`;

export const StyledLink = styled(Link)`
	${fsNormal};
	color: ${colors.primary};
	text-decoration: none;

	&:hover {
		color: ${colors.primaryHighlighted};
		text-decoration: none;
	}

	&:focus {
		${focus};
	}

	.button-label {
		vertical-align: middle;
	}

	.button-icon {
		vertical-align: middle;
	}
`;

import Downshift, { ControllerStateAndHelpers } from 'downshift';
import { useObserver } from 'mobx-react';
import React from 'react';
import { StyledLabel } from '../forms';
import { DownshiftContext } from './downshift-options.context';
import { DownshiftOptionsList } from './downshift-options-list.component';
import { getName } from '../../common';
import { DownshiftBaseProps, SelectionContext } from './downshift.interfaces';

const defaultRenderInput = (downshift: ControllerStateAndHelpers<any>) => <input { ...downshift.getInputProps() } />;

const DownshiftSelect = <Item extends Displayable, Option extends Displayable = Item>(props: DownshiftBaseProps<Item, Option>) => {
	const {
		renderOption = (option: Option) => getName(option),
		selectionState,
		selectionActions,

		label = 'Start typing to search...',
		renderLabel = () => <StyledLabel>{ label }</StyledLabel>,
		renderInput = defaultRenderInput,

		...downshiftProps
	} = props;


	return useObserver(() => (
		<Downshift
			{ ...downshiftProps }
			itemToString={ getName }
			stateReducer={ props.stateReducer }
		>
			{
				(downshift) => {

					const selectionContext: SelectionContext<Item, Option> = {
						downshift: downshift,

						selection: selectionState.selection,
						options: selectionState.options,
						searchPredicate: selectionState.searchPredicate,

						select: selectionActions.select,
						unselect: selectionActions.unselect,
					};

					return (
						<div>
							<DownshiftContext.Provider value={ selectionContext }>

								{ renderLabel(downshift) }

								{ renderInput(downshift) }

								<DownshiftOptionsList renderOption={ renderOption } />

							</DownshiftContext.Provider>
						</div>
					);
				} }
		</Downshift>
	));
};

export default DownshiftSelect;

import {
	IArrayType,
	IMapType,
	IReferenceType,
	ISnapshotProcessor,
	IType,
	types,
} from 'mobx-state-tree';
import { IKeyValueMap, ObservableMap, values } from 'mobx';
import { convertToMapSnapshot, isNotEmpty, isValidEmail } from '../common';

export const emailString = types.refinement(types.string, (v) => {
	return isValidEmail(v);
});

export const urlString = types.refinement(types.string, (v) => {
	// TODO: Support missing values outside of this refinement
	if (!v || v.startsWith('/') || v.startsWith('data:')) {
		return true;
	}

	try {
		return !!new URL(v);
	} catch (err) {
		return false;
	}
});

const MAX_UNIX_DATE = Math.pow(2, 32);
export const unixDate: IType<
	number | Date,
	number,
	Date
> = types.snapshotProcessor(types.Date, {
	preProcessor(ut: number) {
		if (ut < MAX_UNIX_DATE) {
			ut *= 1000;
		}
		return new Date(ut);
	},
	postProcessor(d: Date | number): number {
		const timestamp = typeof d === 'number' ? d : d.valueOf();
		return timestamp < MAX_UNIX_DATE ? timestamp : Math.round(timestamp / 1000);
	},
});

function isIsoString(x: any): x is string {
	/**
	 * RegExp to test a string for a full ISO 8601 Date
	 * Does not do any sort of date validation, only checks if the string is according to the ISO 8601 spec.
	 *  YYYY-MM-DDThh:mm:ss
	 *  YYYY-MM-DDThh:mm:ssTZD
	 *  YYYY-MM-DDThh:mm:ss.sTZD
	 * @see: https://www.w3.org/TR/NOTE-datetime
	 * @type {RegExp}
	 */
	const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

	return ISO_8601_FULL.test(x);
}

export const isoDate: IType<
	string | Date,
	string,
	Date
> = types.snapshotProcessor(types.Date, {
	preProcessor(iso: Date | string): Date {
		if (iso instanceof Date) {
			return iso;
		}
		if (!isIsoString(iso)) {
			throw Error(`Not an ISO-8601 date: "${iso}"`);
		}
		return new Date(iso);
	},
	postProcessor(d: Date | number): string {
		const date = typeof d === 'number' ? new Date(d) : d;
		return date.toISOString();
	},
});

export const tolerantDate: IType<
	number | string | Date,
	string,
	Date
> = types.snapshotProcessor(types.Date, {
	preProcessor(date: Date | string | number): Date {
		if (typeof date === 'string') {
			if (!isIsoString(date)) {
				throw Error(`Not an ISO-8601 date: "${date}"`);
			}
			return new Date(date);
		} else if (typeof date === 'number') {
			return new Date(date < MAX_UNIX_DATE ? date * 1000 : date);
		} else {
			return date;
		}
	},
	postProcessor(d: Date | number): string {
		const date = typeof d === 'number' ? new Date(d) : d;
		return date.toISOString();
	},
});

export const optionalString = types.optional(types.string, '');

export const nonEmptyString = types.refinement(
	'Non-empty string',
	types.string,
	isNotEmpty
);

export const nonEmptyStringWithDefault = (defaultValue = '') =>
	types.optional(types.string, defaultValue, [undefined, null, '']);

export type InstanceReference<InstanceType extends object> = IReferenceType<
	IType<unknown, unknown, InstanceType>
>;
export type InstanceArray<InstanceType extends object> = IArrayType<
	IType<unknown, unknown, InstanceType>
>;

export interface IMapSerializedAsArray<
	C extends Identifiable,
	T extends Identifiable
> extends ISnapshotProcessor<IMapType<IType<C, C, T>>, Maybe<C[]>, C[]> {}

export function mapSerializedAsArray<
	SnapshotType extends Identifiable,
	InstanceType extends Identifiable
>(
	mapValueType: IType<SnapshotType, SnapshotType, InstanceType>
): IMapSerializedAsArray<SnapshotType, InstanceType> {
	return types.snapshotProcessor(types.map(mapValueType), {
		preProcessor(
			snapshot: SnapshotType[] | undefined
		): IKeyValueMap<SnapshotType> {
			return Array.isArray(snapshot) ? convertToMapSnapshot(snapshot) : {};
		},
		postProcessor(map: IKeyValueMap<SnapshotType> | object): SnapshotType[] {
			if (map instanceof ObservableMap) {
				return [...values(map)];
			}

			return Object.values(map).reduce((acc, stage) => [...acc, stage], []);
		},
	});
}

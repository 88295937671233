import { useObserver } from 'mobx-react';
import React from 'react';
import OwnerAvatarList from '../../../components/owner-avatar-list.component';
import { Subheading } from '../../../components/ui';
import { listOwnerNames } from '../../../models/BaseWorkflowOwnableModel';
import { usePermissions } from '../../../stores';
import { WorkflowActionableStageModel, WorkflowStage } from '../../models';
import {
	AssignStageOwnerButton,
	RoadblockStageButton,
	UploadStageAssetButton,
} from '../stage-actions';
import {
	DisabledOwnerButton,
	FlexCardHeader,
} from './workflow-stage-card.styled-components';

interface DetailCardHeaderProps {
	stage: WorkflowStage;
}

const DetailCardHeader = (props: DetailCardHeaderProps) => {
	const { stage } = props;
	const permissions = usePermissions();

	const assignmentLabel = useObserver(() => (
		<div className="align-self-center text-left mx-3 mt-1">
			<Subheading className="mb-0">Assigned to</Subheading>
			<p className="mb-0">{listOwnerNames(stage)}</p>
		</div>
	));

	const render = () => {
		if (!WorkflowActionableStageModel.is(stage)) {
			return null;
		}

		if (stage.owners.length > 0) {
			if (permissions.canSetStageOwners(stage)) {
				return (
					<FlexCardHeader>
						<div>
							<AssignStageOwnerButton>{assignmentLabel}</AssignStageOwnerButton>
						</div>
						<div>
							<UploadStageAssetButton stage={stage} />
							<RoadblockStageButton stage={stage} />
						</div>
					</FlexCardHeader>
				);
			} else {
				return (
					<FlexCardHeader>
						<DisabledOwnerButton>
							<OwnerAvatarList
								owners={stage.owners}
								className="align-self-center"
							/>
							{assignmentLabel}
						</DisabledOwnerButton>
					</FlexCardHeader>
				);
			}
		} else {
			return (
				<FlexCardHeader>
					<AssignStageOwnerButton />
				</FlexCardHeader>
			);
		}
	};

	return useObserver(render);
};

export default DetailCardHeader;

import React, { useCallback } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { buildClassList } from '../../../common';
import { StageStatus } from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';

import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from '../action-button.styled-components';

import { StageActionButtonProps } from '.';
import RejectStageDialog from './reject-stage-dialog.component';

export const RejectStageButton = (props: StageActionButtonProps) => {
	const { className, stage } = props;
	const modalStack = useModalCreator();
	const backwardTransitions = stage.backwardTransitions;

	const openRejectionModal = useCallback(() => {
		modalStack.addModal(<RejectStageDialog stage={stage} />);
	}, [stage, modalStack]);

	if (backwardTransitions.length) {
		const buttonId = `rejectStage${stage._id}`;
		return (
			<ActionButtonContainer
				className={buildClassList('stage-action reject-stage', className)}
			>
				<StyledTooltip target={buttonId}>Reject "{stage.title}"</StyledTooltip>
				<ActionButton
					id={buttonId}
					color=""
					size="sm"
					className="action-button reject-stage-button danger"
					onClick={openRejectionModal}
					disabled={stage.status === StageStatus.roadblocked}
				>
					<IconWithRightMargin icon={faTimes} />
					Reject
				</ActionButton>
			</ActionButtonContainer>
		);
	} else {
		return null;
	}
};

import React, { ReactChild } from 'react';
import styled from 'styled-components';

import { AssetVersion } from '../../models/AssetVersionModel';
import {
	extMatcher,
	typeMatcher,
	typePrefixMatcher,
} from './asset-type-matchers';

import AssetViewerWarning from './asset-viewer-warning.component';
import ImageViewerWithZoom from './image-viewer-with-zoom.component';
import { DamAsset } from '../../models/DamAssetModel';
import { DetailsOnlyViewer, FallbackViewer } from './fallback-viewer.component';
import { stubTrue } from '../../../common/fn.utils';

// styled components
const PreviewContainer = styled.div`
	margin-top: 2em;
`;

export type Previewable = DamAsset | AssetVersion;

interface AssetPreviewProps {
	asset: Previewable;
}

interface AssetRenderer {
	render: (props: AssetPreviewProps) => ReactChild;
	canRender: (a: Previewable) => boolean;
}

const invalidFileRenderer = {
	render: () => (
		<AssetViewerWarning
			text="Unable to preview asset: File type is likely invalid."
			color="danger"
		/>
	),
	canRender: () => true,
};

const renderers: ReadonlyArray<AssetRenderer> = [
	{ render: ImageViewerWithZoom, canRender: typePrefixMatcher('image') },
	{ render: DetailsOnlyViewer, canRender: extMatcher('ai') },
	{
		render: DetailsOnlyViewer,
		canRender: typeMatcher('application/postscript'),
	},
	{ render: ImageViewerWithZoom, canRender: typeMatcher('application/pdf') },
	{ render: FallbackViewer, canRender: typeMatcher('application/zip') },
	{ render: FallbackViewer, canRender: stubTrue },
];

const findRendererFor = (a: Previewable) =>
	renderers.find((r) => r.canRender(a)) ?? invalidFileRenderer;

// component
const AssetPreview = (props: AssetPreviewProps) => {
	const { asset } = props;

	const renderedAsset = findRendererFor(asset).render(props);
	return (
		<PreviewContainer className="asset-preview">
			{renderedAsset}
		</PreviewContainer>
	);
};

export default AssetPreview;

import {
	destroy,
	flow,
	IAnyModelType,
	Instance,
	isAlive,
	SnapshotIn,
	types,
} from 'mobx-state-tree';
import {
	convertToMapSnapshot,
	getId,
	IdentifiableNode,
	makeEntityFetcherFor,
} from '../common/mobx.utils';
import { getClient } from '../core/index';
import { omit } from 'lodash';
import { values } from 'mobx';

const makePagination = <
	M extends IAnyModelType,
	T extends Identifiable & Instance<M>,
	S extends Identifiable & SnapshotIn<M>
>(
	model: M
) => {
	return types
		.model(`${model.name}Pagination`, {
			currentPage: types.optional(types.number, 1),
			totalPages: types.optional(types.number, 1),
			/**
			 We have to use a map of arrays instead of array of arrays
			 because MobX does not support sparse arrays.
			 */
			pages: types.map(
				types.array(types.safeReference(model, { acceptsUndefined: false }))
			),
		})
		.actions((self) => ({
			setCurrentPage(page: number): void {
				self.currentPage = page;
			},
		}));
};

export const makeEntityStore = <
	M extends IAnyModelType,
	T extends Identifiable & Instance<M>,
	S extends Identifiable & SnapshotIn<M> = Identifiable & SnapshotIn<M>
>(
	model: M,
	baseRoute: string,
	loadingValue: S,
	useAll = false
) => {
	const paginationModel = makePagination(model);

	const store = types
		.model(`${model.name}Store`, {
			entities: types.map(model),
			$loadingValue: types.optional(model, loadingValue),
			pages: types.optional(paginationModel, {}),
		})
		.actions((self) => ({
			addOne(item: S | T): void {
				if (item) {
					self.entities.set((item as Identifiable)._id, item);
				}
			},

			addMany(items: ReadonlyArray<S | T>): void {
				self.entities.merge(convertToMapSnapshot(items));
			},

			addPage(result: PaginateResult<S | T>): void {
				this.addMany(result.docs);
				self.pages.totalPages = result.totalPages;
				self.pages.pages.set(`${result.page}`, result.docs.map(getId));
			},
		}))
		.actions((self) => ({
			createOne: flow(function* createEntity(entity?: ForCreation<S>) {
				const client = getClient(self);
				const responseBody = yield client.post(
					`/${baseRoute}`,
					omit(entity, '_id')
				);

				const createdEntity = model.create(responseBody);

				self.addOne(createdEntity);
				return createdEntity;
			}) as (entity?: ForCreation<S>) => Promise<T>,

			updateOne: flow(function* updateEntity(entity: S) {
				const client = getClient(self);
				const updatedEntity = yield client.patch(
					`/${baseRoute}/${entity._id}`,
					entity
				);
				self.addOne(updatedEntity);
				return self.entities.get(entity._id);
			}) as (entity: S | T) => Promise<T>,

			deleteOne: flow(function* deleteEntity(entity: IdentifiableNode) {
				const client = getClient(self);
				yield client.delete(`/${baseRoute}/${entity._id}`);
				if (isAlive(entity)) {
					destroy(entity);
				}
			}) as (entity: IdentifiableNode) => Promise<void>,
		}))
		.views((self) => {
			const entityFetcher = makeEntityFetcherFor(self, baseRoute, useAll);

			return {
				findOne(id?: string): Maybe<T> {
					if (!id) {
						return;
					}
					if (id === loadingValue) {
						return self.$loadingValue;
					}

					const value = self.entities.get(id);

					if (!value) {
						entityFetcher(id);
					}

					return value;
				},

				getOne(id?: string): T {
					return this.findOne(id) || self.$loadingValue;
				},

				getPage(page: number): Maybe<readonly T[]> {
					if (!self.pages.pages.has(`${page}`)) {
						entityFetcher({ page });
					}

					return self.pages.pages.get(`${page}`);
				},

				get currentPage(): readonly T[] {
					return this.getPage(self.pages.currentPage) || [];
				},

				get all(): readonly T[] {
					entityFetcher();
					return values(self.entities);
				},
			};
		});

	return store;
};

import ThemeModel from '../models/ThemeModel';
import { toRGBA } from '../../common/color.utils';

// main colors
const primary = '#F7B500';
const primaryHighlighted = '#FFCF49';

const secondary = '#071B4D';
const secondaryHighlighted = '#103182';

// status colors
const pipeline = primary;

// a11y
const focus = toRGBA(primary, 0.5);

export const executeTheme = ThemeModel.create({
	_id: 'execute',
	title: 'Altavia Execute',
	images: {
		desktopLogo: '/themes/execute/execute-logo.svg',
		mobileLogo: '/themes/execute/execute-logo.svg',
		loginBackground: '/themes/execute/background.jpg',
	},
	colors: {
		primary,
		primaryHighlighted,
		secondary,
		secondaryHighlighted,
		pipeline,
		focus,
	},
});

import { faStepForward } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback } from 'react';

import { StageStatus } from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';
import { usePermissions } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';

import { WorkflowActionableStage } from '../../models';

import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from '../action-button.styled-components';

import ActivateFirstStageDialog from './activate-first-stage-dialog.component';

const workflowTerm = themeStore._.workflow.toLowerCase();

interface Props {
	stage: WorkflowActionableStage;
}

const ActivateFirstStageButton = (props: Props) => {
	const { stage } = props;
	const modalStack = useModalCreator();
	const permissions = usePermissions();

	const openActivateFirstStageModal = useCallback(() => {
		modalStack.addModal(<ActivateFirstStageDialog stage={stage} />);
	}, [stage, modalStack]);

	if (
		!permissions.canForceActivateStage(stage) ||
		stage.status === StageStatus.active ||
		stage.status === StageStatus.roadblocked
	) {
		return null;
	}

	const buttonId = `activateFirstStage${stage._id}`;

	return (
		<ActionButtonContainer className="stage-action activate-first-stage">
			<StyledTooltip target={buttonId}>
				Activate "{stage.title}" to begin {workflowTerm}
			</StyledTooltip>
			<ActionButton
				id={buttonId}
				color="secondary"
				size="sm"
				className="action-button activate-first-stage-button solid"
				onClick={openActivateFirstStageModal}
			>
				<IconWithRightMargin icon={faStepForward} />
				Begin {workflowTerm}
			</ActionButton>
		</ActionButtonContainer>
	);
};

export default ActivateFirstStageButton;

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PanelHeader } from '../admin-dashboard/components/user-details.styled-components';
import { ScrollCard, Subtitle } from '../components/ui';
import { CreateTemplateButton } from '../workflow-templates/components/template-index.styled-components';
import MetadataTemplateListTable from './components/entity-metadata-template-list-table.component';

const MetadataTemplatesIndexView = () => (
	<article>
		<PanelHeader>
			<Subtitle className="mb-md-0">Metadata Templates</Subtitle>
			<CreateTemplateButton to="./new">
				<FontAwesomeIcon icon={faPlus} className="mr-2" />
				New Metadata Template
			</CreateTemplateButton>
		</PanelHeader>
		<ScrollCard className="mt-4">
			<MetadataTemplateListTable />
		</ScrollCard>
	</article>
);

export default MetadataTemplatesIndexView;

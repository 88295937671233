import React, { MouseEventHandler } from 'react';
import { Nav } from 'reactstrap';

import { Filter } from '../../common/filters';

import SideBarFilterNavItem from './side-bar-filters-nav-item.component';

interface FilterOption {
	label: string;
	value: Maybe<Filter | string>;
}

interface SideBarFiltersNavProps<F> {
	options: FilterOption[];
	setFilter: MouseEventHandler<HTMLButtonElement>;
	filter?: F;
}

const SideBarFiltersNav = <F extends unknown>(
	props: SideBarFiltersNavProps<F>
) => (
	<Nav vertical className="mt-3">
		{props.options.map((option) => (
			<SideBarFilterNavItem
				key={option.label}
				value={option.value}
				label={option.label}
				onClick={props.setFilter}
				active={option.value === props.filter}
			/>
		))}
	</Nav>
);

export default SideBarFiltersNav;

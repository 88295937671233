import { getParentOfType } from 'mobx-state-tree';

import { AssetVersion } from '../dam-assets/models/AssetVersionModel';
import { WorkflowCollection } from '../workflow-collections/models/WorkflowCollectionModel';
import { Workflow, WorkflowModel, WorkflowStage } from '../workflows/models';
import { Query } from '../queries/models/QueryModel';
import { QueryTypes } from '../queries/models/QueryStore';
import { User } from '../accounts/models/UserModel';
import { WorkflowTemplate } from '../workflow-templates/models';

import { _logWarning } from './log';

const queryBuilder = (prefix: string) => (q: string) =>
	`${prefix}?q=${encodeURIComponent(q)}`;

const buildAssetQueryUrl = queryBuilder('/admin/dam/assets');
const buildAssetCollectionQueryUrl = queryBuilder('/admin/dam/collections');
const buildWorkflowsQueryUrl = queryBuilder('/admin/workflow/workflows');
const buildWorkflowCollectionQueryUrl = queryBuilder(
	'/admin/workflow/projects'
);

export function buildComparisonUrl(
	versionA: AssetVersion,
	versionB: AssetVersion
) {
	const workflowA = getParentOfType(versionA, WorkflowModel);
	const workflowB = getParentOfType(versionB, WorkflowModel);
	return `/admin/dam/assets/comparison/${workflowA._id}/${versionA._id}/${workflowB._id}/${versionB._id}`;
}

export function buildWorkflowUrl(workflow: Workflow) {
	return `/admin/workflow/workflows/${workflow._id}`;
}

export function buildSavedQueryUrl({ type, value }: Query) {
	switch (type) {
		case QueryTypes.ASSETS:
			return buildAssetQueryUrl(value);

		case QueryTypes.ASSET_COLLECTIONS:
			return buildAssetCollectionQueryUrl(value);

		case QueryTypes.WORKFLOWS:
			return buildWorkflowsQueryUrl(value);

		case QueryTypes.WORKFLOW_COLLECTIONS:
			return buildWorkflowCollectionQueryUrl(value);

		default:
			_logWarning(`The query type "${type}" is not handled.`);
			return '';
	}
}

export function buildStageUrl(workflow: Workflow, stage: WorkflowStage) {
	return `/admin/workflow/workflows/${workflow._id}/stages#${stage._id}`;
}

export function buildTemplateUrl(template: WorkflowTemplate) {
	return `/admin/workflow/templates/${template._id}`;
}

export function buildWorkflowCollectionUrl(
	workflowCollection: WorkflowCollection
) {
	return `/admin/workflow/projects/${workflowCollection._id}`;
}

export function buildUserUrl(user: User) {
	return `/admin/dashboard/users/${user._id}`;
}

export function buildVersionUrl(version: AssetVersion) {
	const workflow = getParentOfType(version, WorkflowModel);
	return `/admin/workflow/workflows/${workflow._id}/asset-versions/${version._id}`;
}

import styled from 'styled-components';

import { ffRegular } from '../../theme/ui/typography';
import select from '../../theme/components/select';


export const MegaTitle = styled.h3`
	${ ffRegular };
	font-size: 40px;
	margin-bottom: 0;
	padding-bottom: 0;
`;

export const StyledSelect = styled.select`
	${ select };
`;

import {
	faFile,
	faFileArchive,
	faFilePdf,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';
import { HasAssetVersion } from '../../models/AssetVersionModel';
import DetailedViewButton from './detail-view-button.component';

const { colors } = themeStore.selectedTheme;

export const FallbackIconWrapper = styled.div`
	width: 100%;
	padding-top: 1em;
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 100px;
	color: ${colors.darkerGrey};
`;

export const FallbackIcon = styled(FontAwesomeIcon)`
	margin: auto;
`;

const Message = styled.p`
	margin-top: 20px;
`;

const fallbackMapping: Record<string, IconDefinition> = {
	'application/zip': faFileArchive,
	'application/pdf': faFilePdf,
	'application/postscript': faFilePdf,
};

export const FallbackViewer = ({ asset }: HasAssetVersion) => (
	<FallbackIconWrapper>
		<FallbackIcon icon={fallbackMapping[asset.type] || faFile} />
		<div className="mt-5">
			<Message>No preview available.</Message>
			<Message>There is no viewer available for this file type.</Message>
		</div>
	</FallbackIconWrapper>
);

export const DetailsOnlyViewer = ({ asset }: HasAssetVersion) => (
	<>
		<FallbackIconWrapper>
			<FallbackIcon icon={fallbackMapping[asset.type] || faFile} />
			<div className="mt-5">
				<p>No preview available.</p>
				<p>Only the detailed view is available for this file type.</p>
			</div>
		</FallbackIconWrapper>
		<div className="text-center">
			<DetailedViewButton asset={asset} />
		</div>
	</>
);

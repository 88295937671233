import { Point } from './utils';
import React from 'react';

const VISIBLE_ANCHOR = 'cyan';

export const PathAnchors = (props: { anchors: Point[] }) => {
	if (!process.env.REACT_APP_DEBUG_CANVAS) {
		return null;
	}
	return (
		<>
			{props.anchors.map((anchor, i) => (
				<circle
					key={`${anchor.x}${anchor.y}`}
					cx={anchor.x}
					cy={anchor.y}
					r={1}
					fill={anchor.color ?? VISIBLE_ANCHOR}
				>
					<text>{i}</text>
				</circle>
			))}
		</>
	);
};

import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import {
	User,
	UserModel,
	UserSnapshotIn,
	UserSnapshotOut,
} from '../../../accounts/models/UserModel';
import { UserRole } from '../../../accounts/models/UserRole.model';
import { UserStatus } from '../../../accounts/models/UserStatus.model';
import { enumValues, unwrapEvent } from '../../../common';

import { LabeledInput, SubmitButton } from '../../../components/forms';
import UserSelect from './user-select.component';
import { castToSnapshot, isStateTreeNode } from 'mobx-state-tree';
import { useMstEditor } from '../../../common/mst.editor';

interface EditUserFormProps {
	selectedUser: User | UserSnapshotIn;
	onSubmit: (updatedUser: UserSnapshotOut) => Promise<unknown>;
	submitLabel?: string;
}

const EditUserForm = (props: EditUserFormProps) => {
	const isNewUser = !isStateTreeNode(props.selectedUser);
	const editor = useMstEditor<UserModel>(
		castToSnapshot(props.selectedUser),
		UserModel
	);

	const handleSubmit = useCallback(() => {
		if (editor.validSnapshot) {
			return props.onSubmit(editor.validSnapshot);
		}
	}, [props, editor.validSnapshot]);

	return useObserver(() => {
		const { editableInstance, patchers, pathErrors } = editor;

		const userStatusSelect = isNewUser ? null : (
			<UserSelect
				id="userStatus"
				name="status"
				label="Status"
				value={editableInstance.status}
				onChange={unwrapEvent(patchers['status'])}
				options={enumValues(UserStatus)}
			/>
		);

		return (
			<Form>
				<Row form>
					<Col md={6}>
						<LabeledInput
							label="Given Name"
							type="text"
							name="givenName"
							id="givenName"
							value={editableInstance.givenName}
							onChange={unwrapEvent(patchers['givenName'])}
							required
							inputValid={!pathErrors['givenName']?.length}
							errorMsg="First name is required"
						/>
					</Col>
					<Col md={6}>
						<LabeledInput
							label="Family Name"
							type="text"
							name="familyName"
							id="familyName"
							value={editableInstance.familyName}
							onChange={unwrapEvent(patchers['familyName'])}
							required
							inputValid={!pathErrors['familyName']?.length}
							errorMsg="Last name is required"
						/>
					</Col>
					<Col md={6}>
						<LabeledInput
							label="Email"
							name="email"
							id="userEmail"
							value={editableInstance.email}
							onChange={unwrapEvent(patchers['email'])}
							required
							inputValid={!pathErrors['email']?.length}
							errorMsg="Please enter a valid email"
						/>
					</Col>
					<Col md={6}>
						<LabeledInput
							label="Expertise (optional)"
							type="text"
							name="expertise"
							id="userExpertise"
							value={editableInstance.expertise}
							onChange={unwrapEvent(patchers['expertise'])}
						/>
					</Col>
					<Col md={6}>{userStatusSelect}</Col>
					<Col md={6}>
						<FormGroup>
							<UserSelect
								id="userRole"
								name="role"
								label="Role"
								value={editableInstance.role}
								onChange={unwrapEvent(patchers['role'])}
								options={enumValues(UserRole)}
							/>
						</FormGroup>
					</Col>
				</Row>
				<SubmitButton
					onClick={handleSubmit}
					disabled={!editor.validSnapshot}
					label={props.submitLabel || 'Save changes'}
					className="float-right"
				/>
			</Form>
		);
	});
};

export default EditUserForm;

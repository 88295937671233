import algoliasearch from 'algoliasearch/lite';
import { useObserver } from 'mobx-react';
import React from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { Col, Container, Row } from 'reactstrap';
import CustomSearchBox from '../components/algolia/search-box.component';
import SaveAlgoliaQueryButton from '../components/save-algolia-query-button.component';
import { QueryTypes } from '../queries/models/QueryStore';
import themeStore from '../theme/models/ThemeStore';
import CustomAssetHits from './components/asset-infinite-hits.component';

const assetTerm = themeStore._.asset.toLowerCase();
const searchInputPlaceholder = `Search all ${assetTerm}s...`;

const searchClient = algoliasearch(
	`${process.env.REACT_APP_ALGOLIA_APP_ID}`,
	`${process.env.REACT_APP_ALGOLIA_API_KEY}`
);

const AssetsIndexView = () => {
	const render = () => {
		return (
			<InstantSearch
				indexName={`${process.env.REACT_APP_ALGOLIA_DAM_INDEX}`}
				searchClient={searchClient}
			>
				<Configure hitsPerPage={12} />
				<Container>
					<Row>
						<Col md={9} className="pb-4">
							<CustomSearchBox placeholder={searchInputPlaceholder} />
						</Col>
						<Col md={3} className="mt-md-0 mt-3">
							<SaveAlgoliaQueryButton
								className="float-md-right float-none"
								type={QueryTypes.ASSETS}
							/>
						</Col>
					</Row>

					<hr />

					<CustomAssetHits />
				</Container>
			</InstantSearch>
		);
	};

	return useObserver(render);
};

export default AssetsIndexView;

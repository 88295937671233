import { css } from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';

import { boxShadowFocus } from '../ui/ui';
import { fsNormal } from '../ui/typography';

const { colors } = themeStore.selectedTheme;

export const primaryButton = css`
	${fsNormal};
	display: inline-block;
	-webkit-appearance: none;
	background-color: ${colors.primary};
	border-color: initial;
	border-image: initial;
	border-radius: 4px;
	border-style: initial;
	border-width: 0px;
	box-sizing: border-box;
	color: ${colors.white};
	cursor: pointer;
	line-height: inherit;
	margin: 0px;
	padding: 8px 16px;
	text-align: center;
	text-decoration: none;

	&:hover {
		background-color: ${colors.primaryHighlighted};
		color: ${colors.white};
		text-decoration: none;
	}

	&:focus {
		${boxShadowFocus};
		text-decoration: none;
	}

	&:disabled {
		cursor: not-allowed;
		background-color: ${colors.darkGrey};

		&:hover {
			background-color: ${colors.darkGrey};
		}
	}
`;

export const secondaryButton = css`
	${primaryButton};
	background-color: ${colors.secondary} !important;

	&:hover {
		background-color: ${colors.secondaryHighlighted} !important;
	}
`;

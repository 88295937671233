import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import { doesNameableMatch } from '../../common';
import DownshiftMultiSelect, {
	DownshiftMultiSelectProps,
} from '../../components/downshift-select/downshift-multi-select.component';
import { useWorkflowStore } from '../../stores';
import themeStore from '../../theme/models/ThemeStore';
import { Workflow } from '../../workflows/models';
import { WorkflowCollection } from '../models/WorkflowCollectionModel';

const CollectionWorkflowSelect = ({
	collection,
}: {
	collection: WorkflowCollection;
}) => {
	const workflowStore = useWorkflowStore();

	const addWorkflow = useCallback(
		(workflow: Nullable<Workflow>) => {
			if (workflow) {
				collection.addWorkflow(workflow);
			}
		},
		[collection]
	);

	const removeWorkflow = useCallback(
		(workflow: Workflow) => {
			collection.removeWorkflow(workflow);
		},
		[collection]
	);

	const canBeAdded = useCallback(
		(workflow: Workflow) =>
			!(workflow.workflowCollection || collection.hasWorkflow(workflow)),
		[collection]
	);

	return useObserver(() => {
		const selectProps: DownshiftMultiSelectProps<Workflow, Workflow> = {
			label: `${themeStore._.workflow}s`,
			selectionState: {
				selection: collection.workflows,
				options: workflowStore.all.filter(canBeAdded),
				searchPredicate: doesNameableMatch,
			},
			selectionActions: {
				select: addWorkflow,
				unselect: removeWorkflow,
			},
		};

		return <DownshiftMultiSelect {...selectProps} />;
	});
};

export default CollectionWorkflowSelect;

import { types } from 'mobx-state-tree';
import { UserReference } from '../accounts/models/UserReference';
import { dateFromId, generateID } from '../common';
import { tolerantDate } from './common';
import { getStores } from '../stores/index';

const CreatableModelInferred = types
	.model('Creatable', {
		_id: types.optional(types.identifier, generateID),

		createdAt: types.maybe(tolerantDate),
		updatedAt: types.maybe(tolerantDate),
		createdBy: UserReference,
	})
	.views((self) => ({
		get isCreatedByCurrentUser(): boolean {
			return self.createdBy === getStores(self).users.currentUser;
		},
		get creationTime(): string {
			return self.createdAt?.toLocaleDateString() || '';
		},
	}));

interface CreatableModel extends Infer<typeof CreatableModelInferred> {}

export const CreatableModel: CreatableModel = CreatableModelInferred;

type Creatable = Identifiable & { createdAt?: Date };

export const getCreationDateOf = (creatable: Creatable): Date =>
	creatable.createdAt ?? dateFromId(creatable._id);
export const getCreationTimestamp = (creatable: Creatable): number =>
	getCreationDateOf(creatable).valueOf();
export const getCreationDateStr = (creatable: Creatable): string =>
	getCreationDateOf(creatable).toLocaleString();

export const compareCreationDates = (a: Creatable, b: Creatable) =>
	getCreationTimestamp(a) - getCreationTimestamp(b);

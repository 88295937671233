import {
	applyPatch,
	applySnapshot,
	flow,
	getSnapshot,
	Instance,
	IType,
	types,
} from 'mobx-state-tree';
import { getId, includesCaseInsensitive, lazyReference } from '../../common';
import { getClient } from '../../core';
import { CreatableModel } from '../../models/CreatableEntityModel';
import { EntityMetadataModel } from '../../metadata/models/EntityMetadataModel';
import { Workflow, WorkflowReference } from '../../workflows/models';
import { BaseWorkflowOwnableModel } from '../../models/BaseWorkflowOwnableModel';
import { IAnyStateTreeNode } from 'mobx-state-tree/dist/internal';
import { getStores } from '../../stores/index';

const CollectionModelInferred = types
	.compose(CreatableModel, BaseWorkflowOwnableModel)
	.named('WorkflowCollection')
	.props({
		title: types.string,
		workflows: types.array(types.late(() => WorkflowReference)),
		metadata: types.optional(EntityMetadataModel, {}),
	})
	.views((self) => ({
		get isCurrentUserInvolved(): boolean {
			return self.isOwnedByCurrentUser || self.isCreatedByCurrentUser;
		},
		includesMentionOf(value: string): boolean {
			// TODO: check into workflows?
			return (
				includesCaseInsensitive(self.title, value) ||
				self.createdBy.includesMentionOf(value) ||
				self.owners.some((o) => o.includesMentionOf(value)) ||
				self.metadata.includesMentionOf(value)
			);
		},
		hasWorkflow(workflow: Workflow): boolean {
			return self.workflows.includes(workflow);
		},
	}))
	.actions((self) => ({
		save: flow(function* save() {
			const client = getClient(self);
			const updatedWorkflowCollection = yield client.patch(
				`/projects/${self._id}`,
				getSnapshot(self)
			);

			applySnapshot(self, updatedWorkflowCollection);
			return updatedWorkflowCollection;
		}),
		addWorkflow(workflow: Workflow) {
			self.workflows.push(getId(workflow));
		},
		removeWorkflow(workflow: Workflow): void {
			const workflowIdx = self.workflows.indexOf(workflow);

			if (workflowIdx >= 0) {
				return applyPatch(self, {
					op: 'remove',
					path: `/workflows/${workflowIdx}`,
				});
			}
		},
	}));

export interface WorkflowCollectionModel
	extends Infer<typeof CollectionModelInferred> {}

export const WorkflowCollectionModel: WorkflowCollectionModel = CollectionModelInferred;

export interface WorkflowCollection extends Instance<WorkflowCollectionModel> {}

export const WorkflowCollectionReference: IType<
	string,
	string,
	WorkflowCollection
> = lazyReference({
	model: WorkflowCollectionModel,
	getter(identifier: string, parent: IAnyStateTreeNode) {
		return getStores(parent).workflowCollections.getOne(identifier);
	},
});

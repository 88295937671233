import { destroy, Instance, types } from 'mobx-state-tree';
import { getName, withId } from '../common';
import { getStores } from '../stores';
import { BaseWorkflowOwner, BaseWorkflowOwnerModel, BaseWorkflowOwnerSnapshot } from './BaseWorkflowOwnerModel';


const BaseWorkflowOwnableModelInferred = types.model(
	'BaseWorkflowOwnable',
	{
		owners: types.array(BaseWorkflowOwnerModel),
	},
).views(
	self => ({
		isOwner(ownerId: OrID<Identifiable>): boolean {
			return self.owners.some(withId(ownerId));
		},
		isOwnerMember(): boolean {
			const groupStore = getStores(self).groups;
			return groupStore.groupsForCurrentUser.some(group => self.owners.some(withId(group._id)));
		},
		get isOwnedByCurrentUser(): boolean {
			const { currentUser } = getStores(self).users;
			return this.isOwner(currentUser._id) || this.isOwnerMember();
		},
	}),
).actions(
	self => ({
		addOwner(owner: BaseWorkflowOwnerSnapshot): void {
			if (!self.isOwner(owner._id)) {
				self.owners.push(owner);
			}
		},
		removeOwner(owner: BaseWorkflowOwner): void {
			destroy(owner);
		},
	}),
);

interface BaseWorkflowOwnableModel extends Infer<typeof BaseWorkflowOwnableModelInferred> {
}

export const BaseWorkflowOwnableModel: BaseWorkflowOwnableModel = BaseWorkflowOwnableModelInferred;

export interface BaseWorkflowOwnable extends Instance<BaseWorkflowOwnableModel> {
}

export interface Ownable {
	owners: ReadonlyArray<BaseWorkflowOwner>;
}

export const listOwnerNames = (ownable: Ownable): string => ownable.owners.map(getName).join(', ');

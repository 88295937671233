import { Link as LinkBase } from '@reach/router';
import { Card, Table } from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../theme/models/ThemeStore';

import { ffBold, ffRegular } from '../theme/ui/typography';
import { focus, heading1, heading2 } from '../theme/ui/ui';

import { Avatar } from './avatar.component';

const { colors } = themeStore.selectedTheme;

export const Heading = styled.h1`
	${heading1};
	padding-bottom: 1em;
	word-break: break-word;
`;

export const Subtitle = styled.h2`
	${heading2};
	font-size: 18px;
`;

export const Subheading = styled.div`
	${ffRegular};
	font-size: 10px;
	letter-spacing: 2px;
	text-transform: uppercase;
`;

export const BoldText = styled.span`
	${ffBold};
`;

export const SmallText = styled.small`
	display: block;
	margin-bottom: 0.5em;
`;

export const SmallTextMuted = styled(SmallText)`
	color: ${colors.darkerGrey};
`;

export const Divider = styled.hr`
	border: 0.5px solid ${colors.darkGrey};
	margin: 0 0 1em;
`;

export const Link = styled(LinkBase)`
	color: ${colors.primary};
	text-decoration: none;

	&:hover {
		color: ${colors.primaryHighlighted};
		text-decoration: none;
	}

	&:focus {
		${focus};
	}
`;

export const CollaboratorAvatar = styled(Avatar)`
	border: 2px solid ${colors.white};
	margin-right: -0.5em;
	flex: none;
`;

export const CollaboratorAvatarXs = styled(CollaboratorAvatar)`
	border-width: 1px;
	width: 0.5em;
	height: 0.5em;
`;

export const CollaboratorAvatarSm = styled(CollaboratorAvatar)`
	border-width: 1px;
	width: 1em;
	height: 1em;
`;

export const CollaboratorAvatarMd = styled(CollaboratorAvatar)`
	border-width: 1px;
	width: 1.5em;
	height: 1.5em;
`;

export const CollaboratorAvatarLg = styled(CollaboratorAvatar)`
	border-width: 2px;
	width: 2em;
	height: 2em;
`;

export const ViewWrapper = styled.div`
	display: block;
	min-height: 100vh;
	overflow: hidden;
	padding-bottom: 10em;
	position: relative;
`;

export const ScrollCard = styled(Card)`
	overflow-y: scroll;
`;

export const ScrollTable = styled(Table)`
	overflow-y: scroll;
`;

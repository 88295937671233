import React from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimesCircle,
	faExclamationTriangle,
	faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import NotificationModel, { NotificationType } from './NotificationModel';
import themeStore from '../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// styled components
const StyledToast = styled(Toast)`
	margin-top: 2em;
	min-width: 200px;

	&:first-of-type {
		margin-top: 1em;
	}
`;

const StyledHeader = styled(ToastHeader)`
	/* ensure class names match NotificationType */
	&.error {
		background-color: ${colors.danger};
		color: ${colors.white};

		.close {
			color: ${colors.white};
			opacity: 1;
		}
	}
	&.warning {
		background-color: ${colors.warning};
		color: ${colors.darkestGrey};
	}
	&.info {
		background-color: ${colors.secondary};
		color: ${colors.white};

		.close {
			color: ${colors.white};
			opacity: 1;
		}
	}
`;

interface NotificationProps {
	model: NotificationModel;
}

// component
const Notification = ({ model }: NotificationProps) => {
	// render methods
	const renderHeading = () => {
		let icon;
		switch (model.type) {
			case NotificationType.ERROR:
				icon = <FontAwesomeIcon icon={faTimesCircle} />;
				break;

			case NotificationType.WARNING:
				icon = <FontAwesomeIcon icon={faExclamationTriangle} />;
				break;

			default:
				icon = <FontAwesomeIcon icon={faCheckCircle} />;
		}

		let heading = model.heading;
		if (!heading) {
			switch (model.type) {
				case NotificationType.ERROR:
					heading = 'Error';
					break;

				case NotificationType.WARNING:
					heading = 'Warning';
					break;

				default:
					heading = 'Info';
			}
		}

		return (
			<StyledHeader
				className={`notification-header ${model.type}`}
				icon={icon}
				toggle={model.dismiss}
			>
				{heading}
			</StyledHeader>
		);
	};

	const renderComponent = () => (
		<StyledToast isOpen={model.shouldBeDisplayed}>
			{renderHeading()}
			<ToastBody>{model.text}</ToastBody>
		</StyledToast>
	);

	return renderComponent();
};

export default Notification;

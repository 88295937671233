import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObserver } from 'mobx-react';
import React from 'react';

import { buildClassList } from '../../common';
import { IndexView, ViewType } from '../models/IndexViewModel';
import {
	ViewButton,
	ViewButtonWrapper,
} from './index-view-toggler.styled-components';

interface IndexViewTogglerProps {
	className?: string;
	view: IndexView;
}

const IndexViewToggler = (props: IndexViewTogglerProps) => {
	const { className, view } = props;

	return useObserver(() => {
		const viewType = view.viewType;

		return (
			<ViewButtonWrapper
				className={buildClassList('index-view-toggler', className)}
			>
				<ViewButton
					className={viewType === ViewType.grid ? 'active' : ''}
					onClick={() => view.setType(ViewType.grid)}
				>
					<FontAwesomeIcon icon={faThLarge} />
				</ViewButton>
				<ViewButton
					className={viewType === ViewType.list ? 'active' : ''}
					onClick={() => view.setType(ViewType.list)}
				>
					<FontAwesomeIcon icon={faList} />
				</ViewButton>
			</ViewButtonWrapper>
		);
	});
};

export default IndexViewToggler;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { StageActionButtonProps } from '.';
import {
	StageStatus,
	statusForRoadblockToggle,
} from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';
import { isInputStage } from '../../models';
import {
	OutlinedCircleButton,
	StyledTooltip,
} from '../action-button.styled-components';
import ChangeStageStatusDialog from './change-stage-status-dialog.component';

export const RoadblockStageButton = (props: StageActionButtonProps) => {
	const { stage } = props;
	const modalStack = useModalCreator();

	const openRoadblockModal = useCallback(() => {
		modalStack.addModal(
			<ChangeStageStatusDialog
				stage={stage}
				statusChange={statusForRoadblockToggle[stage.status]}
			/>
		);
	}, [stage, modalStack]);

	if (!isInputStage(stage)) {
		return null;
	}

	return (
		<>
			<StyledTooltip target="roadblockBtn">
				{stage.status === StageStatus.active
					? 'Roadblock stage'
					: 'Resolve roadblock'}
			</StyledTooltip>
			<OutlinedCircleButton
				id="roadblockBtn"
				className={
					stage.status === StageStatus.roadblocked ? 'roadblocked' : ''
				}
				disabled={stage.status === StageStatus.completed}
				color=""
				size="sm"
				onClick={openRoadblockModal}
			>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{stage.status === StageStatus.roadblocked ? ' Resolve' : ''}
			</OutlinedCircleButton>
		</>
	);
};

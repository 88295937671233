import {
	getParentOfType,
	Instance,
	ITypeUnion,
	SnapshotIn,
	SnapshotOut,
	types,
} from 'mobx-state-tree';
import { hasElement, lazyReference, loadingValue, noop } from '../../common';
import { isActionableStatus, StageStatus } from '../../models/StageStatusModel';
import { BaseWorkflowOwner } from '../../models/BaseWorkflowOwnerModel';
import { StageType } from '../../workflow-templates/models';
import { WorkflowModel } from './WorkflowModel';
import { ForwardRootTransition } from './WorkflowTransitionModel';
import { Roadblock } from './RoadblockModel';
import { sumBy } from 'lodash';
import { isForward } from '../../models/BaseWorkflowTransitionModel';
import {
	WorkflowSubstage,
	WorkflowSubstageModel,
} from './WorkflowSubstageModel';
import { WorkflowActionableStageCommonModel } from './WorkflowActionableStageModel';
import { WorkflowStageBaseModel } from './WorkflowStageBaseModel';

const WorkflowRootStageCommonModelInferred = WorkflowStageBaseModel.named(
	'WorkflowRootStageModel'
)
	.props({
		initial: types.boolean,
		final: types.boolean,
	})
	.views((self) => ({
		get forwardTransition(): Maybe<ForwardRootTransition> {
			return self.transitions.find(isForward) as Maybe<ForwardRootTransition>;
		},
	}))
	.views((self) => ({
		get nextStage(): Maybe<WorkflowRootStage> {
			return self.forwardTransition?.targetStage;
		},
		get previousStage(): Maybe<WorkflowStage> {
			if (self.initial) {
				return undefined;
			}

			return getParentOfType(self, WorkflowModel).stagePointingTo(
				self as WorkflowSingleStage
			);
		},
	}));

interface WorkflowRootStageCommonModel
	extends Infer<typeof WorkflowRootStageCommonModelInferred> {}

const WorkflowRootStageCommonModel: WorkflowRootStageCommonModel = WorkflowRootStageCommonModelInferred;

const WorkflowSingleStageModelInferred = types
	.compose(
		'WorkflowSingleStage',
		WorkflowRootStageCommonModel,
		WorkflowActionableStageCommonModel
	)
	.props({ type: types.literal(StageType.single) });

export const WorkflowSingleStageModel: WorkflowSingleStageModel = WorkflowSingleStageModelInferred;

export interface WorkflowSingleStageModel
	extends Infer<typeof WorkflowSingleStageModelInferred> {}

export interface WorkflowSingleStage
	extends Instance<WorkflowSingleStageModel> {}

const WorkflowParallelStageModelInferred = WorkflowRootStageCommonModel.named(
	'WorkflowParallelStage'
)
	.props({
		type: types.literal(StageType.parallel),
		substages: types.array(types.array(WorkflowSubstageModel)),
	})
	.views((self) => ({
		get flatSubstages(): readonly WorkflowSubstage[] {
			return self.substages.flat();
		},
		get flatStages(): readonly WorkflowStage[] {
			return this.flatSubstages as WorkflowStage[];
		},
		get roadblocks(): ReadonlyArray<Roadblock> {
			return this.flatSubstages.flatMap(
				({ roadblocks }: WorkflowSubstage) => roadblocks
			);
		},
		get owners(): readonly BaseWorkflowOwner[] {
			return [];
		},
		isOwner(): boolean {
			return false;
		},
		get isOwnedByCurrentUser() {
			return false;
		},
		get defaultInputSlot() {
			return undefined;
		},
		get inputSlots() {
			return undefined;
		},
		get expectedDurationHrs(): number {
			return self.substages.reduce(
				(acc: number, substageGroup: WorkflowSubstage[]) => {
					const substageSequenceDuration = sumBy(
						substageGroup,
						'expectedDurationHrs'
					);
					return Math.max(acc, substageSequenceDuration);
				},
				0
			);
		},
		addOwner: noop,
		removeOwner: noop,
	}));

export const WorkflowParallelStageModel: WorkflowParallelStageModel = WorkflowParallelStageModelInferred;

export interface WorkflowParallelStageModel
	extends Infer<typeof WorkflowParallelStageModelInferred> {}

export interface WorkflowParallelStage
	extends Instance<WorkflowParallelStageModel> {}

// Todo is there a way to automatically exclude literals?
export interface WorkflowRootStageSnapshotIn
	extends Omit<
		SnapshotIn<WorkflowSingleStageModel | WorkflowParallelStageModel>,
		'type'
	> {
	type: StageType.single | StageType.parallel;
}

export interface WorkflowRootStageSnapshotOut
	extends Omit<
		SnapshotOut<WorkflowSingleStageModel | WorkflowParallelStageModel>,
		'type'
	> {
	type: StageType.single | StageType.parallel;
}

export interface WorkflowRootStageModel
	extends ITypeUnion<
		WorkflowRootStageSnapshotIn,
		WorkflowRootStageSnapshotOut,
		WorkflowSingleStage | WorkflowParallelStage
	> {}

export const WorkflowRootStageModel: WorkflowRootStageModel = types.union(
	WorkflowSingleStageModel,
	WorkflowParallelStageModel
);

export interface WorkflowStageModel
	extends ITypeUnion<
		SnapshotIn<
			| WorkflowSingleStageModel
			| WorkflowParallelStageModel
			| WorkflowSubstageModel
		>,
		SnapshotOut<
			| WorkflowSingleStageModel
			| WorkflowParallelStageModel
			| WorkflowSubstageModel
		>,
		WorkflowSingleStage | WorkflowParallelStage | WorkflowSubstage
	> {}

export const WorkflowStageModel: WorkflowStageModel = types.union(
	WorkflowSingleStageModel,
	WorkflowParallelStageModel,
	WorkflowSubstageModel
);

export interface WorkflowActionableStageModel
	extends ITypeUnion<
		SnapshotIn<WorkflowSingleStageModel | WorkflowSubstageModel>,
		SnapshotOut<WorkflowSingleStageModel | WorkflowSubstageModel>,
		WorkflowSingleStage | WorkflowSubstage
	> {}

export const WorkflowActionableStageModel: WorkflowActionableStageModel = types.union(
	WorkflowSingleStageModel,
	WorkflowSubstageModel
);

const loadingStage: WorkflowActionableStage = WorkflowActionableStageModel.create(
	{
		_id: loadingValue,
		type: StageType.single,
		title: 'Loading...',
		status: StageStatus.queue,
		initial: false,
		final: false,
	}
);

export const WorkflowActionableStageReference = lazyReference({
	model: WorkflowActionableStageModel,
	getter(id: string, parent) {
		const workflow = (parent as any).workflowId;
		return (
			workflow.flatStages.find(({ _id }: Identifiable) => _id === id) ||
			loadingStage
		);
	},
});

export type WorkflowStage =
	| WorkflowSingleStage
	| WorkflowParallelStage
	| WorkflowSubstage;
export type WorkflowRootStage = WorkflowSingleStage | WorkflowParallelStage;
export type WorkflowActionableStage = WorkflowSingleStage | WorkflowSubstage;
export type WorkflowInputStage = WorkflowActionableStage &
	HasDefinedProp<WorkflowActionableStage, 'inputSlots'>;

export const isAssignable = (
	stage: WorkflowStage
): stage is WorkflowActionableStage =>
	!WorkflowParallelStageModel.is(stage) &&
	stage.status !== StageStatus.completed;

export const isActionable = (
	stage: WorkflowStage
): stage is WorkflowActionableStage =>
	!WorkflowParallelStageModel.is(stage) &&
	isActionableStatus(stage.status) &&
	stage.isOwnedByCurrentUser;

export function isInputStage(
	stage: WorkflowStage
): stage is WorkflowInputStage {
	return isActionable(stage) && hasElement(stage.inputSlots);
}

export function isFulfilled(stage: WorkflowActionableStage): boolean {
	return stage.inputSlots?.every(({ versions }) => versions.length > 0) ?? true;
}

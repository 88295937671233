import { UploadResult } from '@uppy/core';
import { getParentOfType } from 'mobx-state-tree';
import React, { FormEvent, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { noop } from '../../common/fn.utils';
import { useStateFromEvent } from '../../common/hooks';
import { _logError } from '../../common/log';
import { StyledInput } from '../../components/forms/forms.styled-components';
import { SubmitButton } from '../../components/forms/submit-button.component';
import TagInput from '../../components/tag-input/tag-input.component';
import { resultToFile } from '../../core/FileUploadProvider';
import NotificationModel, {
	NotificationType,
} from '../../notifications/NotificationModel';
import notificationStore from '../../notifications/NotificationStore';
import themeStore from '../../theme/models/ThemeStore';
import WorkflowStageInputSlotSelect from '../../workflows/components/workflow-stage-details/workflow-stage-input-slot-select.component';
import {
	WorkflowInputStage,
	WorkflowStageModel,
} from '../../workflows/models/WorkflowStageModel';
import { SelectedInputSlot } from '../../workflows/workflow.contexts';
import { SelectedMetadata } from '../asset.contexts';
import AssetMetadataForm from './asset-metadata-form.component';
import AssetPicker from './asset-picker.component';

export interface AssetUploadFormProps {
	afterSubmit?: () => void;
}

export const AssetUploadForm = (props: AssetUploadFormProps) => {
	const { afterSubmit = noop } = props;

	const metadata = SelectedMetadata.presentValue;
	const slot = SelectedInputSlot.presentValue;
	const stage = getParentOfType(slot, WorkflowStageModel) as WorkflowInputStage;

	const [uppyResult, setResult] = useState<Maybe<UploadResult>>(undefined);
	const [message, setMessage] = useStateFromEvent('');

	const label = themeStore._.asset;

	const onFormSubmit = async (event: FormEvent) => {
		event.preventDefault();
		const file = resultToFile(uppyResult);

		if (!file) {
			notificationStore.pushWarning('You must select a file.');
			return;
		}
		if (!slot) {
			notificationStore.pushWarning('You must select a slot.');
			return;
		}

		try {
			await slot.uploadAsset(file, { message, metadata });
			afterSubmit();
		} catch (error) {
			_logError(error);
			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text:
						'An issue occurred while attempting to upload your file. Please try again later.',
				})
			);
		}
	};

	return (
		<Form>
			<Row form>
				<Col md={12}>
					{stage.inputSlots.length > 1 ? (
						<WorkflowStageInputSlotSelect stage={stage} />
					) : (
						<p>
							Uploading {label?.toLowerCase()} to "{stage.inputSlots[0].label}"
							slot
						</p>
					)}
				</Col>
				<Col md={6} className="p-2">
					<AssetPicker onSelect={setResult} />
				</Col>
				<Col md={6} className="p-2">
					<AssetMetadataForm />
					<TagInput metadata={metadata} />
				</Col>
				<Col md={12}>
					<StyledInput
						className="mt-4"
						type="textarea"
						name="message"
						value={message}
						placeholder={`${label} notes...`}
						onChange={setMessage}
					/>
				</Col>
			</Row>

			<SubmitButton label="Submit" onClick={onFormSubmit} />
		</Form>
	);
};

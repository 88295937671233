import { observer, useObserver } from 'mobx-react';
import { applyPatch } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { Nav, TabContent } from 'reactstrap';
import themeStore from '../../theme/models/ThemeStore';
import { SelectedTemplateStage } from '../template.contexts';
import BasicsTabPanel from './basics-tab-panel.component';
import InputSlotsPanel from './input-slots-panel.component';
import {
	Aside,
	AsideContent,
	Line,
	NavItem,
	TabButton,
} from './template-stage-edit-form.styled-components';
import TransitionsPanel from './transitions-panel.component';

const stageTerm = themeStore._.stage;

// component
const TemplateStageEditForm = observer(() => {
	const currentStage = SelectedTemplateStage.presentValue;

	const [activeTab, setActiveTab] = useState('1');

	useEffect(() => {
		setActiveTab('1');
	}, [currentStage]);

	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const getTabClassName = (tab: any) => {
		let response = '';
		if (isActiveTab(tab)) {
			response = 'selected';
		}
		return response;
	};

	const getTabAriaSelected = (tab: any) => {
		let response = false;
		if (isActiveTab(tab)) {
			response = true;
		}
		return response;
	};

	const getTabAriaLabel = (tab: string) => {
		return 'Shows "' + tab + '" tab panel, hides other panels';
	};

	const isActiveTab = (tab: any) => {
		return tab === activeTab;
	};

	const onChange = (event: any) => {
		const { name, value } = event.target;

		applyPatch(currentStage, {
			op: 'replace',
			path: `/${name}`,
			value,
		});
	};

	// render methods
	const renderBasicsTabButton = () => (
		<NavItem>
			<TabButton
				className={getTabClassName('1')}
				onClick={() => {
					toggle('1');
				}}
				id="basicsStageEditFormTab"
				role="tab"
				aria-selected={getTabAriaSelected('1')}
				aria-label={getTabAriaLabel('basics')}
				title={getTabAriaLabel('basics')}
			>
				Basics
			</TabButton>
		</NavItem>
	);

	const renderInputsTabButton = () => (
		<NavItem>
			<TabButton
				className={getTabClassName('2')}
				onClick={() => {
					toggle('2');
				}}
				id="inputsStageEditFormTab"
				role="tab"
				aria-selected={getTabAriaSelected('2')}
				aria-label={getTabAriaLabel('inputs')}
				title={getTabAriaLabel('inputs')}
			>
				Inputs
			</TabButton>
		</NavItem>
	);

	const renderTransitionsTabButton = () => (
		<NavItem>
			<TabButton
				className={getTabClassName('3')}
				onClick={() => {
					toggle('3');
				}}
				id="transitionsEditFormTab"
				role="tab"
				aria-selected={getTabAriaSelected('3')}
				aria-label={getTabAriaLabel('transitions')}
				title={getTabAriaLabel('transitions')}
			>
				Transitions
			</TabButton>
		</NavItem>
	);

	// component template
	const render = () => (
		<Aside>
			<AsideContent>
				<p>Edit {stageTerm} Details</p>
			</AsideContent>
			<Line />

			<Nav tabs role="tablist" className="pl-md-0 pl-3">
				{renderBasicsTabButton()}
				{renderInputsTabButton()}
				{renderTransitionsTabButton()}
			</Nav>

			<TabContent activeTab={activeTab} className="h-75 overflow-scroll pb-5">
				<BasicsTabPanel
					tabId="1"
					labelledBy="basicsStageEditFormTab"
					onChange={onChange}
				/>
				<InputSlotsPanel
					tabId="2"
					labelledBy="inputsStageEditFormTab"
					onChange={onChange}
				/>
				<TransitionsPanel
					tabId="3"
					labelledBy="transitionsEditFormTab"
					onChange={onChange}
				/>
			</TabContent>
		</Aside>
	);

	return useObserver(render);
});

export default TemplateStageEditForm;

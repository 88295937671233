import { Link } from '@reach/router';
import styled from 'styled-components';

import { Heading } from '../../components/ui';
import { secondaryButton } from '../../theme/components/secondary-button';
import { CardContainerStyles } from './stage-cards/template-stage-card.styles';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const TemplateHeader = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	@media only screen and (min-width: 992px) {
		flex-direction: row;
	}
`;

export const TemplateTitle = styled(Heading)`
	cursor: pointer;
`;

const InfoWrapper = styled.span`
	margin-left: 1em;
	opacity: 0.5;
`;

export const InfoButton = () => (
	<InfoWrapper>
		<FontAwesomeIcon icon={faInfoCircle} />
	</InfoWrapper>
);

export const ButtonLink = styled(Link)`
	${secondaryButton};
	display: inline-block;
	margin-bottom: 2em;
`;

export const FinalTemplateCanvas = styled.div`
	position: relative;
	${CardContainerStyles};
`;

export const FinalTemplateKeyWrapper = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
`;

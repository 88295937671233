import { faFileArchive, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate, RouteComponentProps } from '@reach/router';
import { useObserver } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import React, { useCallback } from 'react';
import Moment from 'react-moment';
import { Col, Container, Row } from 'reactstrap';
import { isIdentifiableLoaded } from '../common';
import BackLink from '../components/back-link/back-link.component';
import CircleButton from '../components/circle-button.component';
import CollectionActionDropdown from '../components/collection-action-dropdown';
import DownloaderButton from '../components/downloader-button.component';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../components/entity-details.styled-components';
import ConfirmationDialog from '../components/modals/confirmation-dialog.component';
import { Divider, Heading } from '../components/ui';
import AssetCard from '../dam-assets/components/asset-card.component';
import { DamAsset } from '../dam-assets/models/DamAssetModel';
import EntityMetadataFields from '../metadata/components/entity-metadata-fields.component';
import NotificationModel from '../notifications/NotificationModel';
import notificationStore from '../notifications/NotificationStore';
import { useAssetCollectionStore, usePermissions } from '../stores';
import { useModalCreator } from '../stores/ModalStack';
import themeStore from '../theme/models/ThemeStore';
import AddAssetsDialog from './components/add-assets-dialog.component';
import { AssetCollection } from './models/AssetCollectionModel';

// constants
const assetTerm = themeStore._.asset.toLowerCase();
const assetCollectionTerm = themeStore._.assetCollection;

// component
const AssetCollectionDetailsView = (
	props: RouteComponentProps<{ collectionId: string }>
) => {
	const collectionStore = useAssetCollectionStore();
	const modalStack = useModalCreator();
	const permissions = usePermissions();

	// callbacks
	const deleteCollection = useCallback(
		(collection: AssetCollection) => {
			collectionStore.deleteOne(collection);

			notificationStore.push(
				NotificationModel.create({
					text: `${assetCollectionTerm} deleted!`,
				})
			);

			return navigate(`/admin/dam/collections`);
		},
		[collectionStore]
	);

	const showAddAssetsModal = useCallback(
		(collection: AssetCollection) => {
			modalStack.addModal(<AddAssetsDialog selectedCollection={collection} />);
		},
		[modalStack]
	);

	const showDeleteCollectionModal = useCallback(
		(collection: AssetCollection) => {
			modalStack.addModal(
				<ConfirmationDialog
					header={`Deleting "${collection.title}"`}
					onConfirm={() => deleteCollection(collection)}
				>
					<p>
						Are you sure you want to delete this{' '}
						{assetCollectionTerm.toLowerCase()}? This action cannot be undone.
					</p>
				</ConfirmationDialog>
			);
		},
		[modalStack, deleteCollection]
	);

	// render methods
	const renderActionDropdown = (collection: AssetCollection) => {
		if (!permissions.canEditAssetCollection(collection)) {
			return null;
		}

		return (
			<CollectionActionDropdown
				collection={collection}
				collectionItems={collection.assets}
				collectionTerm={assetCollectionTerm.toLowerCase()}
				editLink={`/admin/dam/collections/${collection._id}/edit`}
				showDeleteModal={showDeleteCollectionModal}
				extraClasses="d-inline ml-2"
			/>
		);
	};

	const render = () => {
		const collection = collectionStore.findOne(props.collectionId);

		if (!collection) {
			return null;
		}

		return (
			<Container>
				<Row>
					<Col
						xs={12}
						className="d-flex justify-content-between align-items-center"
					>
						<div>
							<BackLink link=".." title={`${assetCollectionTerm}s`} />
							<Heading>{collection.title}</Heading>
						</div>
						<div>
							<DownloaderButton
								url="assets/zip"
								data={getSnapshot(collection.assets)}
							>
								<FontAwesomeIcon className="mr-2" icon={faFileArchive} />
								Download zip
							</DownloaderButton>

							{renderActionDropdown(collection)}
						</div>
					</Col>

					<Col xs={12}>
						<Divider />
					</Col>

					<Col md={3}>
						<EntityPropList className="mt-4">
							<EntityPropListItem>
								<EntityPropLabel>Created on</EntityPropLabel>
								<p>
									<Moment format="MMM DD, YYYY" date={collection.createdAt} />
								</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>Created by</EntityPropLabel>
								<p>{collection.createdBy?.name || 'N/A'}</p>
							</EntityPropListItem>
							<EntityMetadataFields metadata={collection.metadata} />
						</EntityPropList>
					</Col>

					<Col md={9}>
						<Row className="mt-4">
							{collection.assets
								.filter(isIdentifiableLoaded)
								.map((asset: DamAsset) => (
									<Col lg={4} sm={6} key={asset._id} className="mb-4">
										<AssetCard assetVersion={asset} isLinkEnabled={true} />
									</Col>
								))}
							<Col
								lg={4}
								sm={6}
								className="mt-md-4 mt-5 d-flex justify-content-center align-items-center"
							>
								<CircleButton
									className="sm"
									icon={faPlus}
									id="addAssetCollectionButton"
									onClick={() => showAddAssetsModal(collection)}
									tooltip={`Add ${assetTerm}`}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default AssetCollectionDetailsView;

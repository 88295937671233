import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { loadingValue } from '../../common';
import { tolerantDate } from '../../models/common';


/**
 * User to be used as a placeholder while loading.
 */
export const loadingAccount: AccountSnapshotIn = {
	_id: loadingValue,
	title: 'Loading Account...',
	createdAt: new Date(),
};

const AccountModelInferred = types.model('Account')
                                  .props({
		                                  _id: types.identifier,
		                                  title: types.string,
		                                  createdAt: tolerantDate,
	                                  },
                                  );

export interface AccountModel extends Infer<typeof AccountModelInferred> {}
export const AccountModel: AccountModel = AccountModelInferred;
export interface Account extends Instance<AccountModel> {}
export interface AccountSnapshotIn extends SnapshotIn<AccountModel> {}

import { navigate } from '@reach/router';
import { useObserver } from 'mobx-react';
import React, { FormEvent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BackLink from '../components/back-link/back-link.component';
import { Heading } from '../components/ui';
import NotificationModel from '../notifications/NotificationModel';
import notificationStore from '../notifications/NotificationStore';
import { useMetadataTemplateStore } from '../stores';
import EntityMetadataTemplateEditForm from './components/entity-metadata-template-edit-form.component';

interface MetadataTemplateDetailViewProps {
	metadataTemplateId: string;
}

const MetadataTemplateDetailsView = (
	props: MetadataTemplateDetailViewProps
) => {
	const metadataTemplateStore = useMetadataTemplateStore();
	const { metadataTemplateId } = props;

	const render = () => {
		const metadataTemplate = metadataTemplateStore.findOne(metadataTemplateId);

		if (!metadataTemplate) {
			return null;
		}

		const updateMetadataTemplate = (event: FormEvent<Element>) => {
			event.preventDefault();

			metadataTemplateStore.updateOne(metadataTemplate);

			notificationStore.push(
				NotificationModel.create({
					text: `${metadataTemplate.title} saved!`,
				})
			);

			return navigate(``);
		};

		return (
			<Container>
				<Row className="justify-content-center">
					<Col lg={8}>
						<BackLink link=".." title="Metadata Templates" />
						<div className="d-flex justify-content-between">
							<Heading>{metadataTemplate.title}</Heading>
						</div>
					</Col>
					<Col lg={8}>
						<EntityMetadataTemplateEditForm
							selectedMetadataTemplate={metadataTemplate}
							onSubmit={(event) => updateMetadataTemplate(event)}
							displayTemplateField={false}
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default MetadataTemplateDetailsView;

import styled from 'styled-components';

import { fsNormal } from '../../theme/ui/typography';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const Td = styled.td`
	${fsNormal};
	border-top: none !important;
	border-bottom: 1px solid ${colors.grey};
	height: 60px !important;
	vertical-align: middle !important;
`;

export const Tr = styled.tr`
	&:hover {
		background-color: ${colors.lightGrey};
		cursor: pointer;
		transition: background 0.2s linear;
	}

	&.parallel-stage {
		> td {
			&:first-child {
				border-left: 2px solid ${colors.secondaryHighlighted};
			}
		}
	}

	&.selected {
		background-color: ${colors.lightGrey};

		&::after {
			position: absolute;
			border-bottom: 20px solid transparent;
			border-right: 26px solid ${colors.white};
			border-top: 20px solid transparent;
			clear: both;
			content: '';
			height: 0;
			margin-top: 14px;
			right: -32px;
			width: 0;
		}

		@media (max-width: 992px) {
			&::after {
				border-top: none;
				border-bottom: none;
				border-right: none;
			}
		}
	}
`;

export const TrSub = styled(Tr)`
	border-bottom: none;
	background-color: ${colors.lightestGrey};

	&:last-child {
		> td {
			border-bottom: 1px solid ${colors.grey};
		}
	}

	> td {
		border-bottom: none;

		&:first-child {
			border-left: 2px solid ${colors.secondaryHighlighted};
			padding-left: 2.5em;
			position: relative;

			&:before {
				content: '↳ ';
				color: ${colors.secondaryHighlighted};
				position: absolute;
				top: 15px;
				left: 15px;
				font-size: 20px;
			}
		}
	}
`;

export const TrSubChild = styled(TrSub)`
	background-color: ${colors.white};

	> td:first-child {
		padding-left: 4em;

		&:before {
			left: 35px;
		}
	}
`;

import React, { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';

import { HasAssetVersion } from '../../models/AssetVersionModel';

import AssetViewerWarning from './asset-viewer-warning.component';
import DetailedViewButton from './detail-view-button.component';
import { Magnifier } from 'react-image-magnifiers';
import { useAPIClient, useUserStore } from '../../../stores';
import { PermissionsCheck } from '../../../accounts/permissions-check.component';
import SubmitButton from '../../../components/forms/submit-button.component';
import themeStore from '../../../theme/models/ThemeStore';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { DamAssetModel } from '../../models/DamAssetModel';
import { applyPatch } from 'mobx-state-tree';

// styled components
const Container = styled.div`
	display: inline-block;
`;

const StyledImage = styled.img`
	background-image: url('/images/transparency-grid.png');
	background-repeat: repeat;
	border-radius: 0.5em;
	image-rendering: crisp-edges;
`;

const ImageViewerWithZoom = ({ asset }: HasAssetVersion) => {
	const { currentUser } = useUserStore();
	const [isValid, setValid] = useState(true);
	const apiClient = useAPIClient();

	const refreshPreview = useCallback(async () => {
		const prefix = DamAssetModel.is(asset) ? 'd' : 'w';
		const updatedURL = await apiClient.put(
			`/preview/refresh/${prefix}/${asset._id}`
		);
		applyPatch(asset, {
			path: '/previewURL',
			op: 'replace',
			value: updatedURL,
		});
		setValid(true);
	}, [apiClient, asset]);

	let previewImage: ReactElement;

	if (!isValid || !asset.previewURL) {
		previewImage = (
			<AssetViewerWarning
				text={`Unable to preview your file: An error may have occurred while fetching it.`}
				color="danger"
			/>
		);
	} else {
		previewImage = currentUser.isAdmin ? (
			<Magnifier imageSrc={asset.previewURL} largeImageSrc={asset.previewURL} />
		) : (
			<StyledImage
				className="img-fluid"
				src={asset.previewURL}
				onError={() => setValid(false)}
			/>
		);
	}

	return (
		<Container>
			<DetailedViewButton asset={asset} />

			<PermissionsCheck check="canRefreshPreview">
				<SubmitButton
					label={`Refresh ${themeStore._.asset.toLowerCase()} preview`}
					icon={faSync}
					onClick={refreshPreview}
					className="mx-4"
				/>
			</PermissionsCheck>

			{previewImage}
		</Container>
	);
};

export default ImageViewerWithZoom;

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';
import { useObserver } from 'mobx-react';
import React from 'react';
import Moment from 'react-moment';
import { Col, Container, Row } from 'reactstrap';
import { SecondaryButton } from '../../components/buttons.styled-components';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../components/entity-details.styled-components';
import OwnerList from '../../components/owner-list.component';
import EntityMetadataFields from '../../metadata/components/entity-metadata-fields.component';
import themeStore from '../../theme/models/ThemeStore';
import { SelectedTemplate } from '../template.contexts';
import {
	Aside,
	AsideContent,
	Line,
} from './template-stage-edit-form.styled-components';

const TemplateEditorSidebar = () => {
	const template = SelectedTemplate.presentValue;

	return useObserver(() => (
		<Aside>
			<AsideContent>
				<p>Template Details</p>
			</AsideContent>
			<Line />
			<Container>
				<Row>
					<Col xs={7}>
						<EntityPropList>
							<EntityPropListItem>
								<EntityPropLabel>Created on</EntityPropLabel>
								<p>
									<Moment format="MMM DD, YYYY" date={template.createdAt} />
								</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>Created by</EntityPropLabel>
								<p>{template.createdBy.name}</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>{themeStore._.owner}(s)</EntityPropLabel>
								<OwnerList entity={template} />
							</EntityPropListItem>
							<EntityMetadataFields metadata={template.metadata} />
						</EntityPropList>
					</Col>
					<Col xs={5} className="text-right pr-md-3 pr-4">
						<Link to="./edit">
							<SecondaryButton>
								<FontAwesomeIcon icon={faEdit} className="mr-1" />
								Edit
							</SecondaryButton>
						</Link>
					</Col>
				</Row>
			</Container>
		</Aside>
	));
};

export default TemplateEditorSidebar;

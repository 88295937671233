import { css } from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const hoverStyles = css`
	background-color: ${colors.lightGrey} !important;

	&.danger {
		background-color: ${colors.danger} !important;
	}

	&.secondary {
		background-color: ${colors.secondary} !important;
	}

	&.danger,
	&.secondary {
		color: ${colors.white} !important;

		.svg-inline--fa {
			color: ${colors.white} !important;
		}
	}
`;

const dropDownMenuItem = css`
	list-style: none;
	padding: 0.25em 0.5em;

	&.danger {
		color: ${colors.danger};
	}

	&.secondary {
		color: ${colors.secondary};
	}

	:not(.dropdown-divider) {
		cursor: pointer;

		&.dropdown-item:hover,
		&:hover {
			${hoverStyles};
		}

		&.dropdown-item:focus {
			${hoverStyles};
			outline: 0;
		}
	}
`;
export default dropDownMenuItem;

import React, { ChangeEvent } from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { SearchButton, StyledInput } from './search-bar.styled-components';


interface SearchBarProps {
	placeholder: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar = (props: SearchBarProps) => (
	<InputGroup>
		<InputGroupAddon addonType="append">
			<SearchButton>
				<FontAwesomeIcon icon={ faSearch } />
			</SearchButton>
		</InputGroupAddon>
		<StyledInput
			type="search"
			name="search"
			placeholder={ props.placeholder }
			onChange={ props.onChange }
		/>
	</InputGroup>
);

export default SearchBar;

import { useObserver } from 'mobx-react';
import React from 'react';
import { Card } from 'reactstrap';
import DeleteEntityButton from '../../components/delete-entity-button.component';
import {
	ListColumn,
	makeColumns,
} from '../../components/list-column.component';
import ListTable from '../../components/list-table.component';
import OwnerList from '../../components/owner-list.component';

import { CellLink } from '../../components/tables.styled-components';
import { useAssetCollectionStore, usePermissions } from '../../stores';
import themeStore from '../../theme/models/ThemeStore';

import { AssetCollection } from '../models/AssetCollectionModel';

// constants
const collectionTerm = themeStore._.assetCollection;

// interface
interface Props {
	collections: readonly AssetCollection[];
	noResultsLabel?: string;
}

// component
const AssetCollectionListTable = (props: Props) => {
	const {
		collections,
		noResultsLabel = `Loading ${collectionTerm.toLowerCase()}s ...`,
	} = props;

	const permissions = usePermissions();
	const collectionStore = useAssetCollectionStore();

	const collectionListColumns: ListColumn<AssetCollection>[] = makeColumns([
		{
			label: 'Name',
			prop: (c: AssetCollection) => (
				<CellLink to={`./${c._id}`}>{c.title}</CellLink>
			),
		},
		{
			label: `${themeStore._.owner}(s)`,
			prop: (c: AssetCollection) => <OwnerList entity={c} />,
		},
		{
			label: `${themeStore._.asset} count`,
			prop: (c: AssetCollection) => <p>{c.assets.length}</p>,
		},
		{
			label: '',
			prop: (c: AssetCollection) =>
				c.assets.length === 0 && permissions.canEditAssetCollection(c) ? (
					<DeleteEntityButton
						entity={c}
						deleteOne={collectionStore.deleteOne}
						entityName={collectionTerm}
					/>
				) : null,
		},
	]);

	const render = () => (
		<Card className="mt-2">
			<ListTable
				columns={collectionListColumns}
				rows={collections}
				noResultsLabel={noResultsLabel}
			/>
		</Card>
	);

	return useObserver(render);
};

export default AssetCollectionListTable;

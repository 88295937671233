import { css } from 'styled-components';
import themeStore from '../models/ThemeStore';
import { primaryButton } from './primary-button';

const { colors } = themeStore.selectedTheme;

export const secondaryButton = css`
	${primaryButton};

	&& {
		svg {
			fill: ${colors.white};
		}

		background-color: ${colors.secondary};

		&:hover {
			background-color: ${colors.secondaryHighlighted};
		}
	}
`;

export const outlinedSecondaryButton = css`
	${secondaryButton};

	&& {
		background-color: transparent;
		color: ${colors.secondary};
		border: 1px solid ${colors.secondary};

		&:hover {
			color: ${colors.white};
			background-color: ${colors.secondary};
		}
	}
`;

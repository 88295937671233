import { buildPathFromPoints, point, Point } from './utils';
import { getParentOfType } from 'mobx-state-tree';
import { TemplateModel, WorkflowTemplate } from '../models/TemplateModel';
import { TemplateTransition } from '../models/TemplateTransitionModel';
import { ElementOffsets, elementStore } from './ElementStore';
import { SmartRect } from './SmartRect';

const xOffset = 25;
const distanceScaling = 10;

export interface PointPathFn {
	(prevPt: Point): Point;
}

export interface PointPath {
	path: string;
	anchors: Point[];
}

export class PathBuilder {
	private readonly startPt: Point;
	private readonly endPt: Point;

	private get leftMostStageX() {
		const orderedStages = this.template.flatStages;
		const indexDelta =
			orderedStages.indexOf(this.transition.sourceStage) -
			orderedStages.indexOf(this.transition.targetStage);

		return elementStore.leftmostX - indexDelta * distanceScaling - xOffset;
	}

	private get ptFns(): ReadonlyArray<PointPathFn> {
		const ptFns: Array<(prevPt: Point) => Point> = [() => this.startPt];

		if (!this.isForward) {
			const leftMostX = this.leftMostStageX;
			ptFns.push(
				() => point(leftMostX, this.startPt.y),
				() => point(leftMostX, this.endPt.y)
			);
		}

		// Same point twice in a row tells the path to end.
		const goToEndPt = () => this.endPt;
		ptFns.push(goToEndPt, goToEndPt);

		return ptFns;
	}

	private get pts(): Point[] {
		return this.ptFns.reduce((acc, ptFn, i) => {
			acc.push(ptFn(acc[i - 1] ?? acc[0]));
			return acc;
		}, [] as Point[]);
	}

	private get isForward(): boolean {
		return this.transition.isForward;
	}

	private get template(): WorkflowTemplate {
		return getParentOfType(this.transition, TemplateModel);
	}

	public path(): PointPath {
		return buildPathFromPoints(this.pts);
	}

	constructor(
		from: ElementOffsets,
		to: ElementOffsets,
		public transition: TemplateTransition
	) {
		const fromRect = new SmartRect(from);
		const toRect = new SmartRect(to);

		this.startPt = this.isForward ? fromRect.bottomCenter : fromRect.topLeft;
		this.endPt = this.isForward ? toRect.topCenter : toRect.bottomLeft;
	}

	public static buildPath(
		from: ElementOffsets,
		to: ElementOffsets,
		transition: TemplateTransition
	): PointPath {
		const pathBuilder = new PathBuilder(from, to, transition);
		return pathBuilder.path();
	}
}

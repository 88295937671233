import { useObserver } from 'mobx-react';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { isIdentifiableLoaded } from '../common';

import BackLink from '../components/back-link/back-link.component';
import { LoadingRow } from '../components/loading.component';
import { Heading } from '../components/ui';
import { useAssetCollectionStore } from '../stores';
import themeStore from '../theme/models/ThemeStore';
import EditAssetCollectionForm from './components/edit-asset-collection-form';

interface EditAssetCollectionProps {
	collectionId: string;
}

const AssetCollectionEditView = (props: EditAssetCollectionProps) => {
	const { collectionId } = props;
	const assetCollectionStore = useAssetCollectionStore();

	const label = themeStore._.assetCollection;

	const render = () => {
		const collection = assetCollectionStore.getOne(collectionId);

		return (
			<Container>
				{isIdentifiableLoaded(collection) ? (
					<Row className="justify-content-center">
						<Col lg={8}>
							<BackLink link=".." title={`${label} Details`} />
							<div className="d-flex justify-content-between">
								<Heading>Edit "{collection.title}"</Heading>
							</div>
						</Col>
						<Col lg={8}>
							<EditAssetCollectionForm collection={collection} />
						</Col>
					</Row>
				) : (
					<Container>
						<LoadingRow label="Loading..." />
					</Container>
				)}
			</Container>
		);
	};

	return useObserver(render);
};

export default AssetCollectionEditView;

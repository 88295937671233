import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const ProgressContainer = styled.div`
	.bg-healthy {
		background-color: ${colors.healthy};
	}
	.bg-overdue {
		background-color: ${colors.overdue};
	}
	.bg-blocked {
		background-color: ${colors.blocked};
	}
`;

export const Stat = styled.h5`
	font-size: 25px;
	margin-bottom: 0;
	padding-bottom: 0;
`;

export const Dot = styled.div`
	display: inline-block;
	height: 9px;
	width: 9px;
	border-radius: 100%;
	margin-left: 5px;

	&.healthy {
		background-color: ${colors.healthy};
	}
	&.overdue {
		background-color: ${colors.overdue};
	}
	&.roadblocked {
		background-color: ${colors.blocked};
	}
	&.active {
		background-color: ${colors.active};
	}
	&.queue {
		background-color: ${colors.primary};
	}
`;

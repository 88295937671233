import React, { ReactElement } from 'react';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { Workflow } from '../../workflows/models';

export const PaddedBadge = styled(Badge)`
	&& {
		padding: 4px 6px;
		font-size: 11px;
	}
`;

const WorkflowHealthFlag = (props: {
	workflow: Workflow;
	whenInactive?: ReactElement;
}) => {
	const { workflow, whenInactive = null } = props;

	if (!workflow.isActive) {
		return whenInactive;
	} else if (workflow.isOverdue) {
		return <PaddedBadge color="warning">Overdue</PaddedBadge>;
	} else if (workflow.isRoadblocked) {
		return <PaddedBadge color="danger">Roadblocked</PaddedBadge>;
	} else {
		return <PaddedBadge color="success">Healthy</PaddedBadge>;
	}
};

export default WorkflowHealthFlag;

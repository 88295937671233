import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';

import { SelectedTemplate, SelectedTemplateStage } from '../template.contexts';
import { TemplateState, TemplateSubstage } from '../models';

import { CircleButton } from './template-stage-edit-form.styled-components';

const stageTerm = themeStore._.stage.toLowerCase();
const subStageTerm = `sub${stageTerm}`;

// interface
export interface SubstageButtonProps {
	stage: TemplateSubstage;
}

// styled components
const SubstageCircleButton = styled(CircleButton)`
	position: relative;
	margin-left: -30px;
	margin-bottom: 30px;
`;

// component
const SubstageCreationButton = ({ stage }: SubstageButtonProps) => {
	const [, setSelectedStage] = SelectedTemplateStage.useMaybe();
	const template = SelectedTemplate.presentValue;

	const onAddStageClick = useCallback(() => {
		const createdStage = template.addSubstage(stage);

		if (createdStage) {
			setSelectedStage(createdStage);
		}
	}, [template, stage, setSelectedStage]);

	if (template.editingState === TemplateState.final) {
		return null;
	}

	const buttonID = `create-substage-${stage._id}`;
	return (
		<>
			<UncontrolledTooltip placement="top" target={buttonID}>
				Add {subStageTerm}
			</UncontrolledTooltip>
			<SubstageCircleButton onClick={onAddStageClick} id={buttonID}>
				<FontAwesomeIcon icon={faPlus} />
			</SubstageCircleButton>
		</>
	);
};

export default SubstageCreationButton;

import { useObserver } from 'mobx-react';
import React from 'react';

import { BaseWorkflowPhase } from '../../models/BaseWorkflowPhaseModel';
import { generateID, getRandomPastel, stubTrue } from '../../common';
import { SelectionActions } from '../../components/downshift-select/downshift.interfaces';
import { WorkflowTemplate } from '../../workflow-templates/models';
import DownshiftMultiFreeform from '../../components/downshift-select/downshift-multi-freeform.component';
import themeStore from '../../theme/models/ThemeStore';

const phaseTerm = themeStore._.phase;

// interface
interface Props {
	className?: string;
	template: WorkflowTemplate;
}

// component
const PhaseInput = ({ template, className }: Props) => {
	const render = () => {
		const addPhase = (title: MaybeNull<string>): void => {
			if (title) {
				template.addPhase({
					_id: generateID(),
					title: title,
					color: getRandomPastel(),
				});
			}
		};

		const removePhase = (phase: BaseWorkflowPhase): void => {
			template.removePhase(phase);
		};

		const selectionActions: SelectionActions<BaseWorkflowPhase, string> = {
			select: addPhase,
			unselect: removePhase,
		};

		const selectionState = {
			selection: template.phases,
			options: [],
			searchPredicate: stubTrue,
		};

		return (
			<div className={`phase-input ${className}`}>
				<DownshiftMultiFreeform
					label={`${phaseTerm}s`}
					placeholder={`Add a ${phaseTerm.toLowerCase()}...`}
					selectionState={selectionState}
					selectionActions={selectionActions}
				/>
			</div>
		);
	};

	return useObserver(render);
};

export default PhaseInput;

import { RouteComponentProps } from '@reach/router';
import { useObserver } from 'mobx-react';
import React from 'react';
import { Container } from 'reactstrap';
import { isIdentifiableLoaded } from '../common';
import { useAssetStore } from '../stores';
import CommonAssetVersionDisplay from './components/common-asset-version-display.component';
import LoadingAssetState from './components/loading-asset-state.component';

const AssetDetailsView = (props: RouteComponentProps<{ assetId?: string }>) => {
	const assetStore = useAssetStore();

	const render = () => {
		const asset = assetStore.findOne(props.assetId);

		if (asset) {
			if (!asset.versionId) {
				return <CommonAssetVersionDisplay version={asset} damAsset={asset} />;
			}
			if (isIdentifiableLoaded(asset.versionId)) {
				return (
					<CommonAssetVersionDisplay
						version={asset.versionId}
						damAsset={asset}
					/>
				);
			}
		}

		return <LoadingAssetState />;
	};

	return <Container>{useObserver(render)}</Container>;
};

export default AssetDetailsView;

import { generateID } from '../common';
import notificationStore from './NotificationStore';


export enum NotificationType {
	INFO = 'info',
	WARNING = 'warning',
	ERROR = 'error',
}


// interface
export interface NotificationModelProps {
	type?: NotificationType;
	heading?: string;
	text?: string;
	expiryMs?: number
}

// Notifications autoclose after 6 seconds
const DEFAULT_NOTIFICATION_EXPIRY_MS = 6000;

class NotificationModel {
	id = generateID();

	readonly type: NotificationType;
	readonly heading: string;
	readonly text: string;

	raw = undefined;
	shouldBeDisplayed = true;
	shouldBeLogged = false;

	private readonly autoExpiryTimeout?: number;

	dismiss = (): void => {
		this.shouldBeDisplayed = false;

		notificationStore.remove(this);

		if (this.autoExpiryTimeout) {
			clearTimeout(this.autoExpiryTimeout);
		}
	};

	constructor({
		            type = NotificationType.INFO,
		            heading = '',
		            text = '',
		            expiryMs = DEFAULT_NOTIFICATION_EXPIRY_MS,
	            }: NotificationModelProps) {
		this.type = type;
		this.heading = heading;
		this.text = text;

		if (expiryMs > 0) {
			this.autoExpiryTimeout = setTimeout(this.dismiss, expiryMs);
		}
	}

	static create(props: NotificationModelProps) {
		return new NotificationModel(props);
	}

	static error(text: string) {
		return NotificationModel.create({ type: NotificationType.ERROR, text });
	}

	static warning(text: string) {
		return NotificationModel.create({ type: NotificationType.WARNING, text });
	}

	static info(text: string) {
		return NotificationModel.create({ type: NotificationType.INFO, text });
	}

}

export default NotificationModel;

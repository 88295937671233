import { Col } from 'reactstrap';
import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';

import { AssetVersion } from '../models/AssetVersionModel';

import AssetCard from './asset-card.component';

interface Props {
	// flags
	areLinks?: boolean;
	isSelectionAvailable?: boolean;
	// props
	assets: readonly AssetVersion[];
	onAssetSelected?: (assets: AssetVersion) => void;
	selectedAssets?: readonly AssetVersion[];
}

const AssetCardGrid = (props: Props) => {
	const {
		areLinks,
		assets,
		isSelectionAvailable,
		onAssetSelected,
		selectedAssets,
	} = props;

	const areLinksEnabled = () => {
		if (areLinks === undefined) {
			return true;
		} else {
			return areLinks;
		}
	};

	const isCardSelected = (asset: AssetVersion) => {
		if (selectedAssets) {
			return selectedAssets.includes(asset);
		} else {
			return false;
		}
	};

	const clickCallBack = useCallback(
		(asset: AssetVersion) => {
			if (isSelectionAvailable && onAssetSelected) {
				onAssetSelected(asset);
			}
		},
		[isSelectionAvailable, onAssetSelected]
	);

	const render = () => (
		<>
			{assets.map((asset) => (
				<Col lg={3} md={6} className="mt-2 mb-3" key={asset._id}>
					<AssetCard
						assetVersion={asset}
						onClick={clickCallBack}
						isLinkEnabled={areLinksEnabled()}
						isSelected={isCardSelected(asset)}
					/>
				</Col>
			))}
		</>
	);

	return useObserver(render);
};

export default AssetCardGrid;

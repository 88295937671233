import { getParentOfType } from 'mobx-state-tree';
import React from 'react';
import { formatDuration } from '../../common';
import { buildStageUrl } from '../../common/links';
import { _logError } from '../../common/log';
import StageStatusPill from '../../components/stage-status-pill.component';
import { Cell, CellLink } from '../../components/tables.styled-components';
import { Workflow, WorkflowModel, WorkflowStage } from '../../workflows/models';

const getWorkflow = (stage: WorkflowStage) => {
	let workflow: Maybe<Workflow> = undefined;

	try {
		workflow = getParentOfType(stage, WorkflowModel);
	} catch (error) {
		_logError(error);
	}

	return workflow;
};

const AssignmentTableRow = (props: { stage: WorkflowStage }) => {
	const { stage } = props;
	const workflow = getWorkflow(stage);

	if (!workflow) {
		return null;
	}

	return (
		<tr key={stage._id}>
			<Cell>
				<CellLink to={buildStageUrl(workflow, stage)}>{stage.title}</CellLink>
			</Cell>
			<Cell>{formatDuration(stage.expectedDurationHrs)}</Cell>
			<Cell>
				<StageStatusPill status={stage.status} />
			</Cell>
			<Cell>{workflow.title}</Cell>
		</tr>
	);
};

export default AssignmentTableRow;

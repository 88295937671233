import React, { useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputGroup, InputGroupAddon } from 'reactstrap';

import {
	SearchButton,
	StyledInput,
} from '../search-bar/search-bar.styled-components';
import windowModel from '../../models/WindowModel';

const SearchBox = ({
	placeholder,
	currentRefinement,
	refine,
}: {
	placeholder: string;
	currentRefinement: string;
	refine: (event: any) => void;
}) => {
	useEffect(() => {
		const queryValue = windowModel.getURLSearchParam('q');
		if (queryValue) {
			refine(queryValue);
		}
	}, [refine]);

	return (
		<InputGroup>
			<InputGroupAddon addonType="append">
				<SearchButton>
					<FontAwesomeIcon icon={faSearch} />
				</SearchButton>
			</InputGroupAddon>
			<StyledInput
				type="search"
				placeholder={placeholder}
				value={currentRefinement}
				onChange={(event: any) => refine(event.currentTarget.value)}
			/>
		</InputGroup>
	);
};

const CustomSearchBox = connectSearchBox(SearchBox);
export default CustomSearchBox;

import { useObserver } from 'mobx-react';
import React, { FormEvent } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';

import { UserGroup } from '../../../accounts/models/UserGroupModel';
import { usePatchers } from '../../../common';
import { unwrapEvent } from '../../../common/hooks';

import { LabeledInput, SubmitButton } from '../../../components/forms';
import MemberSelect from './group-member-select.component';

interface EditGroupFormProps {
	selectedGroup: UserGroup;
	onSubmit: (event: FormEvent) => Promise<unknown>;
}

const EditGroupForm = (props: EditGroupFormProps) => {
	const { selectedGroup, onSubmit } = props;
	const propertyPatcher = usePatchers(selectedGroup);

	return useObserver(() => {
		const isFormValid = () => {
			return selectedGroup.title !== '';
		};

		return (
			<Form>
				<Row form>
					<Col md={12}>
						<LabeledInput
							label="Group Title"
							type="text"
							name="title"
							id="title"
							value={selectedGroup.title}
							onChange={unwrapEvent(propertyPatcher('title'))}
							required
							inputValid={selectedGroup.title !== ''}
							errorMsg="Title is required"
						/>

						<FormGroup>
							<MemberSelect group={selectedGroup} />
						</FormGroup>
					</Col>
				</Row>
				<SubmitButton
					label="Save Group"
					disabled={!isFormValid()}
					onClick={onSubmit}
				/>
			</Form>
		);
	});
};

export default EditGroupForm;

import { uniqBy } from 'lodash';
import {
	applySnapshot,
	flow,
	Instance,
	SnapshotIn,
	SnapshotOut,
	types,
} from 'mobx-state-tree';
import { AssetVersion } from '../../dam-assets/models/AssetVersionModel';

import {
	asPercent,
	enumValues,
	getId,
	getName,
	includesCaseInsensitive,
	lazyReference,
	loadingValue,
} from '../../common';
import { getClient } from '../../core';
import { EntityMetadataModel } from '../../metadata/models/EntityMetadataModel';
import { BaseWorkflowModel } from '../../models/BaseWorkflowCommonModel';
import { optionalString, tolerantDate } from '../../models/common';
import { StageEvent } from '../../models/StageEventModel';
import { StageStatus } from '../../models/StageStatusModel';
import { getStores } from '../../stores';
import { TemplateReference } from '../../workflow-templates/models';
import { Roadblock } from './RoadblockModel';
import { WorkflowStageInputSlot } from './WorkflowStageInputSlot';

import {
	WorkflowParallelStageModel,
	WorkflowRootStage,
	WorkflowRootStageModel,
	WorkflowStage,
} from './WorkflowStageModel';
import { WorkflowCollectionReference } from '../../workflow-collections/models/WorkflowCollectionModel';
import { getCreationDateOf } from '../../models/CreatableEntityModel';

export enum WorkflowStatus {
	pipeline = 'pipeline',
	active = 'active',
	paused = 'paused',
	cancelled = 'cancelled',
	completed = 'completed',
}

export enum WorkflowHealth {
	healthy = 'healthy',
	overdue = 'overdue',
	roadblocked = 'roadblocked',
}

/**
 * Workflow to be used as a placeholder while loading.
 */
export const loadingWorkflow: WorkflowSnapshotIn = {
	_id: loadingValue,
	createdBy: loadingValue,
	createdAt: new Date(),
	templateUsed: loadingValue,
};

export const workflowStatus = types.enumeration(
	'WorkflowStatus',
	enumValues(WorkflowStatus)
);

export const workflowStatusColors: Record<WorkflowStatus, string> = {
	[WorkflowStatus.pipeline]: 'pipeline',
	[WorkflowStatus.active]: 'active',
	[WorkflowStatus.paused]: 'blocked',
	[WorkflowStatus.cancelled]: 'blocked',
	[WorkflowStatus.completed]: 'completed',
};

export const workflowHealthColors: Record<WorkflowHealth, string> = {
	[WorkflowHealth.healthy]: 'healthy',
	[WorkflowHealth.overdue]: 'overdue',
	[WorkflowHealth.roadblocked]: 'blocked',
};

const WorkflowModelInferred = BaseWorkflowModel.named('Workflow')
	.props({
		stages: types.array(WorkflowRootStageModel),

		templateUsed: TemplateReference,
		workflowCollection: types.maybe(WorkflowCollectionReference),

		status: types.optional(workflowStatus, WorkflowStatus.pipeline),
		statusMsg: optionalString,

		startDate: types.maybeNull(tolerantDate),
		dueDate: types.maybeNull(tolerantDate),

		metadata: types.optional(EntityMetadataModel, {}),
	})
	// stage methods
	.views((self) => ({
		/**
		 * @deprecated
		 * Just use `stages`
		 */
		get rootStages(): ReadonlyArray<WorkflowRootStage> {
			return self.stages;
		},
		get initialStage(): WorkflowRootStage {
			const initialStage: Maybe<WorkflowRootStage> = self.stages.find(
				(s) => s.initial
			);

			if (initialStage) {
				return initialStage;
			} else {
				// Uh oh, we should have one... hopefully we can deduce it.
				const candidates = this.rootStages.filter((s) => !s.previousStage);

				if (candidates.length === 1) {
					const newInitialStage = candidates[0];
					newInitialStage.initial = true;
					return newInitialStage;
				}
			}

			throw new Error('Invalid workflow state');
		},
	}))
	.views((self) => ({
		get orderedStages(): ReadonlyArray<WorkflowRootStage> {
			return self.stages;
		},
		get flatStages(): readonly WorkflowStage[] {
			return self.stages.flatMap((stage: WorkflowRootStage) => {
				if (WorkflowParallelStageModel.is(stage)) {
					return [stage, ...stage.flatStages] as readonly WorkflowStage[];
				}
				return stage;
			});
		},
		get stageEvents(): ReadonlyArray<StageEvent> {
			// flatten events
			let events = this.flatStages.flatMap((stage: WorkflowStage) => {
				return stage.events;
			}) as Array<StageEvent>;

			if (events && events.length > 1) {
				// sort by most recent first
				events = events
					.slice()
					.sort(
						(a, b) =>
							getCreationDateOf(b).getTime() - getCreationDateOf(a).getTime()
					);
			}

			return events;
		},
		get activeStage(): WorkflowRootStage {
			if (self.status === WorkflowStatus.completed) {
				return self.initialStage;
			} else {
				const firstActiveStage = this.orderedStages.find(
					({ status }) =>
						status === StageStatus.active || status === StageStatus.roadblocked
				);
				return firstActiveStage ?? self.initialStage;
			}
		},
		stagePointingTo(targetStage: WorkflowRootStage): Maybe<WorkflowRootStage> {
			return self.rootStages.find((s) => s.nextStage === targetStage);
		},
		get allActiveStages(): Maybe<WorkflowStage>[] {
			return this.flatStages.filter((s) => s.status === StageStatus.active);
		},
		get roadblocks(): ReadonlyArray<Roadblock> {
			return self.rootStages.flatMap(
				({ roadblocks }: WorkflowStage) => roadblocks
			);
		},
		get inputSlots(): readonly WorkflowStageInputSlot[] {
			return this.flatStages.flatMap(
				(s) => s.inputSlots || ([] as WorkflowStageInputSlot[])
			);
		},
		get assetVersions(): readonly AssetVersion[] {
			return this.inputSlots.flatMap((slot) => slot.versions as AssetVersion[]);
		},
		loadAssetVersion(versionId: string): Maybe<AssetVersion> {
			return this.assetVersions.find((a) => a._id === versionId);
		},
	}))
	// status methods
	.views((self) => ({
		get canBeActivated(): boolean {
			return (
				self.status !== WorkflowStatus.completed &&
				self.status !== WorkflowStatus.pipeline &&
				self.status !== WorkflowStatus.active
			);
		},
		get canBeCancelled(): boolean {
			return (
				self.status !== WorkflowStatus.completed &&
				self.status !== WorkflowStatus.cancelled
			);
		},
		get canBePaused(): boolean {
			return (
				self.status !== WorkflowStatus.pipeline &&
				self.status !== WorkflowStatus.completed &&
				self.status !== WorkflowStatus.paused
			);
		},
		get completionStatus(): {
			totalCount: number;
			completedCount: number;
			percent: string;
		} {
			const allStages = self.flatStages;
			const completedStages = allStages.filter(
				({ status }) => status === StageStatus.completed
			);

			const stageLength = allStages.length;
			const numCompletedStages = completedStages.length;

			return {
				totalCount: stageLength,
				completedCount: numCompletedStages,
				percent: asPercent(numCompletedStages / stageLength, 0),
			};
		},
		get isPipeline(): boolean {
			return self.status === WorkflowStatus.pipeline;
		},
		get isActive(): boolean {
			return self.status === WorkflowStatus.active;
		},
		get isPaused(): boolean {
			return self.status === WorkflowStatus.paused;
		},
		get isCancelled(): boolean {
			return self.status === WorkflowStatus.cancelled;
		},
		get isCompleted(): boolean {
			return self.status === WorkflowStatus.completed;
		},
		get isRoadblocked(): boolean {
			return self.flatStages.some((s) => s.roadblocks?.length > 0);
		},
		get isOverdue(): boolean {
			return !!self.dueDate && self.dueDate <= new Date();
		},
	}))
	// user methods
	.views((self) => ({
		get hasAStageOwnedByCurrentUser(): boolean {
			return self.flatStages.some((s) => s.isOwnedByCurrentUser);
		},
		get involvedOwners() {
			return uniqBy(
				[...self.owners, ...self.rootStages.flatMap((s) => s.owners)],
				(o) => o.type + o._id
			);
		},
		get isCurrentUserInvolved(): boolean {
			return (
				self.isOwnedByCurrentUser ||
				self.isCreatedByCurrentUser ||
				this.hasAStageOwnedByCurrentUser
			);
		},
		get stagesOwnedByCurrentUser(): readonly WorkflowStage[] {
			return self.flatStages.filter((s) => s.isOwnedByCurrentUser);
		},
		includesMentionOf(value: string): boolean {
			return (
				includesCaseInsensitive(self.title, value) ||
				self.metadata.includesMentionOf(value) ||
				self.createdBy?.includesMentionOf(value) ||
				self.templateUsed.includesMentionOf(value) ||
				includesCaseInsensitive(self.status, value)
			);
		},
	}))
	.actions((self) => ({
		save: flow(function* save(workflowSnapshot: SnapshotOut<typeof self>) {
			const client = getClient(self);
			const updatedWorkflow = yield client.patch(
				`/workflows/${self._id}`,
				workflowSnapshot
			);

			applySnapshot(self, updatedWorkflow);
			return updatedWorkflow;
		}),
		updateStatus: flow(function* updateStatus(
			newStatus: WorkflowStatus,
			message: string
		) {
			const client = getClient(self);
			const workflowAfterAction = yield client.put(
				`/workflows/${self._id}/status`,
				{ status: newStatus, statusMsg: message }
			);
			applySnapshot(self, workflowAfterAction);
		}) as (newStatus: WorkflowStatus, msg: string) => Promise<void>,
		updateStatusMsg: flow(function* updateStatus(message: string) {
			const client = getClient(self);
			const workflowAfterAction = yield client.put(
				`/workflows/${self._id}/status`,
				{ statusMsg: message }
			);
			applySnapshot(self, workflowAfterAction);
		}) as (msg: string) => Promise<void>,
		updateCollection: flow(function* updateCollection(
			workflowCollection: string
		) {
			const client = getClient(self);
			const { workflow, collections } = yield client.put(
				`/workflows/${self._id}/collection/${workflowCollection}`
			);
			applySnapshot(self, workflow);
			getStores(self).workflowCollections.addMany(collections);
		}) as (id: string) => Promise<void>,
		removeFromCollection: flow(function* removeFromCollection() {
			const client = getClient(self);
			const { workflow, collection } = yield client.delete(
				`/workflows/${self._id}/collection`
			);
			applySnapshot(self, workflow);
			getStores(self).workflowCollections.addOne(collection);
		}) as () => Promise<void>,
	}));

export const WorkflowModel: WorkflowModel = WorkflowModelInferred;

export interface WorkflowModel extends Infer<typeof WorkflowModelInferred> {}

export interface Workflow extends Instance<WorkflowModel> {}

export interface WorkflowSnapshot extends SnapshotOut<WorkflowModel> {}

export interface WorkflowSnapshotIn extends SnapshotIn<WorkflowModel> {}

export interface HasWorkflow {
	workflow: Workflow;
}

export const WorkflowReference = lazyReference<Workflow>({
	model: WorkflowModel,
	getter(workflowId, parent) {
		const stores = getStores(parent);
		return stores.workflows.getOne(getId(workflowId));
	},
});

export const listActiveStageNames = (workflow: Workflow): string => {
	return workflow.allActiveStages.length
		? workflow.allActiveStages.map(getName).join(', ')
		: '—';
};

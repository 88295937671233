import { getParentOfType, Instance, types } from 'mobx-state-tree';
import {
	WorkflowRootStage,
	WorkflowRootStageModel,
	WorkflowStage,
	WorkflowStageModel,
} from './WorkflowStageModel';
import { InstanceReference } from '../../models/common';
import {
	BaseWorkflowTransitionModel,
	TransitionType,
} from '../../models/BaseWorkflowTransitionModel';
import {
	WorkflowSubstage,
	WorkflowSubstageModel,
} from './WorkflowSubstageModel';

const WorkflowTransitionBase = BaseWorkflowTransitionModel.named(
	'WorkflowTransition'
).views((self) => ({
	get sourceStage(): WorkflowStage {
		return getParentOfType(self, WorkflowStageModel);
	},
}));

const RootStageReference: InstanceReference<WorkflowRootStage> = types.late(
	(): InstanceReference<WorkflowRootStage> =>
		types.reference(WorkflowRootStageModel)
);
const SubstageReference: InstanceReference<WorkflowSubstage> = types.late(
	(): InstanceReference<WorkflowSubstage> =>
		types.reference(WorkflowSubstageModel)
);

const WorkflowBackwardRootTransitionModel = WorkflowTransitionBase.named(
	'BackwardRootTransition'
).props({
	type: types.literal(TransitionType.backward),
	targetStage: RootStageReference,
});

const WorkflowForwardRootTransitionModel = WorkflowTransitionBase.named(
	'ForwardRootTransition'
).props({
	type: types.literal(TransitionType.forward),
	targetStage: RootStageReference,
});

const WorkflowForwardSubstageTransitionModel = WorkflowTransitionBase.named(
	'ForwardSubstageTransition'
).props({
	type: types.literal(TransitionType.forward),
	targetStage: SubstageReference,
});

const WorkflowTransitionModelInferred = types.union(
	WorkflowBackwardRootTransitionModel,
	WorkflowForwardRootTransitionModel,
	WorkflowForwardSubstageTransitionModel
);

export interface BackwardRootTransition
	extends Instance<Infer<typeof WorkflowBackwardRootTransitionModel>> {}
export interface ForwardRootTransition
	extends Instance<Infer<typeof WorkflowForwardRootTransitionModel>> {}
export interface ForwardSubstageTransition
	extends Instance<Infer<typeof WorkflowForwardSubstageTransitionModel>> {}

export interface WorkflowTransitionModel
	extends Infer<typeof WorkflowTransitionModelInferred> {}
export const WorkflowTransitionModel: WorkflowTransitionModel = WorkflowTransitionModelInferred;

export type WorkflowTransition =
	| BackwardRootTransition
	| ForwardRootTransition
	| ForwardSubstageTransition;

import React from 'react';

import { _logError } from '../../../common/log';
import { StageStatus } from '../../../models/StageStatusModel';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';

import { WorkflowActionableStage } from '../../models/WorkflowStageModel';

import StageDialog from './stage-dialog.component';

interface Props {
	stage: WorkflowActionableStage;
	statusChange: StageStatus;
}

const ChangeStageStatusDialog = ({ statusChange, stage }: Props) => {
	const onSubmit = async (message: string) => {
		try {
			await stage.updateStatus(statusChange, message);
		} catch (error) {
			_logError(error);
			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text:
						'Unable to change the status, an issue occurred while saving. Please try again later.',
				})
			);
		}
	};

	return (
		<StageDialog
			header={`Changing status to ${statusChange}`}
			context="Please provide some context about the status change."
			successMessage="Status updated!"
			onClick={onSubmit}
		/>
	);
};

export default ChangeStageStatusDialog;

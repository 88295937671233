import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';
import React from 'react';

import styled from 'styled-components';
import anchor from '../theme/components/anchor';
import { fsNormal } from '../theme/ui/typography';

// styled components
const StyledLink = styled(Link)`
	${anchor}
	${fsNormal}

	&:hover {
		text-decoration: none;
	}

	.cta-label {
		line-height: 1;
		vertical-align: middle;
	}

	.cta-icon {
		margin-left: 8px;
		margin-top: 1px
		vertical-align: middle;
	}
`;

// interface
interface SmallCTALinkProps {
	link: string;
	label: string;
	additionalClasses?: string;
}

// component
const SmallCTALink = (props: SmallCTALinkProps) => {
	const buildClassName = () => {
		if (props.additionalClasses) {
			return `small-cta-link ${props.additionalClasses}`;
		} else {
			return 'small-cta-link';
		}
	};

	return (
		<StyledLink to={props.link} className={buildClassName()}>
			<span className="cta-label">{props.label}</span>
			<FontAwesomeIcon icon={faChevronRight} className="cta-icon" />
		</StyledLink>
	);
};

export default SmallCTALink;

import { values } from 'mobx';
import { getParentOfType, Instance, SnapshotIn, types } from 'mobx-state-tree';
import { AssetVersionModel } from '../dam-assets/models/AssetVersionModel';
import { getName } from '../common';
import themeStore from '../theme/models/ThemeStore';
import {
	Workflow,
	WorkflowModel,
	WorkflowStageInputSlotModel,
} from '../workflows/models';
import { BaseWorkflowOwnerModel } from './BaseWorkflowOwnerModel';
import { optionalString } from './common';
import { CreatableModel } from './CreatableEntityModel';
import { EventType } from './StageEventTypeModel';
import { StageStatus, StageStatusModel } from './StageStatusModel';

const StageEventBaseModel = CreatableModel.named('StageEvent')
	.props({
		message: optionalString,
	})
	.views((self) => ({
		get workflow(): Workflow {
			return getParentOfType(self, WorkflowModel);
		},
	}));

const DefaultEventModelInferred = StageEventBaseModel.named(
	'UnknownEvent'
).views((self) => ({
	get asString(): string {
		return `${self.createdBy.name} performed an action`;
	},
	get type(): string {
		return 'unknown';
	},
}));

interface DefaultEventModel extends Infer<typeof DefaultEventModelInferred> {}

const DefaultEventModel: DefaultEventModel = DefaultEventModelInferred;

export interface UnknownEvent extends Instance<DefaultEventModel> {}

const StatusUpdateModelInferred = StageEventBaseModel.named('StatusUpdateEvent')
	.props({
		type: types.literal(EventType.statusChange),
		oldStatus: StageStatusModel,
		newStatus: StageStatusModel,
	})
	.views((self) => ({
		get asString(): string {
			const getStageLabel = (status: StageStatus) => {
				return status === StageStatus.queue
					? themeStore._.queue.toLowerCase()
					: status;
			};

			return `changed the status from ${getStageLabel(
				self.oldStatus
			)} to ${getStageLabel(self.newStatus)}.`;
		},
	}));

interface StatusUpdateModel extends Infer<typeof StatusUpdateModelInferred> {}

const StatusUpdateModel: StatusUpdateModel = StatusUpdateModelInferred;

export interface StatusUpdateEvent extends Instance<StatusUpdateModel> {}

const CommentCreationModelInferred = StageEventBaseModel.named(
	'CommentCreationEvent'
)
	.props({
		type: types.literal(EventType.comment),
		message: optionalString,
	})
	.views(() => ({
		get asString(): string {
			return `added a comment:`;
		},
	}));

interface CommentCreationModel
	extends Infer<typeof CommentCreationModelInferred> {}

const CommentCreationModel: CommentCreationModel = CommentCreationModelInferred;

export interface CommentCreationEvent extends Instance<CommentCreationModel> {}

const AssetUploadModelInferred = StageEventBaseModel.named('AssetUploadEvent')
	.props({
		type: types.literal(EventType.assetUpload),
		slot: types.reference(types.late(() => WorkflowStageInputSlotModel)),
		version: types.reference(types.late(() => AssetVersionModel)),
		fileName: optionalString,
	})
	.views((self) => ({
		get asString(): string {
			return `uploaded an asset version for the slot "${self.slot.label}": `;
		},
	}));

interface AssetUploadModel extends Infer<typeof AssetUploadModelInferred> {}

const AssetUploadModel: AssetUploadModel = AssetUploadModelInferred;

export interface AssetUploadEvent extends Instance<AssetUploadModel> {}

const SetOwnerModelInferred = StageEventBaseModel.named('SetOwnerEvent')
	.props({
		type: types.literal(EventType.setOwner),
		added: types.array(BaseWorkflowOwnerModel),
		removed: types.array(BaseWorkflowOwnerModel),
	})
	.views((self) => ({
		ownerList(owners: Maybe<Nullable<Displayable>>[]) {
			return owners
				.map((user: Maybe<Nullable<Displayable>>) => getName(user))
				.join(', ')
				.replace(/, ([^,]*)$/, ' and $1');
		},
		get asString(): string {
			let addedMsg = '';
			let removedMsg = '';
			let connector = '';

			if (values(self.added).length) {
				addedMsg = `assigned stage to ${this.ownerList(self.added)}`;
			}

			if (values(self.removed).length) {
				removedMsg = `unassigned stage from ${this.ownerList(self.removed)}`;
			}

			if (values(self.added).length && values(self.removed).length) {
				connector = 'and';
			}

			return `${addedMsg} ${connector} ${removedMsg}`;
		},
	}));

interface SetOwnerModel extends Infer<typeof SetOwnerModelInferred> {}

const SetOwnerModel: SetOwnerModel = SetOwnerModelInferred;

export interface SetOwnerEvent extends Instance<AssetUploadModel> {}

const StageEventModelInferred = types.union(
	StatusUpdateModel,
	CommentCreationModel,
	AssetUploadModel,
	SetOwnerModel,
	DefaultEventModel
);

export interface StageEventModel
	extends Infer<typeof StageEventModelInferred> {}

export const StageEventModel: StageEventModel = StageEventModelInferred;

export type StageEvent =
	| StatusUpdateEvent
	| CommentCreationEvent
	| AssetUploadEvent
	| SetOwnerEvent
	| UnknownEvent;
export type StageEventSnapshot = SnapshotIn<
	AssetUploadModel | CommentCreationModel | StatusUpdateModel | SetOwnerModel
>;

export function isRoadblockUpdate(x: any): x is Instance<StatusUpdateModel> {
	return x.newStatus === StageStatus.roadblocked;
}

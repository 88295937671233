import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { includesCaseInsensitive, matcherForKeys } from '../../common';
import {
	emailString,
	nonEmptyString,
	optionalString,
	tolerantDate,
} from '../../models/common';
import defaultAvatar from '../../assets/images/avatars/default-avatar.png';

import { AccountModel } from './AccountModel';
import { UserGroup } from './UserGroupModel';
import { UserRole, userRolesModel } from './UserRole.model';
import { UserStatus, userStatusModel } from './UserStatus.model';

const searchUser = matcherForKeys<User>('givenName', 'familyName', 'email');

const UserModelInferred = types
	.model('User', {
		_id: types.identifier,
		email: types.maybe(emailString),

		givenName: nonEmptyString,
		familyName: nonEmptyString,

		picture: optionalString,

		expertise: optionalString,
		status: types.optional(userStatusModel, UserStatus.active),
		role: types.optional(userRolesModel, UserRole.User),
		location: optionalString,

		account: types.maybe(types.reference(AccountModel)),
		createdAt: types.optional(tolerantDate, () => new Date()),
	})
	.views((self) => ({
		get name(): string {
			return `${self.givenName} ${self.familyName}`;
		},
		get avatar(): string {
			return self.picture ?? defaultAvatar;
		},
		get isAdmin(): boolean {
			return self.role === UserRole.SuperAdmin;
		},
		includesMentionOf(value: string): boolean {
			return searchUser(self as User, value);
		},
	}));

export interface UserModel extends Infer<typeof UserModelInferred> {}

export const UserModel: UserModel = UserModelInferred;

export interface User extends Instance<UserModel> {}

export interface UserSnapshotIn extends SnapshotIn<UserModel> {}
export interface UserSnapshotOut extends SnapshotOut<UserModel> {}

export const userName = (user: User | undefined | null) => user?.name ?? '';

export const doesUserMatch = (input: string | null, u: User) =>
	includesCaseInsensitive(userName(u), input);
export const doesUserGroupMatch = (input: string | null, u: UserGroup) =>
	includesCaseInsensitive(u?.title, input);

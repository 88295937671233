import { navigate } from '@reach/router';
import { getSnapshot } from 'mobx-state-tree';
import React, { FormEvent, useCallback, useRef } from 'react';
import NotificationModel from '../../notifications/NotificationModel';
import notificationStore from '../../notifications/NotificationStore';
import { useMetadataTemplateStore, useStores } from '../../stores';
import { emptyMetadataTemplate } from '../models/EntityMetadataTemplateStore';
import EntityMetadataTemplateEditForm from './entity-metadata-template-edit-form.component';

const EntityMetadataTemplateAddForm = () => {
	const metadataTemplateStore = useMetadataTemplateStore();

	const metadataTemplateRef = useRef(emptyMetadataTemplate(useStores()));

	const addMetadataTemplate = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const createdMetadataTemplate = await metadataTemplateStore.createOne(
				getSnapshot(metadataTemplateRef.current)
			);

			notificationStore.push(
				NotificationModel.create({
					text: `${createdMetadataTemplate.title} saved!`,
				})
			);

			return navigate(
				`/admin/dashboard/metadata-templates/${createdMetadataTemplate._id}`
			);
		},
		[metadataTemplateStore]
	);

	return (
		<EntityMetadataTemplateEditForm
			selectedMetadataTemplate={metadataTemplateRef.current}
			onSubmit={addMetadataTemplate}
			displayTemplateField={false}
		/>
	);
};

export default EntityMetadataTemplateAddForm;

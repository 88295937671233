import { RouteComponentProps } from '@reach/router';
import { Col, Container, Row } from 'reactstrap';
import { useObserver } from 'mobx-react';
import React from 'react';
import { Heading } from '../components/ui';
import { StyledLabel } from '../components/forms/labeled-input.styled-components';
import { useAssetStore } from '../stores';
import BackLink from '../components/back-link/back-link.component';
import themeStore from '../theme/models/ThemeStore';

import { HasDamAsset } from './models/DamAssetModel';
import EditAssetForm from './components/edit-asset-form.component';
import LoadingAssetState from './components/loading-asset-state.component';

const capitalizedAssetTerm = themeStore._.asset;

const DamAssetEditor = ({ asset }: HasDamAsset) => (
	<Row>
		<Col xs={12} className="d-flex justify-content-between">
			<div>
				<BackLink
					link={`/admin/dam/assets/${asset._id}`}
					title={`${capitalizedAssetTerm} Details`}
				/>
				<Heading>{asset.title ? asset.title : asset.fileName}</Heading>
			</div>
		</Col>
		<Col md={8}>
			<EditAssetForm asset={asset} />
		</Col>
		<Col md={4}>
			{asset.previewURL ? (
				<>
					<StyledLabel>{capitalizedAssetTerm} Preview</StyledLabel>
					<img className="img-fluid" src={asset.previewURL} alt={asset.title} />
				</>
			) : null}
		</Col>
	</Row>
);

const EditDAMAssetView = (props: RouteComponentProps<{ assetId: string }>) => {
	const assetStore = useAssetStore();

	const render = () => {
		const damAsset = assetStore.findOne(props.assetId);
		return damAsset ? (
			<DamAssetEditor asset={damAsset} />
		) : (
			<LoadingAssetState />
		);
	};

	return <Container>{useObserver(render)}</Container>;
};

export default EditDAMAssetView;

import { Col } from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';
import { fsSmol } from '../../theme/ui/typography';

import { CardContainerStyles } from './stage-cards/template-stage-card.styles';

const { colors } = themeStore.selectedTheme;

// styled components
export const TemplateHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 40px;

	@media only screen and (min-width: 768px) {
		margin: 0 15%;
	}
`;

export const Sidebar = styled(Col)`
	position: relative;
	height: 100%;
	margin-bottom: 5em;

	@media only screen and (min-width: 768px) {
		&& {
			position: absolute;
		}
		bottom: 0;
		right: 0;
		top: 106px;
	}
`;

export const StageWrapper = styled.div`
	padding: 3em 3em 4em;
`;

export const CanvasWrapper = styled.div`
	display: inline-block;
	position: relative;
	background-color: ${colors.lightGrey};
	margin-bottom: 0;
	margin-top: 2em;
	max-height: 700px;
	min-height: 700px;
	overflow: scroll;
	width: 100%;

	@media only screen and (min-width: 768px) {
		margin-bottom: -5.5em;
	}

	.justify-content-center {
		margin-left: unset;
		margin-right: unset;
	}
`;

export const CanvasContent = styled.div`
	display: inline-block;
	position: absolute;
	top: 0;
	cursor: default;
	padding-bottom: 10em;

	padding-left: 0;
	padding-right: 0;
	max-width: unset;

	&.draggable {
		cursor: grab;

		&.dragging {
			cursor: grabbing;
		}
	}

	${CardContainerStyles};
`;

export const LastActionLabel = styled.p`
	${fsSmol}
`;

import { getParentOfType, Instance, types } from 'mobx-state-tree';
import { StageType } from '../../workflow-templates/models';
import {
	WorkflowParallelStage,
	WorkflowParallelStageModel,
	WorkflowRootStage,
} from './WorkflowStageModel';
import { WorkflowActionableStageCommonModel } from './WorkflowActionableStageModel';
import { ForwardSubstageTransition } from './WorkflowTransitionModel';
import { isForward } from '../../models/BaseWorkflowTransitionModel';
import { isFirstInArray } from '../../common/mobx.utils';

const WorkflowSubstageModelInferred = WorkflowActionableStageCommonModel.named(
	'WorkflowSubstage'
)
	.props({
		type: types.literal(StageType.substage),
	})
	.views((self) => ({
		get forwardTransition(): Maybe<ForwardSubstageTransition> {
			return self.transitions.find(isForward) as Maybe<
				ForwardSubstageTransition
			>;
		},
		get parentStage(): WorkflowParallelStage {
			return getParentOfType(self, WorkflowParallelStageModel);
		},
		get nextStage(): Maybe<WorkflowRootStage> {
			return this.parentStage.nextStage;
		},
		get nextSubstage(): Maybe<WorkflowSubstage> {
			return this.forwardTransition?.targetStage;
		},
		get isGroupStart() {
			return isFirstInArray(self);
		},
	}));

export interface WorkflowSubstageModel
	extends Infer<typeof WorkflowSubstageModelInferred> {}
export const WorkflowSubstageModel: WorkflowSubstageModel = WorkflowSubstageModelInferred;

export interface WorkflowSubstage extends Instance<WorkflowSubstageModel> {}
